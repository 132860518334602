import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//Component
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Action

//Constants

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

// Styles
import "./AnalystApproval.scss";

// Assests

class OperationsOfficersInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errors: { orderId: false },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  handleInputChange = (event) => {
    let value = event.target.value;
    this.setState(
      {
        errors: {
          ...this.state.errors,
          [event.target.name]: false,
        },
        errorMessage: "",
      },
      () => {
        this.props.handleInputChange(value.trim().replace(" ", ""));
      }
    );
  };

  submit = () => {
    if (this.props.orderId === "") {
      this.setState({
        errors: { ...this.state.errors, orderId: true },
      });
    } else if (Utility.containsSpecialChars(this.props.orderId)) {
      this.setState({
        errors: { ...this.state.errors, orderId: true },
        errorMessage: Strings("specialCharError"),
      });
    } else {
      this.setState(
        {
          errorMessage: "",
        },
        () => {
          this.props.updateOfficerInput({
            oid: this.props.orderId,
            appId: this.props.applicationId,
          });
        }
      );
    }
  };

  render() {
    return (
      <div className='analystInput'>
        <div className='errors mb-0'>
          {this.state.errorMessage
            ? this.state.errorMessage
            : ""}
        </div>
        {this.props.isFileUpload
          ? <Row>
            <Col md={2} lg={2} className='px-0'>
              <TextFieldComponent
                required
                name={"transferReceipt"}
                className={
                  this.state.errors.fileInput ? "errorField" : null
                }
                id='transferReceipt'
                type='text'
                placeholderTop={Strings("transferReceipt")}
                value={this.props?.transferReceipt?.name ? this.props?.transferReceipt?.name : Strings("transferReceipt")}
                disabled={true}
              />
            </Col>
            <Col md={1} className='pt-4'>
              <Button
                className='buttonBackground'
                onClick={() => this.refs.fileInput.click()}
                disabled={this.props.isHistory}
              >
                {Strings("Browse")}
                <input
                  ref="fileInput"
                  type="file"
                  className="collapseButton"
                  onChange={this.props.onFileChange}
                />
              </Button>
            </Col>
            <Col md={1.5} className='pt-4 ps-3 pe-3 w-auto'>
              <Button
                className='buttonBackground'
                onClick={this.props.uploadTransferReciept}
                disabled={this.props.isHistory}
              >
                {Strings("Upload")}
              </Button>
            </Col>
            {this.props.uploadedTransferReciept && <Col md={1} className='pt-4'>
              <Button
                className='buttonBackground'
                onClick={this.props.viewReceipt}
                disabled={this.props.isHistory}
              >
                {Strings("View")}
              </Button>
            </Col>}
          </Row>
          : <Row>
            <Col md={2} lg={2} className='px-0'>
              <TextFieldComponent
                required
                name={"orderId"}
                className={
                  this.state.errors.orderId ? "errorField" : null
                }
                id='orderId'
                type='text'
                placeholderTop={Strings("orderId")}
                handleChange={this.handleInputChange}
                value={this.props.orderId}
                maxLength={45}
                disabled={this.props.isHistory}
              />
            </Col>
            <Col md={2} className='pt-4'>
              <Button
                className='buttonBackground'
                onClick={this.submit}
                disabled={this.props.isHistory}
              >
                {Strings("Add")}
              </Button>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default OperationsOfficersInput;
