import React from "react";
import { Row, Col } from "react-bootstrap";
import { Strings } from "../../../resources/i18n/i18n";
import ProgressBar from "react-bootstrap/ProgressBar";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PieChartComponent from "../PieChartComponent/PieChartComponent";
import {
  PIE_CHART_COLORS,
  MINIMART_GRADE_LEGEND,
  DASHBOARD_MINIMART_TYPE_LEGEND,
  USER_ROLES,
  USER_RATING_TABS,
  MINIMART_RATING,
  PIE_CHART_COLORS_CONST,
  USER_TYPE,
  MINIMART_BUSINESS_TYPE_LEGEND,
} from "../../../constants/appConstants";
import { GRADE_TYPE } from "./Constants";
import Utility from "../../../utils/Utility";
import "./MiniMartAndUser.scss";

class MiniMartAndUser extends React.Component {
  renderLegend = (color, grade, data, isType = false) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return (
      <Row className='legend' key={Math.random()}>
        <div
          className='ps-0 legendCommissionColorDiv'
          style={colorStyle}
        />
        <Col md={isType ? 6 : 3} className='pe-0 ps-1'>
          <div
            className='branchNameInLegends textWrap'
            title={
              grade === GRADE_TYPE
                ? Strings("UpForRenewal")
                : Strings(grade)
            }
          >
            {grade === GRADE_TYPE
              ? Strings("UpForRenewal")
              : Strings(grade)}
          </div>
        </Col>
        <Col md={4} className='px-0'>
          <div className='branchNameInLegends fw-bold w-100'>
            {data.count}
          </div>
        </Col>
      </Row>
    );
  };

  renderBusinessTypeLegend = (
    color,
    legends,
    data,
    index,
    legendTextSize = 6
  ) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return (
      <Col
        md={legendTextSize}
        className={`noPadding ${index % 2 === 0 ? "" : "ps-2"}`}
        key={Math.random()}
      >
        <Row className='legend'>
          <div
            className='noPadding legendCommissionColorDiv'
            style={colorStyle}
          />
          <Col md={8} className='ps-1 pe-0 alignLegendCenter'>
            <div
              className='branchNameInLegends'
              title={Strings(legends)}
            >
              {Strings(legends)}
            </div>
          </Col>
          <Col className='alignLegendCenter px-0'>
            <div className='branchNameInLegends fw-bold'>
              {data ? data.count : Strings("NA")}
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  renderProgressBars = (
    persons,
    totalPersons,
    id,
    key,
    index,
    isMinimartUserRating = false
  ) => {
    return persons && persons.length > 0 ? (
      persons.map((Person) => {
        return (
          <div className='parentDiv' key={Math.random()}>
            <div
              className={
                isMinimartUserRating
                  ? "inlineBlock BoldText"
                  : "text1 BoldText"
              }
            >
              <label className=' noMargin paddingRight fixedWidth'>
                {isMinimartUserRating
                  ? MINIMART_RATING[Person.rating]
                  : Person.rating}
              </label>
              {isMinimartUserRating ? null : (
                <Rater
                  id={id}
                  title={Person.rating}
                  rating={1}
                  total={1}
                  interactive={false}
                  style={{
                    fontSize: 15 + "px",
                    color: PIE_CHART_COLORS[key][index],
                  }}
                />
              )}
            </div>
            <div
              className={
                this.props.loggedInUser.role !== USER_ROLES.admin &&
                  this.props.loggedInUser.role !==
                  USER_ROLES.generalManager &&
                  this.props.loggedInUser.role !== USER_ROLES.technology
                  ? "progressForCollection w-75"
                  : "progressBar w-75"
              }
            >
              <ProgressBar
                now={(Person.count / totalPersons) * 100}
                variant={"success"}
              />
            </div>
            <div className='text1 BoldText'>
              {Utility.getCurrencyRepresentationOfAmount(
                Person.count
              )}
            </div>
          </div>
        );
      })
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderPieChartComponent = (data, colors, total) => {
    return data.length > 0 ? (
      <PieChartComponent
        data={data}
        colors={colors}
        index={0}
        datakey='count'
        height={150}
        showLabel={false}
        total={Utility.getCurrencyRepresentationOfAmount(total)}
      />
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderBusinessTypePieChart = (
    colSize,
    pieChartSize,
    legedSize,
    legendTextSize
  ) => {
    return (
      <Col md={colSize} className='noPadding'>
        <label className='noMargin BoldText ps-3'>
          {Strings("BusinessType")}
        </label>

        <Row>
          <Col md={pieChartSize} className='noPadding '>
            {this.renderPieChartComponent(
              this.props.data.businessTypes,
              "businessType",
              this.props.data.totalMinimarts
            )}
          </Col>
          <Col
            md={legedSize}
            className='alignLegends align-self-center px-0 scrollablePieChart'
          >
            {" "}
            <Row>
              {MINIMART_BUSINESS_TYPE_LEGEND.map((legend, index) => {
                let findIndex =
                  this.props.data.businessTypes.findIndex(
                    (business) => legend.key === business.type
                  );
                return this.renderBusinessTypeLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.MINIMART_BUSINESS_TYPE
                  ][index],
                  legend.key,
                  this.props.data.businessTypes[findIndex],
                  index,
                  legendTextSize
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  renderPieCharts = () => {
    let categoryLegends = MINIMART_GRADE_LEGEND;
    let typeLegends = DASHBOARD_MINIMART_TYPE_LEGEND;
    return (
      <>
        {!this.props.isProspecto && (
          <>
            <Col md={3} className='p-0'>
              <label className='m-0 BoldText ps-3'>
                {Strings("minimartCategories")}
              </label>
  
              <Row>
                <Col md={5} className='p-0'>
                  {this.renderPieChartComponent(
                    this.props.data.minimartCategories,
                    "minimartCategories",
                    this.props.data.totalMinimarts
                  )}
                </Col>
                <Col
                  md={7}
                  className='align-self-center px-0 alignLegends'
                >
                  {categoryLegends.map((legend, index) => {
                    return this.renderLegend(
                      PIE_CHART_COLORS[
                        PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES
                      ][index],
                      legend,
                      this.props.data.minimartCategories[index]
                    );
                  })}
                </Col>
              </Row>
            </Col>
            <Col md={3} className='p-0'>
              <label className='m-0 BoldText ps-3'>
                {Strings("minimartTypes")}
              </label>
  
              <Row>
                <Col md={5} className='p-0'>
                  {this.renderPieChartComponent(
                    this.props.data.minimartTypes,
                    "minimartTypes",
                    this.props.data.totalMinimarts
                  )}
                </Col>
                <Col
                  md={7}
                  className=' align-self-center px-0'
                >
                  {typeLegends.map((legend, index) => {
                    return this.renderLegend(
                      PIE_CHART_COLORS[
                        PIE_CHART_COLORS_CONST.MINIMART_TYPES
                      ][index],
                      legend.key,
                      this.props.data.minimartTypes[index],
                      true
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </>
        )}
  
        {this.renderBusinessTypePieChart(6, 3, 9, 6)}
      </>
    );
  };

  renderAdminAndGeneralMgr = () => {
    return (
      <>
        <Row>
          <Col md={this.props.isProspecto ? 6 : 8}>
            <span className='BoldText'>
              {Strings("SALES_PERSON") + " " + Strings("totalUsers")}
              &nbsp;:&nbsp;
            </span>{" "}
            <label className='BoldText mb-0'>
              {Utility.getCurrencyRepresentationOfAmount(
                this.props.data.totalSalesPersons
              )}
            </label>
          </Col>
          <Col md={this.props.isProspecto ? 6 : 4}>
            <span className='BoldText'>
              {Strings("MESSENGER") + " " + Strings("totalUsers")}
              &nbsp;:&nbsp;
            </span>{" "}
            <label className='BoldText mb-0'>
              {Utility.getCurrencyRepresentationOfAmount(
                this.props.data.totalMessengers
              )}
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className='titleText'>{Strings("userRating")}</div>
            {this.renderProgressBars(
              this.props.data.salesPersons,
              this.props.data.totalSalesPersons,
              USER_TYPE.SALES_PERSON,
              PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
              0
            )}
          </Col>
          {!this.props.isProspecto && (
            <Col
              md={4}
              className={
                this.props.loggedInUser.role === USER_ROLES.admin ||
                  this.props.loggedInUser.role ===
                  USER_ROLES.generalManager
                  || this.props.loggedInUser.role === USER_ROLES.technology
                  ? "borderRight"
                  : ""
              }
            >
              {" "}
              <div className='titleText'>
                {Strings("minimartUserRating")}
              </div>
              {this.renderProgressBars(
                this.props.data.salesMinimartRating,
                this.props.data.totalSalesPersons,
                USER_TYPE.SALES_PERSON,
                PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
                3,
                true
              )}
            </Col>
          )}
          <Col
            md={
              this.props.isProspecto
                ? { span: 4, offset: 2 }
                : { span: 4 }
            }
          >
            <div className='titleText'>
              <label className='noMargin'>
                {Strings("userRating")}
              </label>
            </div>
            <div>
              {this.renderProgressBars(
                this.props.data.messengers,
                this.props.data.totalMessengers,
                USER_TYPE.MESSENGER,
                PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
                3
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderSalesModerator = () => {
    return (
      <>
        <Row>
          <Col md={8}>
            <span className='BoldText'>
              {Strings("SALES_PERSON") + Strings("totalUsers")}
              &nbsp;:&nbsp;
            </span>{" "}
            <label className='BoldText mb-0'>
              {Utility.getCurrencyRepresentationOfAmount(
                this.props.data.totalSalesPersons
              )}
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={4} style={{ alignItems: "center" }}>
            <div className='titleText'>{Strings("userRating")}</div>
            {this.renderProgressBars(
              this.props.data.salesPersons,
              this.props.data.totalSalesPersons,
              USER_TYPE.SALES_PERSON,
              PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
              0
            )}
          </Col>
          <Col md={4} style={{ alignItems: "center" }}>
            <div className='titleText'>
              {Strings("minimartUserRating")}
            </div>
            {this.renderProgressBars(
              this.props.data.salesMinimartRating,
              this.props.data.totalSalesPersons,
              USER_TYPE.SALES_PERSON,
              PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
              0,
              true
            )}
          </Col>
        </Row>
      </>
    );
  };

  renderMessengerModerator = () => {
    return (
      <Col md={4}>
        <Row>
          <span className='BoldText'>
            {Strings("MESSENGER") + " " + Strings("totalUsers")}
            &nbsp;:&nbsp;
          </span>{" "}
          <label className='BoldText mb-0'>
            {Utility.getCurrencyRepresentationOfAmount(
              this.props.data.totalMessengers
            )}
          </label>
        </Row>
        <div className='BoldText'>
          {Strings("Messengers")}&nbsp;&nbsp;
        </div>
        {this.renderProgressBars(
          this.props.data.messengers,
          this.props.data.totalMessengers,
          USER_TYPE.MESSENGER,
          PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
          3
        )}
      </Col>
    );
  };

  renderCollectionModerator = () => {
    return (
      <Col md={4}>
        <Row>
          <span className='BoldText'>
            {Strings("COLLECTIONS_OFFICER") +
              " " +
              Strings("totalUsers")}
            &nbsp;:&nbsp;
          </span>{" "}
          <label className='BoldText mb-0'>
            {Utility.getCurrencyRepresentationOfAmount(
              this.props.data.totalCollectionsOfficers
            )}
          </label>
        </Row>
        <div className='BoldText'>
          {Strings("COLLECTIONS_OFFICER")}&nbsp;&nbsp;
        </div>
        {this.renderProgressBars(
          this.props.data.collectionsOfficers,
          this.props.data.totalCollectionsOfficers,
          USER_TYPE.MESSENGER,
          PIE_CHART_COLORS_CONST.MINIMART_CATEGORIES,
          3
        )}
      </Col>
    );
  };

  render() {
    let selectedTabClassName;
    if (this.props.selectedRatingTab.key === 0)
      selectedTabClassName = "leftCornerRadius";
    else if (this.props.selectedRatingTab.key === 1)
      selectedTabClassName = "rightCornerRadius";
    else selectedTabClassName = "cenderCornerRadius";
    let renderProgressBar = this.renderAdminAndGeneralMgr;
    if (this.props.loggedInUser.role === USER_ROLES.salesModerator ||
      this.props.loggedInUser.role === USER_ROLES.salesManager) {
      renderProgressBar = this.renderSalesModerator;
    } else if (
      this.props.loggedInUser.role === USER_ROLES.messengerModerator
    ) {
      renderProgressBar = this.renderMessengerModerator;
    } else if (
      this.props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
      this.props.loggedInUser.role === USER_ROLES.administrativeManager ||
      this.props.loggedInUser.role === USER_ROLES.collectionsManager
    ) {
      renderProgressBar = this.renderCollectionModerator;
    }
    return (
      <div className='miniMartAndUser'>
        {this.props.data && (
          <Row className='pb-2'>
            {this.props.isProspecto ? (
              this.renderBusinessTypePieChart(12, 2, 10, 3)
            ) : (
              <Col md={12} className='p-0'>
                <Tabs
                  selectedIndex={this.props.selectedRatingTab.key}
                  onSelect={this.props.handleRatingTabs}
                  selectedTabClassName={selectedTabClassName}
                >
                  <TabList>
                    {USER_RATING_TABS.map((item) => (
                      <Tab key={item.key}>{Strings(item.value)}</Tab>
                    ))}
                  </TabList>

                  <TabPanel>
                    <Row className='p-0'>
                      {this.renderPieCharts()}
                    </Row>
                  </TabPanel>
                  <TabPanel>{renderProgressBar()}</TabPanel>
                </Tabs>
              </Col>
            )}
          </Row>
        )}
      </div>
    );
  }
}

export default MiniMartAndUser;
