import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Constants
import { SUPPLIER_STATUS } from "../BankConf/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Css
import "../RewardCatalog/RewardCatalog.scss";

//Assets
import cameraImage from "../../resources/images/ic_update_profile_pic.svg";
import cross from "../../resources/images/ic_cross_blue.svg";
import defaultImg from "../../resources/images/ic_moto.png";
import MultipleSelectCheckmarks from "../CustomUIComponents/MultiSelectDropDown/MultipleSelectCheckmarks";

const renderSupplierPhoto = (props) => {
    if (props.readOnly) {
        return props.supplier.img !== undefined ? (
            <img
                src={Utility.getDecryptedData(props.supplier?.img)}
                alt={`${Strings("Image")}`}
                className='motorcyclePhoto backdrop'
            />
        ) : (
            <div className='backdrop'>
                <img
                    src={defaultImg}
                    alt={Strings("NoPhoto")}
                    className='defaultImg'
                />
            </div>
        );
    } else {
        return props.supplier?.img ? (
            <div className='addOrUpdateImageDisplay'>
                <img
                    src={
                        Object.prototype.toString.call(props.supplier?.img) === //NOSONAR
                            "[object File]"
                            ? props.avatar
                            : Utility.getDecryptedData(props.supplier?.img)
                    }
                    className='img-thumbnail w-100 h-100'
                    alt=''
                />
                <img
                    src={cross}
                    className='crossIcon'
                    onClick={props.handleRemoveImage}
                    alt=''
                />
            </div>
        ) : (
            <div className='addOrUpdateImage backdrop'>
                <label htmlFor='uploadPhoto' className='browse-button '>
                    <img
                        src={cameraImage}
                        className={"addOrUpdateImageCamera"}
                        alt={Strings("UploadPhoto")}
                    />
                </label>
                <input
                    type='file'
                    id='uploadPhoto'
                    onChange={props.handleUploadPhoto}
                    accept='image/png,image/jpeg,image/jpg'
                    style={{ display: "none" }}
                />
            </div>
        );
    }
};

const SupplierCatalogModal = (props) => {
    let activeAccountTypes = [...props.activeAccountTypes];
    let bankDetails = [];
    if (props.supplier.bankName && props.supplier.bankName !== Strings("bankNames")) {
        bankDetails = props.bankDetails?.filter(
            (bankDetail) => props.supplier?.bankName === bankDetail.bn
        )
        activeAccountTypes = bankDetails[0]?.ad;
    }
    if (activeAccountTypes?.length !== 0 && activeAccountTypes[0].at !== Strings("accountTypes")) {
        activeAccountTypes.unshift({ ai: "", at: Strings("accountTypes") })
    }
    let bankNames = props.bankDetails?.map((bankDetail) => bankDetail.bn);
    if (bankNames.length !== 0 && !bankNames.includes("bankNames")) {
        bankNames.unshift(Strings("bankNames"))
    }
    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.handleModalClose()}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            center
            classNames={{ modal: "motorcycleDetailModal" }}
            autofocus
        >
            <Row className='px-0'>
                <div className='mx-0 motorcycleDetailModalHeader'>
                    {Strings(props.headerText)}
                </div>
            </Row>
            {props.errorMessage && (
                <Row className='errorText pt-2'>{props.errorMessage}</Row>
            )}
            <form>
                <Col md={12} className="ps-3 pe-3">
                    <Row className="pt-2 warningText">
                        {props.supplier.isVerified === false && Strings("RNC_Unverified_Warning")}
                    </Row>
                    <Row>
                        <TextFieldComponent
                            id='cost'
                            type='text'
                            className={props.readOnly ? "disableText" : "mt-2"}
                            placeholder={`${Strings("businessName")}*`}
                            value={props.supplier?.businessName}
                            handleChange={props.handleInputChnage}
                            disabled={props.readOnly}
                            name={"businessName"}
                            maxLength={45}
                        />
                    </Row>
                    <Row>
                        <TextFieldComponent
                            id='name'
                            type='text'
                            className={props.readOnly ? "disableText" : "mt-3"}
                            placeholder={`${Strings("RNC")}*`}
                            value={props.supplier?.rnc}
                            handleChange={props.handleInputChnage}
                            maxLength={45}
                            disabled={props.readOnly}
                            name={"rnc"}
                        />
                    </Row>
                    <Row>
                        <TextFieldComponent
                            id='cost'
                            type='text'
                            className={props.readOnly ? "disableText" : "mt-3"}
                            placeholder={`${Strings("PhoneNumber")}*`}
                            value={props.supplier?.phoneNumber}
                            handleChange={props.handleInputChnage}
                            disabled={props.readOnly}
                            name={"phoneNumber"}
                            maxLength={10}
                            onKeyDown={Utility.allowOnlyNumber}
                        />
                    </Row>
                    <Row>
                        <TextFieldComponent
                            id='cost'
                            type='text'
                            className={props.readOnly ? "disableText" : "mt-3"}
                            placeholder={`${Strings("contact")}*`}
                            value={props.supplier?.contact}
                            handleChange={props.handleInputChnage}
                            disabled={props.readOnly}
                            name={"contact"}
                            maxLength={200}
                        />
                    </Row>
                    <Row className="mt-2">
                        <MultipleSelectCheckmarks
                            className='w-100 p-0'
                            selectedValues={props.supplier?.branchIds || []}
                            itemList={props.branches}
                            handleChange={props.handleInputChnage}
                            multiple={true}
                            disabled={props.readOnly}
                            placeHolder={"selectBranch"}
                            tag={"branch"}
                            name={"branchIds"}
                        />
                    </Row>
                </Col>
                <Row>
                    <Col md={6}>
                        <Row className='m-2 pe-2'>{renderSupplierPhoto(props)}</Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <TextFieldComponent
                                id='model'
                                type='select'
                                className={props.readOnly ? "disableText" : "mt-3"}
                                placeholder={`${Strings("bankName")}*`}
                                value={props.supplier?.bankName}
                                handleChange={props.handleInputChnage}
                                disabled={props.readOnly}
                                name={"bankName"}
                                maxLength={45}

                            >
                                {bankNames?.map((bankName) => {
                                    return (
                                        <option
                                            value={bankName}
                                            key={bankName}
                                        >
                                            {Strings(bankName)}
                                        </option>
                                    );
                                })}
                            </TextFieldComponent>
                        </Row>
                        <Row>
                            <TextFieldComponent
                                id='category'
                                type={"select"}
                                className={props.readOnly ? "disableText" : "mt-3"}
                                placeholder={`${Strings("accountType")}`}
                                value={props.supplier?.accountType}
                                handleChange={props.handleInputChnage}
                                disabled={props.readOnly}
                                name={"accountType"}
                            >
                                {activeAccountTypes?.map((accountType) => {
                                    return (
                                        <option
                                            value={accountType.at}
                                            key={accountType.ai}
                                        >
                                            {accountType.at}
                                        </option>
                                    );
                                })}
                            </TextFieldComponent>
                        </Row>
                        <Row>
                            <TextFieldComponent
                                id='model'
                                type='text'
                                className={props.readOnly ? "disableText" : "mt-3"}
                                placeholder={`${Strings("accountNumber")}*`}
                                value={props.supplier?.accountNumber}
                                handleChange={props.handleInputChnage}
                                maxLength={30}
                                disabled={props.readOnly}
                                name={"accountNumber"}
                                onKeyDown={Utility.allowOnlyNumber}
                            />
                        </Row>

                        <Row>
                            <TextFieldComponent
                                id='status'
                                type={"select"}
                                className={"mt-3"}
                                placeholder={`${Strings("Status")}`}
                                value={props.supplier.isActive}
                                handleChange={props.handleInputChnage}
                                name={"isActive"}
                                disabled={props.readOnly}
                            >
                                {SUPPLIER_STATUS.map((status) => {
                                    return (
                                        <option
                                            value={status.value}
                                            key={status.value}
                                        >
                                            {status.label}
                                        </option>
                                    );
                                })}
                            </TextFieldComponent>
                        </Row>
                    </Col>
                </Row>
                <Col md={12} className='buttonAlign pe-2'>
                    <Button
                        className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
                        onClick={props.handleModalClose}
                    >
                        {Strings("Cancel")}
                    </Button>

                    {!props.readOnly && (
                        <Button
                            className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                            onClick={props.onSaveClick}
                        >
                            {Strings("Save")}
                        </Button>
                    )}
                </Col>
            </form>
        </Modal>
    );
};

export default SupplierCatalogModal;
