import React from "react";
import { Col } from "react-bootstrap";
import Datetime from "react-datetime";
import "moment/locale/es";
import "moment/locale/en-gb";

// Styles
import "react-datetime/css/react-datetime.css";
import "./MonthYearPicker.scss";

// Utils
import Utility from "../../../utils/Utility";
import { APP_LANGUAGES } from "../../../constants/appConstants";

class MonthYearPicker extends React.Component {
  renderInput = (props, openCalendar, closeCalendar) => {
    //NOSONAR
    let get;
    if (this.props.countOfMonths === 2) {
      let monthYearArray = props.value.split("-");
      get = Utility.getPreviousMonthYear(
        new Date(monthYearArray[1], monthYearArray[0], 1, 0, 0, 0, 0),
        12
      );
    }
    let selectedMonthYearArray = this.props.value.split("-");
    return (
      <Col className='monthYearSelection'>
        <span className='monthYearSelectionSection'>
          {this.props.countOfMonths === 2 ? (
            <span>
              {Utility.getLocalizationIdForMonth(
                Utility.getIntegerValue(get.previousMonth)
              )}
              {" " + get.previousYear + " to "}
            </span>
          ) : (
            <span />
          )}
          <span>
            {Utility.getLocalizationIdForMonth(
              Utility.getIntegerValue(selectedMonthYearArray[0])
            )}
            {" " + selectedMonthYearArray[1]}
          </span>
        </span>
        <i
          className='fa fa-calendar fa-lg '
          aria-hidden='true'
          onClick={openCalendar}
        />
      </Col>
    );
  };
  renderYearInput = (props, openCalendar, closeCalendar) => {
    //NOSONAR
    return (
      <Col className='monthYearSelection'>
        <span className='monthYearSelectionSection'>
          <span>
            {this.props.value}
          </span>
        </span>
        <i
          className='fa fa-calendar fa-lg'
          aria-hidden='true'
          onClick={openCalendar}
        />
      </Col>
    );
  };
  render() {
    return (
      <div className='monthYearPicker'>
        <Datetime
          dateFormat={this.props.type ? "YYYY" : "MM-YYYY"}
          timeFormat={false}
          renderInput={
            this.props.type ? this.renderYearInput : this.renderInput
          }
          onChange={this.props.handleChangeMonthYearSelection}
          closeOnSelect={true}
          locale={APP_LANGUAGES.SPANISH}
          initialValue={Utility.getCurrentMonthYear()}
        />
      </div>
    );
  }
}

export default MonthYearPicker;
