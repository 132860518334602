//libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import Resizer from "react-image-file-resizer";
import { Row, Col, Button } from "react-bootstrap";

//components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import Calender from "../CustomUIComponents/Calender/Calender";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import UserModal from "../CustomUIComponents/UserModal/UserModal";

//actions

//constants
import { Strings } from "../../resources/i18n/i18n";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DEFAULT_DURATION,
  DEFAULT_DATE_FORMAT,
  REPORT_DATE_TIME_FORMAT,
  DATE_TYPE,
  INVOICE_DATE_FORMAT,
  MINIMART_GRADE,
  FILE_CONFIGURATIONS,
  PROMO_IMAGE_FILE_SIZE,
  MINIMART_LEVELS,
} from "../../constants/appConstants";
import {
  PROMO_FIELDS,
  RESET_FIELDS,
  ERROR_FIELDS,
  STATUS_LIST,
  PROMOTION_TYPE,
  BEENFIT_TYPE,
  DEFAULT_LIST,
} from "./PromotionsConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//utilities
import Utility from "../../utils/Utility";
//stylesheets
import "./Promotions.scss";
import "../MotorcycleCatalogueModal/MotorcycleCatalogueModal.scss";
//assets
import promoImage from "../../resources/images/promotion_placeholder.png";
import cameraImage from "../../resources/images/ic_update_profile_pic.svg";
import cross from "../../resources/images/ic_cross_blue.svg";

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        title: "",
        promotionType: "",
        productType: "",
        benefitType: "",
        promotionStatus: "",
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: DEFAULT_DURATION.toDate,
      },
      errors: ERROR_FIELDS,
      selectedPromotion: null,
      editPromotion: null,
      addNewFlag: false,
      editedData: RESET_FIELDS,
      errorMessage: "",
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      showModal: false,
      deletePromoId: null,
      isEdtable: false,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  // #region select promotion records

  toggleDetails = (prid) => {
    let selectedPromotion = prid
      ? this.props.promotionList.find(
        (promotion) => promotion.prid === prid
      )
      : null;
    this.setState({
      selectedPromotion,
    });
  };

  toggleEdit = (prid = null, addNewPromoFlag = false) => {
    let editPromotion = prid
      ? this.props.promotionList.find(
        (promotion) => promotion.prid === prid
      )
      : null;
    let editedData = RESET_FIELDS;
    if (prid) {
      this.props.promotionList.filter((promotion) => {
        if (promotion.prid === prid) {
          editedData = { ...promotion };
        }
        return null;
      });
    }
    let errors = {
      prid: false,
      prt: false,
      prln: false,
      prgn: false,
      prrp: false,
      prdis: false,
      prlt: false,
      prdes: false,
      pradt: false,
      predt: false,
      file: false,
      prminrp: false,
      prmanrp: false,
      prty: false,
      prbty: false,
      prlma: false,
    };
    this.setState({
      isEdtable: !addNewPromoFlag,
      editPromotion,
      selectedPromotion: editPromotion,
      editedData,
      errors,
      errorMessage: "",
      addNewPromoFlag,
    });
  };

  // #endregion

  // #region field value change & validations
  handlePromotionDetailChange = (e) => {
    let value = "",
      etv = e.target.value,
      key = e.target.name,
      errors = this.state.errors,
      errorMessage = "";
    //numeric fields handling
    if (
      key === PROMO_FIELDS.GRADE ||
      key === PROMO_FIELDS.TYPE ||
      key === PROMO_FIELDS.LEVEL ||
      key === PROMO_FIELDS.BEENFIT_TYPE
    ) {
      value = etv;
      if (value === "" || value === Strings("promotionType") || value === Strings("benefitType") || value === Strings("selectMiniMartGrade") || value === Strings("selectMiniMartGrade") || value === Strings("minimartLevel")) {
        errors = { ...errors, [key]: true };
        errorMessage = Strings("EmptyFieldsError");
      } else {
        errors = { ...errors, [key]: false };
      }
    } else if (
      key === PROMO_FIELDS.DISCOUNT ||
      key === PROMO_FIELDS.MIN_LOAN_AMT
    ) {
      let data = this.setDiscountValue(
        etv,
        value,
        errors,
        errorMessage,
        key
      );
      value = data.value;
      errors = data.errors;
      errorMessage = data.errorMessage;
    } else if (
      key === PROMO_FIELDS.REWARD_PTS ||
      key === PROMO_FIELDS.REWARD_MAX ||
      key === PROMO_FIELDS.REWARD_MIN
    ) {
      value = parseInt(etv) || 0;
    }
    //text fields handling
    else {
      value = e.target.value;
      if (key === PROMO_FIELDS.TITLE) {
        value = e.target.value.toLowerCase();
        value = value.charAt(0).toUpperCase() + value.slice(1);
      }
      let data = this.setTextValue(
        key,
        e.target.value,
        errors,
        errorMessage
      );
      errors = data.errors;
      errorMessage = data.errorMessage;
    }
    this.setState({
      editedData: {
        ...this.state.editedData,
        [key]: value,
      },
      errors,
      errorMessage,
    });
  };

  setDiscountValue = (etv, value, errors, errorMessage, key) => {
    if (!isNaN(etv)) {
      if (etv.length > 0 && etv !== 0) {
        value = etv.replace(/^0+/, "");
        errors = { ...errors, [key]: false };
      } else {
        value = 0;
        errors = { ...errors, [key]: true };
        errorMessage = Strings("EmptyFieldsError");
      }
    } else {
      //if typed value is not numeric, it will remain unchanged
      value = this.state.editedData[key];
      errors = { ...errors, [key]: false };
    }
    return { value, errors, errorMessage };
  };

  setTextValue = (key, value, errors, errorMessage) => {
    //description field must accept upto 250 character
    if (key === PROMO_FIELDS.DESC && value.length === 250) {
      errors = { ...errors, prdes: true };
      errorMessage = `150 ${Strings("charactersAllowed")}`;
    } else {
      errors = { ...errors, prdes: false };
    }
    //values must not be empty
    if (value.length < 1) {
      errors = { ...errors, [key]: true };
      errorMessage = Strings("EmptyFieldsError");
    } else {
      errors = { ...errors, [key]: false };
    }
    return { errors, errorMessage };
  };

  checkValidData = () => {
    //NOSONAR
    let errors = this.state.errors,
      errorMessage = this.state.errorMessage;
    if (this.state.editedData.prt.length < 1) {
      errors = { ...errors, prt: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prdes.length < 1) {
      errors = { ...errors, prdes: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prty === "") {
      errors = { ...errors, prty: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prln === "") {
      errors = { ...errors, prln: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prgn === "") {
      errors = { ...errors, prgn: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prdes === "") {
      errors = { ...errors, prty: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prlt === "") {
      errors = { ...errors, prlt: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (this.state.editedData.prbty === "") {
      errors = { ...errors, prbty: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    // if (
    //   this.state.editedData.prbty === "REWARDS" &&
    //   this.state.editedData.prrp === 0
    // ) {
    //   errors = { ...errors, prrp: true };
    //   errorMessage = Strings("EmptyFieldsError");
    // }
    if (
      this.state.editedData.prbty === "DISCOUNT" &&
      this.state.editedData.prdis === 0
    ) {
      errors = { ...errors, prdis: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (
      this.state.editedData.prbty === "DISCOUNT" &&
      this.state.editedData.prlma === 0
    ) {
      errors = { ...errors, prlma: true };
      errorMessage = Strings("EmptyFieldsError");
    }
    if (
      this.state.editedData.pradt === "" ||
      this.state.editedData.pradt === undefined ||
      this.state.editedData.predt === "" ||
      this.state.editedData.predt === undefined
    ) {
      errors = { ...errors, pradt: true, predt: true };
      errorMessage = Strings("selectDate");
    }
    if (this.state.editedData.pradt > this.state.editedData.predt) {
      errors = { ...errors, pradt: true, predt: true };
      errorMessage = Strings("invalidDateRange");
    }
    if (!this.state.editedData.file) {
      errors = { ...errors, file: true };
      errorMessage = Strings("PleaseAddImage");
    }
    // if (
    //   this.state.editedData.prminrp >= this.state.editedData.prmanrp
    // ) {
    //   errors = { ...errors, prminrp: true, prmanrp: true };
    //   errorMessage = Strings("rewardPointsRange");
    // }

    this.setState({
      errors,
      errorMessage,
    });
    return errorMessage === "";
  };

  // #endregion

  // #region render selected details
  conditionalToggle = (promotion) => {
    this.state.selectedPromotion?.prid === promotion.prid
      ? this.toggleDetails()
      : this.toggleDetails(promotion.prid);
  };
  renderPromotionDetails = (promotion) => {
    //NOSONAR
    return (
      <div className='promotion' key={promotion.prid}>
        <Row
          className='cursorPointer'
          onClick={() => this.conditionalToggle(promotion)}
        >
          <Col md={2} lg={2} title={Strings("Image")}>
            <img
              className='smallImage imageLoader'
              src={promotion.file ? promotion.file : promoImage}
              alt={promotion.title}
            />
          </Col>
          <Col
            md={2}
            lg={2}
            title={Strings("promotionName")}
            className='fw-bold'
          >
            {promotion.prt}
          </Col>
          <Col md={2} lg={2} title={Strings("editedBy")}>
            {promotion.preby}
          </Col>
          <Col md={2} lg={2} title={Strings("editedAt")}>
            {moment(promotion.prut).format(REPORT_DATE_TIME_FORMAT)}
          </Col>
          {(promotion.prst === "ACTIVE" ||
            promotion.prst === "NEW") && (
              <>
                <Col
                  md={1}
                  lg={1}
                  title={Strings("Edit")}
                  onClick={() => this.toggleEdit(promotion.prid)}
                >
                  <i className='fa fa-pencil pull-right' />
                </Col>
                <Col
                  md={1}
                  lg={1}
                  title={Strings("Delete")}
                  onClick={() => this.openDeleteModal(promotion.prid)}
                >
                  <i className='fa fa-trash pull-right' />
                </Col>
              </>
            )}
          <Col
            md={{
              offset:
                promotion.prst === "ACTIVE" ||
                  promotion.prst === "NEW"
                  ? 0
                  : 2,
              span: 1,
            }}
            lg={{
              offset:
                promotion.prst === "ACTIVE" ||
                  promotion.prst === "NEW"
                  ? 0
                  : 2,
              span: 1,
            }}
            title={Strings("View")}
            onClick={() => this.conditionalToggle(promotion)}
          >
            <i
              className={`fa pull-right ${this.state.selectedPromotion !== null &&
                this.state.selectedPromotion.prid === promotion.prid
                ? "fa-chevron-up"
                : "fa-chevron-down"
                }`}
            />
          </Col>
        </Row>
        <Row
          className={
            this.state.selectedPromotion !== null &&
              this.state.selectedPromotion.prid === promotion.prid
              ? "visible mt-2 pt-2 border-top"
              : "hidden"
          }
        >
          <Col md={3} lg={3} title={Strings("activationDate")}>
            <span className='detailText me-1'>
              {Strings("activationDate")}:
            </span>
            {moment(promotion.pradt).format(INVOICE_DATE_FORMAT)}
          </Col>
          <Col md={3} lg={3} title={Strings("ExpiryDate")}>
            <span className='detailText me-1'>
              {Strings("ExpiryDate")}:
            </span>
            {moment(promotion.predt).format(INVOICE_DATE_FORMAT)}
          </Col>
          <Col md={3} lg={3} title={Strings("promotionStatus")}>
            <span className='detailText me-1'>
              {Strings("promotionStatus")}:
            </span>
            <span className='text-wrap text-break'>
              {Strings("P_" + promotion.prst)}
            </span>
          </Col>
        </Row>
        <Row
          className={
            this.state.selectedPromotion !== null &&
              this.state.selectedPromotion.prid === promotion.prid
              ? "visible mt-2"
              : "hidden"
          }
        >
          <Col md={3} lg={3} title={Strings("requiredGrade")}>
            <span className='detailText me-1'>
              {Strings("requiredGrade")}:
            </span>
            {promotion.prgn}
          </Col>
          <Col md={3} lg={3} title={Strings("requiredLevel")}>
            <span className='detailText me-1'>
              {Strings("requiredLevel")}:
            </span>
            {promotion.prln}
          </Col>
          {/* <Col md={3} lg={3} title={Strings("rewardPoints")}>
            <span className='detailText me-1'>
              {Strings("rewardPoints")}:
            </span>
            {promotion.prminrp + "-" + promotion.prmanrp}
          </Col> */}
          <Col md={3} lg={3} title={Strings("promotionType")}>
            <span className='detailText me-1'>
              {Strings("promotionType")}:
            </span>
            {Strings(promotion.prty) || Strings("NA")}
          </Col>
          <Col md={3} lg={3} title={Strings("product")}>
            <span className='detailText me-1'>
              {Strings("product")}:
            </span>
            {Strings("P_" + promotion.prlt)}
          </Col>
          <Col md={3} lg={3} title={Strings("benefitType")}>
            <span className='detailText me-1'>
              {Strings("benefitType")}:
            </span>
            {Strings(promotion.prbty)}
          </Col>
          {
            // promotion.prbty === "REWARDS" ? (
            //   <Col md={3} lg={3} title={Strings("rewardPoints")}>
            //     <span className='detailText me-1'>
            //       {Strings("rewardPoints")}:
            //     </span>
            //     {promotion.prrp || Strings("NA")}
            //   </Col>
            // ) :
            promotion.prbty === "DISCOUNT" ? ( //NOSONAR
              <>
                <Col md={3} lg={3} title={Strings("discount")}>
                  <span className='detailText me-1'>
                    {Strings("discount")}:
                  </span>
                  {Utility.getCurrencyRepresentationOfAmount(
                    promotion.prdis
                  ) || Strings("NA")}
                </Col>
                <Col md={3} lg={3} title={Strings("minLoanAmount")}>
                  <span className='detailText me-1'>
                    {Strings("minLoanAmount")}:
                  </span>
                  {Utility.getCurrencyRepresentationOfAmount(
                    promotion.prlma
                  ) || Strings("NA")}
                </Col>
              </>
            ) : null
          }
        </Row>
        <Row
          className={
            this.state.selectedPromotion !== null &&
              this.state.selectedPromotion.prid === promotion.prid
              ? "visible mt-2 pt-2 border-top"
              : "hidden"
          }
        >
          <Col title={Strings("Description")}>
            <span className='detailText me-1'>
              {Strings("Description")}:
            </span>
            <span className='text-wrap text-break'>
              {promotion.prdes}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  // #endregion

  // #region complex editable fields rendering
  renderImageDisplay = () => {
    return Object.prototype.toString.call(
      this.state.editedData.file
    ) === "[object File]"
      ? this.state.avatar
      : this.state.editedData.file;
  };

  renderPromotionImageEditable = (isEdtable = false) => {
    return this.state.editedData.file ? (
      <div className='addOrUpdateImageDisplay'>
        <img
          src={this.renderImageDisplay()}
          className='uploadedImg'
          alt=''
        />
        {isEdtable && (
          <img
            src={cross}
            className='crossIcon'
            onClick={() => this.removeImage()}
            alt=''
          />
        )}
      </div>
    ) : (
      <div
        className={`${this.state.errors.file && "errorField"}
          addOrUpdateImage backdrop`}
      >
        <label htmlFor='uploadPhoto' className='browse-button '>
          <img
            src={cameraImage}
            className={"addOrUpdateImageCamera"}
            alt={Strings("UploadPhoto")}
          />
        </label>
        <input
          type='file'
          id='uploadPhoto'
          onChange={(e) => this.handleUploadPhoto(e)}
          accept='image/png,image/jpeg,image/jpg'
          style={{ display: "none" }}
        />
      </div>
    );
  };

  handleCalenderDateChange = (type) => (value) => {
    this.setState({
      editedData: {
        ...this.state.editedData,
        [type]: moment(value).format(DEFAULT_DATE_FORMAT),
      },
      errorMessage: "",
      errors: {
        ...this.state.errors,
        pradt: false,
        predt: false,
      },
    });
  };

  renderPromotionCalendar = () => {
    return (
      <>
        <Col md={6} className={"calenderFilter ps-0"}>
          <Calender
            required
            id='activationDate'
            dateFormat={INVOICE_DATE_FORMAT}
            placeholder={Strings("From")}
            placeholderTop={Strings("From")}
            handleChange={this.handleCalenderDateChange("pradt")}
            isBefore={true}
            className={this.state.errors.pradt ? "errorField" : null}
            value={
              this.state.editedData.pradt !== undefined &&
                this.state.editedData.pradt !== ""
                ? moment(this.state.editedData.pradt).format(
                  INVOICE_DATE_FORMAT
                )
                : ""
            }
            iconClass=' pt-3'
          />
        </Col>
        <Col md={6} className='calenderFilter ps-0'>
          <Calender
            required
            id='expiryDate'
            dateFormat={INVOICE_DATE_FORMAT}
            placeholder={Strings("To")}
            placeholderTop={Strings("To")}
            handleChange={this.handleCalenderDateChange("predt")}
            isBefore={true}
            className={this.state.errors.predt ? "errorField" : null}
            value={
              this.state.editedData.predt !== undefined &&
                this.state.editedData.predt !== ""
                ? moment(this.state.editedData.predt).format(
                  INVOICE_DATE_FORMAT
                )
                : ""
            }
            iconClass=' pt-3'
          />
        </Col>
      </>
    );
  };

  handleUploadPhoto = (e) => {
    let refThis = this;
    let file = e.target.files[0];

    if (file) {
      let fileExtension = Utility.getFileExtension(file);
      let validFile = true;
      if (
        !_.includes(
          FILE_CONFIGURATIONS.allowedImageExtensions,
          fileExtension
        )
      ) {
        validFile = false;
        this.setState({
          errorMessage: Strings("fileExtension"),
        });
      }
      if (validFile && file.size > PROMO_IMAGE_FILE_SIZE) {
        validFile = false;

        this.setState({
          errorMessage: Strings("sizeIsTooLarge"),
        });
      }
      if (validFile) {
        try {
          Resizer.imageFileResizer(
            //compress image file
            file,
            200,
            200,
            fileExtension,
            25,
            0,
            (uri) => {
              try {
                let reader = new FileReader();
                reader.onloadend = () => {
                  refThis.setState({
                    avatar: reader.result,
                  });
                };
                reader.readAsDataURL(uri);
                this.setState({
                  editedData: {
                    ...this.state.editedData,
                    file,
                  },
                  errorMessage: "",
                });
              } catch (err) {
                this.setState({
                  errorMessage: Strings("imageUploadFailed"),
                });
              }
            },
            "file"
          );
        } catch (err) {
          this.setState({
            errorMessage: Strings("imageUploadFailed"),
          });
        }
      }
    }
  };

  removeImage = () => {
    this.setState({
      editedData: {
        ...this.state.editedData,
        file: null,
      },
    });
  };

  // #endregion

  // #region actions

  renderList = (list) => {
    return list.map((data) => {
      return (
        <option key={data.key} value={data.value}>
          {data.label}
        </option>
      );
    });
  };

  validateNumberField = (event) => {
    if (!Utility.checkIfNumber(event) || event.shiftKey) {
      event.preventDefault();
    }
  };

  renderFirstSection = () => {
    return (
      <Row>
        <Col md={4} lg={4}>
          <TextFieldComponent
            required
            name={PROMO_FIELDS.TITLE}
            className={this.state.errors.prt ? "errorField" : null}
            id='promotionName'
            type='text'
            placeholderTop={Strings("promotionName")}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prt}
            disabled={this.state.isEdtable}
            maxLength={45}
          />
        </Col>
        <Col md={8} lg={8} className='calenderFilterContainer'>
          <Row>{this.renderPromotionCalendar()}</Row>
        </Col>
      </Row>
    );
  };

  renderSecondSection = () => {
    return (
      <Row className='pt-3'>
        <Col md={4} lg={4}>
          <TextFieldComponent
            name={PROMO_FIELDS.GRADE}
            className={this.state.errors.prgn ? "errorField" : null}
            id='grade'
            type='select'
            required
            placeholderTop={Strings("requiredGrade")}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prgn}
            disabled={this.state.isEdtable}
          >
            {this.renderList(MINIMART_GRADE)}
          </TextFieldComponent>
        </Col>
        <Col md={4} lg={4}>
          <TextFieldComponent
            name={PROMO_FIELDS.LEVEL}
            className={this.state.errors.prln ? "errorField" : null}
            required
            id='level'
            type='select'
            placeholderTop={Strings("requiredLevel")}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prln}
            disabled={this.state.isEdtable}
          >
            {this.renderList(MINIMART_LEVELS)}
          </TextFieldComponent>
        </Col>
        {/* <Col md={4} lg={4}>
          <div>
            <span className='position-relative'>
              {Strings("rewardPoints")}
            </span>

            <span className='requiredFields'>*</span>
          </div>
          <Row>
            <Col md={6} className='ps-0'>
              <TextFieldComponent
                required
                name={PROMO_FIELDS.REWARD_MIN}
                className={
                  this.state.errors.prminrp ? "errorField" : null
                }
                id='rewardPoints'
                placeholderTopClass='position-relative'
                type='number'
                onKeyDown={this.validateNumberField}
                placeholder={Strings("min")}
                handleChange={this.handlePromotionDetailChange}
                value={this.state.editedData.prminrp}
                disabled={this.state.isEdtable}
              />
            </Col>
            <Col md={6} className='ps-0'>
              <TextFieldComponent
                required
                name={PROMO_FIELDS.REWARD_MAX}
                className={
                  this.state.errors.prmanrp ? "errorField" : null
                }
                id='rewardPoints'
                placeholderTopClass='position-relative'
                type='number'
                onKeyDown={this.validateNumberField}
                // placeholderTop={Strings("rewardPoints")}
                placeholder={Strings("max")}
                handleChange={this.handlePromotionDetailChange}
                value={this.state.editedData.prmanrp}
                disabled={this.state.isEdtable}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
    );
  };

  renderThirdSection = () => {
    let productList = this.generateList(this.state.editedData.prty);
    return (
      <Row className='pt-3'>
        <Col md={4} lg={4}>
          <TextFieldComponent
            required
            name={PROMO_FIELDS.TYPE}
            className={this.state.errors.prty ? "errorField" : null}
            id='prod'
            type='select'
            placeholderTop={Strings("type")}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prty}
            disabled={this.state.isEdtable}
          >
            {this.renderList(PROMOTION_TYPE)}
          </TextFieldComponent>
        </Col>
        <Col md={4} lg={4}>
          <TextFieldComponent
            required
            name={PROMO_FIELDS.PRODUCT_NAME}
            className={this.state.errors.prlt ? "errorField" : null}
            id='prod'
            type='select'
            placeholderTop={Strings("product")}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prlt}
            disabled={this.state.isEdtable}
          >
            {this.renderList(productList)}
          </TextFieldComponent>
        </Col>
        <Col md={4} lg={4}>
          <TextFieldComponent
            required
            name={PROMO_FIELDS.BEENFIT_TYPE}
            className={this.state.errors.prbty ? "errorField" : null}
            id='prod'
            type='select'
            placeholderTop={Strings("benefitType") + "*"}
            handleChange={this.handlePromotionDetailChange}
            value={this.state.editedData.prbty}
            disabled={this.state.isEdtable}
          >
            {this.renderList(BEENFIT_TYPE)}
          </TextFieldComponent>
        </Col>
      </Row>
    );
  };

  renderFourthSection = () => {
    return (
      <Row className='pt-3'>
        <Col md={4} lg={4}>
          <textarea
            required
            name={PROMO_FIELDS.DESC}
            className={
              this.state.errors.prdes
                ? "form-control errorField"
                : "form-control"
            }
            id='desc'
            placeholder={Strings("Description") + "*"}
            rows={5}
            maxLength={150}
            value={this.state.editedData.prdes}
            onChange={this.handlePromotionDetailChange}
            disabled={this.state.isEdtable}
          />
        </Col>
        <Col md={8}>
          <Row>
            {this.state.editedData.prbty === "DISCOUNT" ? (
              <Col md={6} lg={6} className='px-0'>
                <TextFieldComponent
                  required
                  name={PROMO_FIELDS.DISCOUNT}
                  className={
                    this.state.errors.prdis ? "errorField" : null //NOSONAR
                  }
                  placeholderTopClass='position-relative'
                  id='discount'
                  type='number'
                  onKeyDown={this.validateNumberField}
                  placeholderTop={Strings("discount")}
                  handleChange={this.handlePromotionDetailChange}
                  value={this.state.editedData.prdis}
                  disabled={this.state.isEdtable}
                />
              </Col>
            ) : // : this.state.editedData.prbty === "REWARDS" ? ( //NOSONAR
              //   <Col md={6} lg={6} className="px-0">
              //     <TextFieldComponent
              //       required
              //       name={PROMO_FIELDS.REWARD_PTS}
              //       className={
              //         this.state.errors.prrp ? "errorField" : null //NOSONAR
              //       }
              //       id='rewardPoints'
              //       placeholderTopClass='position-relative'
              //       type='number'
              //       onKeyDown={this.validateNumberField}
              //       placeholderTop={Strings("rewardPoints")}
              //       handleChange={this.handlePromotionDetailChange}
              //       value={this.state.editedData.prrp}
              //       disabled={this.state.isEdtable}
              //     />
              //   </Col>
              // )
              null}
            <Col
              className='pt-3 mt-2'
              md={{
                offset:
                  this.state.editedData.prbty === "" ||
                    this.state.editedData.prbty === "INSTALLMENT"
                    ? 6
                    : 0,
                span: 6,
              }}
              lg={{
                offset:
                  this.state.editedData.prbty === "" ||
                    this.state.editedData.prbty === "INSTALLMENT"
                    ? 6
                    : 0,
                span: 6,
              }}
            >
              <Button
                className='buttonBackground'
                onClick={
                  this.state.isEdtable
                    ? this.handleUpdatePromotion
                    : this.handleAddNewPromotion
                }
              >
                {Strings("Save")}
              </Button>
              <Button
                className='buttonBorder ms-3'
                onClick={() => this.toggleEdit()}
              >
                {Strings("Cancel")}
              </Button>
            </Col>
            {this.state.editedData.prbty === "DISCOUNT" && (
              <Col md={6} lg={6} className='px-0'>
                <TextFieldComponent
                  required
                  name={PROMO_FIELDS.MIN_LOAN_AMT}
                  className={
                    this.state.errors.prlma ? "errorField" : null //NOSONAR
                  }
                  placeholderTopClass='position-relative'
                  id='minLoanAmount'
                  type='number'
                  onKeyDown={this.validateNumberField}
                  placeholderTop={Strings("minLoanAmount")}
                  handleChange={this.handlePromotionDetailChange}
                  value={this.state.editedData.prlma}
                  disabled={this.state.isEdtable}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  renderAddNewPromotion = () => {
    return (
      <div
        className='promotion'
        key={
          this.state.selectedPromotion?.prt +
          this.state.selectedPromotion?.prid
        }
      >
        <Row>
          <Col md={3} lg={3} className='bold text-uppercase'>
            {Strings("AddNewPromotion")}
          </Col>

          {this.state.errorMessage && (
            <Col
              md={6}
              lg={6}
              className='errorText mt-0 pt-0 mb-2 pb-2'
            >
              {this.state.errorMessage}
            </Col>
          )}
        </Row>
        <Row>
          <Col md={3} lg={3}>
            <div className='text-center'>
              <span>{Strings("promotionImage")}</span>
              <span className='mandatoryFields'>*</span>
            </div>
            {this.renderPromotionImageEditable(!this.state.isEdtable)}
          </Col>
          <Col md={9} lg={9} className='pt-3 mt-1'>
            {this.renderFirstSection()}
            {this.renderSecondSection()}
            {this.renderThirdSection()}
            {this.renderFourthSection()}
          </Col>
        </Row>
      </div>
    );
  };

  handleAddNewPromotion = () => {
    let validData = this.checkValidData();
    if (validData) {
      let data = this.state.editedData;

      let reqObj = {
        title: data.prt,
        levelNeeded: data.prln,
        gradeNeeded: data.prgn,
        rewardPoints: data.prbty === "REWARDS" ? data.prrp : null,
        discount: data.prbty === "DISCOUNT" ? data.prdis : null,
        minLoanAmount: data.prbty === "DISCOUNT" ? data.prlma : null,
        productName: data.prlt,
        description: data.prdes,
        activationDate: Utility.convertDateIntoMiliSeconds(
          data.pradt
        ),
        expiryDate: Utility.convertDateIntoMiliSeconds(data.predt),
        file: data.file,
        editedBy: this.props.loggedInUser.username,
        maxRewardPointsNeeded: data.prmanrp,
        minRewardPointsNeeded: data.prminrp,
        type: data.prty,
        benefitType: data.prbty,
      };
      let searchFilter = {
        search: {
          ttl: "",
          ty: "",
          lt: "",
          bty: "",
          st: "",
          fd: Utility.convertDateIntoMiliSeconds(
            DEFAULT_DURATION.fromDate
          ),
          td: Utility.convertDateIntoMiliSeconds(
            DEFAULT_DURATION.toDate
          ),
        },
        lmt: DEFAULT_PAGINATION_RECORDS_COUNT,
        oft: DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
      };
      this.props.addNewPromotion(
        reqObj,
        { ...searchFilter },
        (success) => {
          if (success) {
            this.setState(
              {
                filters: {
                  title: "",
                  promotionType: "",
                  productType: "",
                  benefitType: "",
                  promotionStatus: "",
                  fromDate: DEFAULT_DURATION.fromDate,
                  toDate: DEFAULT_DURATION.toDate,
                },
                recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
                activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
              },
              this.toggleEdit
            );
          }
        }
      );
    }
  };

  handleUpdatePromotion = () => {
    let validData = this.checkValidData();
    if (validData) {
      let data = this.state.editedData;
      let reqObj = {
        id: data.prid,
        title: data.prt,
        levelNeeded: data.prln,
        gradeNeeded: data.prgn,
        rewardPoints: data.prrp,
        discount: data.prdis,
        minLoanAmount: data.prlma,
        productName: data.prlt,
        description: data.prdes,
        activationDate: Utility.convertDateIntoMiliSeconds(
          data.pradt
        ),
        expiryDate: Utility.convertDateIntoMiliSeconds(data.predt),
        eby: this.props.loggedInUser.username,
        maxRewardPointsNeeded: data.prmanrp,
        minRewardPointsNeeded: data.prminrp,
        type: data.prty,
        benefitType: data.prbty,
      };
      Object.prototype.toString.call(data.file) === "[object File]" &&
        (reqObj = { ...reqObj, file: data.file });
      let searchFilter = {
        search: {
          ttl: this.state.filters.title,
          fd: Utility.convertDateIntoMiliSeconds(
            this.state.filters.fromDate
          ),
          td: Utility.convertDateIntoMiliSeconds(
            this.state.filters.toDate
          ),
          ty: this.state.filters.promotionType,
          lt: this.state.filters.productType,
          bty: this.state.filters.benefitType,
          st: this.state.filters.promotionStatus,
        },
        lmt: this.state.recordsPerPage,
        oft: this.state.activePage - 1,
      };
      this.props.updatePromotion(
        reqObj,
        { ...searchFilter },
        (success) => {
          success && this.toggleEdit();
        }
      );
    }
  };
  // #endregion

  // #region search

  handleSearchDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.toDate)) {
          DateValue = this.state.filters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.filters.fromDate,
              "days"
            ) === 0
              ? this.state.filters.fromDate
              : this.state.filters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: DateValue,
      },
    });
  };

  onInputChange = (e) => {
    let value = DEFAULT_LIST.includes(e.target.value)
      ? ""
      : e.target.value;
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]: value,
        productType:
          e.target.name === "promotionType"
            ? ""
            : e.target.name === "productType" //NOSONAR
              ? value
              : this.state.filters.productType,
      },
    });
  };

  resetSearchValues = () => {
    this.setState(
      {
        filters: {
          title: "",
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: DEFAULT_DURATION.toDate,
          productType: "",
          promotionType: "",
          benefitType: "",
          promotionStatus: "",
        },
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      () => this.handleOnClickSearchFilter(true)
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    let requestData = {
      search: {
        ttl: this.state.filters.title,
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.filters.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.filters.toDate
        ),
        ty: this.state.filters.promotionType,
        lt: this.state.filters.productType,
        bty: this.state.filters.benefitType,
        st: this.state.filters.promotionStatus,
      },
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    };
    this.props.handleOnClickSearchFilter({
      ...requestData,
    });
  };

  generateList = (promotionType) => {
    let productList = [
      {
        key: 0,
        value: "",
        label: Strings("productType"),
      },
    ];
    if (promotionType === "" || promotionType === Strings("promotionType") || promotionType === Strings("benefitType")) {
      let list = Object.keys(this.props.productList);
      list.forEach((key) => {
        this.props.productList[key].forEach((product, index) => {
          productList.push({
            key: index + 1,
            value: product,
            label: Strings("P_" + product),
          });
        });
      });
    } else {
      this.props.productList[promotionType].forEach(
        (products, index) => {
          productList.push({
            key: index + 1,
            value: products,
            label: Strings("P_" + products),
          });
        }
      );
    }
    return productList;
  };

  renderSearchFilters = () => {
    let productList = this.generateList(
      this.state.filters.promotionType
    );
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("promotionName"),
        handleOnChange: this.onInputChange,
        Value: this.state.filters.title,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "title",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("promotionStatus"),
        handleOnChange: this.onInputChange,
        Value: this.state.filters.promotionStatus,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        filterDropdownList: STATUS_LIST,
        name: "promotionStatus",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("promotionType"),
        handleOnChange: this.onInputChange,
        Value: this.state.filters.promotionType,
        filterDropdownList: PROMOTION_TYPE,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "promotionType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("productType"),
        handleOnChange: this.onInputChange,
        Value: this.state.filters.productType,
        filterDropdownList: productList,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "productType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("benefitType"),
        handleOnChange: this.onInputChange,
        Value: this.state.filters.benefitType,
        filterDropdownList: BEENFIT_TYPE,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "benefitType",
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleCalenderChange: this.handleSearchDateChange,
        fromDate: this.state.filters.fromDate,
        toDate: this.state.filters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
        paddingTop: true,
      },
    ];
    return (
      <Row className='filters m-3 greyBack'>
        <SearchFilter
          filters={searchFilters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.resetSearchValues}
          paddingLeft={2}
          addButton={true}
          buttonText={Strings("AddNewPromotion")}
          handleAddUser={() => {
            this.setState({
              addNewFlag: true,
              isEdtable: false,
            });
            this.toggleEdit(null, true);
          }}
          paddingTop={2}
          removeLeftPadding={true}
          addButtonMarginTop={"mt-2"}
        />
      </Row>
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  openDeleteModal = (id) => {
    this.setState({
      showModal: true,
      deletePromoId: id,
    });
  };

  deletePromotion = () => {
    const deletePromoId = this.state.deletePromoId;
    this.setState(
      {
        showModal: false,
        deletePromoId: null,
      },
      () => {
        this.props.deletePromotion(
          {
            search: {
              ttl: this.state.filters.title,
              fd: Utility.convertDateIntoMiliSeconds(
                this.state.filters.fromDate
              ),
              td: Utility.convertDateIntoMiliSeconds(
                this.state.filters.toDate
              ),
              ty: this.state.filters.promotionType,
              st: this.state.filters.promotionStatus,
              bty: this.state.filters.benefitType,
              lt: this.state.filters.productType,
            },
            lmt: this.state.recordsPerPage,
            oft: this.state.activePage - 1,
          },
          deletePromoId
        );
      }
    );
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("Delete")}
        modalText2={Strings("DoYouReallyWantToDelete")}
        modalButtonOk={{
          text: Strings("Delete"),
          onClick: this.deletePromotion,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: () =>
            this.setState({
              showModal: false,
              deletePromoId: null,
            }),
        }}
        showHr={true}
      />
    );
  };

  render() {
    return (
      <div className='paper greyBack'>
        {this.state.showModal && this.renderConfirmationModal()}
        {this.renderSearchFilters()}
        {this.props.errorMessage && (
          <Row className='errorText'>{this.props.errorMessage}</Row>
        )}
        <div className='promotionListContainer'>
          {this.state.addNewPromoFlag && this.renderAddNewPromotion()}
          {this.props.promotionList &&
            this.props.promotionList.length > 0 &&
            this.props.promotionList.map((promotion) => {
              return promotion.prid &&
                promotion.prid === this.state.editPromotion?.prid
                ? this.renderAddNewPromotion()
                : this.renderPromotionDetails(promotion);
            })}
          {this.props.promotionList.length > 0 ? (
            <Row>
              <Col md={12} lg={12}>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            </Row>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  //NOSONAR
  return bindActionCreators({});
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions);
