import React from "react";
import { Row, Col } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import RewardCatalogModal from "./RewardCatalogModal";

//Constants
import { DEFAULT_LIST } from "./Constants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  FILE_CONFIGURATIONS,
  MOTORCYCLE_FILE_SIZE,
} from "../../constants/appConstants";
import { STATUS_SELECTION } from "../MotorcycleCatalog/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

export default class RewardCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      rewardFilters: { status: "", name: "", model: "" },
      showRewardDetails: false,
      selectedReward: {
        rpid: null,
        rpn: "",
        rpm: "",
        rp: 0,
        rps: "",
        rpd: "",
        rpi: null,
      },
      avatar: null,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    this.props.handleOnClickSearchFilter({
      rpn: this.state.rewardFilters.name,
      rpm: this.state.rewardFilters.model,
      rps: this.state.rewardFilters.status,
      lmt: this.state.recordsPerPage,
      oft: this.state.activePage,
    });
  };
  handleOnClickResetFilter = () => {
    this.setState(
      {
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        rewardFilters: { status: "", name: "", model: "" },
      },
      this.handleOnClickSearchFilter
    );
  };

  handleInputChnage = (name, event) => {
    this.setState({
      rewardFilters: {
        ...this.state.rewardFilters,
        [name]: DEFAULT_LIST.includes(event.target.value)
          ? ""
          : event.target.value,
      },
      errorMessage: "",
    });
  };

  renderSearchFilter = () => {
    let rewardFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        handleOnChange: this.handleInputChnage,
        Value: this.state.rewardFilters.model,
        name: "model",
        key: "model",
        searchPlaceHolder: Strings("model"),
        fieldname: SEARCH_FILTER_FIELDS.MODEL,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        handleOnChange: this.handleInputChnage,
        Value: this.state.rewardFilters.name,
        name: "name",
        key: "name",
        searchPlaceHolder: Strings("ProductName"),
        fieldname: SEARCH_FILTER_FIELDS.PRODUCT_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("status"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.rewardFilters.status,
        filterDropdownList: STATUS_SELECTION,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        name: "status",
        key: "status",
      },
    ];
    return (
      <SearchFilter
        filters={rewardFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
        removeLeftPadding={true}
        addAnotherButton={!this.props.readOnly && true}
        AnotherButtonClick={this.onAddRewardClick}
        anotherButtonText={Strings("AddReward")}
      />
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  onRewardClick = (selectedReward) => {
    this.setState({
      showRewardDetails: true,
      selectedReward,
    });
  };

  onAddRewardClick = () => {
    this.setState({
      showRewardDetails: true,
      selectedReward: {
        rpid: null,
        rpn: "",
        rpm: "",
        rp: 0,
        rps: "",
        rpd: "",
        rpi: null,
      },
      errorMessage: "",
    });
  };

  closeModal = () => {
    this.setState({
      showRewardDetails: false,
      errorMessage: "",
      selectedReward: {
        rpid: null,
        rpn: "",
        rpm: "",
        rp: 0,
        rps: "",
        rpd: "",
        rpi: null,
      },
    });
  };

  handleModalInputChange = (event) => {
    let selectedReward = { ...this.state.selectedReward };
    let value = DEFAULT_LIST.includes(event.target.value)
      ? ""
      : event.target.value;
    if (event.target.name === "rpn") {
      if (!Utility.containsSpecialChars(value)) {
        selectedReward.rpn = value.replace(/\s+/g, " ");
      }
    } else if (event.target.name === "rp" && value) {
      selectedReward.rp = parseInt(value);
    } else if (event.target.name === "rpm") {
      if (!Utility.containsSpecialChars(value)) {
        selectedReward.rpm = value.replace(/\s+/g, " ");
      }
    } else {
      selectedReward[event.target.name] = value;
    }
    this.setState({
      selectedReward,
      errorMessage: "",
    });
  };

  handleUploadPhoto = (event) => {
    let refThis = this;
    let photo = event.target.files[0];
    if (photo) {
      let fileExtension = Utility.getFileExtension(photo);
      let validFile = true;
      if (
        !_.includes(
          FILE_CONFIGURATIONS.allowedImageExtensions,
          fileExtension
        )
      ) {
        validFile = false;
        this.setState({
          errorMessage: Strings("fileExtension"),
        });
      }
      if (validFile && photo.size > MOTORCYCLE_FILE_SIZE) {
        validFile = false;

        this.setState({
          errorMessage: Strings("sizeIsTooLarge"),
        });
      }
      if (validFile) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0],
            400,
            400,
            fileExtension,
            50,
            0,
            (uri) => {
              try {
                let reader = new FileReader();
                reader.onloadend = () => {
                  refThis.setState({
                    avatar: reader.result,
                  });
                };
                reader.readAsDataURL(uri);
                this.setState({
                  selectedReward: {
                    ...this.state.selectedReward,
                    rpi: uri,
                  },
                  errorMessage: "",
                });
              } catch (err) {
                this.setState({
                  errorMessage: Strings("imageUploadFailed"),
                });
              }
            },
            "file"
          );
        } catch (err) {
          this.setState({
            errorMessage: Strings("imageUploadFailed"),
          });
        }
      }
    }
  };
  handleRemoveImage = () => {
    this.setState({
      avatar: null,
      selectedReward: {
        ...this.state.selectedReward,
        rpi: null,
      },
      errorMessage: "",
    });
  };
  renderRewardCatalogModal = () => {
    return (
      <RewardCatalogModal
        modalOpen={this.state.showRewardDetails}
        headerText={
          this.state.selectedReward.rpid
            ? "updateRewardDetails"
            : "addRewardDetails"
        }
        errorMessage={this.state.errorMessage}
        reward={this.state.selectedReward}
        avatar={this.state.avatar}
        onSaveClick={this.onSaveClick}
        handleModalClose={this.closeModal}
        handleInputChnage={this.handleModalInputChange}
        handleUploadPhoto={this.handleUploadPhoto}
        handleRemoveImage={this.handleRemoveImage}
        readOnly={this.props.readOnly}

      />
    );
  };

  validReward = () => {
    return (
      this.state.selectedReward.rpi === null ||
      this.state.selectedReward.rpn === "" ||
      this.state.selectedReward.rpm === "" ||
      this.state.selectedReward.rp === "" ||
      this.state.selectedReward.rp <= 0 ||
      this.state.selectedReward.rps === ""
    );
  };

  onSaveClick = () => {
    if (this.validReward()) {
      this.setState({ errorMessage: Strings("EmptyFieldsError") });
    } else {
      let request = { ...this.state.selectedReward };
      if (request.rpid === null || request.rpid === undefined) {
        // Add reward product
        this.props.saveReward(request, () => {
          this.closeModal();
          this.handleOnClickResetFilter();
        });
      } else {
        // Edit/Update reward product
        if (
          Object.prototype.toString.call(request.rpi) ===
          "[object String]"
        ) {
          delete request.rpi;
        }
        this.props.saveReward(request, () => {
          this.closeModal();
          this.handleOnClickResetFilter();
        });
      }
    }
  };
  render() {
    return (
      <div className='ListContainer'>
        {this.state.showRewardDetails &&
          this.renderRewardCatalogModal()}
        {this.renderSearchFilter()}
        <Row>
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>
        {this.props.rewardsList &&
          this.props.rewardsList.length !== 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pe-0'>
              <Row className='w-100'>
                {this.props.rewardsList.map((reward) => {
                  return (
                    <CustomCard
                      card={reward}
                      key={reward.rpid}
                      cardType='reward'
                      isMessenger={false}
                      handleCardClick={this.onRewardClick}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}
