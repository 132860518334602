//Components
import React, { Component } from "react";
import LoanRequests from "./LoanRequests.js";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//Actions
import { getUserByBranch } from "../UserList/UserListAction";
import {
  getLoanRequestListAction,
  updateLoanRequestAction,
} from "./LoanRequestsAction.js";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { USER_ROLES } from "../../constants/appConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

//Utility

class LoanRequestsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showLoadingModal: false,
    };
  }
  componentDidMount() {
    this.getUserList(USER_ROLES.salesPerson);
  }

  getUserList = (role, branchId = "") => {
    let users = [];
    const request = {
      role: role,
      branchId: branchId,
    };
    this.setState({ showLoadingModal: true });
    getUserByBranch(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        response.data.data.forEach((object) => {
          object.us.forEach((user) => {
            user.value = user.userId;
            let count = users.reduce(function (n, val) {
              return n + (val.name === user.name);
            }, 0);
            if (count === 0) users.push(user);
          });
        });

        this.setState({
          users,
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
    });
    getLoanRequestListAction(searchFilterObject, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          numberOfPages: responsedata.data.data.nop,
          rows: responsedata.data.data.lr,
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responsedata.error.message,
          rows: [],
          showLoadingModal: false,
        });
      }
    });
  };

  updateLoanRequest = (reqObj, callBack) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      isSuccess: false,
    });
    updateLoanRequestAction(reqObj, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            isSuccess: true,
            successMessage:
              reqObj.rt === "ASSIGNED"
                ? Strings("salesPersonAssignedSuccess")
                : Strings("deletedSuccessfully"),
            level: "success",
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            callBack(true);
          }
        );
      } else {
        this.setState(
          {
            isSuccess: true,
            successMessage: responseData.error.message,
            level: "error",
            errorMessage: responseData.error.message,
            showLoadingModal: false,
          },
          () => {
            callBack(true);
          }
        );
      }
    });
  };

  render() {
    return (
      <>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.isSuccess && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.successMessage),
              level: this.state.level,
            }}
          />
        )}
        <LoanRequests
          userList={this.state.users}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          rows={this.state.rows}
          loggedInUser={this.props.loggedInUser}
          //update salesperson to loan request
          updateLoanRequest={this.updateLoanRequest}
          //Pagination
          numberOfPages={this.state.numberOfPages}
        />
      </>
    );
  }
}

const LoanRequestsWrapper = withHOC(LoanRequestsContainer);

export default LoanRequestsWrapper;
