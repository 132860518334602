import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

//Components
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

// Constants
import {
  MINIMART_MANAGEMENT_TYPE_OF_CUSTOMER,
  MINIMART_GRADE,
} from "../../constants/appConstants";
import { MINIMART_LEVELS } from "./Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./AnalystConfiguration.scss";

class AnalystConfiguration extends Component {
  onCheckboxSelection = (dataType, value) => {
    let { configurationObject } = this.props.configurationData;
    let isIncluded = configurationObject[dataType].includes(value);
    let configurationData = {
      ...configurationObject,
    };
    if (!isIncluded) {
      configurationData[dataType].push(value);
    } else {
      configurationData[dataType].splice(
        configurationObject[dataType].indexOf(value),
        1
      );
    }
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  onChange = (event) => {
    let { configurationObject } = this.props.configurationData;
    let configurationData = {
      ...configurationObject,
    };
    configurationData.minLevel = event.target.value;
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  renderGrade = () => {
    let { configurationObject } = this.props.configurationData;
    let minimartGrade = [...MINIMART_GRADE];
    minimartGrade.shift();
    return (
      <Row>
        <Col md={12} className='px-0 py-3'>
          <strong className='subText'>
            {Strings(
              this.props.readOnly
                ? "MinimartGrade"
                : "selectMinimartGrade"
            )}
          </strong>
        </Col>
        {minimartGrade.map((grade, index) => (
          <Col
            md={{ offset: index === 0 ? 1 : 0, span: 2 }}
            key={grade.value}
          >
            <CheckboxComponent
              onChange={() => {
                if (!this.props.readOnly) {
                  this.onCheckboxSelection("grade", grade.value);
                }
              }}
              checked={configurationObject.grade.includes(
                grade.value
              )}
              disabled={this.props.readOnly}
              label={grade.label}
              key={grade.label}
            />
          </Col>
        ))}
      </Row>
    );
  };

  renderType = () => {
    let { configurationObject } = this.props.configurationData;
    return (
      <Row className='py-3'>
        <Col md={12} className='px-0'>
          <strong className='subText'>
            {Strings(
              this.props.readOnly
                ? "MinimartType"
                : "selectMinimartType"
            )}
          </strong>
        </Col>
        {MINIMART_MANAGEMENT_TYPE_OF_CUSTOMER.map((type, index) => (
          <Col
            md={{ offset: index === 0 ? 1 : 0, span: 2 }}
            key={type.value}
          >
            <CheckboxComponent
              onChange={() => {
                if (!this.props.readOnly) {
                  this.onCheckboxSelection("type", type.value);
                }
              }}
              checked={configurationObject.type.includes(type.value)}
              disabled={this.props.readOnly}
              label={type.label}
              key={type.label}
            />
          </Col>
        ))}
      </Row>
    );
  };

  renderLevel = () => {
    let { configurationObject } = this.props.configurationData;
    return (
      <Col md={4} className="px-0">
        <Row>
          <Col md={12} className='px-0'>
            <strong className='subText'>
              {Strings(
                this.props.readOnly
                  ? "minimartLevel"
                  : "selectMinimartLevel"
              )}
            </strong>
          </Col>
          <Col md={{ offset: 2, span: 5 }} className='ps-0 pt-1'>
            {" "}
            <TextFieldComponent
              title=''
              id='minLevel'
              type='select'
              className='noMargin pt-1 textFieldHeight'
              handleChange={this.onChange}
              value={configurationObject.minLevel}
              multiple={false}
              halfWidth={false}
              disabled={this.props.readOnly}
            >
              {MINIMART_LEVELS.map((version) => {
                return (
                  <option
                    key={
                      version.value ||
                      version.label ||
                      version.name ||
                      version.key ||
                      version.pstid ||
                      version
                    }
                    value={
                      version.value ||
                      version.label ||
                      version.id ||
                      version.pstid ||
                      version.name ||
                      version
                    }
                  >
                    {">= "}
                    {version.label ||
                      version.name ||
                      version.pstn ||
                      version}
                  </option>
                );
              })}
            </TextFieldComponent>
          </Col>
        </Row>
      </Col>
    );
  };

  onPercentageChange = (event) => {
    let { configurationObject } = this.props.configurationData;
    let configurationData = {
      ...configurationObject,
    };
    configurationData.moreThanlargestAmountPercentage = event.value;
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  onAmountChange = (event) => {
    let { configurationObject } = this.props.configurationData;
    let configurationData = {
      ...configurationObject,
    };
    configurationData.loanAmount = event.value;
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  renderPercentage = () => {
    let { configurationObject } = this.props.configurationData;
    return (
      <Col md={4}>
        <Row>
          <Col md={12} className='px-0'>
            <strong className='subText'>
              {Strings("largetPercentage")}
            </strong>
          </Col>
          <Col md={{ offset: 3, span: 5 }} className='ps-0 pt-1'>
            <NumericFormat
              className='form-control w-100 textFieldHeight'
              onValueChange={this.onPercentageChange}
              value={
                configurationObject.moreThanlargestAmountPercentage
              }
              type='text'
              decimalScale='2'
              disabled={this.props.readOnly}
              isAllowed={({ floatValue }) =>
                floatValue <= 100.0 && floatValue >= 0.0
              }
              allowNegative={false}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  renderAmount = () => {
    let { configurationObject } = this.props.configurationData;
    return (
      <Col md={4}>
        <Row>
          <Col md={12} className='px-0'>
            <strong className='subText'>
              {Strings("loanAmountLimit")}
            </strong>
          </Col>
          <Col md={{ offset: 3, span: 5 }} className='ps-0 pt-1'>
            <NumericFormat
              className='form-control w-100 textFieldHeight'
              onValueChange={this.onAmountChange}
              value={configurationObject.loanAmount}
              type='text'
              decimalScale='2'
              disabled={this.props.readOnly}
              isAllowed={({ floatValue }) => floatValue > 0}
              allowNegative={false}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  minimumCredoCreditScoreChange = (event) => {
    let { configurationObject } = this.props.configurationData;
    let configurationData = {
      ...configurationObject,
    };
    configurationData.minCredoScore = event.value;
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  renderCredoCreditScore = () => {
    let { configurationObject } = this.props.configurationData;

    return (
      <Row className="pb-3">
        <Col md={12} className='px-0'>
          <strong className='subText'>
            {Strings("minCredoCreditScore")}
          </strong>
        </Col>
        <Col md={4} className='ps-0 pt-1'>
          <NumericFormat
            className='form-control w-100 textFieldHeight'
            onValueChange={this.minimumCredoCreditScoreChange}
            value={configurationObject.minCredoScore}
            type='text'
            decimalScale='2'
            disabled={this.props.readOnly}
            isAllowed={({ floatValue }) => floatValue > 0}
            allowNegative={false}
          />
        </Col>
      </Row>
    );
  };

  onChangeStopAtOperationsAmount = (event) => {
    let { configurationObject } = this.props.configurationData;
    let configurationData = {
      ...configurationObject,
    };
    configurationData.stopAtOperationsAmount = event.value;
    this.props.saveEditedDataInTheState(configurationData, false);
  };

  renderStopAtOperationsAmount = () => {
    let { configurationObject } = this.props.configurationData;

    return (
      <Row className="pb-3">
        <Col md={12} className='px-0'>
          <strong className='subText'>
            {Strings("stopAtOperationsAmount")}
          </strong>
        </Col>
        <Col md={4} className='ps-0 pt-1'>
          <NumericFormat
            className='form-control w-100 textFieldHeight'
            onValueChange={this.onChangeStopAtOperationsAmount}
            value={configurationObject.stopAtOperationsAmount}
            type='text'
            decimalScale='2'
            disabled={this.props.readOnly}
            isAllowed={({ floatValue }) => floatValue > 0}
            allowNegative={false}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className='px-5 mx-5 analystConfiguration'>
        {/* Render Minimart Grade */}
        {this.renderGrade()}
        {/* Render Minimart Type */}
        {this.renderType()}
        <Row className='py-3'>
          {/* Render Minimart Level */}
          {this.renderLevel()}
          {/* Render Loan larget amount percentage */}
          {this.renderPercentage()}
          {this.renderAmount()}
        </Row>
        {/* Render Credo credit score */}
        {this.renderCredoCreditScore()}
        {/* Stop the loan at Operations if amount is greater than: */}
        {this.renderStopAtOperationsAmount()}
      </div>
    );
  }
}

export default AnalystConfiguration;
