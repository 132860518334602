import React from "react";
import { Row, Col } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import moment from "moment";
import {
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";
import Utility from "../../utils/Utility";
import "./CallNotes.scss";

class CallNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment().subtract(1, "months"),
      toDate: new Date(),
    };
  }

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({ [key]: DateValue });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        fromDate: moment().subtract(1, "months"),
        toDate: new Date(),
      },
      () => {
        this.props.handleResetClick();
      }
    );
  };

  downloadReport = () => {
    this.props.handleOnClickSearchFilter({
      fd: Utility.convertDateIntoMiliSeconds(this.state.fromDate),
      td: Utility.convertDateIntoMiliSeconds(this.state.toDate),
    });
  };
  render() {
    let CallNoteFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.fromDate,

        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
    ];
    return (
      <div className='callNotes container-fluid ListContainer'>
        <Row>
          <Col md={9} className='ps-0 pt-3'>
            <SearchFilter
              filters={CallNoteFilters}
              handleOnClickResetFilter={this.handleOnClickResetFilter}
              paddingRight={0}
            />
          </Col>
          <Col md={3} className='align-button-end pe-0'>
            <div>
              <label
                className='textLabels textBorder cursorPointer bold mb-2'
                onClick={this.downloadReport}
              >
                {Strings("exportReport")}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>
      </div>
    );
  }
}

export default CallNotes;
