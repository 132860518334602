import React from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";

//Constants
import { CONFIGURATION_DATA_TYPE } from "../../constants/appConstants";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import addUser from "../../resources/images/ic_add_user_blue.svg";
import deleteRange from "../../resources/images/ic_notes_minus_blue.svg";
import Utility from "../../utils/Utility";

const stylesTableCell = () => {
  return styled(TableCell)(({ theme }) => {
    return {
      [`&.${tableCellClasses.head}`]: {
        fontWeight: "bold",
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    };
  });
};
const onInputValueChange = (props, event, loanTypeIndex, index) => {
  const value = event.target.value;

  let rewards =
    props.configurationData.configurationObject.loanRewards;

  rewards.data[loanTypeIndex].data[index][event.target.name] =
    parseFloat(value === "-" ? -1 : value);

  props.saveEditedDataInTheState(
    rewards,
    CONFIGURATION_DATA_TYPE.REWARDS,
    false,
    false,
    false,
    true,
    { loanTypeIndex: loanTypeIndex }
  );
};

const renderNumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      type='text'
      decimalScale={"0"}
      isNumericString
      allowNegative={false}
      isAllowed={props.isallowed}
      disabled={props.disabled}
      thousandSeparator={props.thousandSeparator}
    />
  );
});
const renderTableCell = (
  props,
  name,
  value,
  isEnd,
  isAllowed,
  loanTypeIndex,
  index
) => {
  let cellValue =
    isEnd && value === -1
      ? "-"
      : Utility.getCurrencyRepresentationOfAmount(value);
  return (
    <TableCell component='th' scope='row' title={cellValue}>
      {props.readOnly ? (
        cellValue
      ) : (
        <div className='textBox input'>
          <TextField
            placeholder={"0"}
            value={cellValue}
            onChange={(event) =>
              onInputValueChange(props, event, loanTypeIndex, index)
            }
            name={name}
            id='formatted-numberformat-input'
            InputProps={{
              inputComponent: renderNumberFormatCustom,
            }}
            isallowed={isAllowed.toString()}
            variant='standard'
            disabled={isEnd && value === -1}
            thousandSeparator={true}
          />
        </div>
      )}
    </TableCell>
  );
};

const addRange = (props, loanTypeIndex) => {
  let newRangeObject = {
    start: 0,
    end: 0,
    rewards: 0,
  };
  let rewards =
    props.configurationData.configurationObject.loanRewards;
  //Adding row in second last position
  rewards.data[loanTypeIndex].data.splice(
    rewards.data - 1,
    0,
    newRangeObject
  );
  props.saveEditedDataInTheState(
    rewards,
    CONFIGURATION_DATA_TYPE.REWARDS,
    false,
    false,
    false,
    true,
    { loanTypeIndex: loanTypeIndex }
  );
};

const deleteRangeData = (props, loanTypeIndex, index) => {
  let rewards =
    props.configurationData.configurationObject.loanRewards;
  rewards.data[loanTypeIndex].data.splice(index, 1);
  props.saveEditedDataInTheState(
    rewards,
    CONFIGURATION_DATA_TYPE.REWARDS,
    false,
    false,
    false,
    true,
    { loanTypeIndex: loanTypeIndex }
  );
};
const onExpiryChange = (props, event) => {
  let rewards =
    props.configurationData.configurationObject[event.target.name];
  rewards.expiry = event.target.value;
  props.saveEditedDataInTheState(
    rewards,
    CONFIGURATION_DATA_TYPE.REWARDS,
    false,
    false,
    false,
    false,
    null
  );
};

const onRewardChange = (props, event) => {
  let referralRewards =
    props.configurationData.configurationObject[event.target.name];

  referralRewards.rewards = parseInt(
    event.target.value === "" ? 0 : event.target.value
  );
  props.saveEditedDataInTheState(
    referralRewards,
    CONFIGURATION_DATA_TYPE.REWARDS,
    false,
    false,
    false,
    false,
    null
  );
};

const RewardsConfiguration = (props) => {
  let rewards =
    props.configurationData.configurationObject.loanRewards.data;
  const StyledTableCell = stylesTableCell();

  const renderExpiry = (key, colSize) => {
    return (
      <div
        className={`expiryMonth ps-3 ${key === "loanRewards" ? "py-3 m-3 " : ""
          }`}
      >
        <Col md={colSize}>
          <TextFieldComponent
            required
            id='expiryInMonths'
            type='text'
            placeholderTop={Strings("expiryInMonths")}
            handleChange={(event) => onExpiryChange(props, event)}
            value={
              props.configurationData.configurationObject[key].expiry
            }
            disabled={props.readOnly}
            maxLength={4}
            onKeyDown={Utility.allowOnlyNumber}
            name={key}
          />
        </Col>
      </div>
    );
  };

  const renderReferalReward = () => {
    return (
      <Row>
        <Col md={12} className='pb-3'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography component={"span"}>
                {Strings("referralReward")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className='pt-0'>
              <Typography component={"span"}>
                <Row className='justify-content-center newCommissionScheme'>
                  {/* <Col md={3} className='px-4 d-flex '>
                    {renderExpiry("referralRewards", 12)}
                  </Col> */}
                  <Col md={3} className='px-4 d-flex '>
                    <TextFieldComponent
                      required
                      // id='expiryInMonths'
                      type='text'
                      placeholderTop={Strings("rewardPoints")}
                      handleChange={(event) => {
                        onRewardChange(props, event);
                      }}
                      value={
                        props.configurationData.configurationObject
                          .referralRewards.rewards
                      }
                      disabled={props.readOnly}
                      maxLength={4}
                      onKeyDown={Utility.allowOnlyNumber}
                      name={"referralRewards"}
                    />
                  </Col>
                </Row>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {renderExpiry("loanRewards", 2)}
      {rewards.map((loanType, loanTypeIndex) => {
        return (
          <Row key={loanTypeIndex}>
            <Col md={12} className='pb-3' key={loanType.type}>
              <Accordion
                key={loanType.type}
                defaultExpanded={loanTypeIndex === 0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography component={"span"}>
                    {Strings(loanType.type)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={"span"}>
                    <Row className='justify-content-center newCommissionScheme'>
                      <Col
                        md={{ span: 7, offset: 0 }}
                        className='px-4 d-flex '
                      >
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  {Strings("start")} &nbsp;&gt;=
                                </StyledTableCell>
                                <StyledTableCell>
                                  {Strings("end")} &nbsp;&lt;=
                                </StyledTableCell>
                                <StyledTableCell>
                                  {Strings("rewardPoints")}
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loanType.data.length > 0 &&
                                loanType.data.map((range, index) => {
                                  return (
                                    <TableRow key={index}>
                                      {renderTableCell(
                                        props,
                                        "start",
                                        range.start,
                                        false,
                                        true,
                                        loanTypeIndex,
                                        index
                                      )}
                                      {renderTableCell(
                                        props,
                                        "end",
                                        range.end,
                                        true,
                                        true,
                                        loanTypeIndex,
                                        index
                                      )}
                                      {renderTableCell(
                                        props,
                                        "rewards",
                                        range.rewards,
                                        false,
                                        ({ floatValue }) =>
                                          floatValue <= 1.0 &&
                                          floatValue >= 0.0,
                                        loanTypeIndex,
                                        index
                                      )}
                                      {index !==
                                        loanType.data.length - 1 &&
                                        !props.readOnly ? (
                                        <td>
                                          <img
                                            className='deleteIcon'
                                            src={deleteRange}
                                            onClick={() =>
                                              deleteRangeData(
                                                props,
                                                loanTypeIndex,
                                                index
                                              )
                                            }
                                            alt='delete'
                                          />
                                        </td>
                                      ) : (
                                        <td width='35px' />
                                      )}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                      {!props.readOnly && (
                        <Col
                          md={{ span: 2, offset: 5 }}
                          className='px-4 text-end '
                        >
                          <img
                            src={addUser}
                            alt='add'
                            onClick={() => {
                              addRange(props, loanTypeIndex);
                            }}
                            className='addRange'
                          />
                        </Col>
                      )}
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
        );
      })}
      {renderReferalReward()}
    </>
  );
};

export default RewardsConfiguration;
