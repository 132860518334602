import React from "react";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

// Components
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import Calender from "../Calender/Calender";
import MonthYearPicker from "../MonthYearPicker/MonthYearPicker";

// Constants
import {
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_TYPE,
  DATE_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../../constants/appConstants";

//Strings
import { Strings } from "../../../resources/i18n/i18n";

// Styles
import "./SearchFilter.scss";
import Utility from "../../../utils/Utility";

const CalenderGroup = (props) => {
  return (
    <Row className='calenderFilterContainer'>
      <Col md={6} className='calenderFilter ps-0'>
        <Calender
          id='fromDate'
          dateFormat={INVOICE_DATE_FORMAT}
          placeholder={Strings("FromDate")}
          placeholderTopClass='placeholderTopClass'
          handleChange={props.handleCalenderFromChange}
          value={
            props.fromDate !== undefined && props.fromDate !== ""
              ? moment(props.fromDate).format(INVOICE_DATE_FORMAT)
              : ""
          }
        />
      </Col>
      <Col md={6} className='calenderFilter pe-0'>
        <Calender
          id='toDate'
          dateFormat={INVOICE_DATE_FORMAT}
          placeholder={Strings("ToDate")}
          placeholderTopClass='placeholderTopClass'
          handleChange={props.handleCalenderToChange}
          value={
            props.toDate !== undefined && props.toDate !== ""
              ? moment(props.toDate).format(INVOICE_DATE_FORMAT)
              : ""
          }
        />
      </Col>
    </Row>
  );
};

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      invalidFields: []
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.setState({
        showErrorMessage: false,
        invalidFields: []
      });
    }
  }
  renderSearchFilterInput = (filter) => {
    let filterClassName;
    let errorClass = this.state.invalidFields.includes(filter.fieldname) ? " error-data" : "";
    if (filter.paddingRight)
      filterClassName = `ps-0 pe-${filter.paddingRight}`;
    else if (filter.paddingTop) filterClassName = "ps-0 pt-2";
    else filterClassName = "ps-0";
    return (
      <Col
        md={filter.column !== undefined ? filter.column : 2}
        className={filterClassName}
        key={filter.fieldname || filter.name}
      >
        <TextFieldComponent
          id='searchVersion'
          type={filter.inputType ? filter.inputType : "text"}
          value={filter.Value}
          placeholder={
            filter.searchPlaceHolder &&
              filter.searchPlaceHolder !== ""
              ? filter.searchPlaceHolder
              : ""
          }
          handleChange={
            filter.key
              ? (event) => filter.handleOnChange(filter.key, event)
              : (event) => filter.handleOnChange(event)
          }
          halfWidth={false}
          disabled={filter.disabled}
          name={filter.name ? filter.name : ""}
          autoFocus
          errorClass={errorClass}
        />
      </Col>
    );
  };

  renderSearchFilterSelection = (filter) => {
    let filterClassName;
    if (filter.paddingRight !== undefined)
      if (filter.paddingTop)
        filterClassName = `ps-0 pt-2 pe-${filter.paddingRight}`;
      else filterClassName = `ps-0 pe-${filter.paddingRight}`;
    else if (filter.paddingTop) filterClassName = "ps-0 pt-2";
    else filterClassName = "ps-0";
    return (
      <Col
        md={filter.column !== undefined ? filter.column : 2}
        className={filterClassName}
        key={Math.random()}
      >
        <TextFieldComponent
          title={filter.disabled ? Strings("CategoryDisabled") : ""}
          id={filter.id}
          type='select'
          multiple={false}
          value={filter.Value}
          handleChange={
            filter.key
              ? (event) => filter.handleOnChange(filter.key, event)
              : (event) => filter.handleOnChange(event)
          }
          halfWidth={false}
          disabled={filter.disabled}
          key={Math.random()}
          name={filter.name ? filter.name : ""}
        >
          {filter.filterDropdownList &&
            filter.filterDropdownList.map((version) => {
              return (
                <option
                  key={
                    version.value ||
                    version.label ||
                    version.name ||
                    version.key ||
                    version
                  }
                  value={typeof version === "object" ?
                    version.ai || version.value || version.id || version.userId : version
                  }
                >
                  {typeof version === "object" ? version.at || version.label || version.name : Strings(version)}
                </option>
              );
            })}
        </TextFieldComponent>
      </Col>
    );
  };

  renderSearchFilterMonthYearPicker = (filter) => {
    return (
      <Col
        md={filter.column ? filter.column : 2}
        className={
          this.props.paddingRight
            ? `ps-0 pe-${this.props.paddingRight}`
            : "ps-0"
        }
        key={Math.random()}
      >
        <div className='datePickerPanel'>
          <MonthYearPicker
            handleChangeMonthYearSelection={
              filter.handleChangeMonthYearSelection
            }
            countOfMonths={1}
            closeOnSelect={true}
            value={filter.Value}
          />
        </div>
      </Col>
    );
  };

  renderSearchFilterYearPicker = (filter) => {
    return (
      <Col
        md={filter.column ? filter.column : 2}
        className={
          this.props.paddingRight
            ? `ps-0 pe-${this.props.paddingRight}`
            : "ps-0"
        }
        key={Math.random()}
      >
        <div className='datePickerPanel'>
          <MonthYearPicker
            type='year'
            handleChangeMonthYearSelection={
              filter.handleChangeMonthYearSelection
            }
            countOfMonths={1}
            closeOnSelect={true}
            value={filter.Value}
          />
        </div>
      </Col>
    );
  };

  renderSearchFilterSingleDate = (filter) => {
    let filterClassName;
    if (this.props.paddingRight)
      filterClassName = `ps-0 pe-${this.props.paddingRight}`;
    else if (this.props.paddingTop) filterClassName = "pt-3";
    else filterClassName = "ps-0";
    return (
      <Col
        md={filter.column ? filter.column : 2}
        className={filterClassName}
        key={Math.random()}
      >
        <div className='datePickerPanel'>
          <Calender
            placeholderTop={Strings(filter.placeholderTop)}
            placeholder={Strings(filter.placeholder)}
            handleChange={filter.handleCalenderChange}
            value={filter.Value}
            dateFormat={filter.dateFormat}
          />
        </div>
      </Col>
    );
  };

  renderSearchFilterExtraButtons = () => {
    return (
      <>
        {this.props.spaceBetween && (
          <Col md={this.props.spaceInmd ? this.props.spaceInmd : 2} />
        )}
        {/* <div className='addBtnContainer'> */}
        {this.props.addButton && (
          <Col
            md={
              this.props.buttonWidth
                ? this.props.buttonWidth
                : " pe-0 ps-4 w-auto"
            }
          >
            <div
              className={`pull-right p-0 ${this.props.addButtonMarginTop}`}
            >
              <Button
                className='addOrUpdateUserTopButtons alignText px-1'
                onClick={this.props.handleAddUser}
              >
                {this.props.buttonText}
              </Button>
            </div>
          </Col>
        )}

        {this.props.addAnotherButton && (
          <Col
            md={
              this.props.anotherButtonWidth
                ? this.props.anotherButtonWidth
                : " ps-2 w-auto pe-0 "
            }
          >
            <div className='pull-right'>
              <Button
                className='addOrUpdateUserTopButtons alignText px-1'
                onClick={this.props.AnotherButtonClick}
                disabled={this.props.disabled}
              >
                <div
                  title={this.props.anotherButtonText}
                  className='addButtonText'
                >
                  <div
                    title={this.props.anotherButtonText}
                    className='addButtonText'
                  >
                    {this.props.anotherButtonText}
                  </div>
                </div>
              </Button>
            </div>
          </Col>
        )}
        {/* </div> */}
      </>
    );
  };
  testInput = () => {
    let result = true;
    const invalidFields = [];
    const validationMap = {
      [SEARCH_FILTER_FIELDS.MATRICES_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.CI_CODE]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.CEDULA_ID]: Utility.isValidCedulaID,
      [SEARCH_FILTER_FIELDS.LOAN_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.INVOICE_NUMBER]: Utility.isInvoiceNumber,
      [SEARCH_FILTER_FIELDS.VERSION]: Utility.isAlphanumericWithSpecialChars,
      [SEARCH_FILTER_FIELDS.APPLICATION_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.CODE]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.CI_ID]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.CLIENT_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.DOUBLE_INPUT]: Utility.isNumber,
      // Currently we are giving same validations to the Identification number field because currently it has same value as per the
      // Cedula ID but in future we need to change the validation after the passport nummber gets introduced.
      [SEARCH_FILTER_FIELDS.IDENTIFICATION_NUMBER]: Utility.isValidCedulaID,
      [SEARCH_FILTER_FIELDS.MINIMART_CODE]: Utility.isAlphanumeric,
    };
    this.props.filters.forEach((filter) => {
      const validator = validationMap[filter.fieldname];
      if (filter.Value && typeof filter.Value !== "object" && filter.Value.trim().length > 0 && validator) {
        if (!validator(filter.Value)) {
          result = false;
          invalidFields.push(filter.fieldname);
        }
      } else if (filter.Value && typeof filter.Value !== "object" && filter?.Value?.trim() === "") {
        result = false;
        invalidFields.push(filter.fieldname);
      }
    });
    this.setState({ invalidFields });
    return result
  }

  handleOnClickReset = () => {
    this.setState({
      showErrorMessage: false
    })
    this.props.handleOnClickResetFilter()
  }
  handleOnClickGo = () => {
    this.setState({
      showErrorMessage: false
    })
    if (this.testInput()) {
      this.props.handleOnClickSearchFilter();
    }
    else {
      this.setState({
        showErrorMessage: true
      })
    }
  }
  renderSearchFilterButtons = () => {
    let goBtn_md;
    if (this.props.buttonSize) goBtn_md = 2;
    else if (this.props.smallButtonSize) goBtn_md = 3;
    else goBtn_md = 1;
    let goBtnClassName;
    if (this.props.paddingTop)
      if (
        this.props.removeLeftPadding &&
        this.props.removeLeftPadding === true
      )
        goBtnClassName = ` ps-0 pt-${this.props.paddingTop}`;
      else goBtnClassName = `pt-${this.props.paddingTop}`;
    else if (this.props.buttonSize && !this.props.smallButtonSize)
      goBtnClassName = "ps-0 colWidth goBtnContainer";
    else goBtnClassName = "ps-0 goBtnContainer";
    return (
      <>
        {this.props?.filters && (
          <Col md={goBtn_md} className={goBtnClassName}>
            <Button
              className='addOrUpdateUserTopButtons alignText w-100'
              onClick={
                () => this.handleOnClickGo()
              }
            >
              <div title={Strings("Go")} className='addButtonText'>
                {Strings("Go")}
              </div>
            </Button>
          </Col>
        )}

        {this.props?.filters && (
          <Col
            md={1}
            className={
              this.props.paddingTop
                ? `ps-0 pt-${this.props.paddingTop}`
                : "ps-0"
            }
          >
            <Button
              className='buttonBorder'
              onClick={() => this.handleOnClickReset()}
            >
              <div title={Strings("Reset")} className='addButtonText'>
                {Strings("Reset")}
              </div>
            </Button>
          </Col>
        )}
        {this.renderSearchFilterExtraButtons()}
      </>
    );
  };

  renderAmountInputField = (filter) => {
    let filterClassName;
    if (filter.paddingRight)
      filterClassName = `ps-0 pe-${filter.paddingRight}`;
    else if (filter.paddingTop) filterClassName = "ps-0 pt-2";
    else filterClassName = "ps-0";
    return (
      <Col
        md={filter.column !== undefined ? filter.column : 2}
        className={filterClassName}
        key={filter.fieldname}
      >
        <NumericFormat
          className='fullWidth h-100 form-control'
          onValueChange={(event) => {
            filter.handleOnChange({
              ...event,
              target: {
                name: filter.name,
                value: event.floatValue ? event.floatValue : "",
              },
            });
          }}
          value={filter.Value}
          thousandSeparator={true}
          prefix={"$"}
          type='text'
          decimalScale='2'
          name={filter.name}
          placeholder={filter.searchPlaceHolder}
          key={filter.name}
          allowNegative={false}
        />
      </Col>
    );
  };

  render() {
    //NOSONAR
    return (
      <div
        className={
          this.props.paddingLeft
            ? "searchFilter container-fluid ps-3 pe-3"
            : "searchFilter"
        }
      >
        <Row
          className={
            this.props.className
              ? `searchUser h-auto ${this.props.className}`
              : "searchUser h-auto "
          }
        >
          {this.state.showErrorMessage &&
            <Col md={12} className="errors ps-0 ">
              {Strings("InvalidInput")}
            </Col>}
          {this.props.filters?.map((filter, index) => {
            if (filter.type === SEARCH_FILTER_TYPE.INPUT) {
              return this.renderSearchFilterInput(filter, index);
            } else if (filter.type === SEARCH_FILTER_TYPE.SELECTION) {
              return this.renderSearchFilterSelection(filter);
            } else if (
              filter.type === SEARCH_FILTER_TYPE.MONTH_YEAR_PICKER
            ) {
              return this.renderSearchFilterMonthYearPicker(filter);
            } else if (filter.type === "YearPicker") {
              return this.renderSearchFilterYearPicker(filter);
            } else if (
              filter.type === SEARCH_FILTER_TYPE.SINGLE_DATE
            ) {
              return this.renderSearchFilterSingleDate(filter);
            } else if (filter.type === SEARCH_FILTER_TYPE.AMOUNT) {
              return this.renderAmountInputField(filter);
            } else {
              return (
                <Col
                  md={filter.column !== undefined ? filter.column : 4}
                  key={filter.fieldname}
                  className={`${filter.paddingTop ? "pt-2 " : " "
                    } ps-0`}
                >
                  <CalenderGroup
                    fromDate={filter.fromDate}
                    toDate={filter.toDate}
                    handleCalenderFromChange={filter.handleCalenderChange(
                      DATE_TYPE.FROM_DATE
                    )}
                    handleCalenderToChange={filter.handleCalenderChange(
                      DATE_TYPE.TO_DATE
                    )}
                  />
                </Col>
              );
            }
          })}
          {!this.props.hideSearchButtons &&
            this.renderSearchFilterButtons()}
        </Row>
      </div>
    );
  }
}

export default SearchFilter;
