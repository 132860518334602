import React from "react";
import { Row, Button, Col } from "react-bootstrap";

// Strings
import { Strings } from "../../resources/i18n/i18n";

import LOGO_MATRICES from "../../resources/images/ic_login_logo.png";
import lock from "../../resources/images/ic_password.png";
import username from "../../resources/images/ic_username.png";

// Styles
import "./Login.scss";

// Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      showPassword: false,
    };
  }

  /**
   * Function to set form field inputs into states on onChange event
   */
  handleChange = (event, type) => {
    switch (type) {
      case "username":
        this.setState({ username: event.target.value });
        break;
      case "Password":
        this.setState({ password: event.target.value });
        break;
      default:
        break;
    }
  };

  /**
   * On press of 'Enter' button redirect to dashboard
   */
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleLogin();
    }
  };

  isDataValid = () => {
    return !!(
      this.state.username.trim() && this.state.password.trim()
    );
  };

  handleLogin = () => {
    if (localStorage.getItem("state")
      && JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
      this.setState({ errorMessage: Strings("UnauthorizedUser") });
    } else {
      this.setState({ errorMessage: "" });
      if (this.isDataValid()) {
        this.props.handleLogin(
          this.state.username,
          this.state.password
        );
      } else if (
        this.state.username.length !== 0 &&
        this.state.password.length !== 0
      ) {
        this.setState({
          errorMessage: Strings("InvalidLoginCredentials"),
        });
      } else {
        this.setState({ errorMessage: Strings("EmptyFieldsError") });
      }
    }
  };

  handleForgotPassword = () => {
    if (this.state.username.trim()) {
      let requestData = { username: this.state.username };
      this.props.handleForgotPassword(requestData);
    } else {
      this.setState({ errorMessage: Strings("EmptyUsernameError") });
    }
  };

  showOrHidePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  render() {
    return (
      <Col sm={12} className='loginComponent'>
        <Col
          sm={12}
          md={8}
          mdoffset={3}
          className=' noPadding loginPanel'
        >
          <Col sm={12} md={6} className='logoContainer noPadding'>
            <Col md={12} className='logoName'>
              <img
                src={LOGO_MATRICES}
                alt=''
                className='img-fluid'
                style={{ minWidth: "60%" }}
              />
            </Col>
          </Col>

          <Col sm={12} md={6} className='loginFieldsPanel'>
            <Row className='paddingTop'>
              <Col md={12} className='noPadding loginText'>
                {Strings("Login")}
              </Col>

              <Col md={12} className='noPadding errorText'>
                {this.state.errorMessage !== ""
                  ? this.state.errorMessage
                  : this.props.errorMessage}
              </Col>

              <TextFieldComponent
                type='text'
                id='loginUserName'
                value={this.state.username}
                placeholder={Strings("username") + "*"}
                required={true}
                maxLength={30}
                addOn={true}
                addOnImage={username}
                handleChange={(event) =>
                  this.handleChange(event, "username")
                }
                onKeyPress={this.handleKeyPress}
              />

              <TextFieldComponent
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                placeholder={Strings("Password") + "*"}
                required={true}
                maxLength={20}
                id='password'
                addOn={true}
                addOnImage={lock}
                handleChange={(event) =>
                  this.handleChange(event, "Password")
                }
                onKeyPress={this.handleKeyPress}
                showPasswordIcon={true}
                showOrHidePassword={this.showOrHidePassword}
              />
              <Col md={12} className='noPadding'>
                <Row className='noMargin loginPanelFooter d-flex align-items-center'>
                  <Col md={8} className='noPadding forgotPassword'>
                    <span
                      onClick={this.handleForgotPassword}
                      className='cursorPointer forgotPasswordText'
                    >
                      {Strings("ForgotPassword")}
                    </span>
                  </Col>
                  <Col md={4} className='d-flex justify-content-end'>
                    <Button
                      className='loginButton buttonBackground buttonText noWrapText'
                      onClick={this.handleLogin}
                    >
                      {Strings("Login")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Col>
      </Col>
    );
  }
}

export default Login;
