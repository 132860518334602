//Libraries
import React, { Component } from "react";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import Rater from "react-rater";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";

//Constants
import { SPED_LOAN_LEGEND } from "./constants";
import { Strings } from "../../resources/i18n/i18n";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  USER_RATING,
  DEFAULT_DURATION,
  CURRENCY,
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_CONST,
  DAILY_STATUS_SALES_PERSON_LEGENDS,
  DOWNLOAD_FILE_TYPE,
} from "../../constants/appConstants";
import { DEFAULT_SELECTION } from "../GeneralReports/Constants";
import { DailyLoanArray } from "../DailyStatus/DailyStatusConstants";
import { API } from "../../constants/APIConstants";

//Utilities
import Utility from "../../utils/Utility";

//Styles
import "./EfficiencyDashboard.scss";

//Assets
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

class EfficiencyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      filters: {
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
        name: props.searchFilter.un,
        branchId: props.searchFilter.br,
        grade: props.searchFilter.gr,
        offset: props.searchFilter.offset,
        limit: props.searchFilter.limit,
      },
      branches: this.props.branches,
      tooltipOpen: false,
    };
  }

  componentDidMount() {
    this.props.getBranchList();
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
  }

  // #region search filter handling
  renderSearchFilters = () => {
    let filters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.filters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.filters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("User"),
        handleOnChange: this.handleSearchChange,
        Value: this.state.filters.name,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON_NAME,
        key: "name",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSearchChange,
        Value: this.state.filters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.state.branches,
        key: "branchId",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSearchChange,
        Value: this.state.filters.grade,
        fieldname: SEARCH_FILTER_FIELDS.USER_RATING,
        filterDropdownList: USER_RATING,
        key: "grade",
      },
    ];

    return (
      <Row className='pt-3 ps-3'>
        <SearchFilter
          filters={filters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={() => this.resetSearchValues()}
          removeLeftPadding={true}
        />
        {this.renderDownloadButtons()}
      </Row>
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.toDate)) {
          DateValue = this.state.filters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.filters.fromDate,
              "days"
            ) === 0
              ? this.state.filters.fromDate
              : this.state.filters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: DateValue,
      },
    });
  };

  handleSearchChange = (key, event) => {
    let value = event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value,
      },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    let reqObj = {
      name: this.state.filters.name,
      branchId: this.state.filters.branchId
        ? this.state.filters.branchId
        : undefined,
      grade: this.state.filters.grade,
      fromDate: Utility.convertDateIntoMiliSeconds(
        this.state.filters.fromDate
      ),
      toDate: Utility.convertDateIntoMiliSeconds(
        this.state.filters.toDate
      ),
      limit: this.state.recordsPerPage,
      offset: this.state.activePage,
    };
    this.props.handleOnClickSearchFilter(reqObj);
  };

  resetSearchValues = () => {
    this.setState(
      {
        filters: {
          name: "",
          branchId: "",
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          grade: "",
          recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        },
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };
  // #endregion

  // #region visual representation handling
  renderCharts = () => {
    let loanPiechartData = [
      { count: this.props.pieChartStats.createdLoanCount },
      { count: this.props.pieChartStats.disbursedLoanCount },
      { count: this.props.pieChartStats.deniedLoans },
      { count: this.props.pieChartStats.pendingLoans },
    ];
    return (
      <React.Fragment>
        <Row className='pt-4 pb-2'>
          <Col md={4}>
            <span className='subHeading'>{Strings("minimarts")}</span>
          </Col>
          <Col md={6}>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
        </Row>
        <Row className='ms-3 ps-3'>
          <Col md={2} className='pe-0'>
            {this.renderPieChart(
              1,
              "salesPersonDailyStatus",
              this.props.pieChartStats.totalAssignedMinimarts !==
                undefined
                ? this.props.pieChartStats.totalAssignedMinimarts
                : 0
            )}
          </Col>
          <Col md={2} className='alignLegends pt-3 px-0'>
            {DAILY_STATUS_SALES_PERSON_LEGENDS.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.SALES_PERSON_DAILY_STATUS
                  ][index],
                  legend.key,
                  this.props.pieChartStats[legend.value]
                );
              }
            )}
          </Col>
          <Col md={2} className='pe-0'>
            {this.renderPieChart(
              0,
              "salesPersonDailyStatusForLoans",
              this.props.pieChartStats.totalLoans !== undefined
                ? this.props.pieChartStats.totalLoans
                : 0
            )}
          </Col>
          <Col md={2} className='alignLegends px-0 pt-3'>
            {SPED_LOAN_LEGEND.map((legend, index) => {
              return this.renderLegend(
                PIE_CHART_COLORS[
                PIE_CHART_COLORS_CONST
                  .SALES_PERSON_DAILY_STATUS_FOR_LOANS
                ][index],
                legend,
                loanPiechartData[index].count
              );
            })}
          </Col>
          <Col md={3} className='alignLegends pt-3 ps-5 pe-0'>
            {this.renderDailyStatusNameInLegends("inProcess")}
            {this.renderDailyStatusNameInLegends("disbursed")}
            {this.renderDailyStatusNameInLegends("pending")}
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderLegend = (color, grade, data, index) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };

    return (
      <Row className='legend' key={Math.random()}>
        <div className='ps-0' style={colorStyle} />
        <div className='pe-0 ps-2'>
          <div className='branchNameInLegends' title={Strings(grade)}>
            {Strings(grade)} &nbsp;&nbsp;
          </div>
        </div>
        <div className='branchNameInLegends fw-bold'>
          {data ? data : 0}
        </div>
      </Row>
    );
  };

  renderPiechartData = (data) => {
    let datakeys = Object.keys(data);
    let dataArray = datakeys.forEach((datakey, index) => {
      if (DailyLoanArray.includes(datakey)) {
        dataArray.push({ count: data[datakey] });
      }
    });
    return dataArray;
  };

  renderPieChart = (dataFlag, colors, total) => {
    let tobevisitedminimarts = 0;
    if (this.props.pieChartStats.totalAssignedMinimarts > 0) {
      tobevisitedminimarts =
        this.props.pieChartStats.totalAssignedMinimarts -
        this.props.pieChartStats.visitedMinimarts;
    }
    let data = [
      {
        count:
          this.props.pieChartStats.visitedMinimarts !== undefined
            ? this.props.pieChartStats.visitedMinimarts
            : 0,
      },
      { count: tobevisitedminimarts },
    ];
    let dailyloanArray = [
      { count: this.props.pieChartStats.createdLoanCount },
      { count: this.props.pieChartStats.disbursedLoanCount },
      { count: this.props.pieChartStats.deniedLoans },
      { count: this.props.pieChartStats.pendingLoans },
    ];
    let chartData = dataFlag ? data : dailyloanArray;
    return (
      <PieChartComponent
        data={chartData}
        colors={colors}
        index={0}
        innerRadius='60%'
        outerRadius='90%'
        datakey='count'
        height={150}
        showLabel={true}
        total={total}
        user='salesPerson'
      />
    );
  };

  renderDailyStatusNameInLegends = (typeStr) => {
    let amount = 0;
    if (typeStr === "inProcess") {
      amount = this.props.pieChartStats.createdLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.pieChartStats.createdLoanAmount
        )
        : 0;
    } else if (typeStr === "disbursed") {
      amount = this.props.pieChartStats.disbursedLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.pieChartStats.disbursedLoanAmount
        )
        : 0;
    } else if (typeStr === "pending") {
      amount = this.props.pieChartStats.pendingLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.pieChartStats.pendingLoanAmount
        )
        : 0;
    }

    return (
      <Row className='legend'>
        <div className='dailyStatusNameInLegends'>
          {Strings(typeStr)}
          {CURRENCY}
          {amount}
        </div>
      </Row>
    );
  };
  // #endregion

  // #region download button handling
  renderDownloadButtons = () => {
    return (
      <Row className='downloadButtonsContainer'>
        <Col md={2} className='downloadButtonsPosition'>
          <Button
            className='downloadButtons me-3'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
            }}
          >
            <img src={PDFdownload} alt='' />
          </Button>
          <Button
            className='downloadButtons'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
            }}
          >
            <img src={CSVdownload} alt='' />
          </Button>
        </Col>
      </Row>
    );
  };

  downloadFile = (type) => {
    this.props.handleDownloadFile(
      API.downloadSalespersonEfficiencyTableData + type,
      {
        search: {
          sn: this.state.filters.name || undefined,
          brn: this.state.filters.branchId
            ? this.state.filters.branchId
            : undefined,
          gr: this.state.filters.grade || undefined,
          fd: Utility.convertDateIntoMiliSeconds(
            this.state.filters.fromDate
          ),
          td: Utility.convertDateIntoMiliSeconds(
            this.state.filters.toDate
          ),
          limit: this.state.recordsPerPage * this.props.numberOfPages,
          offset: 0,
        },
      },
      type
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "fn":
        return row["fn"] + " " + row["ln"];
      case "vm":
        return row["vm"] + "/" + row["tam"];
      case "amtd":
        return row["amtd"] + "/" + row["pa"];
      case "rat":
        return (
          <Rater
            title={row[key]}
            rating={row[key]}
            total={5}
            interactive={false}
          />
        );
      case "glm":
        return row[key]
          ? Utility.getCurrencyRepresentationOfAmount(row[key])
          : 0;
      case "ple":
        return row[key].toFixed(2);
      case "crat":
        return row[key].toFixed(2);
      default:
        return row[key];
    }
  };

  // #endregion

  // #region tabular representation handling
  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "fn"
                          ? ` px-2 header`
                          : "text-center px-2 header"
                      }
                      title={column.name}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.uid}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "fn"
                          ? ` px-2`
                          : "text-center px-2"
                      }
                      component='th'
                      scope='row'
                      title={this.renderTableData(row, column.key)}
                      style={{ minWidth: column.minWidth }}
                      onClick={() =>
                        callBack(rowIndex, row, this.state.filters)
                      }
                    >
                      <div>
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderGrid = () => {
    return this.props.rowsCount !== 0 ? (
      <div className='branchListTable mt-5'>
        {this.renderCustomDataGrid(
          this.props.columns,
          this.props.rows,
          this.props.onRowClick
        )}
        <Col md={12} className='noPadding'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </div>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  // #endregion

  render() {
    return (
      <div className='paper'>
        {this.renderSearchFilters()}
        {this.renderCharts()}
        {this.renderGrid()}
      </div>
    );
  }
}

export default EfficiencyDashboard;
