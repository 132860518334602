import React, { Component } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
//UI Components
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

//Styles
import "./UserModal.scss";

//Strings
import { Strings } from "../../../resources/i18n/i18n";
import { Col } from "react-bootstrap";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      applicationId: "",
      errorMessage: "",
      loanNote: "",
      day: "",
      loanPayOff: null,
      gradeUpdate: "",
      resetGrade: false,
      revertPayment: "",
      textfieldDisabled: true,

    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    //NOSONAR
    return {
      open: nextProps.open,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }
  //to close modal
  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  handleUserInput = (event) => {
    this.setState({
      applicationId: event.target.value,
    });
  };

  handleLoanNote = (event) => {
    this.setState({
      loanNote: event.target.value,
      errorMessage: "",
    });
  };

  handleOnClickOk = () => {
    //NOSONAR
    let errorMessage = "";
    if (
      this.props.headerText === Strings(Strings("paymentReverse"))
    ) {
      if (this.state.loanNote.length === 0 && this.state.revertPayment === "DownPayment") {
        this.setState({
          errorMessage: Strings("errorEmptyValue"),
        });
      } else {
        if (this.state.revertPayment === "") {
          this.setState({
            errorMessage: Strings("ErrorOptionSelection"),
          });
        }
        else {

          this.props.modalButtonOk.onClick(this.state.loanNote);
        }
      }
    } else if (this.props.userIdInput) {
      if (this.state.applicationId !== "") {
        this.props.modalButtonOk.onClick(this.state.euroPhileId);
      } else {
        errorMessage = Strings("errorEmptyValue");
      }
      this.setState({
        errorMessage: errorMessage,
      });
    } else if (this.props.isCloseLoan) {
      if (this.state.loanNote) {
        if (this.props.loanStatus === "DISBURSED") {
          if (this.state.loanPayOff !== null) {
            this.props.modalButtonOk.onClick(
              this.state.loanNote,
              this.state.loanPayOff
            );
          } else {
            errorMessage = Strings("errorEmptyValue");
          }
        } else {
          this.props.modalButtonOk.onClick(
            this.state.loanNote,
            false
          );
        }
      } else {
        errorMessage = Strings("errorEmptyValue");
      }
      this.setState({
        errorMessage: errorMessage,
      });
    } else if (
      this.props.headerText === Strings("salesPersonAssignment") &&
      !this.props.isPayment &&
      this.state.day === ""
    ) {
      errorMessage = Strings("pleaseSelectDay");
      this.setState({
        errorMessage: errorMessage,
      });
    } else {
      this.props.modalButtonOk.onClick();
    }
  };

  renderModalInput = () => {
    return (
      <>
        {this.props.userIdInput && (
          <Row className='userInput'>
            <div className='inputFields '>
              <input
                type='number'
                onChange={this.handleUserInput}
                autoFocus
              />
            </div>
          </Row>
        )}
        {this.props.isCloseLoan && (
          <Row className='loanNoteInput'>
            <div className='loanNoteInputField '>
              <textarea
                placeholder={Strings("Note")}
                className='topMargin form-control noteTextArea'
                onChange={this.handleLoanNote}
                value={this.state.loanNote}
                cols={100}
                rows={3}
                maxLength={this.props.isCloseLoan ? 300 : 500}
              />
            </div>
          </Row>
        )}
      </>
    );
  };

  renderModalButton = () => {
    return (
      <div className='modalButton'>
        {this.props.modalButtonCancel && (
          <div className='pe-1'>
            <Button
              className={
                "primaryModalButton buttonMinWidth marginCancelButton " +
                this.props.modalButtonCancelStyle
              }
              onClick={() => {
                this.setState({
                  applicationId: "",
                  errorMessage: "",
                  loanNote: "",
                });
                this.props.modalButtonCancel.onClick();
              }}
            >
              {this.props.modalButtonCancel.text}
            </Button>
          </div>
        )}
        {this.props.modalButtonOk && (
          <div className='ps-1'>
            <Button
              className='primaryModalButton buttonBackground buttonMinWidth'
              onClick={this.handleOnClickOk}
            >
              {this.props.modalButtonOk.text}
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleRadioChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        errorMessage: ""
      },
      () => {
        this.props.handleRadioChange({
          [event.target.name]: this.state[event.target.name]
        });
      }
    )
    if (event.target.value === "EMI_payment") {
      this.setState({
        textfieldDisabled: false,
        loanNote: ""
      })
    }
    else {
      this.setState({
        textfieldDisabled: true

      })
    }
  };

  renderRadioButtons = (radioButtons) => {
    return (
      <div>
        <Col className='d-flex justify-content-center mt-0'>
          <RadioGroup
            row
            aria-label='radioButtons'
            name={radioButtons.key}
            onChange={this.handleRadioChange}
            value={radioButtons.key === "resetGrade"
              ? this.props.gradeObject.resetGrade
              : this.state[radioButtons.key]}
          >
            <FormControlLabel
              value={radioButtons.radiobutton1.value}
              control={<Radio />}
              label={radioButtons.radiobutton1.label}
              disabled={radioButtons.key === "gradeUpdate" && this.props.gradeObject.currentGrade === "A"}
            />
            <FormControlLabel
              value={radioButtons.radiobutton2.value}
              control={<Radio />}
              label={radioButtons.radiobutton2.label}
              disabled={radioButtons.key === "gradeUpdate" && this.props.gradeObject.currentGrade === "E"}
            />
          </RadioGroup>
        </Col>
      </div>
    );
  };

  renderLoanPayOffInput = () => {
    return (
      <Row>
        <Col md={8} className='closeLoanInput px-0'>
          <span>{this.props.modalText1}</span>
        </Col>

        <Col md={4} className=' px-0'>
          <span>
            <input
              type='radio'
              name='radio'
              onChange={() => {
                this.setState({
                  loanPayOff: true,
                  errorMessage: "",
                });
              }}
            />
            <label className='closeLoanRadioLabel'>
              {Strings("Yes")}
            </label>
          </span>
          <span>
            <input
              type='radio'
              name='radio'
              onChange={() => {
                this.setState({
                  loanPayOff: false,
                  errorMessage: "",
                });
              }}
            />
            <label className='closeLoanRadioLabel'>
              {Strings("No")}
            </label>
          </span>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        {/* general modal */}
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          //Modal opens with specified messages in props
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{ modal: "customModal" }}
          center
        >
          <Row className='userModal'>
            <Col md={12} className='headerModal'>
              {this.props.headerText}
            </Col>
            {/* Show only specified props of modal */}
            <Col md={12} className='modalContent'>
              {this.props.modalImage && (
                <Row className='modalImage'>
                  <img
                    src={this.props.modalImage}
                    alt=''
                    className='imageSpecification'
                  />
                </Row>
              )}
              {this.state.errorMessage && (
                <Row className='errors centerAlign ps-0 pt-2'>
                  {this.state.errorMessage}
                </Row>
              )}
              {(this.props.modalText1 || this.props.modalText2) && (
                <Row
                  noGutters
                  className={"message pb-2"}
                >
                  {this.props.modalText1 && (
                    <Col
                      md={12}
                      className={
                        this.props.isCloseLoan ? "text-start" : ""
                      }
                    >
                      {this.props.isCloseLoan ? (
                        this.props.loanStatus === "DISBURSED" ? ( //NOSONAR
                          this.renderLoanPayOffInput()
                        ) : null
                      ) : (
                        <span>{this.props.modalText1}</span>
                      )}
                    </Col>
                  )}
                  {this.props.headerText !== Strings("changeGrade") && this.props.modalText2 && (
                    <Col
                      md={12}
                      className={
                        this.props.isCloseLoan ? "text-start" : ""
                      }
                    >
                      {this.props.modalText2}
                    </Col>
                  )}
                </Row>
              )}

              {this.renderModalInput()}
              {this.props.headerText ===
                Strings("salesPersonAssignment") &&
                !this.props.isPayment && this.renderRadioButtons({
                  key: "day",
                  radiobutton1: { label: Strings("Today"), value: "Today" },
                  radiobutton2: { label: Strings("Tomorrow"), value: "Tomorrow" }
                })}
              {this.props.headerText ===
                Strings("paymentReverse") && (
                  <>
                    {this.renderRadioButtons({
                      key: "revertPayment",
                      radiobutton1: {
                        label: Strings("EMI_payment"),
                        value: "EMI_payment",
                      },
                      radiobutton2: {
                        label: Strings("DownPayment"),
                        value: "DownPayment",
                      },
                    })}
                    <Row className='loanNoteInput'>
                      <div className='loanNoteInputField '>
                        <textarea
                          placeholder={Strings("Note")}
                          className='topMargin form-control noteTextArea'
                          onChange={this.handleLoanNote}
                          value={this.state.loanNote}
                          cols={100}
                          rows={3}
                          maxLength={500}
                          disabled={this.state.textfieldDisabled ? false : true}
                        />
                      </div>
                    </Row>
                  </>
                )}
              {this.props.headerText === Strings("changeGrade") && this.renderRadioButtons({
                key: "gradeUpdate",
                radiobutton1: { label: Strings("UPGRADE"), value: "UPGRADE" },
                radiobutton2: { label: Strings("DOWNGRADE"), value: "DOWNGRADE" }
              })}
              {this.props.headerText === Strings("changeGrade") && (
                <Col
                  md={12}
                  className={"text-center"}
                >
                  {this.props.modalText2}
                </Col>
              )}
              {this.props.headerText === Strings("changeGrade") && this.renderRadioButtons({
                key: "resetGrade",
                radiobutton1: { label: Strings("Yes"), value: true },
                radiobutton2: { label: Strings("No"), value: false }
              })}

              {this.props.note && (
                <Col
                  md={12}
                  className='authorInfo d-flex justify-content-center pt-2'
                >
                  {this.props.note}
                </Col>
              )}
              {this.props.showHr && <hr className="opacity-100"/>}

              {this.renderModalButton()}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

UserModal.propTypes = {
  headerText: PropTypes.string,
  modalImage: PropTypes.string,
  modalText1: PropTypes.string,
  modalText2: PropTypes.string,
  modalButtonOk: PropTypes.object,
  modalButtonCancel: PropTypes.object,
  modalButtonCancelStyle: PropTypes.string,
  userIdInput: PropTypes.bool,
  isCloseLoan: PropTypes.bool,
};

UserModal.defaultProps = {
  modalButtonCancelStyle: "buttonBorder",
  isCloseLoan: false,
};

export default UserModal;
