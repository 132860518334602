import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";
import _ from "lodash";

//Components
import AnalystApproval from "./AnalystApproval";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//Action
import {
  getLoanDetailsByIdAction,
  sendUpdatedMinimartDetailsAction,
  setLoanDetailsIDAction,
} from "../LoanDetails/LoanDetailsAction";
import { getLoanApplicationDetailsByIdAction } from "../ViewLoanApplication/ViewLoanApplicationAction";
import {
  getCreditScoreOfMinimartAction,
  getCredoCreditScoreAction,
  updateLoanApplicationStatusAction,
  calculateCreditScoreAction,
  AddNoteAction,
  updateNoteAction,
  deleteNoteAction,
  getNoteAction,
  getAnalystDraftedInputAction,
  updateLoanNegotiationAndTermsAction,
  getEmiDurationAction,
  getDraftedNegotiationAction,
  updateOperationsOfficerInputsAction,
  updateAccountingOfficerInputAction,
  getAccountingOfficersDraftedInputAction,
  getOperationsOfficerDraftedInputAction,
  getApprovalNotesAction,
  deleteEvidenceDocumentAction,
  downloadDownpaymentReceiptAction,
  onSaveConciliationAction,
  updateOfficerInputAction,
  uploadTransferRecieptAction,
  getDropdownValuesForLoanApplicationFormAction,
  getDropdownValuesForSupplierFormAction,
  updateLoanApplicationAction,
  getLoanDetailsForConciliationAction,
  getPossibleCollectionCycleAction,
  getDisbursementStatusAction,
  getFrequencyConfigurationAction,
  getTermsAndrateAction,
} from "./AnalystApprovalAction";
import {
  getLoansBySearchFilterAction,
  downloadCreditAgreementAction,
} from "../MinimartDetails/MiniMartDetailsAction";
import { updateMinimartDetailsAction } from "../ApprovalMinimart/ApprovalMinimartAction";
import { setSearchFilterAction } from "../../constants/Action";
import { getMinimartBusinessTypes } from "../SuperDashBoard/SuperDashBoardAction";
import { supplierSearchFilterForParticularMinimartAction } from "../SupplierCatalog/SupplierCatalogAction";
import { bankDetailsSearchFilter } from "../BankConf/BankConfAction";

//Constants
import {
  NOTES_TYPE,
  LOAN_TYPES,
  ANALYSED_BY_SYSTEM,
  OPERATIONS_OFFICER_ACTION,
} from "./Constants";
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  LOAN_APPROVAL_FILTER,
  OPERATIONS_LOAN_APPROVAL_FILTER,
  LOAN_HISTORY_FILTER,
  ANALYST_LOAN_DETAIL_FILTER,
} from "../../constants/appConstants";
import { OPERATION_STATUS, API_ERROR_CODE } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import { FREQUENCIES } from "../LoanDetails/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./AnalystApproval.scss";
import { WEEKDAYS } from "../CustomConfiguration/Constants";

//Assets

class AnalystApprovalContainer extends React.Component {
  constructor(props) {
    super(props);
    /**
     * Below function call is used for restricting multiple API calls.
     * Passed API call and time in miliseconds to debounce function as arguments which ensures that the API call is made only once within a specified time frame, even if the button is clicked multiple times.
     */
    this.debounceUpdateLoanApplicationStatusAction = Utility.debounce(this.callUpdateLoanApplicationStatusAction, 90000); // 90000 is default request timeout
    this.state = {
      isAnalyst: props.loggedInUser.role === USER_ROLES.analyst,
      isOperationsOfficer:
        props.loggedInUser.role === USER_ROLES.operationsOfficer,
      isOperationsModerator:
        props.loggedInUser.role === USER_ROLES.operationsModerator,
      isOperationsManager:
        props.loggedInUser.role === USER_ROLES.operationsManager,
      isAccountsOfficer:
        props.loggedInUser.role === USER_ROLES.accountsOfficer,
      isAnalystModerator:
        props.loggedInUser.role === USER_ROLES.analystModerator,
      applicationId: props.selectedLoanDetails.applicationId,
      loanType: props.selectedLoanDetails.loanType,
      loanId: props.selectedLoanDetails.loanId,
      creditScore: 0.0,
      credoCreditScore: 0.0,
      thresholdCredoCreditScore: "",
      loanHeaderDetails: null,
      loanApplicationDetails: {},
      regulatorsNotes: [],
      referenceNotes: [],
      riskNotes: [],
      analystPrefilledData: null,
      draftedNegotiation: null,
      emiDuration: null,
      errorMessage: "",
      successToastMessage: "",
      toShowToast: false,
      accountsDraftedData: null,
      operationsDraftedData: null,
      isHistory: props.isHistory ? props.isHistory : false,
      toUpdateNegotiation: false,
      loanStatus: props.selectedLoanDetails.status,
      approvalNotesList: [],
      messengerNotes: [],
      loans: [],
      numberOfpages: 0,
      branches: props.branches,
      zones: props.zones,
      mZones: props.mZones,
      dropdownValues: [],
      supplierDropdownValues: [],
      suppliersList: [],
      bankDetails: [],
      loansToConciliate: [],
      termsArray: [],
      freqList: [],
      possibleCollectionDays: [],
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.getLoanDetailsById(this.state.applicationId);
    this.getLoanApplicationDetailsById(this.state.applicationId);
    if (
      this.state.isOperationsOfficer ||
      ((this.state.isOperationsModerator || this.state.isOperationsManager) &&
        this.props.selectedFeature.featureName ===
        "OPERATIONS_OFFICER_DASHBOARD")
    ) {
      this.props.getBranchList();
    }
    if (
      this.state.isAnalyst
      || this.state.isAnalystModerator ||
      this.state.isOperationsOfficer
      || this.state.isOperationsManager ||
      this.state.isOperationsModerator
    ) {
      this.getMinimartBusinessTypes();
    }

    if (
      this.props.selectedFeature.featureName ===
      "OPERATIONS_OFFICER_DASHBOARD" &&
      (this.state.isOperationsOfficer ||
        this.state.isOperationsModerator || this.state.isOperationsManager)
    ) {
      this.getOperationsDraftedInputs(this.state.applicationId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_APPROVAL &&
      this.props.selectedFeature?.urls &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_APPROVAL,
        { ...LOAN_APPROVAL_FILTER }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL &&
      this.props.selectedFeature?.urls &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL,
        { ...OPERATIONS_LOAN_APPROVAL_FILTER }
      );
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_HISTORY &&
      this.props.selectedFeature?.urls &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_HISTORY,
        { ...LOAN_HISTORY_FILTER }
      );
    }
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL,
        { ...ANALYST_LOAN_DETAIL_FILTER }
      );
    }
  }

  getTermsArray = (amount, freq) => {
    let termsArray = [];
    termsArray.push(
      this.state.draftedNegotiation.term,
    );
    this.setState({ termsArray });
  }


  getFreqList = (amount) => {
    let freqList = [];
    freqList.push({
      label: this.state.draftedNegotiation.frequency,
      value: this.state.draftedNegotiation.frequency,
    });
    this.setState({ freqList: freqList });
  }


  getPossibleCollectionCycles = (req) => {
    this.setState({ showLoadingModal: true });
    let request = req;
    request["mid"] = this.state.loanHeaderDetails.minimart.mid;
    if (request.fr !== FREQUENCIES.WEEKLY_THRICE && !this.state.isHistory) {
      request["frs"] = "LOAN_CREATION"
    }

    getPossibleCollectionCycleAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let possibleCollectionDays = Utility.convertCollectionDaysToSpanishForDropdown(response.data.data.possibleCollectionDays)
        this.setState({
          showLoadingModal: false,
          possibleCollectionDays,
          currentCollectionCycle: response.data.data.minimartCollectionCycle,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  }

  getLoanDetailsForConciliation = (callback) => {
    this.setState({ showLoadingModal: true });
    let request = {
      minimartId: this.state.loanHeaderDetails?.minimart.mid,
      applicationId: this.state.applicationId
    }
    getLoanDetailsForConciliationAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          loansToConciliate: response.data.data,
          errorMessage: "",
        }, callback);
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        }, callback);
      }
    });
  }

  getDropdownValuesForLoanApplicationForm = () => {
    getDropdownValuesForLoanApplicationFormAction(this.state.loanHeaderDetails.minimart.mid, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let dropdownValues = response.data.data.dcsm;
        this.setState({
          dropdownValues,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  }

  getDropdownValuesForSupplierForm = () => {
    getDropdownValuesForSupplierFormAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let supplierDropdownValues = response.data.data;
        this.setState({
          supplierDropdownValues,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  }

  getSupplierDetails = () => {
    supplierSearchFilterForParticularMinimartAction({
      mid: this.state.loanHeaderDetails.minimart.mid,
      ia: true
    },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            suppliersList: response.data.data.li,
            errorMessage: "",
            numberOfPages: response.data.data.np,
            showLoadingModal: false,
          });
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
            suppliersList: [],
            numberOfPages: 0,
          });
        }
      });
  }

  getBankDetails = () => {
    bankDetailsSearchFilter({}, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          numberOfPages: response.data.data.np,
          bankDetails: response.data.data.li,
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  }

  getMinimartBusinessTypes = () => {
    getMinimartBusinessTypes((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let list = Utility.createBusinessTypesList(
          response.data.data.bty
        );
        this.setState({
          businessTypeList: list,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  };

  getLoanDetailsById = (applicationId) => {
    this.setState({ showLoadingModal: true });
    const requestData = {
      applicationId: applicationId,
      callback: (Response) => {
        //NOSONAR
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              errorMessage: "",
              loanHeaderDetails: Response.data.data,
              showLoadingModal: false,
            },
            () => {
              this.getSuggestedLoanAmountAndTerm(this.state.applicationId);

              if (
                this.state.isAnalyst ||
                this.state.isAnalystModerator ||
                (this.state.isOperationsModerator &&
                  this.props.selectedFeature.featureName ===
                  "ANALYST_DASHBOARD")
              ) {
                this.getAnalystsDraftedInputs(
                  this.state.applicationId
                );
                this.getApprovalNotes(this.state.applicationId);
                this.getCreditScoreofMinimart(
                  Response.data.data.minimart.mid
                );
                this.state.loanHeaderDetails.info.if === "TPRESTA" &&
                  this.state.loanHeaderDetails.info.icr &&
                  this.getCredoCreditScore();
                this.getDropdownValuesForLoanApplicationForm();
                this.getDropdownValuesForSupplierForm();
                this.getSupplierDetails();
                this.getBankDetails();
              }
              if (
                this.props.selectedFeature.featureName ===
                "OPERATIONS_OFFICER_DASHBOARD" &&
                (this.state.isOperationsOfficer ||
                  this.state.isOperationsModerator || this.state.isOperationsManager)
              ) {
                if (
                  Response.data.data.association.aa !== null &&
                  Response.data.data.association.aa !==
                  ANALYSED_BY_SYSTEM
                ) {
                  this.getAnalystsDraftedInputs(
                    this.state.applicationId
                  );
                  this.getApprovalNotes(this.state.applicationId);
                }
              }
              this.props.getZonesByBranch(
                Response.data.data.minimart.br
              );
            }
          );
        } else {
          this.setState(
            {
              showLoadingModal: false,
              errorMessage: Response.error.message,
            },
            () => {
              this.scrollToTop();
            }
          );
        }
      },
      isAnalyst: this.state.isAnalyst,
      isOperationsOfficer: this.state.isOperationsOfficer,
      isOperationsManager: this.state.isOperationsManager,
      isAccountsOfficer: this.state.isAccountsOfficer,
      isAnalystModerator: this.state.isAnalystModerator,
      isOperationsModerator: this.state.isOperationsModerator,
      isAnalystDashboard:
        this.props.selectedFeature.featureName ===
        "ANALYST_DASHBOARD",
    };
    getLoanDetailsByIdAction(requestData);
  };

  getLoanApplicationDetailsById = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getLoanApplicationDetailsByIdAction(applicationId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          loanApplicationDetails: Response.data.data,
        });
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getCreditScoreofMinimart = (minimartId) => {
    this.setState({ showLoadingModal: true });
    getCreditScoreOfMinimartAction(minimartId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          creditScore: Response.data.data,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getCredoCreditScore = () => {
    this.setState({ showLoadingModal: true });
    getCredoCreditScoreAction(
      this.state.applicationId,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            credoCreditScore: Response.data.data.creditScore,
            thresholdCredoCreditScore: Response.data.data.threshold,
            showLoadingModal: false,
            errorMessage: "",
          });
        } else {
          this.setState(
            {
              showLoadingModal: false,
              credoErrorMessage: Response.error.message,
            },
            () => {
              this.scrollToTop();
            }
          );
        }
      }
    );
  };

  viewDocument = (documentId) => {
    this.setState({ showLoadingModal: true });
    const stateObject = Utility.viewDocuments(documentId);
    this.setState(stateObject, () => {
      if (this.state.errorMessage) {
        this.scrollToTop();
      }
    });
  };

  callUpdateLoanApplicationStatusAction = (
    request,
    closeNotesModal = () => { },
    callback = () => {
      //This is intensional
    }
  ) => {
    updateLoanApplicationStatusAction(
      {
        status: request.status,
        applicationId: this.state.applicationId,
        note: request.note ? request.note : "",
        loanIdToConciliate: request.loanIdToConciliate ? request.loanIdToConciliate : undefined,
        oc: request.oc ? request.oc : undefined,
      },
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              showLoadingModal: false,
              errorMessage: "",
              toShowToast: true,
              successToastMessage: Strings(
                `${request.status}_notification`
              ),
              level: "success",
            },
            () => {
              closeNotesModal();
              setTimeout(() => {
                this.props.history.push(
                  ROUTES.LOAN_APPROVAL_DASHBOARD
                );
              }, 2000);
            }
          );
        } else {
          if (
            (this.state.isAnalyst
              || this.state.isAnalystModerator) &&
            Response.error.code ===
            API_ERROR_CODE.INSUFFICIENT_CREDIT_LIMIT
          ) {
            this.setState(
              {
                showLoadingModal: false,
              },
              () => {
                closeNotesModal();
                callback(Response.error.data);
              }
            );
          } else if (
            (this.state.isOperationsOfficer || this.state.isOperationsModerator || this.state.isOperationsManager) &&
            Response.error.code === API_ERROR_CODE.CONCILIATION_DISBURSMENT_IS_ALREADY_IN_PROGRESS
          ) {
            setTimeout(() => {
              this.getDisbursementStatus();
            }, 5000);
          } else if (
            (this.state.isOperationsOfficer || this.state.isOperationsModerator || this.state.isOperationsManager) &&
            Response.error.code === API_ERROR_CODE.CONCILIATED_LOAN_IS_ALREADY_DISBURSED
          ) {
            this.setState(
              {
                showLoadingModal: false,
                errorMessage: "",
                toShowToast: true,
                successToastMessage: Strings(
                  `${request.status}_notification`
                ),
                level: "success",
              },
              () => {
                closeNotesModal();
                setTimeout(() => {
                  this.props.history.push(
                    ROUTES.LOAN_APPROVAL_DASHBOARD
                  );
                }, 2000);
              }
            );
          }
          else if ((this.state.isOperationsOfficer || this.state.isOperationsModerator || this.state.isOperationsManager) && this.state.loanHeaderDetails?.cld.length !== 0) {
            if (Response.error.code === undefined) {
              setTimeout(() => {
                this.getDisbursementStatus();
              }, 5000);
            }
            else {
              this.setState(
                {
                  showLoadingModal: false,
                  toShowToast: true,
                  level: "error",
                  successToastMessage: Response.error.message,
                  errorMessage: Response.error.message,
                },
                () => {
                  closeNotesModal();
                  this.scrollToTop();
                }
              );
            }
          }
          else {
            this.setState(
              {
                showLoadingModal: false,
                toShowToast: true,
                level: "error",
                successToastMessage: Response.error.message,
                errorMessage: Response.error.message,
              },
              () => {
                closeNotesModal();
                this.scrollToTop();
              }
            );
          }
        }
      },
      this.state.isAnalyst,
      this.state.isOperationsOfficer,
      this.state.isAccountsOfficer,
      this.state.isAnalystModerator,
      this.state.isOperationsModerator,
      this.state.isOperationsManager,
    );
  }

  updateLoanApplicationStatus = (
    request,
    closeNotesModal = () => { },
    callback = () => {
      //This is intensional
    }
  ) => {
    this.setState(
      {
        showLoadingModal: true,
        errorMessage: "",
        toShowToast: false,
        successToastMessage: "",
        level: "",
      },
      () => {
        if (request.status === OPERATIONS_OFFICER_ACTION.DISBURSE) {
          this.debounceUpdateLoanApplicationStatusAction(request, closeNotesModal, callback);
        }
        else {
          this.callUpdateLoanApplicationStatusAction(request, closeNotesModal, callback);
        }
      }
    );
  };

  getDisbursementStatus = () => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      toShowToast: false,
      successToastMessage: "",
      level: "",
    },
      () => {
        getDisbursementStatusAction(this.state.applicationId, (Response) => {
          if (Response.status === OPERATION_STATUS.SUCCESS) {
            if (Response.data.data.clds === 'PROCESSING') {
              setTimeout(() => {
                this.getDisbursementStatus();
              }, 5000);
            }
            else {
              this.setState({
                showLoadingModal: false,
                errorMessage: "",
                toShowToast: true,
                successToastMessage: Strings(`${OPERATIONS_OFFICER_ACTION.DISBURSE}_notification`),
                level: "success",
              },
                () => {
                  setTimeout(() => {
                    this.props.history.push(
                      ROUTES.LOAN_APPROVAL_DASHBOARD
                    );
                  }, 2000);
                })
            }
          } else {
            this.setState({
              showLoadingModal: false,
              errorMessage: Response.error.message,
              toShowToast: true,
              successToastMessage: Response.error.message,
              level: "error",
            });
          }
        }
        );
      }
    );
  }

  getNotesIdsArray = (notesArray) => {
    let noteIdArray = [];
    notesArray.length > 0 &&
      notesArray.forEach((note) => {
        noteIdArray.push(note.id);
      });
    return noteIdArray;
  };

  calculateCreditScore = (
    requestData,
    callback = () => {
      //This is intentional
    }
  ) => {
    let profileNotes = this.getNotesIdsArray(this.state.riskNotes),
      refNotes = this.getNotesIdsArray(this.state.referenceNotes),
      regulatorsNotes = this.getNotesIdsArray(
        this.state.regulatorsNotes
      );
    requestData = {
      applicationId: this.state.applicationId,
      profileAnalysis: {
        ...requestData.profileAnalysis,
        profileNotes: [...profileNotes],
      },
      refAnalysis: {
        ...requestData.refAnalysis,
        refNotes: [...refNotes],
      },
      regulatorsAnalysis: {
        ...requestData.regulatorsAnalysis,
        regulatorsNotes: [...regulatorsNotes],
      },
    };
    this.setState({
      showLoadingModal: true,
      toShowToast: false,
    });

    calculateCreditScoreAction(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            successToastMessage: Strings("analystInputsAreSaved"),
            level: "success",
            toShowToast: true,
          },
          () => {
            this.getCreditScoreofMinimart(
              this.state.loanHeaderDetails?.minimart.mid
            );
            this.getSuggestedLoanAmountAndTerm(
              this.state.applicationId
            );
            if (
              requestData.refAnalysis.personalRefVerified === false &&
              requestData.refAnalysis.commercialRefVerified === false
            ) {
              callback();
            }
          }
        );
      } else {
        this.setState(
          {
            errorMessage: Response.error.message,
            successToastMessage: Response.error.message,
            showLoadingModal: false,
            level: "error",
            toShowToast: true,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  addNote = (noteRequest) => {
    noteRequest = {
      refId: this.state.applicationId,
      ...noteRequest,
    };
    this.setState({ showLoadingModal: true });

    AddNoteAction(noteRequest, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            noteAdditionadata: Response.data.data,
            errorMessage: "",
          },
          () => {
            this.getNote(Response.data.data.id, noteRequest.type);
            if (
              (this.state.isAnalyst
                || this.state.isAnalystModerator) &&
              noteRequest.type === NOTES_TYPE.EVALUATION_NOTE
            ) {
              this.getLoanApplicationDetailsById(
                this.state.applicationId
              );
            }
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getNote = (noteId, type = null) => {
    this.setState({ showLoadingModal: true });
    getNoteAction(noteId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            note: Response.data.data,
            errorMessage: "",
          },
          () => {
            this.state.loanType !== LOAN_TYPES.FAST_CASH &&
              this.state.loanType !== LOAN_TYPES.FACTORING &&
              this.updateNotesIntoState(type, Response.data.data);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  editNote = (note, noteId, noteType) => {
    this.updateNote({ note: note, noteId: noteId }, noteType);
  };

  updateNote = (noteObject, noteType) => {
    this.setState({ showLoadingModal: true });
    updateNoteAction(noteObject, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          { showLoadingModal: false, errorMessage: "" },
          () => {
            this.updateNotesIntoState(
              noteType,
              Response.data.data,
              null,
              true
            );
            if (
              (this.state.isAnalyst
                || this.state.isAnalystModerator) &&
              noteType === NOTES_TYPE.EVALUATION_NOTE
            ) {
              this.getLoanApplicationDetailsById(
                this.state.applicationId
              );
            }
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  deleteNote = (noteId, type = null) => {
    this.setState({ showLoadingModal: true });
    deleteNoteAction(noteId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          { showLoadingModal: false, errorMessage: "" },
          () => {
            this.updateNotesIntoState(type, null, noteId);
            if (
              (this.state.isAnalyst
                || this.state.isAnalystModerator) &&
              type === NOTES_TYPE.EVALUATION_NOTE
            ) {
              this.getLoanApplicationDetailsById(
                this.state.applicationId
              );
            }
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  updateNotesIntoState = (
    noteType,
    noteObject = null,
    noteId = null,
    isEdit = false
  ) => {
    let NotesArray = [];
    switch (noteType) {
      case NOTES_TYPE.ANALYST_INPUT_REFERENCE:
        NotesArray = this.operationsOnNotesArray(
          [...this.state.referenceNotes],
          isEdit,
          noteObject,
          noteId
        );
        this.setState({
          referenceNotes: [...NotesArray],
        });
        break;

      case NOTES_TYPE.ANALYST_INPUT_RISK:
        NotesArray = this.operationsOnNotesArray(
          [...this.state.riskNotes],
          isEdit,
          noteObject,
          noteId
        );
        this.setState({ riskNotes: [...NotesArray] });
        break;

      case NOTES_TYPE.ANALYST_INPUT_REGULATOR:
        NotesArray = this.operationsOnNotesArray(
          [...this.state.regulatorsNotes],
          isEdit,
          noteObject,
          noteId
        );
        this.setState({
          regulatorsNotes: [...NotesArray],
        });
        break;

      default:
    }
  };

  operationsOnNotesArray = (
    NotesArray,
    isEdit,
    noteObject,
    noteId
  ) => {
    let noteObjectIndex = "";
    if (isEdit) {
      noteObjectIndex = NotesArray.findIndex(
        (note) => note.id === noteObject.id
      );
      NotesArray.splice(noteObjectIndex, 1, noteObject);
    } else if (noteObject !== null && !isEdit) {
      NotesArray.push(noteObject);
    }
    if (noteId) {
      noteObjectIndex = NotesArray.findIndex(
        (note) => note.id === noteId
      );
      NotesArray.splice(noteObjectIndex, 1);
    }
    return NotesArray;
  };

  getAnalystsDraftedInputs = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getAnalystDraftedInputAction(applicationId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            analystPrefilledData: Response.data.data,
            errorMessage: "",
            toShowToast: false,
            successToastMessage: "",
            toUpdateNegotiation: true,
          },
          () => {
            this.fillNotesIntoState(this.state.analystPrefilledData);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getOperationsDraftedInputs = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getOperationsOfficerDraftedInputAction(
      applicationId,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState(
            {
              showLoadingModal: false,
              operationsDraftedData: Response.data.data,
              errorMessage: "",
            },
            () => {
              this.state.operationsDraftedData.ni !== null &&
                this.getNote(this.state.operationsDraftedData.ni);
            }
          );
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: Response.error.message,
          });
        }
      }
    );
  };

  getAccountingOfficersDraftedInput = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getAccountingOfficersDraftedInputAction(
      applicationId,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            showLoadingModal: false,
            accountsDraftedData: Response.data.data,
            errorMessage: "",
          });
        } else {
          this.setState(
            {
              showLoadingModal: false,
              errorMessage: Response.error.message,
            },
            () => {
              this.scrollToTop();
            }
          );
        }
      }
    );
  };

  fillNotesIntoState = (analystPrefilledData) => {
    analystPrefilledData?.profileAnalysis?.profileNotes &&
      analystPrefilledData.profileAnalysis.profileNotes.length > 0 &&
      analystPrefilledData.profileAnalysis.profileNotes.forEach(
        (noteId) => {
          this.getNote(noteId, NOTES_TYPE.ANALYST_INPUT_RISK);
        }
      );
    analystPrefilledData.refAnalysis &&
      analystPrefilledData?.refAnalysis?.refNotes &&
      analystPrefilledData.refAnalysis.refNotes.length > 0 &&
      analystPrefilledData.refAnalysis.refNotes.forEach((noteId) => {
        this.getNote(noteId, NOTES_TYPE.ANALYST_INPUT_REFERENCE);
      });

    analystPrefilledData?.regulatorsAnalysis?.regulatorsNotes &&
      analystPrefilledData.regulatorsAnalysis.regulatorsNotes.length >
      0 &&
      analystPrefilledData.regulatorsAnalysis.regulatorsNotes.forEach(
        (noteId) => {
          this.getNote(noteId, NOTES_TYPE.ANALYST_INPUT_REGULATOR);
        }
      );
  };

  resetError = () => {
    this.setState({
      errorMessage: "",
    });
  };

  getSuggestedLoanAmountAndTerm = (applicationId) => {
    this.setState({
      showLoadingModal: true,
    });
    getDraftedNegotiationAction(applicationId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            draftedNegotiation: Response.data.data,
            toUpdateNegotiation: true,
          },
          () => {
            if (
              this.state.draftedNegotiation.amount &&
              this.state.loanHeaderDetails &&
              (this.state.draftedNegotiation.cd ||//If collection days are empty, then EMI calculation will not be done
                (this.state.isHistory && !this.state.draftedNegotiation.cd)) //If collection days are empty in history, then EMI calculation will be done
            ) {
              this.getEmiDuration({
                ...this.state.draftedNegotiation,
                applicationId: this.state.applicationId,
              });
            }
            /** API Call to get terms as per requested amount and requested frequency in negotiation component.*/
            if (this.state.draftedNegotiation.frequency) {
              const requestData = {
                loanType: this.state.loanType,
                amount: this.state.draftedNegotiation["amount"],
                frequency: this.state.draftedNegotiation["frequency"]
              }
              if (requestData.frequency !== "" || requestData.amount !== "") {
                this.getTermsAndrate(requestData);
              }
            }
            if (this.state.draftedNegotiation.frequency !== FREQUENCIES.WEEKLY_SIX) {
              this.getPossibleCollectionCycles({
                fr: this.state.draftedNegotiation.frequency
                  || FREQUENCIES.WEEKLY_THRICE
              })
            }
            else {
              this.setState({
                possibleCollectionDays: Utility.convertCollectionDaysToSpanishForDropdown(WEEKDAYS)
              })
            }
            this.getFreqList(this.state.draftedNegotiation.amount)
            this.getTermsArray(this.state.draftedNegotiation.amount, this.state.draftedNegotiation.frequency)
          }
        );
      } else {
        this.setState(
          {
            errorMessage: Response.error.message,
            showLoadingModal: false,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  getEmiDuration = (requestObject) => {
    let request = {
      ...requestObject,
      applicationId: this.state.applicationId,
      minimartId: this.state.loanHeaderDetails.minimart.mid,
      loanType: this.state.loanHeaderDetails.info.ty,
    }
    this.setState({ showLoadingModal: true });
    getEmiDurationAction(request, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          emiDuration: Response.data.data,
          toShowToast: false,
          successToastMessage: "",
        });
      } else {
        this.setState(
          {
            errorMessage: Response.error.message,
            showLoadingModal: false,
          },
          () => {
            this.scrollToTop();
          }
        );
      }
    });
  };

  updateLoanNegotiationAndTerms = (loanNegotiation, status, callback) => {
    loanNegotiation = {
      ...loanNegotiation,
      applicationId: this.state.applicationId,
    };
    this.setState({ showLoadingModal: true, toShowToast: false });
    updateLoanNegotiationAndTermsAction(
      loanNegotiation,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            showLoadingModal: false,
            errorMessage: "",
            successToastMessage: Strings(
              "negotiationChangesAreSaved"
            ),
            level: "success",
            toShowToast: true,
          }, () => {
            if (status) {
              callback();
            } else if (!status) {
              this.getLoanDetailsById(this.state.applicationId);
            }
          }
          );
        } else {
          this.setState(
            {
              errorMessage: Response.error.message,
              showLoadingModal: false,
              successToastMessage: Response.error.message,
              level: "error",
              toShowToast: true,
            },
            () => {
              this.scrollToTop();
              setTimeout(() => {
                this.props.history.goBack();
              }, 2000);
            }
          );
        }
      }
    );
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  submitInfo = (
    infoObject,
    callback = () => {
      //This is intensional
    }
  ) => {
    infoObject = {
      ...infoObject,
      aid: this.state.applicationId,
    };
    this.setState({ showLoadingModal: true, toShowToast: false });
    (this.state.isOperationsOfficer ||
      this.state.isOperationsModerator || this.state.isOperationsManager) &&
      updateOperationsOfficerInputsAction(
        infoObject,
        (ResponseData) => {
          if (ResponseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState(
              {
                showLoadingModal: false,
                errorMessage: "",
                successToastMessage: Strings("operationsOfficersChangesAreSaved"),
                level: "success",
                toShowToast: true,
              },
              () => {
                this.getLoanDetailsById(this.state.applicationId);
                callback();
              }
            );
          } else {
            this.setState(
              {
                errorMessage: ResponseData.error.message,
                showLoadingModal: false,
                toShowToast: true,
                level: "error",
                successToastMessage: ResponseData.error.message,
              },
              () => {
                this.scrollToTop();
              }
            );
          }
        }
      );

    this.state.isAccountsOfficer &&
      updateAccountingOfficerInputAction(
        infoObject,
        (ResponseData) => {
          if (ResponseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState(
              {
                showLoadingModal: false,
                errorMessage: "",
                successToastMessage: Strings(
                  "AccountingOffficersChangesAreSaved"
                ),
                level: "success",
                toShowToast: true,
              },
              () => {
                this.getAccountingOfficersDraftedInput(
                  this.state.applicationId
                );
              }
            );
          } else {
            this.setState(
              {
                errorMessage: Response.error.message,
                showLoadingModal: false,
                toShowToast: false,
                successToastMessage: "",
                level: "error"
              },
              () => {
                this.scrollToTop();
              }
            );
          }
        }
      );
  };

  getApprovalNotes = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getApprovalNotesAction(applicationId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          approvalNotesList: response.data.data.list,
          messengerNotes: response.data.data.mns,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  sendUpdatedMinimartDetails = (request, callback) => {
    this.setState({ showLoadingModal: true, toShowToast: false });
    sendUpdatedMinimartDetailsAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            toShowToast: true,
            isError: false,
            successToastMessage: Strings("DataUpdatedSuccessfully"),
            level: "success",
          },
          () => {
            callback();
            this.getLoanDetailsById(this.state.applicationId);
            this.getLoanApplicationDetailsById(
              this.state.applicationId
            );
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          isError: true,
          toShowToast: false,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  handleLoanSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      searchFilter: {
        st: searchFilterObject.search.status,
        ct: searchFilterObject.search.category,
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        lid: searchFilterObject.search.loanId,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getLoansBySearchFilterAction(
      {
        search: searchFilterObject.search,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.loans.length === 0) {
            this.setState({
              loans: [],
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
            });
          } else {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
              loans: response.data.data.loans,
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
          });
        }
      }
    );
  };

  handleDeleteEvidenceDocumentAction = (documentId) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    deleteEvidenceDocumentAction(documentId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          { showLoadingModal: false, errorMessage: "" },
          () => {
            this.getLoanApplicationDetailsById(
              this.state.applicationId
            );
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  downloadDownPaymentRecepit = (receiptId, applicationId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    downloadDownpaymentReceiptAction(receiptId, true, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
          fileDownload(
            response.data,
            `Recibo de Anticipo-${applicationId} ${Utility.getFileType(
              "pdf"
            )}`
          );

          this.setState({
            errorMessage: "",
            showLoadingModal: false,
            isError: false,
          });
        } else {
          this.setState({
            showLoadingModal: false,
            isError: true,
            errorMessage: Strings("NoRecordsFound"),
          });
        }
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  downloadCreditAgreement = (documentId) => {
    this.setState({ showLoadingModal: true, isSuccess: false });
    downloadCreditAgreementAction(documentId, (response) => {
      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        response.data.data.url
      ) {
        if (response.data.data.url) {
          const s3Url = Utility.getDecryptedData(
            response.data.data.url
          );
          window.open(s3Url);
        }
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
          isError: false,
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  updateMinimartDetails = (requestData) => {
    delete requestData.inm;
    this.setState({ showLoadingModal: true });
    updateMinimartDetailsAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            successToastMessage: Strings("DataUpdatedSuccessfully"),
            level: "success",
            errorMessage: "",
          },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState(
          {
            toShowToast: true,
            successToastMessage: Strings(responseData.error.message),
            level: "error",
            errorMessage: responseData.error.message,
            showLoadingModal: false,
          },
          this.scrollToTop
        );
      }
    });
  };

  setLoanDetailsID = (loanDetails) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL
    );

    this.setState({ showLoadingModal: true });
    this.props.setLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };

  saveConciliation = (
    requestData,
    callback = () => {
      //This is intensional
    }
  ) => {
    this.setState({
      showLoadingModal: true,
    });
    let request = {
      ...requestData,
      aid: this.state.applicationId,
    };
    onSaveConciliationAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          { showLoadingModal: false, errorMessage: "" },
          () => {
            callback();
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  updateOfficerInput = (request) => {
    this.setState({
      showLoadingModal: true,
    });
    updateOfficerInputAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            successToastMessage: Strings("DataUpdatedSuccessfully"),
            level: "success",
            toShowToast: true,
          },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          successToastMessage: Strings(response.error.message),
          level: "error",
          toShowToast: true,
        });
      }
    });
  };

  uploadTransferReciept = (request) => {
    this.setState({
      showLoadingModal: true,
    });
    uploadTransferRecieptAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            successToastMessage: Strings("DataUpdatedSuccessfully"),
            level: "success",
            toShowToast: true,
          },
          () => {
            this.getLoanDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          successToastMessage: Strings(response.error.message),
          level: "error",
          toShowToast: true,
        });
      }
    });
  };

  updateLoanApplicationForm = (request, callback) => {
    request["loanApplicationId"] = this.state.applicationId;
    request["minimartId"] = this.state.loanHeaderDetails.minimart.mid;

    //Backend accepts remittance details as array, 
    //but we are accepting only one value for remittance details.
    if (request["basicInfo"] && !Array.isArray(request["basicInfo"]["remittanceDetails"])) {
      request["basicInfo"]["remittanceDetails"] =
        request["basicInfo"]["remittanceDetails"] !== ""
          && request["basicInfo"]["remittanceDetails"] !== null
          ? [request["basicInfo"]["remittanceDetails"]]
          : [];
    }
    this.setState({
      showLoadingModal: true,
    });
    updateLoanApplicationAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            successToastMessage: Strings("DataUpdatedSuccessfully"),
            level: "success",
            toShowToast: true,
          },
          () => {
            callback();
            this.getLoanDetailsById(this.state.applicationId);
            this.getLoanApplicationDetailsById(this.state.applicationId);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          successToastMessage: Strings(response.error.message),
          level: "error",
          toShowToast: true,
        }, callback);
      }
    });
  }

  getFrequencyList = (request) => {
    this.setState({
      showLoadingModal: true,
    });
    getFrequencyConfigurationAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            freqList: response.data.data.frqs
          },

        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };
  setTermsArray = (terms) => {
    let termArray = [];
    if (terms !== null) {
      termArray = terms?.map((term) => term.term)
    }
    this.setState({
      termsArray: termArray
    })
  }
  getTermsAndrate = (request) => {
    this.setState({
      showLoadingModal: true,
    });
    getTermsAndrateAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            this.setTermsArray(response.data.data.td)
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  render() {
    let loanDetails;
    if (
      (this.state.isAnalyst
        || this.state.isAnalystModerator) ||
      (this.state.isOperationsModerator &&
        this.props.selectedFeature.featureName ===
        "ANALYST_DASHBOARD")
    ) {
      loanDetails = Strings("AnalystLoanDetails");
    } else if (
      this.state.isOperationsOfficer ||
      this.state.isOperationsModerator || this.state.isOperationsManager
    ) {
      loanDetails = Strings("operationsLoanDetails");
    } else if (this.state.isAccountsOfficer) {
      loanDetails = Strings("accountsOfficerLoandetails");
    }
    return (
      <div className='analystApproval'>
        <Row className='heading'>{loanDetails}</Row>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {(this.state.loanHeaderDetails?.info?.appfq === FREQUENCIES.WEEKLY_SIX
          || this.state.possibleCollectionDays)
          && this.state.draftedNegotiation && <AnalystApproval
            loanHeaderDetails={this.state.loanHeaderDetails}
            loanApplicationDetails={this.state.loanApplicationDetails}
            applicationId={this.state.applicationId}
            creditScore={this.state.creditScore}
            credoCreditScore={this.state.credoCreditScore}
            thresholdCredoCreditScore={
              this.state.thresholdCredoCreditScore
            }
            viewDocument={this.viewDocument}
            calculateCreditScore={this.calculateCreditScore}
            goBack={this.goBack}
            //ErrorMessage
            errorMessage={this.state.errorMessage}
            credoErrorMessage={this.state.credoErrorMessage}
            updateLoanApplicationStatus={
              this.updateLoanApplicationStatus
            }
            addNote={this.addNote}
            updateNote={this.updateNote}
            deleteNote={this.deleteNote}
            getNote={this.getNote}
            regulatorsNotes={this.state.regulatorsNotes}
            referenceNotes={this.state.referenceNotes}
            riskNotes={this.state.riskNotes}
            editNote={this.editNote}
            analystPrefilledData={this.state.analystPrefilledData}
            resetError={this.resetError}
            //For Negotiation Component
            draftedNegotiation={this.state.draftedNegotiation}
            emiDuration={this.state.emiDuration}
            updateLoanNegotiationAndTerms={
              this.updateLoanNegotiationAndTerms
            }
            getEmiDuration={this.getEmiDuration}
            isAnalyst={this.state.isAnalyst}
            isAnalystModerator={this.state.isAnalystModerator}
            isOperationsOfficer={this.state.isOperationsOfficer}
            isOperationsModerator={this.state.isOperationsModerator}
            isAccountsOfficer={this.state.isAccountsOfficer}
            isAuditor={
              this.props.loggedInUser.role === USER_ROLES.auditor
            }
            isOperationsManager={this.state.isOperationsManager}
            submitInput={this.submitInfo}
            operationsDraftedData={this.state.operationsDraftedData}
            accountsDraftedData={this.state.accountsDraftedData}
            isHistory={this.state.isHistory}
            toUpdateNegotiation={this.state.toUpdateNegotiation}
            loanType={this.state.loanType}
            loanStatus={this.state.loanStatus}
            approvalNotesList={this.state.approvalNotesList}
            messengerNotes={this.state.messengerNotes}
            sendUpdatedMinimartDetails={this.sendUpdatedMinimartDetails}
            loans={this.state.loans}
            numberOfPages={this.state.numberOfPages}
            handleLoanSearchFilter={this.handleLoanSearchFilter}
            handleDeleteEvidenceDocumentAction={
              this.handleDeleteEvidenceDocumentAction
            }
            downloadDownPaymentRecepit={this.downloadDownPaymentRecepit}
            downloadCreditAgreement={this.downloadCreditAgreement}
            updateMinimartDetails={this.updateMinimartDetails}
            branches={this.state.branches}
            setLoanDetailsID={this.setLoanDetailsID}
            searchFilter={this.props.searchFilter}
            businessTypeList={this.state.businessTypeList}
            zones={this.state.zones}
            mZones={this.state.mZones}
            getZonesByBranch={this.props.getZonesByBranch}
            note={this.state.note}
            saveConciliation={this.saveConciliation}
            updateOfficerInput={this.updateOfficerInput}
            featureName={this.props.selectedFeature.featureName}
            uploadTransferReciept={this.uploadTransferReciept}

            //update loan application form
            dropdownValues={this.state.dropdownValues}
            suppliersList={this.state.suppliersList}
            bankDetails={this.state.bankDetails}
            supplierDropdownValues={this.state.supplierDropdownValues}
            updateLoanApplicationForm={this.updateLoanApplicationForm}

            //Conciliation flow
            loansToConciliate={this.state.loansToConciliate}
            getLoanDetailsForConciliation={this.getLoanDetailsForConciliation}

            //Change collection days
            getPossibleCollectionCycles={this.getPossibleCollectionCycles}
            possibleCollectionDays={this.state.possibleCollectionDays}
            currentCollectionCycle={this.state.currentCollectionCycle}
            getFreqList={this.getFreqList}
            getTermsArray={this.getTermsArray}
            termsArray={this.state.termsArray}
            freqList={this.state.freqList}
            getFrequencyList={this.getFrequencyList}
            getTermsAndrate={this.getTermsAndrate}
          />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLoanDetails: state.loanDetailsReducer.analystLoanDetails,
  isHistory: state.loanDetailsReducer.isHistory,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  searchFilter: state.searchFilterReducer.analystLoanDetailFilter,
  levelConfig: state.loanDetailsReducer.levelConfig,
  loanConfig: state.loanDetailsReducer.loanConfig
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchFilterAction,
      setLoanDetailsIDAction,
    },
    dispatch
  );
};

const AnalystApprovalWrapper = withHOC(AnalystApprovalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalystApprovalWrapper);
