import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import MultipleSelectCheckmarks from "../CustomUIComponents/MultiSelectDropDown/MultipleSelectCheckmarks";

import { Strings } from "../../resources/i18n/i18n";
import cameraImage from "../../resources/images/ic_update_profile_pic.svg";
import cross from "../../resources/images/ic_cross_blue.svg";
import defaultImg from "../../resources/images/ic_moto.png";
// styles
import "./MotorcycleCatalogueModal.scss";

class MotorcycleCatalogueModal extends Component {
  renderMotorcyclePhoto = () => {
    if (this.props.readOnly) {
      return this.props.photo !== undefined ? (
        <img
          src={this.props.photo}
          alt={`${this.props.name} ${Strings("Image")}`}
          className='motorcyclePhoto backdrop'
        />
      ) : (
        <div className='backdrop'>
          <img
            src={defaultImg}
            alt={Strings("NoPhoto")}
            className='defaultImg'
          />
        </div>
      );
    } else {
      return this.props.photo ? (
        <div className='addOrUpdateImageDisplay'>
          <img
            src={
              Object.prototype.toString.call(this.props.photo) === //NOSONAR
                "[object File]"
                ? this.props.avatar
                : this.props.photo
            }
            className='uploadedImg'
            alt=''
          />
          <img
            src={cross}
            className='crossIcon'
            onClick={this.props.removeImage}
            alt=''
          />
        </div>
      ) : (
        <div className='addOrUpdateImage backdrop'>
          <label htmlFor='uploadPhoto' className='browse-button '>
            <img
              src={cameraImage}
              className={"addOrUpdateImageCamera"}
              alt={Strings("UploadPhoto")}
            />
          </label>
          <input
            type='file'
            id='uploadPhoto'
            onChange={this.props.handleUploadPhoto}
            accept='image/png,image/jpeg,image/jpg'
            style={{ display: "none" }}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <Modal
        headerText={Strings("MotorcycleDetails")}
        open={this.props.modalOpen}
        onClose={() => this.props.handleModalClose()}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}
        center
        classNames={{ modal: "motorcycleDetailModal" }}
        autofocus
      >
        <Row className='px-0'>
          <div className='mx-0 motorcycleDetailModalHeader'>
            {this.props.headerText}
          </div>
        </Row>
        {this.props.errorMessage && (
          <Row className='errorText pt-2'>
            {this.props.errorMessage}
          </Row>
        )}
        <form>
          <Col md={12} className="ps-3 pe-3">
            <Row>
              <TextFieldComponent
                id='name'
                type='text'
                className={
                  this.props.readOnly ? "disableText" : "mt-3"
                }
                placeholder={`${Strings("ProductName")}*`}
                value={this.props.name}
                handleChange={(e) => this.props.handleChange("mn", e)}
                maxLength={50}
                disabled={this.props.readOnly}
              />
            </Row>
            <Row>
              <textarea
                id='details'
                className={
                  this.props.readOnly
                    ? "disableText form-control"
                    : "mt-3 form-control"
                }
                rows={5}
                placeholder={Strings("Description")}
                value={this.props.desc || ""}
                onChange={(e) => this.props.handleChange("dc", e)}
                disabled={this.props.readOnly}
              />
            </Row>
          </Col>
          <Row>
            <Col md={6}>
              <Row className='m-2 pe-2'>
                {this.renderMotorcyclePhoto()}
              </Row>
            </Col>
            <Col md={6}>
              <Row className='pt-2 w-100'>
                <MultipleSelectCheckmarks
                  className='w-100'
                  selectedValues={this.props.bid}
                  itemList={this.props.branches}
                  handleChange={this.props.handleChange}
                  multiple={true}
                  //width={220}
                  disabled={this.props.readOnly}
                  placeHolder={"selectBranch"}
                  tag={"branch"}
                  MOTORCYCLE={this.props.MOTORCYCLE}
                />
              </Row>
              <Row>
                <TextFieldComponent
                  id='category'
                  type={this.props.readOnly ? "text" : "select"}
                  className={
                    this.props.readOnly ? "disableText" : "mt-3"
                  }
                  placeholder={`${Strings("Category")}`}
                  value={this.props.category}
                  handleChange={(e) =>
                    this.props.handleChange("category", e)
                  }
                  disabled={this.props.readOnly}
                >
                  {!this.props.readOnly
                    ? this.props.categoryList.map((category) => {
                      return (
                        <option
                          value={category.value}
                          key={category.value}
                        >
                          {category.label}
                        </option>
                      );
                    })
                    : null}
                </TextFieldComponent>
              </Row>
              <Row>
                <TextFieldComponent
                  id='model'
                  type='text'
                  className={
                    this.props.readOnly ? "disableText" : "mt-3"
                  }
                  placeholder={`${Strings("ProductModel")}*`}
                  value={this.props.model}
                  handleChange={(e) =>
                    this.props.handleChange("mo", e)
                  }
                  maxLength={6}
                  disabled={this.props.readOnly}
                />
              </Row>
              <Row>
                <TextFieldComponent
                  id='cost'
                  type='text'
                  className={
                    this.props.readOnly ? "disableText" : "mt-3"
                  }
                  placeholder={`${Strings("cost")}*`}
                  value={this.props.cost}
                  handleChange={(e) =>
                    this.props.handleChange("co", e)
                  }
                  maxLength={6}
                  disabled={this.props.readOnly}
                />
              </Row>
              <Row>
                <TextFieldComponent
                  id='status'
                  type={this.props.readOnly ? "text" : "select"}
                  className={
                    this.props.readOnly ? "disableText " : "mt-3"
                  }
                  placeholder={`${Strings("Status")}`}
                  value={this.props.status}
                  handleChange={(e) =>
                    this.props.handleChange("st", e)
                  }
                  disabled={this.props.readOnly}
                >
                  {!this.props.readOnly &&
                    this.props.statusList.map((status) => {
                      return (
                        <option
                          value={status.value}
                          key={status.value}
                        >
                          {status.label}
                        </option>
                      );
                    })}
                </TextFieldComponent>
              </Row>
            </Col>
          </Row>
          <Col md={12} className='buttonAlign pe-2'>
            <Button
              className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
              onClick={this.props.handleModalClose}
            >
              {Strings("Cancel")}
            </Button>

            {!this.props.readOnly && (
              <Button
                className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                onClick={this.props.handleSaveClick}
              >
                {Strings("Save")}
              </Button>
            )}
          </Col>
        </form>
      </Modal>
    );
  }
}

export default MotorcycleCatalogueModal;
