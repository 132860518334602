//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import { colors } from "../../resources/styles/themes";

export const INVOICE_DETAILS_KEYS = [
  { key: "number", label: "InvoiceNumber", className: "" },
  { key: "minimartCode", label: "code", className: "" },
  { key: "date", label: "creationDate", className: "" },
  { key: "expiryDate", label: "expiryDate", className: "address" },
  { key: "balance", label: "balance", className: "" },
  { key: "amount", label: "Amount", className: "address" },
  { key: "status", label: "Status", className: "" },
  { key: "supplierName", label: "SupplierName", className: "" },
  { key: "ciZone", label: "CIZone", className: "" },
  { key: "visitDay", label: "visitDay", className: "" },
];

export const INVOICE_STATUS_LIST = [
  {
    key: 0,
    value: Strings("Status"),
    label: Strings("Status"),
  },
  {
    key: 1,
    value: "PENDING",
    label: Strings("PENDING"),
  },
  {
    key: 2,
    value: "APPROVED",
    label: Strings("APPROVED"),
  },
  {
    key: 4,
    value: "REJECT",
    label: Strings("REJECT"),
  },
];

export const DEFAULT_LIST = [Strings("Status")];

export const INVOICE_STATUS_LEGEND = [
  {
    key: "APPROVED",
    color: colors.legendColor2,
    value: "APPROVED",
  },
  {
    key: "REJECT",
    color: colors.legendColor1,
    value: "REJECT",
  },
  {
    key: "PENDING",
    color: colors.legendColor3,
    value: "PENDING",
  },
];
