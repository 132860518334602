import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

//Styles
import { Row, Col } from "react-bootstrap";

//Strings
import { Strings } from "../../resources/i18n/i18n";
import Utility from "../../utils/Utility";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Constants
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_FIELDS,
  SEARCH_FILTER_TYPE,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

class MessengerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(props.searchFilter.dt) || new Date(),
      messengerInfos: [],
      usersResponseData: [],
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: props.searchFilter.offset,
    };
  }

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });

    this.props.handleOnClickSearchFilter({
      name: "",
      branchId: "",
      rating: "",
      date: this.state.date,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  handleOnClickResetFilter = () => {
    let date = moment();
    this.setState(
      {
        date: date,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => this.handleOnClickSearchFilter(true)
    );
  };

  handleDateChange = (value) => {
    this.setState({ date: value });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  setMessengerId = (messenger) => {
    this.props.setMessengerId(messenger);
    this.props.setDateForMessenger(
      Utility.convertDateIntoMiliSeconds(this.state.date)
    );
    this.props.history.push(ROUTES.VERIFY_COLLECTION);
  };

  render() {
    let messengerFilters = [
      {
        type: SEARCH_FILTER_TYPE.SINGLE_DATE,
        handleCalenderChange: this.handleDateChange,
        Value: this.state.date,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        paddingTop: 3,
        dateFormat: INVOICE_DATE_FORMAT,
      },
    ];
    return (
      <div className='ps-3 salesPerson ListContainer'>
        <SearchFilter
          filters={messengerFilters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingTop={3}
        />

        {this.props.messengerInfos.length > 0 ? (
          <Row className='cardAlign pt-3'>
            <Col md={12} className='w-100 pe-0'>
              <Row className='w-100'>
                {this.props.messengerInfos.map((messenger, index) => {
                  return (
                    <CustomCard
                      card={messenger}
                      key={index}
                      cardType='Person'
                      handleCardClick={this.setMessengerId}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(MessengerList);
