import React from "react";
import "./CiLoanRequest.scss"
import { Strings } from "../../resources/i18n/i18n";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import {
    DEFAULT_PAGINATION_RECORDS_COUNT,

} from "../CustomUIComponents/Pagination/PaginationConstants";
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import { DATE_TYPE, DEFAULT_DATE_FORMAT, DEFAULT_DURATION, DEFAULT_VALUES, DOWNLOAD_FILE_TYPE, SEARCH_FILTER_FIELDS, SEARCH_FILTER_TYPE } from "../../constants/appConstants";
import moment from "moment";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import Utility from "../../utils/Utility";
import { Button, Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DEFAULT_STATUS, LOAN_REQUEST_REPORT, STATUS_VALUES } from "./CiLoanRequestReportConstants";
class CiLoanRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                referenceId: props.searchFilter.ri,
                invoiceNumber: props.searchFilter.in,
                minimartCode: props.searchFilter.cd,
                from: props.searchFilter.fd,
                to: props.searchFilter.td,
                status: props.searchFilter.st,
            },
            activePage: props.searchFilter.offset,
            recordsPerPage: props.searchFilter.limit,
        };
    }
    componentDidMount() {
        this.handleOnSearchFilterClick(false);
    }
    handleOnSearchFilterClick = (isDefaultOffset) => {
        isDefaultOffset &&
            this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
        this.props.handleOnClickSearchFilter({
            search: {
                referenceId:
                    this.state.filters.referenceId !== "" ? this.state.filters.referenceId : undefined,
                status:
                    this.state.filters.status !== DEFAULT_STATUS && this.state.filters.status !== "" ? this.state.filters.status : undefined,
                minimartCode: this.state.filters.minimartCode !== "" ? this.state.filters.minimartCode : undefined,
                invoiceNumber: this.state.filters.invoiceNumber !== "" ? this.state.filters.invoiceNumber : undefined,
                from: this.state.filters.from
                    ? Utility.convertDateIntoMiliSeconds(this.state.filters.from)
                    : DEFAULT_DURATION.fromDate,
                to: this.state.filters.to
                    ? Utility.convertDateIntoMiliSeconds(this.state.filters.to)
                    : DEFAULT_DURATION.toDate,
                limit: this.state.recordsPerPage,
                offset: isDefaultOffset
                    ? DEFAULT_PAGINATION_ACTIVE_PAGE
                    : this.state.activePage,
            }
        });
    };
    handleChange = (event) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [event.target.name]: event.target.value,
            },
        });
        !this.props.validation && this.props.resetErrorState(true);
    };
    handleStatusChange = (event) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [event.target.name]: DEFAULT_VALUES.includes(event.target.value)
                    ? ""
                    : event.target.value
            }
        })
    }
    handleSearchDateChange = (key) => (dateMoment) => {
        let DateValue = "";
        switch (key) {
            case DATE_TYPE.FROM_DATE:
                if (dateMoment.isAfter(this.state.filters.to)) {
                    DateValue = this.state.filters.from;
                } else {
                    dateMoment.set({ hour: 0, minute: 0, second: 0 });
                    DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
                }
                this.setState({
                    filters: {
                        ...this.state.filters,
                        from: DateValue,
                    },
                });
                break;
            case DATE_TYPE.TO_DATE:
                if (dateMoment.isBefore(this.state.filters.from)) {
                    DateValue =
                        moment(dateMoment).diff(
                            this.state.filters.from,
                            "days"
                        ) === 0
                            ? this.state.filters.from
                            : this.state.filters.to;
                } else {
                    DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
                }
                this.setState({
                    filters: {
                        ...this.state.filters,
                        to: DateValue,
                    },
                });
                break;
            default:
        }
    };
    resetSearchValues = () => {
        this.setState(
            {
                filters: {
                    referenceId: "",
                    invoiceNumber: "",
                    minimartCode: "",
                    from: DEFAULT_DURATION.fromDate,
                    to: DEFAULT_DURATION.toDate,
                    status: DEFAULT_STATUS,
                    recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
                    activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
                },
            },
            () => {
                this.handleOnSearchFilterClick(true);
            }
        );
    };
    renderSearchFilter = () => {
        let ciLoanRequestFilters = [
            {
                type: SEARCH_FILTER_TYPE.DATE,
                handleOnChange: this.handleSearchDateChange,
                fromDate: this.state.filters.from,
                handleCalenderChange: this.handleSearchDateChange,
                toDate: this.state.filters.to,
                fieldname: SEARCH_FILTER_FIELDS.DATE,
                column: 4,
            },
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("minimartCode"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.minimartCode,
                fieldname: SEARCH_FILTER_FIELDS.MINIMART_CODE,
                name: "minimartCode",
            },
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("invoiceNumber"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.invoiceNumber,
                fieldname: SEARCH_FILTER_FIELDS.INVOICE_NUMBER,
                name: "invoiceNumber",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleStatusChange,
                Value: this.state.filters.status,
                fieldname: SEARCH_FILTER_FIELDS.GRADE,
                filterDropdownList: STATUS_VALUES,
                name: "status"
            },
        ];
        return (
            <SearchFilter
                filters={ciLoanRequestFilters}
                handleOnClickSearchFilter={this.handleOnSearchFilterClick}
                handleOnClickResetFilter={this.resetSearchValues}
                paddingLeft={4}
                removeLeftPadding={true}
            />
        );
    };
    downloadFile = (filetype) => {
        const requestData = {
            offSet: 0,
            limit: this.state.recordsPerPage ? this.state.recordsPerPage : 10,
            search: {
                fd: this.state.filters.from
                    ? Utility.convertDateIntoMiliSeconds(this.state.filters.from)
                    : DEFAULT_DURATION.fromDate,
                td: this.state.filters.to
                    ? Utility.convertDateIntoMiliSeconds(this.state.filters.to)
                    : DEFAULT_DURATION.toDate,
                ri: this.state.filters.referenceId !== "" ? this.state.filters.referenceId : undefined,
                in: this.state.filters.invoiceNumber !== "" ? this.state.filters.invoiceNumber : undefined,
                cd: this.state.filters.minimartCode !== "" ? this.state.filters.minimartCode : undefined,
                st: this.state.filters.status !== DEFAULT_STATUS && this.state.filters.status !== "" ? this.state.filters.status : undefined,
            },
        };
        this.props.downloadReports(
            LOAN_REQUEST_REPORT.CI_REPORT_DOWNLOAD + filetype,
            requestData,
            filetype
        );
    };
    renderDownloadButtons = () => {
        return (
            <React.Fragment>
                {" "}
                <div className="pt-4 pe-4">
                    <Button
                        className='downloadButtons me-3'
                        onClick={() => {
                            this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
                        }}
                    >
                        <img src={PDFdownload} alt='' />
                    </Button>
                    <Button
                        className='downloadButtons'
                        onClick={() => {
                            this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
                        }}
                    >
                        <img src={CSVdownload} alt='' />
                    </Button>
                </div>
            </React.Fragment>
        );
    };
    renderCustomDataGrid = (
        columns, rows
    ) => {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.divider,
                color: theme.palette.common.black,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        }));
        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));
        return (
            <div className='d-flex justify-content-center mx-3 mb-3'>
                <TableContainer component={Paper} className='w-100'>
                    <Table aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                {columns.length > 0 &&
                                    columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"text-center px-2 headerContent"}
                                            title={column.name}
                                        >
                                            <div>{column.name}</div>
                                        </StyledTableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <StyledTableRow
                                    key={rowIndex}
                                    hover
                                >
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            className={"text-center px-0"}
                                            component='th'
                                            scope='row'
                                            title={row[column.key]}
                                            style={{ width: column.minWidth }}
                                        >
                                            <div>{row[column.key]}</div>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };
    onPageChange = ({ page, pageLength }) => {
        this.setState(
            {
                activePage: page,
                recordsPerPage: pageLength,
            },
            () => this.handleOnSearchFilterClick(false)
        );
    };
    renderPagination = () => {
        return (
            <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
            />
        );
    };
    render() {
        return (
            <div>
                <Row>
                    <span className='heading p-0'>{Strings("CILoanRequestReport")}</span>
                </Row>
                <div class="ciLoanRequest">
                    {!this.props.validation &&
                        <Row className="errors ps-3 mb-0 pt-2">
                            {Strings("invalidData")}
                        </Row>}
                    <Row className="filters">
                        {this.renderSearchFilter()}
                    </Row>
                    <Row>
                        <Col

                            md={{ span: 2, offset: 10 }}
                            className='pull-right alignDownloadButtons'
                        >
                            {this.renderDownloadButtons()}
                        </Col>
                    </Row>
                    <Row className='errors ps-3'>
                        {this.props.errorMessage !== "" &&
                            Strings(this.props.errorMessage)}
                    </Row>
                    <div className='branchListTable noPadding'>
                        {this.props.rows && this.props.rows.length > 0 ? (<>
                            {this.renderCustomDataGrid(
                                this.props.columns,
                                this.props.rows,
                            )}
                            <Col md={12} className='noPadding'>
                                {this.renderPagination()}
                            </Col> </>
                        ) : (<div className='noRecordsFound'>
                            {Strings("NoRecordsFound")}
                        </div>)}
                    </div>

                </div>
            </div>
        );
    }
}
export default CiLoanRequest;