//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { USER_ROLES } from "../../constants/appConstants";
export const PROMOTION_STATUS_ANALYST = [
  {
    key: 0,
    value: Strings("Status"),
    label: Strings("Status"),
  },
  {
    key: 1,
    value: "PENDING",
    label: Strings("PENDING"),
  },
  {
    key: 2,
    value: "APPROVED",
    label: Strings("APPROVED"),
  },
  {
    key: 3,
    value: "REJECTED",
    label: Strings("REJECTED"),
  },
];
export const PROMOTION_STATUS = [
  {
    key: 0,
    value: Strings("Status"),
    label: Strings("Status"),
  },
  {
    key: 1,
    value: "PENDING",
    label: Strings("PENDING"),
  },
  {
    key: 2,
    value: "APPROVED",
    label: Strings("APPROVED"),
  },
  {
    key: 3,
    value: "APPROVED_BY_SYSTEM",
    label: Strings("APPROVED_BY_SYSTEM"),
  },
  {
    key: 4,
    value: "REJECTED",
    label: Strings("REJECTED"),
  },
];

export const DEFAULT_LIST = [
  Strings("benefitType"),
  Strings("promotionType"),
  Strings("Status"),
  Strings("productType"),
  Strings("username"),
];

export const PROMOTION_APPROVAL_USERS = [
  USER_ROLES.analyst,
  USER_ROLES.operationsModerator,
  USER_ROLES.operationsOfficer
]