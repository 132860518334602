import React from "react";

import { Strings } from "../../../resources/i18n/i18n";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import "./CustomerTypeLegend.scss";

class CustomerTypeLegend extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.paddingTop
            ? `pt-${this.props.paddingTop} customerTypeLegend w-auto ps-0`
            : "customerTypeLegend w-auto ps-0"
        }
      >
        <Row
          md={{ span: this.props.heading ? 12 : 6 }}
          className='legendRow'
        >
          {this.props.heading && (
            <div className='legendHeading ps-0 w-auto'>
              {this.props.heading + ": "}
            </div>
          )}
          {this.props.arrayOfTypes.map((legend) => {
            return (
              <div
                key={legend.key}
                className={`${
                  this.props.isOperationsOfficerLegened
                    ? "col-md-2 ps-0 "
                    : "ps-0 w-auto"
                } 
                  ${
                    this.props.paddingLeft && !this.props.heading
                      ? "ps-" + this.props.paddingLeft + " pe-2"
                      : "pe-" + this.props.paddingRight
                  }`}
              >
                <Row className='align-center'>
                  <Col
                    md={1}
                    className='circleBorder pe-0 heightWidth w-auto'
                    style={{ backgroundColor: legend.color }}
                  ></Col>
                  <div md={{ span: 8 }} className='pe-0 ps-1 w-auto'>
                    <div>{Strings(legend.key)}</div>
                  </div>
                </Row>
              </div>
            );
          })}
        </Row>
      </div>
    );
  }
}

CustomerTypeLegend.propTypes = {
  heading: PropTypes.string,
  arrayOfTypes: PropTypes.array,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  key: PropTypes.number,
  isOperationsOfficerLegened: PropTypes.bool,
};

CustomerTypeLegend.defaultProps = {
  isOperationsOfficerLegened: false,
};

export default CustomerTypeLegend;
