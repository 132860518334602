import { Col, Form, Row, Table } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import React, { Component } from "react";
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, tableCellClasses } from "@mui/material";
import {NumericFormat} from "react-number-format";
import { CONFIGURATION_DATA_TYPE } from "../../constants/appConstants";

class LateFeesConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                columns: [
                    { key: 'description', title: Strings('Description') },
                    { key: 'value', title: Strings('Value') }
                ],
                dataRows: [],
                configurationData: this.props.configurationData.configurationObject,
                originalConfigurationData: { ...this.props.configurationData.configurationObject }
            },
        };
    }
    componentDidMount() {
        this.renderDataForLateFees();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.configurationData !== this.props.configurationData) {
            this.setState({
                data: {
                    ...this.state.data,
                    configurationData: this.props.configurationData.configurationObject,
                }
            }, () => {
                this.renderDataForLateFees();
            });
        }
    }

    renderDataForLateFees = () => {
        let configurationData = this.state.data.configurationData;
    
        let configuration = Object.keys(configurationData)
            .filter(key => key !== "isCalculationFromDayActive" && key !== "isMaxCapitalPercentageActive")
            .map(key => {
                let toggle;
                if (key === "startCalculatationFromDay") {
                    toggle = configurationData.isCalculationFromDayActive;
                } else if (key === "maxCapitalPercentage") {
                    toggle = configurationData.isMaxCapitalPercentageActive;
                }else{
                    toggle=false;
                }
                return {
                    description: key,
                    value: configurationData[key],
                    toggle: toggle
                };
            });
    
        this.setState({
            data: {
                ...this.state.data,
                dataRows: configuration,
                configurationData 
            }
        });
    };
    onInputValueChange = (e, rowIndex, key) => {
        let configurationData = this.state.data.configurationData;
        let newValue = e.target.value;
        if (rowIndex === 0) {
            newValue = newValue !== "" ? parseFloat(newValue) : 0;
        } else if (rowIndex === 2) {
            newValue = parseFloat(newValue);
        } else {
            newValue = parseInt(newValue);
        }
        switch (rowIndex) {
            case 0:
                configurationData.lateFeesPercent = newValue;
                break;
            case 1:
                configurationData.startCalculatationFromDay = newValue;
                break;
            case 2:
                configurationData.maxCapitalPercentage = newValue;
                break;
            default:
                break;
        }
        this.setState(prevState => {
            const newDataRows = [...prevState.data.dataRows];
            newDataRows[rowIndex][key] = newValue;
            return {
                data: {
                    ...prevState.data,
                    dataRows: newDataRows,
                    configurationData: configurationData
                },
            };
        }
        );
        this.props.saveEditedDataInTheState(configurationData, CONFIGURATION_DATA_TYPE.LATE_FFES);
    };
    stylesTableCell = () => {
        return styled(TableCell)(({ theme }) => {
            return {
                [`&.${tableCellClasses.head}`]: {
                    fontWeight: "bold",
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.common.black,
                },
                [`&.${tableCellClasses.body}`]: {
                    fontSize: 14,
                },
            };
        });
    };
    renderNumberFormatCustom = React.forwardRef((props, ref) => {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                type='text'
                decimalScale='2'
                isNumericString
            />
        );
    });
    onToggleChange = (e, rowIndex) => {
        const { checked } = e.target;
        this.setState(prevState => {
            const newDataRows = [...prevState.data.dataRows];
            const newConfigurationData = { ...prevState.data.configurationData };
            const originalConfigurationData = { ...prevState.data.originalConfigurationData };

            if (newDataRows[rowIndex].description === "startCalculatationFromDay") {
                newConfigurationData.isCalculationFromDayActive = checked;
            } else if (newDataRows[rowIndex].description === "maxCapitalPercentage") {
                newConfigurationData.isMaxCapitalPercentageActive = checked;
            }
    
             // Update the toggle state
             if (checked) {
                newDataRows[rowIndex].originalValue = newDataRows[rowIndex].value;
            } else {
                // Set originalValue from configurationObject if not already set
                if (newDataRows[rowIndex].originalValue === undefined) {
                    if (newDataRows[rowIndex].description === "startCalculatationFromDay") {
                        newDataRows[rowIndex].originalValue = this.props.configurationData.configurationObject.startCalculatationFromDay;
                    } else if (newDataRows[rowIndex].description === "maxCapitalPercentage") {
                        newDataRows[rowIndex].originalValue = this.props.configurationData.configurationObject.maxCapitalPercentage;
                    }
                }
                // Check for NaN and assign the original value if NaN
                if (isNaN(newDataRows[rowIndex].value) || newDataRows[rowIndex].value === null) {
                    if (newDataRows[rowIndex].description === "startCalculatationFromDay") {
                        newDataRows[rowIndex].value = originalConfigurationData.startCalculatationFromDay;
                    } else if (newDataRows[rowIndex].description === "maxCapitalPercentage") {
                        newDataRows[rowIndex].value = originalConfigurationData.maxCapitalPercentage;
                    }
                } else {
                    newDataRows[rowIndex].value = newDataRows[rowIndex].originalValue;
                }
                // Assign previous original value to configurationData object
                if (newDataRows[rowIndex].description === "startCalculatationFromDay") {
                    newConfigurationData.startCalculatationFromDay = newDataRows[rowIndex].value;
                } else if (newDataRows[rowIndex].description === "maxCapitalPercentage") {
                    newConfigurationData.maxCapitalPercentage = newDataRows[rowIndex].value;
                }
            }
            newDataRows[rowIndex].toggle = checked;

            return {
                data: {
                    ...prevState.data,
                    dataRows: newDataRows,
                    configurationData: newConfigurationData,
                    originalConfigurationData: originalConfigurationData
                },
            };
        }, () => {
            this.renderDataForLateFees();
            this.props.saveEditedDataInTheState(this.state.data.configurationData, CONFIGURATION_DATA_TYPE.LATE_FFES);
        });
    };
    renderConfigurationTable = () => {
        const StyledTableCell = this.stylesTableCell();
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label='customized table' className="mb-0">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            {this.state.data &&
                                this.state.data.columns &&
                                this.state.data.columns.length > 0 &&
                                this.state.data.columns.map((column, index) => (
                                    <StyledTableCell key={column.key + index}>
                                        {column.title}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data &&
                            this.state.data.dataRows &&
                            this.state.data.dataRows.map((row, rowIndex) => (
                                <TableRow key={row.description + rowIndex}>

                                    <TableCell>
                                        {rowIndex !== 0 && (
                                            <Form.Check
                                                type="switch"
                                                id={`custom-switch-${rowIndex}`}
                                                checked={row.toggle || false}
                                                onChange={(e) => this.onToggleChange(e, rowIndex)}
                                                label=""
                                                disabled={this.props.readOnly}
                                                className={this.props.readOnly ? "disabled" : ""}
                                                title={Strings(row.toggle ? "Active" : "Inactive")}
                                            />
                                        )}
                                    </TableCell>

                                    {this.state.data.columns.map((column, colIndex) => (
                                        <TableCell
                                            key={column.key + rowIndex + colIndex}
                                            component='th'
                                            scope='row'
                                            title={column.key === "description" ? Strings(row[column.key]) : row[column.key]}
                                        >
                                            {column.key === 'value' ? (
                                                (!this.props.readOnly && (row.toggle || rowIndex === 0)) ? (
                                                    <TextField
                                                        InputProps={{
                                                            style: { textAlign: "center" },
                                                            inputComponent: this.renderNumberFormatCustom,
                                                            inputProps: { style: { textAlign: "center" } }
                                                        }}
                                                        placeholder={0}
                                                        type='number'
                                                        value={
                                                            row[column.key]
                                                        }
                                                        onChange={(e) =>
                                                            this.onInputValueChange(
                                                                e,
                                                                rowIndex,
                                                                column.key
                                                            )
                                                        }
                                                        variant='standard'
                                                        name='numberformat'
                                                        id='formatted-numberformat-input'
                                                    />
                                                ) :
                                                    row[column.key] !== null ? (row[column.key]) :
                                                        !this.props.readOnly ? null : "-"
                                            ) : (
                                                <>
                                                    {Strings(row[column.key])}
                                                    {row[column.key] === "lateFeesPercent" && <span className='mandatoryFields'>*</span>}
                                                </>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    render() {
        return (
            <Row className="editableDataTable justify-content-center pt-4">
                <Col md={6} className='noPadding d-flex justify-content-center'>
                    {this.renderConfigurationTable()}
                </Col>
            </Row>

        );
    }
}
export default LateFeesConfiguration;