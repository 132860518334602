import React from "react";
import { Col, Row } from "react-bootstrap";

// String
import { Strings } from "../../resources/i18n/i18n";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constant
import {
  LOAN_TYPE,
  CATEGORY,
  LOAN_CATEGORY_LEGEND,
  USER_ROLES,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

class AssignRepaymentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getUserList({
      filterRole: USER_ROLES.collectionsOfficer,
    });
    this.props.handleOnClickSearchFilter(false);
  }

  renderSearchFilter = () => {
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.props.LoanFiltersForAssignRepayment.fromDate,
        handleCalenderChange: this.props.handleDateChange,
        toDate: this.props.LoanFiltersForAssignRepayment.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleChangeTypeFilter,
        Value: this.props.LoanFiltersForAssignRepayment.loanType,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
        filterDropdownList: LOAN_TYPE,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        disabled: this.state.disabled,
        handleOnChange: this.props.handleChangeCategoryFilter,
        Value: this.props.LoanFiltersForAssignRepayment.category,
        fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
        filterDropdownList: CATEGORY,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("loanId"),
        handleOnChange: this.props.handleSearchIdChange,
        Value: this.props.LoanFiltersForAssignRepayment.loanId,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_ID,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleRequesterChange,
        Value: this.props.LoanFiltersForAssignRepayment.requester,
        fieldname: SEARCH_FILTER_FIELDS.REQUESTER,
        filterDropdownList: this.props.users,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleBranchChange,
        Value: this.props.LoanFiltersForAssignRepayment.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.props.branches,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleZoneChange,
        Value: this.props.LoanFiltersForAssignRepayment.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        name: "zone",
        filterDropdownList: this.props.zones,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleZoneChange,
        Value: this.props.LoanFiltersForAssignRepayment.mZone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        name: "mZone",
        filterDropdownList: this.props.mZones,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MiniMartname"),
        handleOnChange: this.props.handleSearchNameChange,
        Value: this.props.LoanFiltersForAssignRepayment.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        paddingTop: true,
      },
    ];
    return (
      <SearchFilter
        filters={LoanFilters}
        handleOnClickSearchFilter={
          this.props.handleOnClickSearchFilter
        }
        handleOnClickResetFilter={this.props.handleOnClickResetFilter}
        paddingRight={0}
        paddingTop={2}
        removeLeftPadding={true}
      />
    );
  };

  renderCustomerTypeLegend = () => {
    return (
      <CustomerTypeLegend
        heading={Strings("LoanCategories")}
        arrayOfTypes={LOAN_CATEGORY_LEGEND}
        paddingRight={4}
      />
    );
  };

  renderCards = () => {
    return this.props.loans.length > 0 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pe-0'>
          <Row className='w-100'>
            {this.props.loans.map((card, index) => {
              return (
                <CustomCard
                  card={card}
                  cardType='loan'
                  key={Math.random()} //NOSONAR
                  handleCardClick={() => this.props.setLoanDetailsID(card, this.props.activeTab)}
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.props.activePage}
            recordsPerPage={this.props.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.props.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  render() {
    return (
      <div className='loanList container-fluid TabListContainer'>
        {this.renderSearchFilter()}
        {this.renderCustomerTypeLegend()}
        {this.renderCards()}
      </div>
    );
  }
}

export default AssignRepaymentPlan;
