import React from "react";
// import { Row, Col } from "react-bootstrap";
// import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
// Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
// import { G_API_URL } from "../../constants/appConstants";

//Styles
import "../Map/Map.scss";

class ConsolidatedMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Capital of Dominican Republic 'Santo Domingo'
      center: {
        lat: 18.50012,
        lng: -69.98857,
      },
      showingInfoWindow: false,
      index: 0,
    };
  }

  /* #region  Marker Methods */

  // onMarkerClick = (minimart) => {
  //   this.props.getMapData(minimart.mid, () => {
  //     this.setState({
  //       showingInfoWindow: true,
  //       index: minimart.cd,
  //       lat: minimart.lt,
  //       lang: minimart.lg,
  //     });
  //   });
  // };

  // onMarkerCloseClick = () => {
  //   this.setState({
  //     showingInfoWindow: false,
  //   });
  // };

  // /* #endregion */

  // /* #region  InfoWindow */

  // onGetSingleMinimart = () => {
  //   return (
  //     this.state.showingInfoWindow === true &&
  //     this.state.index === this.props.singleMinimartData.code && (
  //       <InfoWindow
  //         key={this.props.singleMinimartData.code}
  //         marker={this.state.activeMarker}
  //         visible={this.state.showingInfoWindow}
  //         onCloseClick={this.onMarkerCloseClick}
  //         position={{
  //           lat: this.state.lat,
  //           lng: this.state.lang,
  //         }}
  //       >
  //         <Row className='infoBox'>
  //           <Row>
  //             <Col md={6} className='ps-0 title'>
  //               {Strings("MatricesID")}
  //             </Col>
  //             &nbsp;&nbsp;:&nbsp;
  //             <Col md={4} className='ps-0 detailText'>
  //               {this.props.singleMinimartData.matricesId}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col md={4} className='ps-0 title'>
  //               {Strings("code")}
  //             </Col>
  //             : &nbsp;
  //             <Col md={4} className='ps-0 detailText'>
  //               {this.props.singleMinimartData.code}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col md={4} className='ps-0 title'>
  //               {Strings("Name")}
  //             </Col>
  //             : &nbsp;
  //             <Col md={6} className='ps-0 detailedText'>
  //               {this.props.singleMinimartData.name}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col md={3} className='ps-0 title'>
  //               {Strings("Address")}
  //             </Col>
  //             : &nbsp;
  //             <Col md={8} className='ps-0 detailedText'>
  //               {this.props.singleMinimartData.address}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col md={7} className='ps-0 title'>
  //               {Strings("category")}
  //             </Col>
  //             : &nbsp;
  //             <Col md={2} className='ps-0 detailText'>
  //               {this.props.singleMinimartData.category}
  //             </Col>
  //           </Row>
  //         </Row>
  //       </InfoWindow>
  //     )
  //   );
  // };

  /* #endregion */

  render() {
    return (
      <div className="noRecordsFound">{Strings("notWorking")}</div>


      //  Billing must be enable on the Google Cloud Project to access map


      // <LoadScript googleMapsApiKey={G_API_URL} >

      //   <GoogleMap
      //     center={this.state.center}
      //     zoom={10}
      //   >
      //     {this.props.miniMartData.map((minimart, index) => {
      //       return (
      //         <div key={minimart.mid}>
      //           <Marker
      //             icon={{
      //               url: minimart.ct,
      //             }}
      //             position={{ lat: minimart.lt, lng: minimart.lg }}
      //             onClick={() => {
      //               this.onMarkerClick(minimart);
      //             }}
      //           />
      //         </div>
      //       );
      //     })}
      //     {
      //       this.props.singleMinimartData &&
      //       this.onGetSingleMinimart()
      //     }
      //   </GoogleMap>
      // </LoadScript>
    )
  }
}

export default ConsolidatedMap;