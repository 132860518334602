import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import moment from "moment";

//Component
import Loans from "../Loans/Loans";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";

//Constants
import {
  MINIMART_DETAILS_KEYS,
  PROMOTION_DETAILS_KEYS,
  FILTER_DEFAULT_LIST,
  STATUS_LIST,
} from "./Constants";
import {
  DEFAULT_INVOICE_STATUS,
  DEFAULT_DURATION,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  STATUS_FOR_LOANS,
  CATEGORY,
  DATE_TYPE,
  DEFAULT_DATE_FORMAT,
  CONFIGURATION_DATE_TIME_FORMAT,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { PROMOTION_REQUEST_TYPE_LEGEND } from "../CustomUIComponents/CustomCard/CardConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./PromotionDetails.scss";

//Assets
import MTP_PROMOTION from "../../resources/images/promotion_request_status.png";

export default class PromotionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LoanFilters: {
        status:
          props.searchFilter?.pst || DEFAULT_INVOICE_STATUS.value,
        fromDate: props.searchFilter?.fd || DEFAULT_DURATION.fromDate,
        category: props.searchFilter?.ct || "",
        toDate: props.searchFilter?.td || DEFAULT_DURATION.toDate,
      },
      activePage: props.searchFilter?.offset,
      recordsPerPage: props.searchFilter?.limit,
      disabled: props.searchFilter.st !== "DISBURSED",
      notesModal: false,
      showModal: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  renderDetails = (keyList, title, data) => {
    return (
      <>
        <Row className='subHeadingText pt-3'>{title}</Row>
        <Row className='d-flex'>
          {keyList.map((dataKey) => {
            return (
              <Col
                md={6}
                className='d-flex contentWidth ps-0'
                key={dataKey.key}
              >
                {dataKey.formmater(dataKey, data)}
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.fromDate) ||
          dateMoment.isAfter(new Date())
        ) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }

    this.setState({
      LoanFilters: { ...this.state.LoanFilters, [key]: DateValue },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter({
      search: {
        matricesId: this.props.promotionDetails?.md.mid,
        status: this.state.LoanFilters.status,
        fromDate: this.state.LoanFilters.fromDate,
        category:
          this.state.LoanFilters.category !== ""
            ? this.state.LoanFilters.category
            : undefined,
        toDate: this.state.LoanFilters.toDate,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };
  handleOnClickResetSearchFilter = () => {
    this.setState(
      {
        LoanFilters: {
          ...this.state.LoanFilters,
          status: DEFAULT_INVOICE_STATUS.value,
          fromDate: DEFAULT_DURATION.fromDate,
          category: "",
          toDate: DEFAULT_DATE_FORMAT.toDate,
        },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      this.handleOnClickSearchFilter
    );
  };
  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };
  handleFilterInputChange = (event) => {
    let value = FILTER_DEFAULT_LIST.includes(event.target.value)
      ? ""
      : event.target.value;
    let localState = { ...this.state };
    localState.LoanFilters = {
      ...this.state.LoanFilters,
      [event.target.name]: value,
    };
    if (event.target.name === "status") {
      localState.disabled = value === "DISBURSED" ? false : true;
    }
    this.setState(localState);
  };

  renderLoanSection = () => {
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFilterInputChange,
        Value: this.state.LoanFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: STATUS_FOR_LOANS,
        name: "status",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        disabled: this.state.disabled,
        handleOnChange: this.handleFilterInputChange,
        Value: this.state.LoanFilters.category,
        fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
        filterDropdownList: CATEGORY,
        name: "category",
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.LoanFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.LoanFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <Loans
        loanFilters={LoanFilters}
        loans={this.props.loans}
        recordsPerPage={this.state.recordsPerPage}
        activePage={this.state.activePage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetSearchFilter}
        setLoanDetailsID={this.props.setLoanDetailsID}
      />
    );
  };

  renderNotesModal = () => {
    return (
      <NotesModal
        open={this.state.notesModal}
        modalHeader={Strings("Notes")}
        toggleModal={this.toggleModal}
        addNote={this.addNote}
        isNotesCompulsory={true}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  toggleModal = () => {
    this.setState({
      notesModal: !this.state.notesModal,
      errorMessage: "",
    });
  };
  addNote = (notes) => {
    const request = {
      refId: this.props.singlePromotion.prrid,
      note: notes,
      type: "PROMOTION_REQUEST",
    };
    this.props.addNote(
      request,
      (noteId) => {
        this.props.updateAnalysisPromotionStatus(
          {
            prrid: this.props.singlePromotion.prrid,
            nid: noteId,
            st: STATUS_LIST.REJECTED,
          },
          "requestRejectedSuccessFully",
          this.toggleModal,
          (errorMessage) => {
            this.setState({ errorMessage });
          }
        );
      },
      (errorMessage) => {
        this.setState({ errorMessage });
      }
    );
  };

  updateAnalysisPromotionStatus = () => {
    this.props.updateAnalysisPromotionStatus(
      {
        prrid: this.props.singlePromotion.prrid,
        nid: null,
        st: STATUS_LIST.APPROVED,
      },
      "RequestApprovedSuccessfully",
      () => {
        this.setState({
          showModal: false,
        });
      },
      (errorMessage) => {
        this.setState({ errorMessage });
      }
    );
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("Approved")}
        modalText2={Strings("doYouWantToContinue")}
        modalButtonOk={{
          text: Strings("Yes"),
          onClick: this.updateAnalysisPromotionStatus,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: () =>
            this.setState({
              showModal: false,
            }),
        }}
        showHr={true}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  renderActionButtons = () => {
    return (
      <Row className='pb-2'>
        <Col md={1}>
          <Button
            className='buttonBorder'
            onClick={this.props.goBack}
          >
            {Strings("Back").toUpperCase()}
          </Button>
        </Col>
        <Col md={{ offset: 9, span: 1 }} className='ps-0'>
          <Button
            className='capitalText buttonBackground'
            onClick={() => {
              this.setState({ showModal: true });
            }}
            disabled={
              this.props.promotionDetails?.pd.status !==
              STATUS_LIST.PENDING
            }
          >
            {Strings("approve")}
          </Button>
        </Col>
        <Col md={1} className='ps-0'>
          <Button
            className='capitalText buttonBackground'
            onClick={this.toggleModal}
            disabled={
              this.props.promotionDetails?.pd.status !==
              STATUS_LIST.PENDING
            }
          >
            {Strings("reject")}
          </Button>
        </Col>
      </Row>
    );
  };

  renderNotes = () => {
    return (
      <>
        <div className='outerBorder notesHeader'>
          <div className='my-2 ps-2'>{Strings("Notes")}</div>
          <div className='notes pt-2'>
            <div key={this.props.note.id} className='innerBox px-2'>
              <span className='author'>
                {Strings("author")}:&nbsp;
                {this.props.note.author}
              </span>
              <span className='date ps-3'>
                {moment(this.props.note.updatedAt).format(
                  CONFIGURATION_DATE_TIME_FORMAT
                )}
              </span>

              <div className='noteColor'>{this.props.note.note}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        {this.state.notesModal && this.renderNotesModal()}
        {this.state.showModal && this.renderConfirmationModal()}
        <Row className='heading'>{Strings("promotionDetails")}</Row>
        <div className='promotionDetails container-fluid ListContainer'>
          {this.props.promotionDetails ? (
            <>
              <Row className='primaryDetails'>
                <Col md={1} className='p-0'>
                  <div className='imageplaceholder justify-center display-flex'>
                    <img
                      style={{
                        height: "2.5em",
                      }}
                      src={MTP_PROMOTION}
                      alt='minimart'
                    />
                  </div>

                  <div
                    className='gradeText justify-center display-flex pt-2'
                    title={Strings("promotionStatus")}
                    style={{
                      color:
                        PROMOTION_REQUEST_TYPE_LEGEND[
                          this.props.promotionDetails?.pd.status
                        ].color,
                    }}
                  >
                    <span title={Strings("promotionStatus")}>
                      {Strings(
                        this.props.promotionDetails?.pd.status
                      )}
                    </span>
                  </div>
                </Col>
                <Col md={11}>
                  {this.renderDetails(
                    MINIMART_DETAILS_KEYS,
                    Strings("Minimart"),
                    this.props.promotionDetails?.md
                  )}
                  {this.renderDetails(
                    PROMOTION_DETAILS_KEYS,
                    Strings("Promotions"),
                    this.props.promotionDetails?.pd
                  )}
                </Col>
              </Row>
              <hr className="opacity-100"/>
              {this.props.promotionDetails?.pd.status ===
                STATUS_LIST.REJECTED && this.renderNotes()}
              <hr className="opacity-100"/>
              <Row className='loanSection'>
                <Col md={12} className='loanTitle'>
                  {Strings("Loans")}
                </Col>
                <Col md={12}>{this.renderLoanSection()}</Col>
              </Row>
              <hr className="opacity-100"/>
              {this.renderActionButtons()}
            </>
          ) : (
            <Row className=' ps-5 justify-center display-flex noRecordsFound'>
              <span>{Strings("NoRecordsFound")}</span>
            </Row>
          )}
        </div>
      </>
    );
  }
}
