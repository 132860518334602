// Libraries
import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Components
import { Row, Col } from "react-bootstrap";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

// Actions

// Constants
import {
  CONFIGURATION_DATA_TYPE,
  LOAN_TYPE,
  LOAN_TYPES,
  MINIMART_GRADE,
} from "../../constants/appConstants";
import { CONCILIATION_TABLE_ROWS, FAST_CASH_LOAN_AMOUNT, FAST_CASH_TABLE_ROWS, INFINITE_VALUE, LADDER_COLUMNS, TABLE_ROWS } from "./Constants";

//Utilities
import Utility from "../../utils/Utility";

// Styles
import "../MTPProducts/Products.scss";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import addUser from "../../resources/images/ic_add_user_blue.svg";
import deleteRange from "../../resources/images/ic_notes_minus_blue.svg";

class Ladder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLevel: null,
      levelsList: props.levelsList,
      errorMessage: "",
      errorstate: {
        errorLevel: null,
      },
      errIndexArray: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.levelsList !== this.props.levelsList) {
      this.setState({
        levelsList: this.props.levelsList,
      });
    }
  }

  //on expand icon click
  toggleDetails = (id) => {
    let selectedLevel = id ? id : null;
    this.setState({
      selectedLevel,
    });
  };

  //Format the list of loan types which is shown in first row
  getLoanTypes = (level) => {
    let loanTypes = level.pd;
    let strLoanTypes = "";
    loanTypes.map((type) => {
      strLoanTypes += Strings(type.ln) + ", ";
      return strLoanTypes;
    });
    return strLoanTypes.slice(0, strLoanTypes.length - 2);
  };

  stylesTableCell = () => {
    return styled(TableCell)(({ theme }) => {
      return {
        [`&.${tableCellClasses.head}`]: {
          fontWeight: "bold",
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      };
    });
  };

  deleteLoanType = (levelName, index) => {
    let levelsList = { ...this.state.levelsList };
    levelsList["lder"][levelName]["pd"].splice(index, 1);
    this.setState({
      levelsList: levelsList,
    });
    this.props.saveEditedDataInTheState(
      levelName,
      CONFIGURATION_DATA_TYPE.LEVEL,
      this.state.levelsList,
      false
    );
  };

  onLoanTypeChange = (event, levelName, index) => {
    let loanTypeArray =
      this.props.originalConfiguration.configurationObject.lder[
        levelName
      ].pd.map((loanType) => loanType.ln);
    let levelsList = { ...this.state.levelsList };
    let isIncluded = loanTypeArray.includes(event.target.value);
    levelsList["lder"][levelName]["pd"][index].ln = !isIncluded
      ? event.target.value
      : Strings("errorDuplicateLoanType");
    let errorMessage =
      isIncluded && Strings("errorDuplicateLoanType");
    let errIndex = isIncluded && index;
    let errLevelAccordian = isIncluded && levelName
    this.setState({
      levelsList: levelsList,
    });
    this.props.saveEditedDataInTheState(
      levelName,
      CONFIGURATION_DATA_TYPE.LEVEL,
      this.state.levelsList,
      false,
      index,
      false,
      false,
      0,
      errorMessage
    );
    this.setState((prevState) => ({
      errIndexArray: [errIndex, ...prevState.errIndexArray],
      errorMessage,
      errorstate: {
        errorLevel: errLevelAccordian
      },
    }));
  };

  handleAmountChange = (event, levelName, column, index) => {
    let levelsList = { ...this.state.levelsList };
    levelsList["lder"][levelName]["pd"][index][column] = event.value;
    this.setState({
      levelsList: levelsList,
    }, () => {
      this.props.saveEditedDataInTheState(
        levelName,
        CONFIGURATION_DATA_TYPE.LEVEL,
        this.state.levelsList,
        false,
        index
      );
    });

  };

  onCheckboxSelection = (levelName, index, value) => {
    let levelsList = { ...this.state.levelsList };
    let isIncluded =
      levelsList.lder[levelName].pd[index].minFastCashGrade.includes(
        value
      );

    if (!isIncluded) {
      levelsList.lder[levelName].pd[index].minFastCashGrade.push(
        value
      );
    } else {
      levelsList.lder[levelName].pd[index].minFastCashGrade.splice(
        levelsList.lder[levelName].pd[index].minFastCashGrade.indexOf(
          value
        ),
        1
      );
    }
    this.setState({
      levelsList: levelsList,
    });
    this.props.saveEditedDataInTheState(
      levelName,
      CONFIGURATION_DATA_TYPE.LEVEL,
      this.state.levelsList
    );
  };

  renderGrade = (levelName, index, type) => {
    let minimartGrade = [...MINIMART_GRADE];
    type.minFastCashGrade = type.minFastCashGrade
      ? type.minFastCashGrade
      : ["A"];
    minimartGrade.shift();
    return (
      <Row>
        <Col md={12} className='px-0 py-3'>
          <strong className='subText'>
            {Strings(
              this.props.readOnly
                ? "MinimartGrade"
                : "selectMinimartGrade"
            )}
          </strong>
        </Col>
        {minimartGrade.map((grade, index1) => (
          <Col
            md={{ offset: index1 === 0 ? 1 : 0, span: 2 }}
            key={grade.value}
          >
            <CheckboxComponent
              onChange={() => {
                if (!this.props.readOnly) {
                  this.onCheckboxSelection(
                    levelName,
                    index,
                    grade.value
                  );
                }
              }}
              checked={type.minFastCashGrade.includes(grade.value)}
              disabled={this.props.readOnly}
              label={grade.label}
              key={grade.label}
            />
          </Col>
        ))}
      </Row>
    );
  };

  renderNumberFormatForLoanAmounts = (value, type, levelName, column, index) => {
    let data = type.ln ===
      LOAN_TYPES.FAST_CASH
      ? FAST_CASH_LOAN_AMOUNT
      : value;
    return (
      <NumericFormat
        className='form-control w-50'
        onValueChange={(event) =>
          this.handleAmountChange(
            event,
            levelName,
            column,
            index
          )
        }
        decimalScale='2'
        allowLeadingZeros={false}
        value={data}
        type='text'
        allowNegative={false}
        thousandSeparator
        disabled={
          type.ln ===
          LOAN_TYPES.FAST_CASH
        }
      />
    )
  }

  renderTableContainer = (level, type, tableRows, levelName, index, columns) => {
    const StyledTableCell = this.stylesTableCell();
    const Symbols = { Amount: "($)", Percentage: "(%)" }
    return (
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => {
                return <StyledTableCell key={column} className={i === 0 ? "text-start" : ""}>
                  {Symbols[column] ?
                    Strings(column) + " " + Symbols[column] :
                    Strings(column)}
                </StyledTableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((column) => {
              let value;

              if (type[column] && type[column] !==
                INFINITE_VALUE) {
                value = Utility.getCurrencyRepresentationOfAmount(
                  type[column]
                )
              }
              else {
                value = Strings("NA")
              }

              return <TableRow key={column}>
                <TableCell
                  component='th'
                  scope='row'
                  className='text-start'
                >
                  {Strings(column)}
                </TableCell>
                <TableCell
                  component='th'
                  scope='row'
                >
                  {this.props.readOnly ? value : <div className='textBox input d-flex justify-content-center'>
                    {this.renderNumberFormatForLoanAmounts(
                      value, type, levelName, column, index, column === CONCILIATION_TABLE_ROWS[0]
                    )}
                  </div>}


                </TableCell>

              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  //Expand the level accordion
  getLevelDescription = (level, levelName, index) => {
    let loanTypes = level.pd;
    return loanTypes.map((type, index) => {
      let tableRows = TABLE_ROWS;
      if (levelName === "LEVEL1" &&
        type.ln !== LOAN_TYPES.FAST_CASH) {
        tableRows = FAST_CASH_TABLE_ROWS
      }


      return (
        <>
          <Col md={11} className='pt-2'>
            <Accordion
              className='emiAccordian'
              defaultExpanded={!type.ln}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='emiAccordianSummary'
              >
                <Typography component={"span"}>
                  {Strings(type.ln)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!this.props.readOnly && (
                  <Col md={3}>
                    <TextFieldComponent
                      className={
                        this.state.errorMessage &&
                          this.state.errIndexArray.includes(index) &&
                          this.state.errorstate.errorLevel === levelName ? "errors" : ""
                      }
                      title={type.ln}
                      id={index}
                      type='select'
                      multiple={false}
                      value={type.ln}
                      handleChange={(event) => {
                        this.onLoanTypeChange(
                          event,
                          levelName,
                          index
                        );
                      }}
                      halfWidth={false}
                      key={levelName + type.ln}
                      name='ln'
                    >
                      {LOAN_TYPE.map((type) => {
                        return (
                          <option key={type.key} value={type.value}>
                            {type.label}
                          </option>
                        );
                      })}
                    </TextFieldComponent>
                  </Col>
                )}
                {type.ln === "FAST_CASH" &&
                  this.renderGrade(levelName, index, type)}

                {!this.state.errorMessage && type.ln && (
                  <Typography component={"span"}>
                    <Row className='justify-content-center newCommissionScheme pt-2'>
                      <Col
                        md={{ span: 8, offset: 0 }}
                        className='px-4 d-flex '
                      >
                        {this.renderTableContainer(level, type, tableRows, levelName, index, LADDER_COLUMNS)}

                      </Col>
                    </Row>
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col md={1}>
            {!this.props.readOnly && (
              <Col
                md={6}
                className='d-flex justify-content-end m-auto py-3'
              >
                <img
                  className='deleteIcon'
                  src={deleteRange}
                  onClick={() =>
                    this.deleteLoanType(levelName, index)
                  }
                  alt='delete'
                />
              </Col>
            )}
          </Col>
        </>
      );
    });
  };

  onInputValueChange = (event, levelName, index) => {
    let levelsList = { ...this.state.levelsList };
    levelsList.lder[levelName][event.target.name] = event.target.value
      ? parseFloat(event.target.value)
      : 0;
    this.setState({
      levelsList,
    });
    this.props.saveEditedDataInTheState(
      levelName,
      CONFIGURATION_DATA_TYPE.LEVEL,
      this.state.levelsList,
      false,
      index
    );
  };

  renderNumberFormatCustomForAmount = React.forwardRef((props, ref) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        style={{ textAlign: "center" }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        type='text'
        isNumericString
        allowNegative={false}
        thousandSeparator={true}
      />
    );
  });

  renderNumberFormatCustomForCreditScore = React.forwardRef(
    (props) => {
      const { inputRef, onChange, ...other } = props;
      return (
        <NumericFormat
          {...other}
          style={{ textAlign: "center" }}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          decimalScale='2'
          type='text'
          allowNegative={false}
          isAllowed={({ floatValue }) =>
            floatValue > 0.0 && floatValue < 100.0
          }
        />
      );
    }
  );

  addLoanType = (levelName, index) => {
    let levelsList = { ...this.state.levelsList };
    levelsList["lder"][levelName]["pd"].push({
      ln: "",
      minla: 1,
      maxla: 1,
      flma: levelName === "LEVEL1" ? 1 : null,
      slma: levelName === "LEVEL1" ? 1 : null,
    });
    this.setState({
      levelsList: levelsList,
    });
    this.props.saveEditedDataInTheState(
      levelName,
      CONFIGURATION_DATA_TYPE.LEVEL,
      this.state.levelsList,
      false,
      index
    );
  };

  //level list
  renderLevelDetails = (level, levelName, index) => {
    return (
      <div className='product headerContent' key={levelName}>
        <Row className={"cursorPointer"}>
          <Col md={1} lg={1} title={Strings("levelName")}>
            {Strings(levelName.toUpperCase())}
          </Col>
          <Col md={3} lg={3} title={Strings("minCreditScore")}>
            <span className='label'>
              {Strings("minCreditScore") + " (%)"}:&nbsp;
            </span>
            {this.props.readOnly ? (
              level?.minCreditScore
            ) : (
              <TextField
                key={levelName}
                InputProps={{
                  inputComponent:
                    this.renderNumberFormatCustomForCreditScore,
                }}
                value={level.minCreditScore}
                onChange={(event) => {
                  this.onInputValueChange(event, levelName, index);
                }}
                variant='standard'
                name='minCreditScore'
              />
            )}
          </Col>
          <Col md={3} lg={3} title={Strings("creditLimit")}>
            <span className='label'>
              {Strings("creditLimit") + " ($)"}:&nbsp;
            </span>
            {this.props.readOnly ? (
              Utility.getCurrencyRepresentationOfAmount(level.cl)
            ) : (
              <TextField
                key={levelName}
                InputProps={{
                  inputComponent:
                    this.renderNumberFormatCustomForAmount,
                }}
                value={Utility.getCurrencyRepresentationOfAmount(
                  level.cl
                )}
                onChange={(event) => {
                  this.onInputValueChange(event, levelName, index);
                }}
                variant='standard'
                name='cl'
              />
            )}
          </Col>
          <Col md={4} lg={4} title={Strings("loanType")}>
            <span className='label'>
              {Strings("loanType")}:&nbsp;
            </span>
            {this.getLoanTypes(level)}
          </Col>

          <Col
            md={1}
            lg={1}
            onClick={() =>
              this.state.selectedLevel === levelName
                ? this.toggleDetails()
                : this.toggleDetails(levelName)
            }
          >
            <i
              className={`fa pull-right ${this.state.selectedLevel !== null &&
                this.state.selectedLevel === levelName
                ? "fa-chevron-up"
                : "fa-chevron-down"
                }`}
              title={Strings("View")}
            />
          </Col>
        </Row>
        <Row
          className={
            this.state.selectedLevel !== null &&
              this.state.selectedLevel === levelName
              ? "visible mt-2"
              : "hidden"
          }
        >
          <Col md={12} lg={12} title={Strings("Description")}>
            <Row>{this.getLevelDescription(level, levelName, index)}</Row>
            <Row className="px-3">
              <Col md={11}
                className='paper renewalCriteriaClass'
              > <span>{Strings("renewalCriteria").toUpperCase()}</span>
                {this.props.readOnly ? <span>{` ${level.renewalCriteria} %`}</span> :
                  <TextField
                    key={levelName}
                    InputProps={{
                      inputComponent:
                        this.renderNumberFormatCustomForAmount,
                    }}
                    value={level.renewalCriteria}
                    onChange={(event) => {
                      this.onInputValueChange(event, levelName, index);
                    }}
                    variant='standard'
                    name='renewalCriteria'
                  />}
              </Col>
            </Row>
            <Row>
              {!this.props.readOnly && (
                <Col
                  md={{ span: 1, offset: 11 }}
                  className='px-4 text-end '
                >
                  <img
                    src={addUser}
                    alt='add'
                    onClick={() =>
                      this.addLoanType(levelName, level.pd.length)
                    }
                    className='addRange'
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    let levelsKeys = Object.keys(this.state.levelsList["lder"]);
    return (
      <div className='paper greyback'>
        <div className='productListContainer'>
          {levelsKeys.length > 0 ? (
            levelsKeys.map((item, index) => {
              return this.renderLevelDetails(
                this.state.levelsList.lder[item],
                item,
                index
              );
            })
          ) : (
            <div className='noRecordsFound'>
              {" "}
              {Strings("NoRecordsFound")}{" "}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Ladder;
