import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Constants
import { VISITED_DAYS } from "../../constants/appConstants";
import { MERGE_MINIMART_DETAILS_KEYS } from "./Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

export default function MinimartDetailsPopup(props) {
  const [openModal, setOpenModal] = useState(props.openModal);

  const renderTextField = (name, value, list, type = "select") => {
    return (
      <TextFieldComponent
        type={type}
        handleChange={props.handleModalInputChange}
        maxLength={100}
        autoFocus
        className='modalInput titleText'
        name={name}
        disabled={
          props.actionBtnDisabled ||
          ((name === "zone" || name === "mZone") && props.branch === "")
        }
        value={value}
        onKeyDown={Utility.allowOnlyNumber}
      >
        {list?.map((item) => {
          return (
            <option
              key={item.name || item.key}
              value={item.id || item.name || item.value}
            >
              {item.name || item.label}
            </option>
          );
        })}
      </TextFieldComponent>
    );
  };

  const renderMergeMinimartDetailValues = (minimart) => {

    let value = "";
    if (props.mergeMinimart && props.mergeMinimart[minimart.key]) {
      if (minimart.key === "collectionDays") {
        value = Utility.convertCollectionDaysToSpanish(props.mergeMinimart[minimart.key].split(","))
      } else {
        value = Strings(props.mergeMinimart[minimart.key]);
      }
    } else if (!props.mergeMinimart || !props.mergeMinimart[minimart.key]) {
      value = Strings("NA")
    }
    return value;
  }

  const renderMergeMinimartDetails = () => {
    return (
      <>
        <Row className="pt-4">
          <Col md={4} className='titleText my-auto'>
            {Strings("MatricesID")}
            {<span className='mandatoryFields'>*</span>}
            :&nbsp;
          </Col>
          <Col md={4} className='noMargin breakWord addressOrName'>
            {renderTextField(
              "megreMatricesId",
              props.megreMatricesId,
              null,
              "text"
            )}
          </Col>
          <Col md={3} className='ps-0'>
            <Button
              className={"buttonBackground primaryModalButton w-100"}
              onClick={() => {
                props.getMergeMinimartData(props.megreMatricesId);
              }}
              disabled={props.megreMatricesId === ""}
            >
              {Strings("Go")}
            </Button>
          </Col>
        </Row>
        <Row className='py-2'>
          {props.mergeMinimart && (
            <Col>
              <span className='warningText'>
                {Strings("mergeMinimartWarning")
                  .replace("$$", props.matricesId)
                  .replace("$", props.mergeMinimart.id)}
              </span>
            </Col>
          )}
        </Row>
        <Row>
          {MERGE_MINIMART_DETAILS_KEYS.map((minimart) => (
            <Col md={6} key={minimart.key}>
              <span className='titleText'>
                {Strings(minimart.label)} &nbsp;:&nbsp;
              </span>

              <span className={`detailText ${minimart.className}`}>
                {renderMergeMinimartDetailValues(minimart)}
              </span>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const renderMergeMinimartWarning = () => {
    let warning = ""
    if (props.mergeMinimart) {
      if (props.mergeMinimart.collectionDays !== props.oldMinimartCollectionDays
        && props.mergeMinimart.activeLoanIds.length > 0) {
        warning = Strings("mergeMinimartCollectionDaysWarning")
          .replace("#", props.mergeMinimart.activeLoanIds)
          .replace("$", props.mergeMinimart.id)
      }
    }
    return warning;
  }

  return (
    <Modal
      open={props.openModal}
      onClose={() => openModal(false)}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      classNames={{ modal: props.isMergeMinimart ? "mergeMinimartModal" : "customModal" }}
      showCloseIcon={false}
      center
    >
      <Row className='inputModal'>
        <Col md={12} className='headerModal'>
          {Strings(props.modalHeaderText)}
        </Col>
        <Col md={12} className='modalContent'>
          {props.errorForPopup && <Row>
            <Col
              md={12}
              className={"errors px-0"}
            >
              {props.errorForPopup}
            </Col>
          </Row>}
          {props.isMergeMinimart &&
            <Col className="warningText">
              {Strings("mergeMinimartAlert")}
            </Col>
          }
          {<Col md={12} className="warningText mt-0 pt-0">
            {renderMergeMinimartWarning()}
          </Col>}
          {props.isMergeMinimart ? (
            renderMergeMinimartDetails()
          ) : (
            <Row className='userInput '>
              {props.isAssociationEdit ? (
                <>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      {Strings("Branch")}{" "}
                      {<span className='mandatoryFields'>*</span>}
                      :&nbsp;
                    </Col>
                    <Col
                      md={8}
                      className='noMargin breakWord addressOrName'
                    >
                      {renderTextField(
                        "branch",
                        props.branch,
                        props.branchList
                      )}
                    </Col>
                  </Row>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      {Strings("Zone")}{" "}
                      {<span className='mandatoryFields'>*</span>}
                      :&nbsp;
                    </Col>
                    <Col
                      md={8}
                      className='noMargin breakWord addressOrName'
                    >
                      {renderTextField(
                        "zone",
                        props.zone,
                        props.zones
                      )}
                    </Col>
                  </Row>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      {Strings("mzone")}{" "}
                      {<span className='mandatoryFields'>*</span>}
                      :&nbsp;
                    </Col>
                    <Col
                      md={8}
                      className='noMargin breakWord addressOrName'
                    >
                      {renderTextField(
                        "mZone",
                        props.mZone,
                        props.mZones
                      )}
                    </Col>
                  </Row>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      {Strings("visitDay")}{" "}
                      {<span className='mandatoryFields'>*</span>}
                      :&nbsp;
                    </Col>
                    <Col
                      md={8}
                      className='noMargin breakWord addressOrName'
                    >
                      {renderTextField(
                        "visitDay",
                        props.visitDay,
                        VISITED_DAYS
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      {Strings("minimartLevel")}{" "}
                      {<span className='mandatoryFields'>*</span>}
                      :&nbsp;
                    </Col>
                    <Col
                      md={8}
                      className='noMargin breakWord addressOrName'
                    >
                      {renderTextField(
                        "level",
                        props.level,
                        props.levelList
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {!props.isSignupRequest && (
                <Row className='w-100 m-1'>
                  <Col md={4} className='titleText'>
                    {Strings("assignedSalesPerson")} :&nbsp;
                  </Col>
                  <Col
                    md={8}
                    className='noMargin breakWord addressOrName'
                  >
                    {props.sp || Strings("NA")}
                  </Col>
                </Row>
              )}
              {!props.isSignupRequest && (
                <Row className='w-100 m-1'>
                  <Col md={4} className='titleText'>
                    {Strings("assignedMessenger")} :&nbsp;
                  </Col>
                  <Col
                    md={8}
                    className='noMargin breakWord addressOrName'
                  >
                    {props.msg || Strings("NA")}
                  </Col>
                </Row>
              )}
            </Row>
          )}

          <Row className='pt-3 pb-4'>
            <Col md={{ span: 3, offset: 3 }} className=''>
              <Button
                className='buttonBackground primaryModalButton w-100'
                onClick={props.handleRouteChange}
                disabled={
                  props.isMergeMinimart
                    ? props.mergeMinimart //NOSONAR
                      ? props.mergeMinimart.id !==
                      parseInt(props.megreMatricesId)
                      : true
                    : props.actionBtnDisabled
                }
              >
                {Strings(props.yesBtnText)}
              </Button>
            </Col>
            <Col md={3} className='ps-0'>
              <Button
                className={"primaryModalButton buttonBorder w-100"}
                onClick={() => {
                  props.closeModal();
                  setOpenModal(false);
                }}
              >
                {Strings(props.cancelBtnText)}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
