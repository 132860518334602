import React from "react";
import { Row, Col } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";
import {
  PIE_CHART_COLORS,
  PROMOTION_LEGENDS,
  PIE_CHART_COLORS_CONST,
} from "../../constants/appConstants";
import Utility from "../../utils/Utility";

import "./PromotionStats.scss";
const renderLegend = (color, legendName, data, isAmount) => {
  let colorStyle = {
    backgroundColor: color,
    height: "1.5em",
    width: "1.5em",
  };
  return (
    <Row className='legend' key={Math.random()}>
      <div
        className='noPadding legendCommissionColorDiv'
        style={colorStyle}
      />
      <Col md={6} className='ps-1'>
        <div className='branchNameInLegends'>
          {Strings(legendName)}
        </div>
      </Col>
      <div className='branchNameInLegends w-auto fw-bold p-0'>
        {isAmount
          ? Utility.getCurrencyRepresentationOfAmount(data)
          : data}
      </div>
    </Row>
  );
};
const renderPieChartComponent = (data, total, datakey, colorCode) => {
  return (
    <PieChartComponent
      data={data}
      colors={colorCode}
      index={0}
      innerRadius='68%'
      outerRadius='90%'
      total={total}
      datakey={datakey}
      showLabel={true}
      height={140}
    />
  );
};

const renderPieChart = (
  data,
  dataKey,
  colorCode,
  isAmount = true
) => {
  return (
    <>
      {PROMOTION_LEGENDS.length > 0 &&
        PROMOTION_LEGENDS.map((legend, index) => {
          return renderLegend(
            PIE_CHART_COLORS[colorCode][index],
            legend,
            data[index][dataKey],
            isAmount
          );
        })}
    </>
  );
};
const PromotionStats = (props) => {
  return (
    <div className='promotionStats'>
      <Row className='pb-2'>
        <Col md={6} className='borderRight'>
          <div className='BoldText'>{Strings("promotionCount")}</div>
          {props.data.promotionCount.length > 0 ? (
            <Row className='w-100'>
              <Col md={5}>
                {renderPieChartComponent(
                  props.data.promotionCount,
                  props.data.tcnt,
                  "count",
                  PIE_CHART_COLORS_CONST.PROMOTION_STATS_COUNT
                )}
              </Col>

              <Col md={6} className='noPadding align-self-center'>
                {renderPieChart(
                  props.data.promotionCount,
                  "count",
                  PIE_CHART_COLORS_CONST.PROMOTION_STATS_COUNT,
                  false
                )}
              </Col>
            </Row>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoData")}
            </div>
          )}
        </Col>
        <Col md={6} className='borderRight'>
          <div className='BoldText'>{Strings("promotionAmount")}</div>
          {props.data.promotionCount.length > 0 ? (
            <Row className='w-100'>
              <Col md={5}>
                {renderPieChartComponent(
                  props.data.promotionAmount,
                  Utility.getCurrencyRepresentationOfAmount(
                    props.data.tamt
                  ),
                  "amount",
                  PIE_CHART_COLORS_CONST.PROMOTION_STATS_AMOUNT
                )}
              </Col>

              <Col md={6} className='noPadding align-self-center'>
                {renderPieChart(
                  props.data.promotionAmount,
                  "amount",
                  PIE_CHART_COLORS_CONST.PROMOTION_STATS_AMOUNT
                )}
              </Col>
            </Row>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoData")}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PromotionStats;
