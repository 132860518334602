import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";

//Components
import InputModal from "../CustomUIComponents/InputModal/InputModal";
import TemplateModal from "./TemplateModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";

//Styles
import "./ConfigurationDashboard.scss";

//Constants
import {
  TEMPLATE_MODAL_BUTTONS,
  CONFIGURATION_EDIT_FIELD_KEYS,
} from "./Constants";
import {
  MODAL_TYPE,
  SEARCH_FILTER_TYPE,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";


export default class ConfigurationType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      templateModal: {
        show: false,
      },
      showNotificationModal: false,
    };
  }

  /*
    This method sets necessary data required for modal in state then calls toggle modal
    method to actually show the modal
  */
  showModal = (message, value, type, modalSubTypeIndex = null) => {
    let modalHeader = message;
    this.setState(
      {
        modalHeader,
        modalValue: value,
        modalSubTypeIndex,
        modalType: type,
      },
      () => {
        this.toggleModal();
      }
    );
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleChangeValue = (key, value) => {
    if (key === CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT) {
      value = parseInt(value, 10);
      if (value <= 100 && value >= 0) {
        this.props.handleChangeValue(
          this.props.index,
          this.state.modalSubTypeIndex,
          key,
          value
        );
      }
    } else {
      if (value) {
        this.props.handleChangeValue(
          this.props.index,
          this.state.modalSubTypeIndex,
          key,
          value
        );
      }
    }
    this.props.resetError();
    this.toggleModal();
  };

  handleOnClickAction = (actionType, typeIndex, subTypeIndex) => {
    this.props.handleOnClickAction(
      actionType,
      typeIndex,
      subTypeIndex
    );
  };

  editSubtypeData = (
    subtypeIndex,
    subtypeName,
    subtypeRange,
    mapping,
    template
  ) => {
    this.props.editSubtypeData(
      this.props.index,
      subtypeIndex,
      subtypeName,
      subtypeRange,
      mapping,
      template
    );
    this.setState({
      templateModal: {
        show: false,
      },
    });
  };

  editSubtype = (subType, index) => {
    this.setState({
      templateModal: {
        show: true,
        type: MODAL_TYPE.EDIT,
        onClickOkCallback: this.editSubtypeData,
        subType: subType,
        subTypeIndex: index,
      },
    });
  };


  closeTemplateModal = () => {
    this.setState({
      templateModal: {
        show: false,
      },
    });
  };

  renderTemplateModal = () => {
    const mappingsList = _.cloneDeep(this.props.filteredmappings);

    let mappingObject = {};

    if (this.props.Editable) {
      if (this.state.templateModal.type === MODAL_TYPE.EDIT) {
        mappingsList.splice(
          0,
          0,
          this.state.templateModal.subType.mapping
        );
      }

      //Added select option to mappings list
      mappingObject[SEARCH_FILTER_TYPE.SELECT] =
        Strings("selectVariable");

      mappingsList.forEach((mapping) => {
        mappingObject[mapping] =
          this.getFieldValueForMapping(mapping);
      });
    } else {
      mappingObject[this.state.templateModal.subType.mapping] =
        this.getFieldValueForMapping(
          this.state.templateModal.subType.mapping
        );
    }
    return (
      <TemplateModal
        Editable={this.props.Editable}
        open={true}
        omodalText1Id={this.state.templateModal.text}
        modalButtonOk={{
          id: TEMPLATE_MODAL_BUTTONS.SET,
          onClick: this.state.templateModal.onClickOkCallback,
        }}
        modalType={this.state.templateModal.type}
        modalButtonCancel={{
          id: TEMPLATE_MODAL_BUTTONS.CANCEL,
          onClick: this.closeTemplateModal,
        }}
        mappings={this.props.mappings}
        mappingsList={mappingObject}
        subtypeName={
          this.state.templateModal.type === MODAL_TYPE.EDIT &&
          this.state.templateModal.subType.name
        }
        subTypeIndex={
          this.state.templateModal.type === MODAL_TYPE.EDIT &&
          this.state.templateModal.subTypeIndex
        }
        mapping={
          this.state.templateModal.type === MODAL_TYPE.EDIT &&
          this.state.templateModal.subType.mapping
        }
        template={
          this.state.templateModal.type === MODAL_TYPE.EDIT &&
          this.state.templateModal.subType.template
        }
        rangeData={
          this.state.templateModal.type === MODAL_TYPE.EDIT &&
          this.state.templateModal.subType.data
        }
      />
    );
  };

  renderNotificationModal = () => {
    return (
      <UserModal
        open={true}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        modalButtonOk={{
          text: Strings("ok"),
          onClick: this.closeNotificationModal,
        }}
        modalText1={Strings("errorAllMappingsAreConfigured")}
        headerText={Strings("error")}
        showHr={true}
      />
    );
  };

  closeNotificationModal = () => {
    this.setState({
      showNotificationModal: false,
    });
  };

  getFieldValueForMapping = (mapping) => {
    let mappingObject = _.find(this.props.mappings, (mappingObj) => {
      return mappingObj.key === mapping;
    });
    return mappingObject ? Strings(mappingObject.field) : "";
  };

  render() {
    let totalOfSubtypes = 0;
    if (this.props.configurationType.subType) {
      this.props.configurationType.subType.map((subType) => {
        totalOfSubtypes += parseInt(subType.weight, 10);
        return null;
      });
    }
    return (
      <div className='configurationTypeSection'>
        {this.state.showNotificationModal &&
          this.renderNotificationModal()}
        {this.state.templateModal.show && this.renderTemplateModal()}
        <Row md={12} className='typeNameRow'>
          <Col md={10} className='configurationName'>
            {this.props.index + 1}
            {". "}
            {Strings(this.props.configurationType.name)}
            {this.props.Editable && (
              <i
                className='fa fa-pencil editTypeIcon clickable'
                onClick={() =>
                  this.showModal(
                    Strings("enterValueOf"),
                    this.props.configurationType.name,
                    "name"
                  )
                }
                title='Editar tipo'
              />
            )}
          </Col>

          <Col
            md={1}
            className={
              this.props.Editable
                ? "pull-right configurationTypeWeight clickable"
                : "pull-right configurationTypeWeight nonClickable"
            }
          >
            <div
              className='assignedWeightBlock'
              disabled={this.props.Editable}
              onClick={() =>
                this.showModal(
                  Strings("editTypeWeight"),
                  this.props.configurationType.weight,
                  CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT
                )
              }
              title='Tipo de edición de peso'
            >
              <span className='configurationTypeAssignedWeight'>
                {this.props.configurationType.weight}
              </span>
              <span className='configurationPercentage'>%</span>
            </div>
          </Col>

          <Col md={1} className='pull-right configurationTypeWeight'>
            <div className='totalWeightBlock'>
              <span className='configurationTypeTotalWeight'>
                {totalOfSubtypes}
              </span>
              <span className='configurationPercentage'>%</span>
            </div>
          </Col>
        </Row>
        <div className='configurationSubtypeContainer'>
          {this.props.configurationType.subType?.map(
            (subType, index) => {
              return (
                <Row className='configurationSubtype' key={index}>
                  <Col
                    md={11}
                    className='noRightPadding alignSelfCenter'
                  >
                    {index + 1}.
                    <span className='subtypeName'>
                      {" "}
                      {subType.name}
                    </span>
                    <span className='subtypeMapping'>
                      {subType.name}&nbsp;
                    </span>
                    <span className='subtypeTemplate noLeftPadding'>
                      -&nbsp;{Strings(subType.template)}
                    </span>
                    <div className='pull-right'>
                      {this.props.Editable ? (
                        <i
                          className='fa fa-pencil editIcon pt-2'
                          onClick={() => {
                            this.editSubtype(subType, index);
                          }}
                          aria-hidden='true'
                          title='Editar Subtipo'
                        />
                      ) : (
                        <button
                          className='showInputButton btn btn-default'
                          onClick={() => {
                            this.editSubtype(subType, index);
                          }}
                        >
                          {Strings("Detail")}
                        </button>
                      )}
                    </div>
                  </Col>

                  <Col
                    md={1}
                    className={
                      this.props.Editable
                        ? "pull-right textAlignRight configurationPerSection"
                        : "pull-right textAlignRight configurationPerSection nonClickable"
                    }
                  >
                    <div
                      className='subtypeConfigPer clickable'
                      onClick={() =>
                        this.showModal(
                          Strings("editSubtypeWeight"),
                          subType.weight,
                          CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT,
                          index
                        )
                      }
                      title='Editar peso de subtipo'
                    >
                      <span className='subtypeAssignedWeight'>
                        {subType.weight}
                      </span>
                      <span className='configurationPercentage subtypePercentage'>
                        %
                      </span>
                    </div>
                  </Col>
                </Row>
              );
            }
          )}
        </div>
        <hr className="opacity-100"/>
        <InputModal
          open={this.state.openModal}
          type={this.state.modalType}
          toggleModal={this.toggleModal}
          modalHeader={this.state.modalHeader}
          modalValue={this.state.modalValue}
          handleChangeValue={this.handleChangeValue}
        />
      </div>
    );
  }
}
ConfigurationType.defaultProps = {
  Editable: true,
};
