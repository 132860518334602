import React from "react";
import { Row, Col } from "react-bootstrap";
import { Strings } from "../../../resources/i18n/i18n";
import {
  MINIMART_CARD,
  CI_MINIMART_CARD,
  SIGNUP_REQUEST_CARDS,
  PROMOTION_APPROVAL_CARDS,
  PROMOTION_REQUEST_TYPE_LEGEND,
  ANALYST_PROMOTION_APPROVAL_CARDS,
  REWARD_APPROVAL_CARD,
  REWARD_HISTORYL_CARD,
  REWARD_TYPE_LEGEND,
  INVOICE_STATUS_LEGEND,
} from "./CardConstants";
import moment from "moment";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

//Constants
import {
  INVOICE_DATE_FORMAT,
  MINIMART_TYPE_LEGEND,
  LOAN_STATUS_LEGEND,
  LOAN_HISTORY_STATUS_LEGEND,
  LOAN_CATEGORY_LEGEND,
  CURRENCY,
  MINIMART_RATING,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  TIME_FORMAT,
  DAILY_STATUS_MINIMART_CARD,
  DISBURSED,
  CARD_TYPE,
  INVOICE_TYPE_IMAGES,
  USER_ROLES,
  ALLOWED_LOAN_TYPES_NEGOTIATION,
  HISTORY_MINIMART_LEGEND,
} from "../../../constants/appConstants";
import { LOAN_TYPES } from "../../AnalystApproval/Constants";
import { STATUS_SELECTION } from "../../MotorcycleCatalog/Constants";
import { INVOICE_CARD } from "../../CiInterface/CIConstants";

//Utility
import Utility from "../../../utils/Utility";

//Styles
import "./CustomCard.scss";

//Assets
import ProfileImage from "../../../resources/images/ic_user.svg";
import Checked from "../../../resources/images/checked_mark.svg";
import unCheck from "../../../resources/images/uncheck.svg";
import minimartImage from "../../../resources/images/ic_mini_market_new.svg";
import InvoiceCardImage from "../../../resources/images/invoice_card_image.svg";
import LoanCardImage from "../../../resources/images/ic_loan_portfolio_management_small.svg";
import TPrestaIcon from "../../../resources/images/Scala_logo.png";
import MTP_PROMOTION from "../../../resources/images/promotion_request_status.png";
import REWARD_APPROVAL from "../../../resources/images/reward_redeem_request_card.png";
import CIImage from "../../../resources/images/ic_CI.svg";

class CustomCard extends React.Component {
  renderMotorCycleCard = () => {
    return (
      <Col
        md={4}
        className='px-0 motorcycleCard mb-3 ms-0  cursorPointer'
        title={this.props.card.dc || Strings("NoDescription")}
        onClick={() => {
          this.props.handleCardClick(this.props.card);
        }}
      >
        <Row className='w-100'>
          <Col md={6} lg={6} className='motorcycleImgContainer'>
            <img
              className='motorcycleImg'
              src={this.props.card.img ? this.props.card.img : ""}
              alt={this.props.card.mn}
              key={this.props.card.id}
            />
          </Col>
          <Col
            md={6}
            lg={6}
            className='px-0 motorcycleDetailsContainer'
          >
            <Row>
              <Col
                md={6}
                lg={6}
                title={Strings("Category")}
                className='px-0 pt-2 motorCycleCategory'
              >
                {this.props.card.cat.category}
              </Col>
              <Col
                md={6}
                lg={6}
                className={`pt-1 ps-0 
                  ${this.props.card.st === STATUS_SELECTION[1].value
                    ? "motorCycleStatusActive"
                    : "motorCycleStatusInActive "
                  }
                `}
                title={Strings("Status")}
              >
                {Strings(
                  this.props.card.st === STATUS_SELECTION[1].value
                    ? "MActive"
                    : "MInActive"
                )}
              </Col>
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-1'
              title={`${Strings("Name")}`}
            >
              {this.props.card.mn}
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-1'
              title={Strings("model")}
            >
              {this.props.card.mo}
            </Row>
            <Row
              title={Strings("cost")}
              className='pt-2 px-0 motorCycleProductCost'
            >
              {"RD$ " +
                (this.props.card.co !== undefined
                  ? Utility.getCurrencyRepresentationOfAmount(
                    this.props.card.co
                  )
                  : "0")}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };
  renderRewardCard = () => {
    return (
      <Col
        md={4}
        className='px-0 motorcycleCard mb-3 ms-0  cursorPointer'
        // title={this.props.card.dc || Strings("NoDescription")}
        onClick={() => {
          this.props.handleCardClick(this.props.card);
        }}
      >
        <Row className='w-100 h-100'>
          <Col md={6} lg={6} className='py-3 h-100'>
            <img
              className='img-rounded img-thumbnail w-100 h-100'
              src={Utility.getDecryptedData(this.props.card.rpi)}
              alt={this.props.card.rpn}
              key={this.props.card.rpid}
            />
          </Col>
          <Col md={6} lg={6} className='px-0'>
            <Row>
              <Col
                md={12}
                lg={12}
                className={`pt-1 ps-0 pull-right
                  ${this.props.card.rps === STATUS_SELECTION[1].value
                    ? "motorCycleStatusActive"
                    : "motorCycleStatusInActive "
                  }
                `}
                title={Strings("Status")}
              >
                {Strings(
                  this.props.card.rps === STATUS_SELECTION[1].value
                    ? "MActive"
                    : "MInActive"
                )}
              </Col>
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-1'
              title={`${Strings("Name")}`}
            >
              {this.props.card.rpn}
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-1'
              title={Strings("model")}
            >
              {this.props.card.rpm}
            </Row>
            <Row
              title={Strings("rewardPoints")}
              className='pt-2 px-0 motorCycleProductCost'
            >
              {this.props.card.rp}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };
  renderCIMinimart = () => {
    const { card, handleCardClick, isHistory, readOnly } = this.props;
    let legend = MINIMART_TYPE_LEGEND[1];
    if (isHistory) {
      legend = HISTORY_MINIMART_LEGEND.find((legendType) => {
        return legendType.key === card.st;
      });
    }
    return (
      <Col
        md={4}
        className={`loanCard mt-3 px-0 me-3 pt-1  ${readOnly ? "" : "cursorPointer"
          }`}
        onClick={() => {
          !readOnly && handleCardClick(card);
        }}
      >
        <Row className='pt-2'>
          <Col md={4} className={"marginLeft rightSide"}>
            <div className={"px-1"}>
              <Row className='borderCard'>
                <div
                  style={{
                    borderRadius: "50%",
                    border: ` 2px solid ${legend.color}`,
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <img
                    className='applicantImage'
                    src={minimartImage}
                    alt='minimartImage'
                    key={Math.random()}
                  // title={Strings(miniMartCard.type)}
                  />
                </div>
              </Row>
            </div>
            <div
              key={Math.random()}
              className='categoryColor pt-1 text-center'
            >
              {/* {Strings("Grade")} &nbsp;
              {card.category} */}
            </div>
          </Col>
          <Col md={8}>
            {CI_MINIMART_CARD.map((minimart) => {
              return (
                <div
                  className='noMargin detailText textWrap'
                  title={minimart.title}
                  key={minimart.title}
                >
                  {Strings(card[minimart.key])}
                </div>
              );
            })}
          </Col>
          <></>
        </Row>
      </Col>
    );
  };

  //minimart cards
  renderMinimartCard = () => {
    const { card, handleCardClick, isNewMinimart } = this.props;
    let index = 0;
    if (!isNewMinimart && card.type) {
      index = MINIMART_TYPE_LEGEND.findIndex((legendType) => {
        return legendType.value === card.type;
      });
    }
    const isMiniMartVisit =
      card[DAILY_STATUS_MINIMART_CARD.visitStatus];

    return (
      <Col
        md={4}
        className={
          isMiniMartVisit !== undefined
            ? "loanCard mt-3 px-0 me-3 pt-1 cursorPointer"
            : "loanCard mt-3 px-0 me-3 cursorPointer"
        }
        onClick={() => {
          handleCardClick(card);
        }}
        key={Math.random()}
      >
        {isMiniMartVisit !== undefined && (
          <Row>
            <Col md={{ span: 2, offset: 10 }}>
              <img
                className={
                  isMiniMartVisit
                    ? "visitStatus_checked"
                    : "visitStatus_unchecked"
                }
                src={isMiniMartVisit ? Checked : unCheck}
                title={
                  isMiniMartVisit
                    ? moment(
                      card[DAILY_STATUS_MINIMART_CARD.visitedAt]
                    ).format(TIME_FORMAT)
                    : Strings("notVisited")
                }
                alt='visitStatus'
                key={Math.random()}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4} className={"marginLeft rightSide"} style={{ paddingRight: "15px", paddingLeft: "15px" }}>
            <div className={"px-1"}>
              <Row className='borderCard'>
                <div
                  style={{
                    borderRadius: "50%",
                    border: ` 2px solid ${MINIMART_TYPE_LEGEND[index].color}`,
                    paddingLeft: "0px",
                    paddingRight: "0px"
                  }}
                >
                  <img
                    className='applicantImage'
                    src={minimartImage}
                    alt='minimartImage'
                    key={Math.random()}
                    title={Strings(card.type)}
                  />
                </div>
              </Row>
            </div>
            {!isNewMinimart && (
              <div
                key={Math.random()}
                className='categoryColor pt-1 text-center'
              >
                {Strings("Grade")} &nbsp;
                {card.category}
              </div>
            )}
          </Col>
          <Col md={8}>
            {MINIMART_CARD.map((minimart) => {
              return (
                <div
                  className='noMargin detailText textWrap'
                  title={Strings(minimart)}
                  key={Strings(minimart)}
                >
                  {Strings(card[minimart])}
                </div>
              );
            })}
          </Col>
          <></>
        </Row>
      </Col>
    );
  };

  renderInvoiceCard = (invoiceCard) => {
    let invoiceCardImage =
      invoiceCard.it === null
        ? InvoiceCardImage
        : INVOICE_TYPE_IMAGES[invoiceCard.it];

    const tvdDate = invoiceCard[INVOICE_CARD.tvd]
      ? Strings(invoiceCard[INVOICE_CARD.tvd])
      : Strings("N/A");
    return (
      <Col
        className='loanCard mt-3 px-0 me-3'
        md={4}
        onClick={() => {
          this.props.handleCardClick(invoiceCard);
        }}
      >
        <Row className='arrowPointer' key={invoiceCard.number}>
          <Col className='rightSide px-0' md={4}>
            <Row className='borderCard text-center'>
              <img
                className='InvoiceImage ps-0 pe-0'
                src={invoiceCardImage}
                alt='InvoicePic'
                key={Math.random()}
                loading='lazy'
                title={
                  invoiceCard.it
                    ? Strings(invoiceCard.it)
                    : Strings("default")
                }
              />
              <div
                key={Math.random()}
                className='GradeText'
                style={{
                  color:
                    INVOICE_STATUS_LEGEND[invoiceCard.status].color,
                }}
              >
                {Strings(invoiceCard.status)}
              </div>
            </Row>
          </Col>
          <Col md={8}>
            <div
              className='InvoiceTextWrap detailText'
              title={Strings(INVOICE_CARD.minimartCode)}
            >
              {Strings(invoiceCard[INVOICE_CARD.minimartCode])}
            </div>

            <div
              className='InvoiceTextWrap detailText'
              title={Strings(INVOICE_CARD.number)}
            >
              {Strings(invoiceCard[INVOICE_CARD.number])}
            </div>

            <div
              className='InvoiceTextWrap detailText'
              title={Strings(INVOICE_CARD.date)}
            >
              {moment(invoiceCard[INVOICE_CARD.date]).format(
                INVOICE_DATE_FORMAT
              )}
            </div>

            <div
              className='InvoiceTextWrap detailText'
              title={Strings(INVOICE_CARD.expiryDate)}
            >
              {moment(invoiceCard[INVOICE_CARD.expiryDate]).format(
                INVOICE_DATE_FORMAT
              )}
            </div>

            <div
              key={Math.random()}
              className='InvoiceTextWrap detailText'
              title={Strings("balance") + "/" + Strings("amount")}
            >
              {CURRENCY +
                Utility.getCurrencyRepresentationOfAmount(
                  invoiceCard[INVOICE_CARD.balance]
                ) +
                " / " +
                CURRENCY +
                Utility.getCurrencyRepresentationOfAmount(
                  invoiceCard[INVOICE_CARD.amount]
                )}
            </div>

            <div
              className='InvoiceTextWrap detailText'
              title={Strings(INVOICE_CARD.tvd)}
            >
              {tvdDate}
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  renderLoanCardDetails = () => {// NOSONAR
    let loanCardLeftStyle = {};
    let loanCardLeftTitle = Strings(this.props.card.status);
    let border =
      LOAN_STATUS_LEGEND[
        LOAN_STATUS_LEGEND.findIndex((legendType) => {
          return legendType.key === this.props.card.status;
        })
      ].color;
    let loanCardImage;
    if (this.props.isHistory) {
      border =
        LOAN_HISTORY_STATUS_LEGEND[
          LOAN_HISTORY_STATUS_LEGEND.findIndex((legendType) => {
            return legendType.key === this.props.card.status;
          })
        ].color;
    }
    if (
      this.props.card.status !== undefined &&
      this.props.card.status !== DISBURSED
    ) {
      loanCardLeftTitle = Strings(this.props.card.status);
      loanCardLeftStyle = {
        borderRadius: "50%",
        border: ` 2px solid ${border}`,
      };
    } else if (this.props.card.category) {
      loanCardLeftTitle = Strings(this.props.card.category);
      loanCardLeftStyle = {
        borderRadius: "50%",
        border: ` 2px solid ${LOAN_CATEGORY_LEGEND[
          LOAN_CATEGORY_LEGEND.findIndex((legendType) => {
            return legendType.value === this.props.card.category;
          })
        ].color
          }`,
      };
    } else {
      loanCardLeftStyle = {
        borderRadius: "50%",
        border: ` 2px solid ${border}`,
      };
    }
    let isApprovedAmountLoan =
      ALLOWED_LOAN_TYPES_NEGOTIATION.includes(
        this.props.card.loanType
      );
    const cursorPointerClass = this.props.isAnalyst
      ? "w-100"
      : "cursorPointer w-100";
    let HighLigherColor = "";
    const isOperation =
      this.props.isOperationsOfficer ||
      this.props.isOperationsModerator;
    if (this.props.card.loanType === LOAN_TYPES.FAST_CASH) {
      HighLigherColor =
        isOperation && !this.props.isHistory
          ? "fastCashHighliter"
          : "";
    } else if (this.props.card.loanType === LOAN_TYPES.FACTORING) {
      HighLigherColor =
        isOperation && !this.props.isHistory
          ? "factoringHighligher"
          : "";
    } else {
      if (this.props.isAnalyst || isOperation) {
        HighLigherColor = this.props.card.reminder
          ? "loanCardReminder"
          : "";
      }
    }
    if (this.props.card.initiatedFrom === "TPRESTA") {
      loanCardImage = TPrestaIcon
    }
    else if (this.props.card.initiatedFrom === "CORE") {
      loanCardImage = LoanCardImage
    }
    else {
      loanCardImage = CIImage
    }
    return (
      <Col
        md={4}
        className={`loanCard mt-3 px-0 me-3 ${HighLigherColor}`}
      >
        <Row
          className={
            this.props.readOnly
              ? "arrowPointer w-100"
              : cursorPointerClass
          }
          onClick={() => {
            this.props.handleCardClick(this.props.card);
          }}
          key={this.props.card.applicationId}
        >
          <div className='px-2 rightSide minContent'>
            <Row className='borderCard  text-center'>
              <div
                className='text-center pe-0 ps-0'
                style={loanCardLeftStyle}
                title={loanCardLeftTitle}
              >
                <img
                  className='loanImage text-center'
                  src={
                    loanCardImage
                  }
                  alt='loanImage'
                  key={Math.random()}
                  width='70'
                  height='70'
                />
              </div>
              <div
                key={this.props.card.applicationId}
                className='justify-center display-flex'
                title={Strings("applicationId")}
              >
                <span className='displayBlock detailText'>
                  {this.props.card.applicationId}
                </span>
              </div>
            </Row>
          </div>
          <Col md={8} lg={8}>
            <div key={Math.random()} className='InvoiceTextWrap'>
              {isApprovedAmountLoan && (
                <span
                  className='noMargin detailText'
                  title={Strings("approvedAmountTitle")}
                >
                  {CURRENCY +
                    (this.props.card.approvedAmount
                      ? Utility.getCurrencyRepresentationOfAmount(
                        this.props.card.approvedAmount
                      )
                      : 0) +
                    " / "}
                </span>
              )}
              {
                <span
                  className='noMargin detailText'
                  title={Strings(
                    isApprovedAmountLoan
                      ? "approvedAmountTitle"
                      : "loanAmount"
                  )}
                >
                  {(isApprovedAmountLoan ? "" : CURRENCY) +
                    Utility.getCurrencyRepresentationOfAmount(
                      this.props.card.loanAmount
                    )}
                </span>
              }
            </div>
            <div key={Math.random()} className='InvoiceTextWrap'>
              <span
                className='noMargin detailText'
                title={Strings("loanType")}
              >
                {Strings(this.props.card.loanType)}
              </span>
            </div>
            <div key={Math.random()} className='InvoiceTextWrap'>
              <span
                className='noMargin detailText'
                title={Strings("minimartName")}
              >
                {Strings(this.props.card.minimartName)}
              </span>
            </div>
            <div
              key={Math.random()}
              className='InvoiceTextWrap'
              title={Strings("disbursedDate")}
            >
              <span className='noMargin detailText'>
                {this.props.card.disbursementDate
                  ? moment(this.props.card.disbursementDate).format(
                    INVOICE_DATE_FORMAT
                  )
                  : Strings("NotDisbursed")}
              </span>
            </div>
            {this.props.card.initiatedFrom === "TPRESTA" &&
              this.props.card.createdAt && (
                <div
                  key={Math.random()}
                  className='InvoiceTextWrap'
                  title={Strings("creationDate")}
                >
                  <span className='noMargin detailText'>
                    {moment(this.props.card.createdAt).format(
                      INVOICE_DATE_FORMAT
                    )}
                  </span>
                </div>
              )}
            {this.props.card.userName && (
              <div key={Math.random()} className='InvoiceTextWrap'>
                <span
                  className='noMargin detailText'
                  title={
                    this.props.isAnalystModerator
                      ? Strings("analystName")
                      : Strings("operationOfficerName")
                  }
                >
                  {this.props.card.userName}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  renderPersonCard = () => {
    return (
      <Col
        md={4}
        className='loanCard mt-3 px-0 me-3'
      // className='customCard max-width max-height'
      >
        <Row
          // md={12}
          className={this.props.isAnalyst ? "" : "cursorPointer"}
          onClick={() => {
            this.props.handleCardClick(this.props.card);
          }}
          key={this.props.card.id}
        >
          <Col md={9}>
            <div
              className='subHeading pb-2 userNameWrap'
              title={
                this.props.card.type !== undefined &&
                  this.props.card.type === SPECIAL
                  ? this.props.card.firstName +
                  "  " +
                  this.props.card.lastName +
                  SPECIAL_MESSENGER_PRESENTATION
                  : this.props.card.firstName +
                  "  " +
                  this.props.card.lastName
              }
            >
              <span>
                {this.props.card.type !== undefined &&
                  this.props.card.type === SPECIAL &&
                  SHORT_SPECIAL_MESSENGER_PRESENTATION}{" "}
                {this.props.card.firstName}&nbsp;
                {this.props.card.lastName}
              </span>
            </div>
          </Col>

          {
            <Col md={3} className=' alignCounts pull-right  pb-2'>
              <div
                title={
                  Strings("visitedMinimarts") +
                  " / " +
                  Strings("totalAssignedMinimarts")
                }
                className='subHeading'
              >
                <span className='noMargin detailText'>
                  {this.props.card.visitedMinimarts}
                </span>
                /
                <span className='noMargin detailText'>
                  {this.props.card.totalAssignedMinimarts}
                </span>
              </div>
            </Col>
          }

          <Col md={5}>
            <img
              className='UserImage'
              src={
                this.props.card.profileUrl === "0" ||
                  this.props.card.profileUrl === null
                  ? ProfileImage
                  : this.props.card.profileUrl
              }
              onError={(error) => {
                error.target.onError = null;
                error.target.src = ProfileImage;
              }}
              alt='ProfilePic'
              key={Math.random()}
            />
            {this.props.card.rating !== undefined && (
              <div>
                <Rater
                  id={this.props.card.id}
                  title={
                    this.props.card.mRat !== undefined
                      ? Strings("userRating") +
                      " : " +
                      this.props.card.rating +
                      "\n" +
                      Strings("minimartUserRating") +
                      " : " +
                      MINIMART_RATING[this.props.card.mRat]
                      : Strings("userRating") +
                      " : " +
                      this.props.card.rating
                  }
                  rating={this.props.card.rating}
                  total={5}
                  interactive={false}
                  style={{
                    fontSize: 15 + "px",
                    color: "#3ea853",
                  }}
                />
              </div>
            )}
          </Col>
          <Col md={7}>
            {this.props.card.branchName && (
              <div className='InvoiceTextWrap'>
                <span
                  className='noMargin detailText'
                  title={Strings("branch")}
                >
                  {this.props.card.branchName}
                </span>
              </div>
            )}
            {this.props.card.userRole === USER_ROLES.salesPerson ? (
              <div className='InvoiceTextWrap'>
                <span
                  className='noMargin detailText'
                  title={Strings("generatedLoansCount")}
                >
                  {this.props.card.generatedLoansCount}
                </span>
              </div>
            ) : (
              <div>
                <div
                  title={
                    Strings("amountCollected") +
                    " / " +
                    Strings("amountToBeCollected")
                  }
                  className='InvoiceTextWrap'
                >
                  <span className='noMargin detailText'>
                    {this.props.card.amountCollected !== undefined &&
                      CURRENCY +
                      Utility.getCurrencyRepresentationOfAmount(
                        this.props.card.amountCollected
                      )}
                  </span>
                  {" / "}
                  <span className='noMargin detailText'>
                    {this.props.card.amountToBeCollected !==
                      undefined &&
                      Utility.getCurrencyRepresentationOfAmount(
                        this.props.card.amountToBeCollected
                      )}
                  </span>
                </div>
              </div>
            )}
            {this.props.card.amountInstransit !== false &&
              this.props.card.amountInstransit !== undefined && (
                <div className='InvoiceTextWrap'>
                  <span
                    className='noMargin detailText'
                    title={Strings("InTransit")}
                  >
                    {CURRENCY +
                      Utility.getCurrencyRepresentationOfAmount(
                        this.props.card.amountInstransit
                      )}
                  </span>
                </div>
              )}
            {this.props.card.ef !== undefined && (
              <div className='InvoiceTextWrap'>
                <span
                  className='noMargin detailText'
                  title={Strings("Effectiveness")}
                >
                  {Utility.getDecimalNumberUptoDigit(
                    this.props.card.ef,
                    2
                  ) + " %"}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  renderSignUpCards = () => {
    return (
      <Col
        md={4}
        className={"loanCard mt-3 px-0 me-3 pt-3 cursorPointer"}
        onClick={() => {
          this.props.handleCardClick(this.props.card);
        }}
        key={Math.random()}
      >
        <Row>
          <Col md={4} className={"marginLeft rightSide"}>
            <div className={"px-1"}>
              <Row className='borderCard'>
                <div
                  style={{
                    borderRadius: "50%",
                    border: ` 2px solid ${MINIMART_TYPE_LEGEND[0].color}`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <img
                    className='applicantImage'
                    src={TPrestaIcon}
                    alt='TPrestaIcon'
                    key={Math.random()}
                    title={Strings(this.props.card.type)}
                  />
                </div>
              </Row>
            </div>
          </Col>
          <Col md={8}>
            {SIGNUP_REQUEST_CARDS.map((minimart) => {
              return (
                <div
                  className='noMargin detailText textWrap'
                  title={minimart.title}
                  key={this.props.card[minimart.key]}
                >
                  {this.props.card[minimart.key]}
                </div>
              );
            })}
          </Col>
        </Row>
      </Col>
    );
  };

  renderPromotionRewardApprovalCard = () => {
    // NOSONAR
    let list = PROMOTION_APPROVAL_CARDS;
    let key = "st";
    let icon = MTP_PROMOTION;
    if (this.props.cardType === CARD_TYPE.REWARD_APPROVAL) {
      list = REWARD_APPROVAL_CARD;
      key = "rs";
      icon = REWARD_APPROVAL;
    } else if (this.props.isAnalyst) {
      list = ANALYST_PROMOTION_APPROVAL_CARDS;
    } else if (this.props.cardType === CARD_TYPE.REWARD_HISTORY) {
      list = REWARD_HISTORYL_CARD;
      icon = REWARD_APPROVAL;
    }

    return (
      <Col
        md={4}
        className={`loanCard mt-3 px-0 me-3 pt-3 ${this.props.isAnalyst ? "cursorPointer" : ""
          }`}
        onClick={
          this.props.isAnalyst ||
            this.props.cardType === CARD_TYPE.REWARD_APPROVAL
            ? () => {
              this.props.handleCardClick(this.props.card);
            }
            : () => {
              //NOSONAR
            }
        }
        key={Math.random()}
      >
        <Row>
          <Col
            md={4}
            className={`marginLeft rightSide ${this.props.cardType === CARD_TYPE.REWARD_HISTORY
              ? "pt-4"
              : ""
              }`}
          >
            <div className={"px-1"}>
              <Row className='borderCard'>
                <div
                  style={{
                    borderRadius: "50%",
                    border: ` 2px solid ${this.props.cardType === CARD_TYPE.REWARD_HISTORY
                      ? REWARD_TYPE_LEGEND[this.props.card.ret]
                        .color
                      : this.props.card.st === "APPROVED_BY_SYSTEM" //NOSONAR
                        ? PROMOTION_REQUEST_TYPE_LEGEND["APPROVED"]
                          .color
                        : PROMOTION_REQUEST_TYPE_LEGEND[
                          this.props.card[key]
                        ].color
                      }`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <img
                    className='applicantImage'
                    src={icon}
                    alt='TPrestaIcon'
                    key={Math.random()}
                    title={Strings(this.props.card.mn)}
                  />
                </div>
                {this.props.cardType !== CARD_TYPE.REWARD_HISTORY && (
                  <div
                    key={
                      this.props.cardType ===
                        CARD_TYPE.REWARD_APPROVAL
                        ? this.props.card.mid
                        : this.props.card.appid
                    }
                    className='justify-center display-flex ps-0 pe-0'
                    title={Strings(
                      this.props.cardType ===
                        CARD_TYPE.REWARD_APPROVAL
                        ? "MatricesID"
                        : "applicationId"
                    )}
                  >
                    <span className='displayBlock detailText'>
                      {this.props.cardType ===
                        CARD_TYPE.REWARD_APPROVAL
                        ? this.props.card.mid
                        : this.props.card.appid}
                    </span>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          <Col md={8}>
            {list.map((promotion) => {
              return (
                <div
                  className='noMargin detailText textWrap'
                  title={promotion.title}
                  key={Strings(this.props.card[promotion.key])}
                >
                  {promotion.key === "rdt" && this.props.card["rdt"]
                    ? moment(this.props.card["rdt"]).format(
                      "DD MMM YYYY"
                    )
                    : this.props.card[promotion.key] &&
                    Strings(this.props.card[promotion.key])}
                </div>
              );
            })}
          </Col>
        </Row>
      </Col>
    );
  };

  renderSupplierCard = () => {
    let showUnVerified = this.props.card.isVerified === false;
    return (
      <Col
        md={4}
        className='px-0 motorcycleCard mb-3 ms-0  cursorPointer d-flex'
        title={this.props.card.dc || Strings("NoDescription")}
        onClick={() => {
          this.props.handleCardClick(this.props.card);
        }}
      >
        <Row className='w-100'>
          <Col md={6} lg={6} className='motorcycleImgContainer d-flex justify-content-center'>
            <img
              className='motorcycleImg'
              src={Utility.getDecryptedData(this.props.card.img ? this.props.card.img : "")}
              alt={this.props.card.bankName}
              key={this.props.card.supplierId}
            />
          </Col>
          <Col
            md={6}
            lg={6}
            className='px-0 motorcycleDetailsContainer'
          >
            <Row>
              <Col
                md={8}
                lg={8}
                title={Strings("businessName")}
                className='px-0 pt-2 motorCycleCategory text-truncate'
              >
                {this.props.card.businessName}
              </Col>
              <Col
                md={4}
                lg={4}
                className={`pt-1 ps-0 
                  ${this.props.card.isActive
                    ? "motorCycleStatusActive"
                    : "motorCycleStatusInActive "
                  }
                `}
                title={Strings("Status")}
              >
                {Strings(
                  this.props.card.isActive
                    ? "MActive"
                    : "MInActive"
                )}
              </Col>
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-2'
              title={`${Strings("rnc")}`}
            >
              <Col md={showUnVerified ? 8 : 12} className="ps-0 text-truncate">
                {this.props.card.rnc}
              </Col>
              {showUnVerified && <Col
                md={4}
                lg={4}
                className={`ps-0 pe-1 
                    ${this.props.card.isVerified === false && "motorCycleStatusInActive"
                  }
                `}
                title={Strings("rnc")}
              >
                {this.props.card.isVerified === false && Strings("UnVerified")}
              </Col>}
            </Row>
            <Row
              className='motorCycleProductName pt-2 pe-1 text-truncate'
              title={Strings("phoneNumber")}
            >
              {this.props.card.phoneNumber}
            </Row>
            <Row
              title={Strings("bankName")}
              className='pt-2 px-0 motorCycleProductCost text-truncate'
            >
              {this.props.card.bankName}
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    let renderComponent = null;
    if (this.props.cardType === CARD_TYPE.MINIMART) {
      renderComponent = this.renderMinimartCard();
    } else if (this.props.cardType === CARD_TYPE.INVOICES) {
      renderComponent = this.renderInvoiceCard(this.props.card);
    } else if (this.props.cardType === CARD_TYPE.PERSON) {
      renderComponent = this.renderPersonCard();
    } else if (this.props.cardType === CARD_TYPE.LOAN) {
      renderComponent = this.renderLoanCardDetails();
    } else if (
      this.props.cardType === CARD_TYPE.MOTORCYCLE_CATALOGUE
    ) {
      renderComponent = this.renderMotorCycleCard();
    } else if (
      this.props.cardType === CARD_TYPE.CI_APPROVAL_MINIMART
    ) {
      renderComponent = this.renderCIMinimart();
    } else if (this.props.cardType === CARD_TYPE.REQUEST) {
      renderComponent = this.renderSignUpCards();
    } else if (
      this.props.cardType === CARD_TYPE.PROMOTION ||
      this.props.cardType === CARD_TYPE.REWARD_APPROVAL ||
      this.props.cardType === CARD_TYPE.REWARD_HISTORY
    ) {
      renderComponent = this.renderPromotionRewardApprovalCard();
    } else if (this.props.cardType === CARD_TYPE.REWARD) {
      renderComponent = this.renderRewardCard();
    } else if (this.props.cardType === CARD_TYPE.SUPPLIER) {
      renderComponent = this.renderSupplierCard();
    }
    return <>{renderComponent}</>;
  }
}

export default CustomCard;
