import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Constants
import {
  TABLE_COLUMNS_FOR_PERSON,
  SINGLE_USER_WORK_HISTORY,
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./SinglePersonWorkHistory.scss";

class SinglePersonWorkHistory extends React.Component {
  constructor(props) {
    super(props);
    this._columns = TABLE_COLUMNS_FOR_PERSON[this.props.user];
    this.state = {
      userId: props.userId,
      userName: props.searchFilters?.selectedUserName,
      fromDate: props.searchFilters?.fromDate || new Date(),
      toDate: props.searchFilters?.toDate || new Date(),
      apiUrl: SINGLE_USER_WORK_HISTORY[this.props.user].url,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
    };
  }

  componentDidMount() {
    this.handleClickSearchFilter(false, this.state.userId);
  }

  handleSearchUserNameChange = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };
  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue = this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      [key]: DateValue,
    });
  };

  handleClickSearchFilter = (
    isDefaultOffset,
    userId = this.state.userId
  ) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleSearchFilter(this.state.apiUrl, {
      id: this.state.userId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        fromDate: new Date(),
        toDate: new Date(),
      },
      () => {
        this.handleClickSearchFilter(true);
      }
    );
  };

  goBack = () => {
    this.props.goBack();
  };

  renderTableClass = (key) => {
    if (key === "mn" || key === "cd" || key === "vd") {
      return "text-start px-2";
    } else {
      return "text-center px-2";
    }
  };

  renderCustomDataGrid = (columns, rows) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='w-75'>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )} header`}
                      title={column.name}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )} `}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      <div>{row[column.key]}</div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    let WorkHistorySearchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("username"),
        handleOnChange: this.handleSearchUserNameChange,
        Value: this.state.userName,
        fieldname: SEARCH_FILTER_FIELDS.USER_NAME,
        disabled: true,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <div className='singlePersonWorkHistory'>
        <Row className='pt-3 alignItemCenter'></Row>
        <SearchFilter
          filters={WorkHistorySearchFilters}
          handleOnClickSearchFilter={this.handleClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingLeft={true}
        />
        <Row className='errors ps-3'>
          {this.props.errorMessage !== "" &&
            Strings(this.props.errorMessage)}
        </Row>
        <Row className="pt-2">
          {this.props.rowsCount !== 0 ? (
            <div className='branchListTable noPadding'>
              {this.renderCustomDataGrid(
                this._columns,
                this.props.rows
              )}
            </div>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </Row>
        <Row>
          <Col md={1} className="alignCenter py-1">
            <Button
              className='buttonBorder'
              onClick={this.goBack}
            >
              {Strings("Back").toUpperCase()}
            </Button>
          </Col>
          {this.props.rowsCount !== 0 && (
            <Col md={11} className='noPadding alignPagination'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default SinglePersonWorkHistory;
