//React components
import React from "react";
import { Row, Col } from "react-bootstrap";

// Components
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Constants
import {
  RATING_CONSTANTS,
  PIE_CHART_COLORS,
  DAILY_STATUS_SALES_PERSON_LEGENDS,
  DAILY_STATUS_SALES_PERSON_LOAN_LEGEND,
  INVOICE_DATE_FORMAT,
  CURRENCY,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  SEARCH_FILTER_PLACE_HOLDER,
  PIE_CHART_COLORS_CONST,
} from "../../constants/appConstants";
import { DailyLoanArray } from "./DailyStatusConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./DailyStatus.scss";

// Utils
import Utility from "../../utils/Utility";

class SalesPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLegend = (color, grade, data, index) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };

    return (
      <Row className='legend' key={Math.random()}>
        <div className='ps-0 legendCommissionColorDiv' style={colorStyle} />
        <Col md={6} className="pe-0 ps-1">
          <div className='branchNameInLegends textWrap' title={Strings(grade)}>{Strings(grade)} &nbsp;&nbsp;</div>
        </Col>
        <Col md={4} className='px-0'>
          <div className='branchNameInLegends fw-bold w-100'>
            {data}
          </div>
        </Col>
      </Row>
    );
  };

  renderPiechartData = (data) => {
    let datakeys = Object.keys(data);
    let dataArray = datakeys.forEach((datakey, index) => {
      if (DailyLoanArray.includes(datakey)) {
        dataArray.push({ count: data[datakey] });
      }
    });
    return dataArray;
  };

  //render
  renderHeaderFilter = () => {
    let dateFilters = [
      {
        type: SEARCH_FILTER_TYPE.SINGLE_DATE,
        column: 6,
        placeholder: SEARCH_FILTER_PLACE_HOLDER.SELECT_DATE,
        handleCalenderChange: this.props.handleDateChange,
        Value: this.props.salesPersonFilters.date,
        dateFormat: INVOICE_DATE_FORMAT,
      },
    ];
    return (
      <SearchFilter
        filters={dateFilters}
        handleOnClickSearchFilter={
          this.props.handleOnClickSearchFilterForCalender
        }
        handleOnClickResetFilter={
          this.props.handleOnClickResetFilterForCalender
        }
        smallButtonSize={true}
      />
    );
  };

  renderPieChart = (dataFlag, colors, total) => {
    let tobevisitedminimarts = 0;
    if (this.props.piechartStats.totalAssignedMinimarts > 0) {
      tobevisitedminimarts =
        this.props.piechartStats.totalAssignedMinimarts -
        this.props.piechartStats.visitedMinimarts;
    }
    let data = [
      {
        count:
          this.props.piechartStats.visitedMinimarts !== undefined
            ? this.props.piechartStats.visitedMinimarts
            : 0,
      },
      { count: tobevisitedminimarts },
    ];
    let dailyloanArray = [
      { count: this.props.piechartStats.createdLoanCount },
      { count: this.props.piechartStats.disbursedLoanCount },
      { count: this.props.piechartStats.deniedLoans },
      { count: this.props.piechartStats.pendingLoans },
    ];
    let chartData = dataFlag ? data : dailyloanArray;
    return (
      <PieChartComponent
        data={chartData}
        colors={colors}
        index={0}
        innerRadius='60%'
        outerRadius='90%'
        datakey='count'
        height={150}
        showLabel={true}
        total={total}
        user='salesPerson'
      />
    );
  };

  renderSalesLegend = (legendList, colorValue) => {
    legendList.map((legend, index) => {
      return this.renderLegend(
        PIE_CHART_COLORS[colorValue][index],
        legend
      );
    });
  };

  renderDailyStatusNameInLegends = (typeStr) => {
    let amount = 0;
    if (typeStr === "created") {
      amount = this.props.piechartStats.createdLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.piechartStats.createdLoanAmount
        )
        : 0;
    } else if (typeStr === "disbursed") {
      amount = this.props.piechartStats.disbursedLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.piechartStats.disbursedLoanAmount
        )
        : 0;
    } else if (typeStr === "pending") {
      amount = this.props.piechartStats.pendingLoanAmount
        ? Utility.getCurrencyRepresentationOfAmount(
          this.props.piechartStats.pendingLoanAmount
        )
        : 0;
    }

    return (
      <Row className='legend'>
        <div className='dailyStatusNameInLegends'>
          {Strings(typeStr)}
          {CURRENCY}
          {amount}
        </div>
      </Row>
    );
  };

  renderListFilter = () => {
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.props.handleSearchNameChange,
        Value: this.props.salesPersonFilters.name,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleBranchChange,
        Value: this.props.salesPersonFilters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.props.branches,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleZoneChange,
        Value: this.props.salesPersonFilters.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        filterDropdownList: this.props.zones,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleSelectedRatingChange,
        Value: this.props.salesPersonFilters.rating,
        fieldname: SEARCH_FILTER_FIELDS.RATING,
        filterDropdownList: RATING_CONSTANTS,
      },
    ];
    return (
      <SearchFilter
        filters={minimartFilters}
        handleOnClickSearchFilter={
          this.props.handleOnClickSearchFilter
        }
        handleOnClickResetFilter={this.props.handleOnClickResetFilter}
        paddingLeft={true}
      />
    );
  };

  renderCards = () => {
    return (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pe-0'>
          <Row className='w-100'>
            {this.props.salesPersons.map((salesPerson, index) => {
              return (
                <CustomCard
                  card={salesPerson}
                  key={index}
                  cardType='Person'
                  handleCardClick={this.props.setSalesPersonID}
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.props.activePage}
            recordsPerPage={this.props.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.props.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className='salesPerson ListContainer'>
        <Row className='pb-2'>
          <Col md={4} className='pt-3'>
            <span className='subHeading'>{Strings("minimarts")}</span>
          </Col>
          <Col md={4} className='pt-3'>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
          <Col md={4}>{this.renderHeaderFilter()}</Col>
        </Row>

        <Row>
          <Col md={2} className='pe-0'>
            {this.renderPieChart(
              1,
              "salesPersonDailyStatus",
              this.props.piechartStats.totalAssignedMinimarts !==
                undefined
                ? this.props.piechartStats.totalAssignedMinimarts
                : 0
            )}
          </Col>
          <Col md={2} className='alignLegends pt-3 px-0'>
            {DAILY_STATUS_SALES_PERSON_LEGENDS.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.SALES_PERSON_DAILY_STATUS
                  ][index],
                  legend.key,
                  this.props.piechartStats[legend.value]
                );
              }
            )}
          </Col>
          <Col md={2} className='pe-0'>
            {this.renderPieChart(
              0,
              "salesPersonDailyStatusForLoans",
              this.props.piechartStats.totalLoans !== undefined
                ? this.props.piechartStats.totalLoans
                : 0
            )}
          </Col>
          <Col md={2} className='alignLegends px-0 pt-3'>
            {DAILY_STATUS_SALES_PERSON_LOAN_LEGEND.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST
                    .SALES_PERSON_DAILY_STATUS_FOR_LOANS
                  ][index],
                  legend,
                  this.props.piechartStats[legend]
                );
              }
            )}
          </Col>
          <Col md={3} className='alignLegends pt-3 ps-5 pe-0'>
            {this.renderDailyStatusNameInLegends("created")}
            {this.renderDailyStatusNameInLegends("disbursed")}
            {this.renderDailyStatusNameInLegends("pending")}
          </Col>
        </Row>

        <Row>
          <div className='ps-4'>
            <span className='subHeading'>
              {Strings("salesPersonList")}
            </span>
          </div>
        </Row>

        {this.renderListFilter()}

        {this.props.salesPersons.length > 0 ? (
          this.renderCards()
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default SalesPerson;
