import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

//Component
import Rater from "react-rater";

// Strings
import { Strings } from "../../resources/i18n/i18n";
//Constants
import {
  CURRENCY,
  SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
} from "../../constants/appConstants";

//Styles
import "react-rater/lib/react-rater.css";

// Assets
import ProfileImage from "../../resources/images/ic_user.svg";
import ReceiptPlaceHolder from "../../resources/images/Receipt.png";

export const MessengerProfile = (props) => {
  return (
    props.profileDetails && (
      <Row className='messengerRow'>
        <Col md={1} className='ps-0'>
          <img
            src={
              props.messengerProfilePic !== null
                ? props.messengerProfilePic
                : ProfileImage
            }
            onError={(error) => {
              error.target.onError = null;
              error.target.src = ProfileImage;
            }}
            alt='Profile'
            className='profileIcon'
          />
        </Col>
        <Col
          md={4}
          className='messengerProfileMedium my-1 pe-0'
          title={
            props.profileDetails.msty === SPECIAL
              ? props.profileDetails.nm +
              " " +
              SPECIAL_MESSENGER_PRESENTATION
              : props.profileDetails.nm
          }
        >
          <div>
            {props.profileDetails.msty === SPECIAL
              ? SHORT_SPECIAL_MESSENGER_PRESENTATION +
              " " +
              props.profileDetails.nm
              : props.profileDetails.nm}
          </div>
          <Rater
            id={"SALES_PERSON"}
            title={props.profileDetails.rat}
            rating={props.profileDetails.rat}
            total={5}
            interactive={false}
            style={{
              fontSize: 20 + "px",
              color: "#3ea853",
              cursor: "pointer",
            }}
          />
        </Col>
        <Col
          md={7}
          className=' messengerProfileRegular my-1 d-flex justify-content-end'
        >
          <div>
            <strong>{Strings("Branch")}</strong> :{" "}
            <span>{props.profileDetails.br}</span>
          </div>
        </Col>
      </Row>
    )
  );
};
export const CollectionRow = ({ header, content }) => {
  return (
    <Row className="w-100">
      <Col md={7} className="collectionHeader my-auto pe-0 ps-0">
        {header}
      </Col>
      <Col md={5} className='collectionContent ps-0 pe-0 my-auto' >
        {CURRENCY}
        {content}
      </Col>
    </Row>
  )
}
export const CollectionView = (props) => {
  return props.collectionData ? (
    <div className="mb-2">
      <Row className="w-100">
        <Col md={6} className="rightSide">
          <Row className="collectionHeader justify-center">
            <Col md={9}>
              <strong>
                {Strings("CollectionDetails")}
              </strong>
            </Col>
          </Row>
          <Row className="w-100 mb-2 pt-1">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("TotalAmountCollected")}
                content={props.collectionData.amtCol}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("amountCollectedBycash")}
                content={props.collectionData.amtCash}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("amountCollectedByTransfer")}
                content={props.collectionData.amtTransfer}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("amountCollectedByDeposit")}
                content={props.collectionData.amtDeposit}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6} className="pe-0 CashDetails">
          <Row className="collectionHeader justify-center mb-2">
            <Col md={9}>
              <strong>
                {Strings("CashDetails")}
              </strong>
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("cashDeposited")}
                content={props.collectionData.amtDep}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("cashInTransit")}
                content={props.collectionData.amtTra}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("AdministrativeExpense")}
                content={props.collectionData.amtAECash}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-2">
            <Col md={12} className="pe-0 ps-0">
              <CollectionRow
                header={Strings("EMIAndInitialCash")}
                content={props.collectionData.amtCash - props.collectionData.amtAECash}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : null;
};

export const Receipt = (props) => {
  let titleText = "";
  if (props.idOfReceipt === "") {
    titleText = Strings("pleaseSelectReceipt");
  } else if (props.status !== 0) {
    titleText = Strings("StatusMustbeOther");
  }
  return (
    <div className='receipt'>
      <Row className='receiptMedium'>
        {Strings("ReceiptForDepositId")}{" "}
        {props.idOfReceipt ? "- " + props.idOfReceipt : ""}
      </Row>
      <div className='receiptContainer'>
        <img
          src={
            props.receiptUrl !== null
              ? props.receiptUrl
              : ReceiptPlaceHolder
          }
          alt=''
        />
      </div>
      <Row>
        {props.errorMessage !== "" && (
          <Col md={12} className='p-0 errors m-0'>
            {props.errorMessage}
          </Col>
        )}
        <Col md={12} className='receiptMedium'>
          {Strings("ReEnterAmount")}
          {CURRENCY}
        </Col>
        <Col md={6} className='noPadding'>
          <Form.Control
            placeholder={Strings("EnterAmount")}
            type='number'
            className='removeStepper'
            value={props.reEnteredAmount}
            onChange={props.handleReEnterAmount}
          />
        </Col>
        <Col md={3} className='noPadding centerAlign'>
          <Button
            className={
              props.idOfReceipt === "" || props.status !== 0
                ? " cursorDisables buttonBackground"
                : "buttonBackground"
            }
            onClick={props.onVerifyClick}
            disabled={props.idOfReceipt === "" || props.status !== 0}
            title={titleText}
          >
            {Strings("Verify")}
          </Button>
        </Col>
        <Col md={3} className='ps-0 centerAlign'>
          <Button
            className='clearButton'
            onClick={props.onCancelClick}
          >
            {Strings("Clear")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export const RowRenderer = ({ renderBaseRow, ...props }) => {
  const color = props.idx % 2 ? "green" : "blue";
  props["color"] = color;
  return (
    <div style={{ background: `${color}!important` }}>
      {renderBaseRow(props)}
    </div>
  );
};
