import React from "react";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

//Coponents
import PieChartComponent from "../PieChartComponent/PieChartComponent";
import BarGraphComponent from "../BarGraphComponent/BarGraphComponent";
import BarChartComponent from "../BarChartComponent/BarChartComponent";

//Constants
import {
  PIE_CHART_COLORS,
  LOAN_CHART_TABS,
  LOAN_APPLICATION_LEGENDS,
  LOAN_STATUS_LEGENDS,
  DENIAL_REASONS_LEGENDS,
  USER_ROLES,
  DIVIDED_BAR_CHART_COLORS,
  ALL,
  BAR_ARRAY,
  AMOUNT_COLLECTION_STATUS,
  CURRENCY,
  PIE_CHART_COLORS_CONST,
} from "../../../constants/appConstants";
import Utility from "../../../utils/Utility";

//Strings
import { Strings } from "../../../resources/i18n/i18n";

//Styles
import "./LoanCharts.scss";

class LoanCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  renderLegend = (
    color,
    legends,
    data,
    colSize,
    fullLength = false
  ) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return (
      <Col
        md={fullLength ? 12 : 6}
        className='noPadding'
        key={Math.random()}
      >
        <Row className='legend'>
          <div
            className='noPadding legendCommissionColorDiv'
            style={colorStyle}
          />
          <Col md={colSize} className='ps-1 alignLegendCenter me-2'>
            <div className='branchNameInLegends'>
              {Strings(legends)}
            </div>
          </Col>
          <div className='alignLegendCenter w-auto p-0'>
            <div className='branchNameInLegends fw-bold ps-4'>
              {data}
            </div>
          </div>
        </Row>
      </Col>
    );
  };

  getOrderedPieChartData = (propsArray, legendsArray, key) => {
    let tempStatus = [];
    propsArray !== null &&
      legendsArray.forEach((legend, index) => {
        let objIndex = propsArray.findIndex((loanAppStatus) => {
          return loanAppStatus[key] === legend;
        });
        tempStatus[index] = propsArray[objIndex];
      });
    return tempStatus;
  };

  renderPieChartComponent = (data, color, total = false) => {
    return (
      <PieChartComponent
        data={data}
        colors={color}
        index={0}
        innerRadius='65%'
        outerRadius='90%'
        datakey='count'
        showLabel={true}
        height={150}
        total={
          total !== false
            ? Utility.getCurrencyRepresentationOfAmount(total)
            : ""
        }
      />
    );
  };

  renderBarGraphComponent = (data, colorsOfBar) => {
    return (
      <BarGraphComponent
        data={data}
        colorOfBar={colorsOfBar}
        height={200}
      />
    );
  };

  renderBarChartComponent = (
    data,
    colors,
    barsArray,
    isFinance = true
  ) => {
    return (
      <BarChartComponent
        data={data}
        colors={colors}
        height={190}
        barsArray={barsArray}
        isFinance={isFinance}
      />
    );
  };

  renderSalesModeratorPieCharts = () => {
    return this.props.loanStatus.length > 0 ? (
      <Row>
        <Col md={5}>
          {this.renderPieChartComponent(
            this.props.loanStatus,
            "loanApplicationStatus",
            this.props.loanStatusCount
          )}
        </Col>
        <Col md={7} className='noPadding align-self-center'>
          {this.renderLoanApplicationLegend()}
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderBarChartAndBarGraph = () => {
    if (
      this.props.activeTab.key !== 2 &&
      this.props.activeTab.key !== 3 &&
      this.props.activeTab.key !== 4
    ) {
      return (
        <Col md={6} className='noPadding alignCenter px-0 position-relative w-100'>
          {this.props.loanBarchartData.length > 0 ? (
            this.renderBarGraphComponent(
              this.props.loanBarchartData,
              "#0477b3"
            )
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoData")}
            </div>
          )}
        </Col>
      );
    } else if (this.props.activeTab.key === 3) {
      return (
        <Col md={6} className='p-0 alignCenter'>
          {this.renderBarChartComponent(
            this.props.denialBarChartData,
            DIVIDED_BAR_CHART_COLORS["denialReasonsClasses"],
            BAR_ARRAY["DENIAL_REASON"],
            false
          )}
        </Col>
      );
    } else if (this.props.activeTab.key === 2) {
      return (
        <Col md={6} className='alignCenter p-0'>
          {this.renderBarChartComponent(
            this.props.provisionBarChartData,
            DIVIDED_BAR_CHART_COLORS["provisionClasses"],
            BAR_ARRAY["PROVISION"],
            false
          )}
        </Col>
      );
    } else if (this.props.activeTab.key === 4) {
      return (
        <Col md={6} className='alignCenter p-0'>
          {this.renderBarChartComponent(
            this.props.financeProfitBarChartData,
            DIVIDED_BAR_CHART_COLORS["financeProfitClasses"],
            this.props.loggedInUser ===
              USER_ROLES.collectionsSupervisor
              ? BAR_ARRAY["COL_SUP_FINANCE_PROFIT"]
              : BAR_ARRAY["FINANCE_PROFIT"]
          )}
        </Col>
      );
    }
  };

  renderLoanApplicationLegend = () => {
    return (
      <Row>
        {" "}
        {LOAN_APPLICATION_LEGENDS.length > 0 &&
          LOAN_APPLICATION_LEGENDS.map((legend, index) => {
            return this.renderLegend(
              PIE_CHART_COLORS[
              PIE_CHART_COLORS_CONST.LOAN_APPLICATION_STATUS
              ][index],
              legend,
              this.props.loanStatus[index].count,
              5,
              false
            );
          })}
      </Row>
    );
  };

  renderLoanCategoriesLegend = (data) => {
    return (
      <Row>
        {" "}
        {LOAN_STATUS_LEGENDS.length > 0 &&
          LOAN_STATUS_LEGENDS.map((legend, index) => {
            return this.renderLegend(
              PIE_CHART_COLORS[
              PIE_CHART_COLORS_CONST.LOAN_CATEGORIES
              ][index],
              legend,
              data[index].count,
              5,
              true
            );
          })}
      </Row>
    );
  };

  renderDenialReasonsLegend = (data) => {
    return (
      <Row>
        {" "}
        {DENIAL_REASONS_LEGENDS.length > 0 &&
          DENIAL_REASONS_LEGENDS.map((legend, index) => {
            return this.renderLegend(
              PIE_CHART_COLORS[PIE_CHART_COLORS_CONST.DENIAL_REASONS][
              index
              ],
              legend,
              data[index].count,
              7,
              true
            );
          })}
      </Row>
    );
  };

  renderAmountCollectionStatusLegend = (data) => {
    return (
      <Row className='mt-4'>
        {" "}
        {AMOUNT_COLLECTION_STATUS.length > 0 &&
          AMOUNT_COLLECTION_STATUS.map((legend, index) => {
            return this.renderLegend(
              PIE_CHART_COLORS[
              PIE_CHART_COLORS_CONST.AMOUNT_COLLECTION
              ][index],
              legend,
              <span className='d-inline'>
                <span className='me-1'>$</span>
                {Utility.getCurrencyRepresentationOfAmount(data[index].count)}
              </span>,
              5,
              true
            );
          })}
      </Row>
    );
  };

  renderLoanCategoriesData = () => {
    return this.props.loanCategories !== null &&
      this.props.loanCategories.length > 0 ? (
      <Row>
        <Col md={5} className='pe-0'>
          {this.renderPieChartComponent(
            this.props.loanCategories,
            "loanCategories",
            this.props.loanCategoryCount
          )}
        </Col>
        <Col md={7} className='p-0 align-self-center'>
          {this.renderLoanCategoriesLegend(this.props.loanCategories)}
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderLoanApplicationStatusData = () => {
    return this.props.loanStatus !== null &&
      this.props.loanStatus.length > 0 ? (
      <Row>
        <Col md={4}>
          {this.renderPieChartComponent(
            this.props.loanStatus,
            "loanApplicationStatus",
            this.props.loanStatusCount
          )}
        </Col>
        <Col md={8} className='p-0 align-self-center'>
          {this.renderLoanApplicationLegend()}
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderProvisionData = () => {
    return this.props.provisionPieChartData !== null &&
      this.props.provisionPieChartData.length > 0 ? (
      <Row>
        <Col md={4}>
          {this.renderPieChartComponent(
            this.props.provisionPieChartData,
            "provisionAmount",
            this.props.provisionCount
          )}
        </Col>
        <Col md={8} className='p-0 align-self-center'>
          <Row>
            {ALL.length > 0 &&
              ALL.map((legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.PROVISION_AMOUNT
                  ][index],
                  legend,
                  this.props.provisionPieChartData[index].count,
                  3
                );
              })}
          </Row>
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderDenialData = () => {
    return this.props.denialPieChartData !== null &&
      this.props.denialPieChartData.length > 0 ? (
      <Row>
        <Col md={4} className='pe-0'>
          {this.renderPieChartComponent(
            this.props.denialPieChartData,
            "denialReasons",
            this.props.denialCount
          )}
        </Col>
        <Col md={8} className='p-0 align-self-center'>
          {this.renderDenialReasonsLegend(
            this.props.denialPieChartData
          )}
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  renderAmountCollectionData = () => {
    return this.props.amount !== null &&
      this.props.amount.length > 0 ? (
      <>
        <Row>
          <Col md={{ span: 6, offset: 4 }} className='ps-0'>
            <div className='BoldText'>
              {Strings("amountToBeCollected") +
                " : " +
                CURRENCY +
                Utility.getCurrencyRepresentationOfAmount(
                  this.props.amountToBeCollected
                )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {this.renderPieChartComponent(
              this.props.amount,
              "amountCollection"
            )}
          </Col>
          <Col md={8} className='p-0 align-self-center'>
            <Row>
              <Col md={12} className='ps-0'>
                <div className='BoldText '>
                  {Strings("DisbursedAmount") +
                    " : " +
                    CURRENCY +
                    Utility.getCurrencyRepresentationOfAmount(
                      this.props.disbursedAmount
                    )}
                </div>
              </Col>
            </Row>
            {this.renderAmountCollectionStatusLegend(
              this.props.amount
            )}
          </Col>
        </Row>
      </>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoData")}
      </div>
    );
  };

  selectedTabClass = () => {
    if (this.props.activeTab.key === 0) {
      return "leftCornerRadius";
    } else if (this.props.activeTab.key === 4) {
      return "rightCornerRadius";
    } else {
      return "cenderCornerRadius";
    }
  };

  render() {
    return (
      <Row className='container-fluid pb-2 pe-2'>
        <Col md={6} className='borderRight ps-0'>
          {(this.props.loggedInUser === USER_ROLES.admin ||
            this.props.loggedInUser === USER_ROLES.generalManager ||
            this.props.loggedInUser === USER_ROLES.technology ||
            this.props.loggedInUser === USER_ROLES.collectionsSupervisor ||
            this.props.loggedInUser === USER_ROLES.administrativeManager ||
            this.props.loggedInUser === USER_ROLES.salesManager ||
            this.props.loggedInUser === USER_ROLES.collectionsManager) && (
              <Tabs
                selectedIndex={this.props.activeTab.key}
                onSelect={this.props.handleTabs}
                selectedTabClassName={this.selectedTabClass()}
              >
                <TabList>
                  {LOAN_CHART_TABS.map((item) => (
                    <Tab key={item.key}>{Strings(item.value)}</Tab>
                  ))}
                </TabList>

                <TabPanel>{this.renderLoanCategoriesData()}</TabPanel>
                <TabPanel>{this.renderLoanApplicationStatusData()}</TabPanel>
                <TabPanel>{this.renderProvisionData()}</TabPanel>
                <TabPanel>{this.renderDenialData()}</TabPanel>
                <TabPanel>{this.renderAmountCollectionData()}</TabPanel>
              </Tabs>
            )}

          {this.props.loggedInUser === USER_ROLES.messengerModerator &&
            this.props.loanCategories !== null &&
            this.props.loanCategories.length > 0 ? (
            <Row>
              <Col md={5}>
                {this.renderPieChartComponent(
                  this.props.loanCategories,
                  "loanCategories",
                  this.props.loanCategoryCount
                )}
              </Col>
              <Col md={7} className='noPadding align-self-center'>
                {this.renderLoanCategoriesLegend(this.props.loanCategories)}
              </Col>
            </Row>
          ) : (
            this.props.loggedInUser === USER_ROLES.salesModerator &&
            this.props.loanStatus !== null &&
            this.renderSalesModeratorPieCharts()
          )}
        </Col>

        {this.renderBarChartAndBarGraph()}
      </Row>
    );
  }
}

export default LoanCharts;
