import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

// Component
import EditableModal from "../LoanDetails/EditableModal";

// Constants
import { VISITED_DAYS, DEFAULT_VALUES } from "../../constants/appConstants";
import { ACCOUNTS_OFFICER_ACTION } from "./Constants";

// String
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "../ApprovalMinimart/ApprovalMinimart.scss";
import "../../resources/styles/globalStyles.scss";

//Assets
import editNote from "../../resources/images/ic_edit.svg";

class ApprovalMinimart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      minimart: props.minimart,
      editDetailIndex: 0,
      editMinimart: {},
      errorMessage: "",
      visitDayErrorMessage: props.errorMessage,
    };
  }

  componentDidUpdate(prevProp) {
    if (!_.isEqual(prevProp.minimart, this.props.minimart)) {
      this.setState({ minimart: this.props.minimart });
    }
    if (
      prevProp.visitDayErrorMessage !==
      this.props.visitDayErrorMessage
    ) {
      this.setState({
        visitDayErrorMessage: this.props.visitDayErrorMessage,
      });
    }
  }

  showEditableFields = (index) => {
    if (!this.state.showModal) {
      if (
        this.props.isOperationsOfficer ||
        this.props.isOperationsModerator ||
        this.props.isOperationsManager
      ) {
        this.props.getZonesByBranch(this.state.editMinimart.br);
      }
    }
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      editMinimart: { ...this.state.minimart },
      editDetailIndex: index,
      errorMessage: "",
    }));
  };

  renderDetailsHeader = (headerText, index) => {
    return (
      <Row
        className={`subHeadingText pb-1 ${index === 0
          ? " d-flex align-items-center"
          : "pt-2  d-flex align-items-center"
          }`}
      >
        {headerText}
        {!this.props.isHistory &&
          (this.props.isAnalyst ||
            ((this.props.isOperationsOfficer ||
              this.props.isOperationsModerator || this.props.isOperationsManager) &&
              index === 0 &&
              this.props.applicationStatus ===
              ACCOUNTS_OFFICER_ACTION.INPROCESS_OPERATIONS)) ? (
          <div className='pencil mx-1 p-1 w-auto'>
            <img
              src={editNote}
              className={"editIcon m-0"}
              alt={"edit"}
              title={Strings("EditableFileds")}
              onClick={() => {
                this.showEditableFields(index);
              }}
            />
          </div>
        ) : null}
      </Row>
    );
  };

  renderMinimartDetails = () => {
    return (
      <>
        {this.renderDetailsHeader(Strings("minimartDetailsText"), 0)}
        <Row>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>
                {Strings("MiniMarketName")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.mn
                  ? this.state.minimart.mn
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("associatedBranch")}
              </span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>
                {this.state.minimart.brn
                  ? this.state.minimart.brn
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>{Strings("Zone")}</span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.zn
                  ? this.state.minimart.zn
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>{Strings("mzone")}</span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.mzn
                  ? this.state.minimart.mzn
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>
                {Strings("BusinessType")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.bty
                  ? this.state.minimart.bty
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("Sector")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.scr
                  ? this.state.minimart.scr
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("Street")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.srt
                  ? this.state.minimart.srt
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("number")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.no
                  ? this.state.minimart.no
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("Reference")}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.ref
                  ? this.state.minimart.ref
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("City")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin addressOrName'>
                {this.state.minimart.ct
                  ? this.state.minimart.ct
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>{Strings("visitDay")}</span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.vd
                  ? Strings(this.state.minimart.vd)
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderOwnerDetails = () => {
    return (
      <>
        {this.renderDetailsHeader(Strings("ownerDetails"), 1)}
        <Row>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("ownerName")}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.on
                  ? this.state.minimart.on
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("adminName")}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.an
                  ? this.state.minimart.an
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("PhoneNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.tp
                  ? this.state.minimart.tp
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("AlternateNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.atp
                  ? this.state.minimart.atp
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("ownerCedulaNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.oc
                  ? this.state.minimart.oc
                  : Strings("NA")}
              </label>
            </div>
          </Col>

          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("email")}</span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>
                {this.state.minimart.eml
                  ? this.state.minimart.eml
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  onChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (DEFAULT_VALUES.includes(value)) {
      value = "";
    }

    this.setState(
      {
        editMinimart: {
          ...this.state.editMinimart,
          [name]: value,
          zn:
            name === "br"
              ? ""
              : name === "zn" //NOSONAR
                ? value
                : this.state.editMinimart.zn,
          mzn:
            name === "br"
              ? ""
              : name === "mzn" //NOSONAR
                ? value
                : this.state.editMinimart.mzn,
        },
        errorMessage: "",
      },
      () => {
        if (name === "br") {
          this.props.getZonesByBranch(this.state.editMinimart.br);
        } else if (name === "vd") {
          this.props.setVisitDayRequiredError("");
        }
      }
    );
  };

  checkValid = () => {
    let valid = false;
    if (
      this.props.isOperationsOfficer ||
      this.props.isOperationsModerator ||
      this.props.isOperationsManager
    ) {
      if (this.state.editDetailIndex === 0) {
        return (
          this.state.editMinimart.br !== "" &&
          this.state.editMinimart.zn !== "" &&
          this.state.editMinimart.vd !== null &&
          this.state.editMinimart.vd !== ""
        );
      }
    } else {
      if (this.state.editDetailIndex === 0) {
        return (
          this.state.editMinimart.mn !== "" &&
          this.state.editMinimart.bty !== "" &&
          this.state.editMinimart.scr !== "" &&
          this.state.editMinimart.scr !== null &&
          this.state.editMinimart.srt !== "" &&
          this.state.editMinimart.srt !== null &&
          this.state.editMinimart.no !== "" &&
          this.state.editMinimart.no !== null &&
          this.state.editMinimart.ref !== "" &&
          this.state.editMinimart.ref !== null &&
          this.state.editMinimart.ct !== "" &&
          this.state.editMinimart.ct !== null
        );
      } else if (this.state.editDetailIndex === 1) {
        return (
          this.state.editMinimart.on !== "" &&
          this.state.editMinimart.tp &&
          this.state.editMinimart.tp.length === 10 &&
          this.state.editMinimart.atp &&
          this.state.editMinimart.atp.length === 10 &&
          this.state.editMinimart.oc !== "" &&
          this.state.editMinimart.oc.length === 11
        );
      }
    }
    return valid;
  };

  onSubmitMinimartDetails = () => {
    let valid = this.checkValid();
    if (valid) {
      this.setState({ showModal: false }, () => {
        this.props.updateMinimartDetails({
          ...this.state.editMinimart,
        });
      });
    } else {
      if (
        this.state.editMinimart.zn === "" ||
        this.state.editMinimart.vd === "" ||
        this.state.editMinimart.vd === null ||
        this.state.editMinimart.br === "" ||
        this.state.editMinimart.mn === "" ||
        this.state.editMinimart.scr === "" ||
        this.state.editMinimart.srt === "" ||
        this.state.editMinimart.no === "" ||
        this.state.editMinimart.ref === "" ||
        this.state.editMinimart.ct === "" ||
        this.state.editMinimart.scr === null ||
        this.state.editMinimart.srt === null ||
        this.state.editMinimart.no === null ||
        this.state.editMinimart.ref === null ||
        this.state.editMinimart.ct === null ||
        this.state.editMinimart.on === "" ||
        this.state.editMinimart.tp === "" ||
        this.state.editMinimart.atp === "" ||
        this.state.editMinimart.bty === "" ||
        (this.props.mod === null && this.state.editMinimart.oc === "")
      ) {
        this.setState({
          errorMessage: Strings("pleaseEnterRequiredFields"),
        });
      } else if (
        isNaN(this.state.editMinimart.atp) ||
        this.state.editMinimart.atp.length !== 10 ||
        isNaN(this.state.editMinimart.tp) ||
        this.state.editMinimart.tp.length !== 10
      ) {
        this.setState({
          errorMessage: Strings("MobileNumberMustBeTenDigits"),
        });
      } else if (
        this.props.mod === null &&
        (isNaN(this.state.editMinimart.oc) ||
          this.state.editMinimart.oc.length !== 11)
      ) {
        this.setState({
          errorMessage: Strings("CedulaIdMustBeElevenDigits"),
        });
      }
    }
  };

  renderEditableModal = () => {
    let editableData = [];
    if (
      !this.props.isOperationsOfficer &&
      !this.props.isOperationsModerator && !this.props.isOperationsManager &&
      this.state.editDetailIndex === 0
    ) {
      editableData = [
        {
          title: Strings("MiniMarketName"),
          value: this.state.editMinimart.mn,
          isMandatoryField: true,
          name: "mn",
          type: "text",
        },
        {
          title: Strings("BusinessType"),
          value: this.state.editMinimart.bty,
          isMandatoryField: true,
          name: "bty",
          dropDownList: this.props.businessTypeList,
          type: "select",
          isSelection: true,
        },
        {
          title: Strings("City"),
          value: this.state.editMinimart.ct,
          isMandatoryField: true,
          name: "ct",
          type: "text",
        },
        {
          title: Strings("Sector"),
          value: this.state.editMinimart.scr,
          isMandatoryField: true,
          name: "scr",
          type: "text",
        },
        {
          title: Strings("Street"),
          value: this.state.editMinimart.srt,
          isMandatoryField: true,
          name: "srt",
          type: "text",
        },
        {
          title: Strings("number"),
          value: this.state.editMinimart.no,
          isMandatoryField: true,
          name: "no",
          type: "text",
        },
        {
          title: Strings("Reference"),
          value: this.state.editMinimart.ref,
          isMandatoryField: true,
          name: "ref",
          type: "text",
        },
        {
          title: Strings("mzone"),
          value: this.state.editMinimart.mzn,
          isMandatoryField: true,
          name: "mzn",
          dropDownList: this.props.mZones,
          type: "select",
          isSelection: true,
        },
      ];
    } else if (this.state.editDetailIndex === 1) {
      editableData = [
        {
          title: Strings("ownerName"),
          value: this.state.editMinimart.on,
          isMandatoryField: true,
          name: "on",
          type: "text",
        },
        {
          title: Strings("adminName"),
          value: this.state.editMinimart.an,
          isMandatoryField: false,
          name: "an",
          type: "text",
        },
        {
          title: Strings("PhoneNumber"),
          value: this.state.editMinimart.tp,
          isMandatoryField: true,
          name: "tp",
          type: "number",
        },
        {
          title: Strings("AlternateNumber"),
          value: this.state.editMinimart.atp,
          isMandatoryField: true,
          name: "atp",
          type: "number",
        },
        {
          title: Strings("ownerCedulaNumber"),
          value: this.state.editMinimart.oc,
          isMandatoryField: true,
          name: "oc",
          type: "number",
          disabled: this.props.mod !== null,
        },
        {
          title: Strings("email"),
          value: this.state.editMinimart.eml,
          isMandatoryField: false,
          name: "eml",
          type: "text",
        },
      ];
    } else if (
      (this.props.isOperationsOfficer ||
        this.props.isOperationsModerator || this.props.isOperationsManager) &&
      this.state.editDetailIndex === 0
    ) {
      let branchZones = [];
      let branchMZones = [];
      for (let i = 0; i < this.props.zones.length; i++)
        branchZones.push({
          key: i + 1,
          label: this.props.zones[i].name,
          value: this.props.zones[i].name,
        });
      for (let i = 0; i < this.props.mZones.length; i++)
        branchMZones.push({
          key: i + 1,
          label: this.props.mZones[i].name,
          value: this.props.mZones[i].name,
        });
      editableData = [
        {
          title: Strings("associatedBranch"),
          value: this.state.editMinimart.br,
          isMandatoryField: true,
          name: "br",
          dropDownList: this.props.branches,
          type: "select",
          isSelection: true,
        },
        {
          title: Strings("selectZone"),
          value: this.state.editMinimart.zn,
          isMandatoryField: true,
          name: "zn",
          dropDownList: branchZones,
          type: "select",
          isSelection: true,
          disabled: this.state.editMinimart.br === "",
        },
        {
          title: Strings("mzone"),
          value: this.state.editMinimart.mzn,
          isMandatoryField: true,
          name: "mzn",
          dropDownList: branchMZones,
          type: "select",
          isSelection: true,
          disabled: this.state.editMinimart.br === "",
        },
        {
          dropDownList: VISITED_DAYS,
          type: "select",
          title: Strings("visitDay"),
          name: "vd",
          value: this.state.editMinimart.vd,
          isSelection: true,
          isMandatoryField: true,
        },
      ];
    }
    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.editDetailIndex === 1
            ? Strings("EditOwnerDetails")
            : Strings("EditMinimartDetails")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.showEditableFields,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.SubmitMinimartDetails,
        }}
        onChange={this.onChange}
        editableData={editableData}
        SubmitMinimartDetails={this.onSubmitMinimartDetails}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  render() {
    return (
      <div>
        {this.state.showModal && this.renderEditableModal()}
        <div className='loanApprovalMinimartDetails'>
          <Row className='primaryDetails'>
            {this.state.visitDayErrorMessage && (
              <div className='errors'>
                {this.state.visitDayErrorMessage}
              </div>
            )}
            <Col md={12} className='px-0'>
              {this.renderMinimartDetails()}
              {this.renderOwnerDetails()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(ApprovalMinimart);
