import React from "react";
import moment from "moment";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import Utility from "../../../utils/Utility";
// Components
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import Calender from "../Calender/Calender";
import MonthYearPicker from "../MonthYearPicker/MonthYearPicker";

// Constants
import {
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_TYPE,
  DATE_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../../constants/appConstants";

//Strings
import { Strings } from "../../../resources/i18n/i18n";

// Styles
import "./ReportFilters.scss";

//Assets
import pageDividerOpened from "../../../resources/images/ic_page_divider_arrow.svg";
import pageDividerClosed from "../../../resources/images/ic_page_divider_arrow_close.svg";

const CalenderGroup = (props) => {
  return (
    <Row className='calenderFilterContainer pt-2 pb-2'>
      <Col md={6} className='calenderFilter ps-0 pe-1'>
        <Calender
          id='fromDate'
          dateFormat={INVOICE_DATE_FORMAT}
          placeholder={Strings("FromDate")}
          placeholderTopClass='placeholderTopClass'
          handleChange={props.handleCalenderFromChange}
          value={
            props.fromDate !== undefined && props.fromDate !== ""
              ? moment(props.fromDate).format(INVOICE_DATE_FORMAT)
              : ""
          }
        />
      </Col>
      <Col md={6} className='calenderFilter px-0 '>
        <Calender
          id='toDate'
          dateFormat={INVOICE_DATE_FORMAT}
          placeholder={Strings("ToDate")}
          placeholderTopClass='placeholderTopClass'
          handleChange={props.handleCalenderToChange}
          value={
            props.toDate !== undefined && props.toDate !== ""
              ? moment(props.toDate).format(INVOICE_DATE_FORMAT)
              : ""
          }
        />
      </Col>
    </Row>
  );
};

class ReportFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterDivExpanded: true,
      showErrorMessage: false,
      invalidFields: []
    };
  }

  toggleCollpasibleView = () => {
    this.setState({
      isFilterDivExpanded: !this.state.isFilterDivExpanded,
    });
  };

  renderCalender = (filter) => {
    return (
      <div className='datePickerPanel'>
        <Calender
          placeholderTop={Strings(filter.placeholderTop)}
          placeholder={Strings(filter.placeholder)}
          handleChange={filter.handleCalenderChange}
          value={filter.Value}
          dateFormat={filter.dateFormat}
        />
      </div>
    );
  };
  renderDate = (filter) => {
    return (
      <CalenderGroup
        fromDate={filter.fromDate}
        toDate={filter.toDate}
        handleCalenderFromChange={
          filter.fromDateKey !== undefined
            ? filter.handleCalenderChange(filter.fromDateKey)
            : filter.handleCalenderChange(DATE_TYPE.FROM_DATE)
        }
        handleCalenderToChange={
          filter.toDateKey !== undefined
            ? filter.handleCalenderChange(filter.toDateKey)
            : filter.handleCalenderChange(DATE_TYPE.TO_DATE)
        }
      />
    );
  };

  emptyFunc = () => {
    // This is intentional
  };

  renderMonthPicker = (filter) => {
    return (
      <div className='datePickerPanel'>
        <MonthYearPicker
          handleChangeMonthYearSelection={
            filter.handleChangeMonthYearSelection
          }
          countOfMonths={1}
          closeOnSelect={true}
          value={filter.Value}
        />
      </div>
    );
  };

  renderTF = (
    filter,
    id,
    type,
    title = null,
    childComponent = null
  ) => {
    let errorClass = this.state.invalidFields.includes(filter.fieldname) ? " error-data" : "";
    return (
      <TextFieldComponent
        id={id}
        type={type}
        title={title}
        onKeyDown={
          filter.inputType === SEARCH_FILTER_TYPE.NUMBER
            ? Utility.allowOnlyNumber
            : this.emptyFunc
        }
        value={filter.Value}
        placeholder={filter.searchPlaceHolder}
        handleChange={
          filter.key
            ? (event) => {
              this.resetErrorMessage();
              filter.handleOnChange(filter.key, event)
            }
            : (event) => filter.handleOnChange(event)
        }
        halfWidth={false}
        disabled={filter.disabled}
        autoFocus
        errorClass={errorClass}
      >
        {childComponent}
      </TextFieldComponent>
    );
  };

  renderChildComponent = (filterDropdownList) => {
    return (
      filterDropdownList &&
      filterDropdownList.map((version) => {
        return (
          <option
            key={
              version.value ||
              version.label ||
              version.name ||
              version.key
            }
            value={version.value || version.id}
          >
            {version.label || version.name}
          </option>
        );
      })
    );
  };

  getRenderInputField = (filter, index) => {
    //NOSONAR
    let paddingRight =
      filter.paddingRight !== undefined && filter.paddingRight
        ? " pe-" + filter.paddingRight
        : "";
    let renderComponent = null;
    let renderProps = {
      md: filter.column !== undefined ? filter.column : 6,
      className: "",
      key: Math.random(),
    };
    if (filter.type === SEARCH_FILTER_TYPE.INPUT) {
      renderProps.className = "ps-0 pt-2 pb-2" + paddingRight;
      renderProps.key = filter.fieldname;
      renderComponent = this.renderTF(
        filter,
        "searchVersion",
        filter.inputType ? filter.inputType : "text"
      );
    } else if (filter.type === SEARCH_FILTER_TYPE.SELECTION) {
      renderProps.className = "ps-0 pb-2 pt-2 " + paddingRight;
      renderProps.key = Math.random();
      let childComponent = this.renderChildComponent(
        filter.filterDropdownList
      );
      renderComponent = this.renderTF(
        filter,
        filter.id,
        "select",
        filter.disabled ? Strings("CategoryDisabled") : "",
        childComponent
      );
    } else if (filter.type === SEARCH_FILTER_TYPE.MONTH_YEAR_PICKER) {
      renderProps.className = this.props.paddingRight
        ? `ps-0 pt-2 pb-2 pe-${this.props.paddingRight}`
        : "ps-0 pt-2 pb-2";
      renderProps.key = Math.random();
      renderComponent = this.renderMonthPicker(filter);
    } else if (filter.type === SEARCH_FILTER_TYPE.SINGLE_DATE) {
      renderProps.md = filter.column ? filter.column : 12;
      let paddingTop = this.props.paddingRight
        ? `ps-0 pt-2 pb-2 pe-${this.props.paddingRight}`
        : "";
      renderProps.className =
        paddingTop + this.props.paddingTop
          ? " pt-3 pb-2"
          : " ps-0 pt-2 pb-2 ";

      renderComponent = this.renderCalender(filter);
    } else {
      renderProps.className = "ps-0";
      renderProps.key = filter.fieldname;
      renderComponent = this.renderDate(filter);
    }
    return { props: renderProps, component: renderComponent };
  };
  onSearchClick = () => {
    if (this.testInput()) {
      this.props.handleOnClickSearchFilter();
    }
    else {
      this.setState({
        showErrorMessage: true
      })
    }
  }
  testInput = () => {
    let result = true;
    const invalidFields = [];
    const validationMap = {
      [SEARCH_FILTER_FIELDS.MATRICES_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.CI_CODE]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.LOAN_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.APPLICATION_ID]: Utility.isNumber,
      [SEARCH_FILTER_FIELDS.CODE]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.CI_ID]: Utility.isAlphanumeric,
      [SEARCH_FILTER_FIELDS.DAYS_IN_ARREARS]: Utility.isNumber,
    };
    this.props.filters.forEach((filter) => {
      if (filter.Value1 && filter.fieldname === SEARCH_FILTER_FIELDS.DOUBLE_INPUT && filter.Value1?.trim().length > 0) {
        if (!Utility.isNumber(filter.Value1)) {
          result = false
          invalidFields.push(filter.searchPlaceHolder1);
        }
      }
      if (filter.Value2 && filter.fieldname === SEARCH_FILTER_FIELDS.DOUBLE_INPUT && filter.Value2?.trim().length > 0) {
        if (!Utility.isNumber(filter.Value2)) {
          result = false
          invalidFields.push(filter.searchPlaceHolder2);
        }
      }
      const validator = validationMap[filter.fieldname];
      if (filter.Value && filter.Value.trim().length > 0 && validator) {
        if (!validator(filter.Value)) {
          result = false;
          invalidFields.push(filter.fieldname);
        }
      }
      if (filter.Value && filter.Value.trim() === "") {
        result = false;
        invalidFields.push(filter.fieldname);
      }
    })
    this.setState({ invalidFields });
    return result
  }



  renderSearchFilterButtons = () => {
    return (
      <Row className='pt-3 '>
        <Col md={{ span: 6 }}></Col>
        <Col md={{ span: 6 }}>
          <div className='displayButtonEnd'>
            <Col md={2} className='ps-0'>
              <Button
                className='addOrUpdateUserTopButtons alignText'
                onClick={this.onSearchClick}
              >
                <div
                  title={Strings("Go")}
                  className='addButtonText'
                >
                  {Strings("Go")}
                </div>
              </Button>
            </Col>

            <Col md={2} className="ps-3">
              <Button
                className='buttonBorder'
                onClick={this.props.handleOnClickResetFilter}
              >
                <div
                  title={Strings("Reset")}
                  className='addButtonText'
                >
                  {Strings("Reset")}
                </div>
              </Button>
            </Col>
          </div>
        </Col>
      </Row>
    );
  };
  resetErrorMessage = () => {
    this.setState({
      showErrorMessage: false,
      invalidFields: []
    })
  }
  renderReportFilter = () => {
    return this.props.filters.map((filter, index) => {
      //NOSONAR
      const handleOnChange1 = filter.key
        ? (event) => {
          this.resetErrorMessage();
          filter.handleOnChange(filter.key, event)
        }
        : (event) => {
          this.resetErrorMessage();
          filter.handleOnChange(event)
        }
      const handleOnChange2 = filter.key1
        ? (event) => {
          this.resetErrorMessage();
          filter.handleOnChange(filter.key1, event)
        }
        : (event) => {
          this.resetErrorMessage();
          filter.handleOnChange(event)
        }
      const props = {
        id: "searchVersion",
        type: filter.inputType ? filter.inputType : "text",
        onKeyDown:
          filter.inputType === SEARCH_FILTER_TYPE.NUMBER
            ? Utility.allowOnlyNumber
            : this.emptyFunc,
        halfWidth: false,
        disabled: filter.disabled,
        fieldname: SEARCH_FILTER_FIELDS.DOUBLE_INPUT,
      };
      const renderData =
        filter.type === SEARCH_FILTER_TYPE.DOUBLE_INPUT
          ? null
          : this.getRenderInputField(filter, index);
      return (
        <Col md={6} key={index}>
          <Row>
            <Col md={3} className='alignCenter'>
              {filter.searchPlaceHolder}
            </Col>
            <span className='noPadding alignCenter w-auto'></span>
            {filter.type === SEARCH_FILTER_TYPE.DOUBLE_INPUT ? (
              <Col md={6} className=' pt-2 pb-2 ps-0'>
                <Row>
                  <Col
                    md={6}
                    className='ps-0 pe-1'
                    key={filter.fieldname}
                  >
                    <TextFieldComponent
                      {...props}
                      handleChange={handleOnChange1}
                      value={filter.Value1}
                      placeholder={filter.searchPlaceHolder1}
                      autoFocus
                      errorClass={this.state.invalidFields.includes(filter.searchPlaceHolder1) ? " error-data" : null}
                    />
                  </Col>
                  <Col md={6} className='px-0' key={filter.fieldname}>
                    <TextFieldComponent
                      {...props}
                      handleChange={handleOnChange2}
                      value={filter.Value2}
                      placeholder={filter.searchPlaceHolder2}
                      autoFocus
                      errorClass={this.state.invalidFields.includes(filter.searchPlaceHolder2) ? " error-data" : null}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col {...renderData.props}>{renderData.component}</Col>
            )}
          </Row>{" "}
        </Col>
      );
    });
  };

  render() {
    return (
      <div
        className={
          this.props.paddingLeft
            ? "ReportFilters container-fluid"
            : "ReportFilters"
        }
      >
        <Row className='heading mt-2'>{Strings("Filters")}</Row>
        <Collapse in={this.state.isFilterDivExpanded}>
          <div>
            {this.state.showErrorMessage &&
              <div className="errors ps-0 ">
                {Strings("InvalidInput")}
              </div>}
            <Row>{this.renderReportFilter()}</Row>
            {this.renderSearchFilterButtons()}
          </div>
        </Collapse>

        <Row className='collapseContainer'>
          <img
            src={
              this.state.isFilterDivExpanded
                ? pageDividerOpened
                : pageDividerClosed
            }
            alt='img'
            className='collapseIcon pull-right p-0'
            onClick={this.toggleCollpasibleView}
          />
          <hr className='collapseHr opacity-100' />
        </Row>
      </div>
    );
  }
}

export default ReportFilters;
