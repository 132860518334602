import { Strings } from "../../resources/i18n/i18n";

export const BANK_DETAILS_COLUMNS = [{
    key: "bn",
    name: Strings("bankName"),
    minWidth: 200,
    width: 500
},
{
    key: "ad",
    name: Strings("accountTypes"),
    minWidth: 200,
    width: 500

},
{
    key: "action",
    name: Strings("action"),
    minWidth: 200,
    width: 500

},
]
export const ADD_BANK_DETAILS_COLUMNS = [{
    key: "bn",
    name: Strings("bankName"),
    minWidth: 200,
},
{
    key: "ad",
    name: Strings("accountTypes"),
    minWidth: 200,
}
]

export const BANK_STATUS = [
    { key: 0, value: Strings("selectBankStatus"), label: Strings("selectBankStatus") },
    { key: 0, value: "true", label: Strings("Active") },
    { key: 0, value: "false", label: Strings("InAcive") },
];

export const SUPPLIER_STATUS = [
    { key: 0, value: "", label: Strings("selectSupplierStatus") },
    { key: 0, value: "true", label: Strings("Active") },
    { key: 0, value: "false", label: Strings("InAcive") },
];