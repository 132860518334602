import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button, Form } from "react-bootstrap";

//Strings
import { Strings } from "../../resources/i18n/i18n";


class WorkloadDistributionModal extends React.Component {

    render() {
        return (
            <div>
                <Modal
                    // Change visibility of modal based on props.
                    open={this.props.open}
                    //Modal opens with specified messages in props
                    closeOnEsc={this.props.modalCloseOnEsc}
                    closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
                    showCloseIcon={this.props.modalShowCloseIcon}
                    classNames={{ modal: "loanDetailModal" }}
                    center
                    focusTrapped={false}
                >
                    <Row className={"loanDetailModal"}>
                        <Col md={12} className='loanDetailModalHeader ps-3'>
                            {this.props.headerText}
                        </Col>
                        {/* Show only specified props of modal */}
                        <Col md={12} className='modalContent pt-3'>
                            {this.props.errorMessage && (
                                <Row className='errors p-0'>
                                    {Strings(this.props.errorMessage)}
                                </Row>
                            )}
                            <Row className='warningText p-0'>
                                {`${Strings("workloadCountDisplay")} ${this.props.workloadCount}`}
                            </Row>
                            <Row>
                                {this.props.data?.length > 1 && <Col md={12} className="p-1">
                                    <Form.Check
                                        name={"workloadSelectedUserIds"}
                                        type={"radio"}
                                        id={"all"}
                                        label={Strings("All")}
                                        onChange={this.props.handleRadioButtonChange}
                                        value={"all"}

                                    />
                                </Col>}
                                {this.props.data?.map((user) => {
                                    return (
                                        <Col md={6} key={user.uid} className="p-1">
                                            <Form.Check
                                                name={"workloadSelectedUserIds"}
                                                value={user.uid}
                                                type={"radio"}
                                                id={user.uid}
                                                label={user.un}
                                                onChange={this.props.handleRadioButtonChange}

                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                            <div className='loanDetailModalButton'>
                                <Button
                                    className={
                                        "primaryModalButton buttonMinWidth marginCancelButton buttonBorder " +
                                        this.props.modalButtonCancelStyle
                                    }
                                    onClick={this.props.modalButtonCancel.onClick}
                                >
                                    {this.props.modalButtonCancel.text}
                                </Button>

                                <Button
                                    className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                                    onClick={this.props.modalButtonOk.onClick}
                                    disabled={this.props.warningText}
                                >
                                    {this.props.modalButtonOk.text}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Modal>
            </div>
        );
    }
}

export default WorkloadDistributionModal;
