import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";

//Component
import UserModal from "../CustomUIComponents/UserModal/UserModal";

//Constant
import {
  REWARD_DETAILS_KEYS,
  ASSOCIATION_DETAILS_KEY,
} from "./Constants";
import {
  MINIMART_DETAILS_KEYS,
  STATUS_LIST,
} from "../MTPPromotionDetails/Constants";
import { PROMOTION_REQUEST_TYPE_LEGEND } from "../CustomUIComponents/CustomCard/CardConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./RewardDetails.scss";

const renderDetails = (keyList, title, data) => {
  return (
    <>
      <Row className='subHeadingText pt-3'>{title}</Row>
      <Row className='d-flex'>
        {keyList.map((dataKey) => {
          return (
            <Col md={6} className='d-flex  ps-0' key={dataKey.key}>
              {dataKey.formmater(dataKey, data)}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const renderRewardDetails = (props) => {
  let leftPoints = props.rewardDetails.rp - props.rewardDetails.pd.rp;
  return (
    <>
      <Col md={2} className='pb-3'>
        <div className='rewardImage'>
          <img
            src={props.rewardDetails.pd.rpi}
            className='img-thumbnail rewardImg'
            alt=''
          />
        </div>
        <hr className="opacity-100"/>
        <div
          className='gradeText justify-center display-flex pt-2'
          title={Strings("promotionStatus")}
          style={{
            color:
              PROMOTION_REQUEST_TYPE_LEGEND[props.rewardDetails.rs]
                .color,
          }}
        >
          <span title={Strings("promotionStatus")}>
            {Strings(props.rewardDetails.rs)}
          </span>
        </div>
      </Col>

      <Col md={10} className='pb-2'>
        {props.rewardDetails?.rs === STATUS_LIST.PENDING && (
          <div className='warningText'>
            {leftPoints >= 0
              ? Strings("totalRewardPoints").concat(leftPoints)
              : Strings("insuffientRewardPoints")}
          </div>
        )}
        {renderDetails(
          REWARD_DETAILS_KEYS,
          Strings("rewardInfo"),
          props.rewardDetails?.pd
        )}
        {renderDetails(
          MINIMART_DETAILS_KEYS,
          Strings("Minimart"),
          props.rewardDetails?.md
        )}
        {renderDetails(
          ASSOCIATION_DETAILS_KEY,
          Strings("Association"),
          props.rewardDetails?.asc
        )}
      </Col>
    </>
  );
};

const renderActionButtons = (props, toggleModal, setStatus) => {
  let leftPoints = props.rewardDetails.rp - props.rewardDetails.pd.rp;
  return (
    <Row className='pb-2'>
      <Col md={1}>
        <Button className='buttonBorder' onClick={props.goBack}>
          {Strings("Back").toUpperCase()}
        </Button>
      </Col>
      <Col md={{ offset: 9, span: 1 }} className='ps-0'>
        <Button
          className='capitalText buttonBackground'
          onClick={() => {
            toggleModal(true);
            setStatus("Approved");
          }}
          disabled={
            leftPoints < 0 ||
            props.rewardDetails?.rs !== STATUS_LIST.PENDING
          }
        >
          {Strings("approve")}
        </Button>
      </Col>
      <Col md={1} className='ps-0'>
        <Button
          className='capitalText buttonBackground'
          onClick={() => {
            toggleModal(true);
            setStatus("Reject");
          }}
          disabled={props.rewardDetails?.rs !== STATUS_LIST.PENDING}
        >
          {Strings("reject")}
        </Button>
      </Col>
    </Row>
  );
};

const updateRewardStatus = (props, toggleModal, status) => {
  props.updateRewardStatus(
    {
      rrid: props.reward.rrid,
      rs: status === "Approved" ? "APPROVED" : "REJECTED",
    },
    status === "Approved"
      ? Strings("RequestApprovedSuccessfully")
      : Strings("RequestRejectedSuccessfully"),
    () => {
      toggleModal(false);
    }
  );
};

const renderConfirmationModal = (
  props,
  toggleModal,
  showModal,
  status
) => {
  return (
    <UserModal
      open={showModal}
      modalCloseOnEsc={false}
      modalCloseOnOverlayClick={false}
      modalShowCloseIcon={false}
      headerText={Strings(status)}
      modalText2={Strings("doYouWantToContinue")}
      modalButtonOk={{
        text: Strings("Yes"),
        onClick: () => {
          updateRewardStatus(props, toggleModal, status);
        },
      }}
      modalButtonCancel={{
        text: Strings("Cancel"),
        onClick: () => {
          props.resetError();
          toggleModal(false);
        },
      }}
      showHr={true}
      errorMessage={props.errorMessage}
    />
  );
};

const RewardDetails = (props) => {
  const [showModal, toggleModal] = useState(false);
  const [status, setStatus] = useState("");
  return (
    <>
      {showModal &&
        renderConfirmationModal(
          props,
          toggleModal,
          showModal,
          status
        )}
      <Row className='heading'>
        {Strings("rewardApprovalDetailsTitle")}
      </Row>
      <div className='rewardDetails container-fluid ListContainer'>
        {props.rewardDetails ? (
          <>
            <Row className='primaryDetails'>
              {renderRewardDetails(props)}
            </Row>
            <hr className="opacity-100"/>
            {renderActionButtons(props, toggleModal, setStatus)}
          </>
        ) : (
          <Row className=' ps-5 justify-center display-flex noRecordsFound'>
            <span>{Strings("NoRecordsFound")}</span>
          </Row>
        )}
      </div>
    </>
  );
};

export default RewardDetails;
