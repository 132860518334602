import React, { Component } from "react";

import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import InputModal from "../InputModal/InputModal";
import addUser from "../../../resources/images/ic_add_user.svg";

//Utils
import {
  CONFIGURATION_DATE_TIME_FORMAT,
  CONFIGURATION_STATUS,
  CONFIGURATION_TYPE,
  MODAL_TYPE,
} from "../../../constants/appConstants";

// Strings
import { Strings } from "../../../resources/i18n/i18n";

import "./ConfigurationHeader.scss";

export default class ConfigurationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalHeader: "",
      modalValue: 0,
      modalType: "",
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  showModal = (message, value, type, modalSubTypeIndex = null) => {
    this.setState(
      {
        modalHeader: message,
        modalValue: value,
        modalType: type,
        modalSubTypeIndex,
      },
      () => {
        this.toggleModal();
      }
    );
  };

  handleChangeValue = (key, version, description) => {
    if (this.state.modalType === MODAL_TYPE.VERSION) {
      this.props.handleChangeValue(key, version, description);
      this.toggleModal();
    }
  };

  renderEditVersion = () => {
    return (
      <div>
        <span className='user ps-0 version'>
          {Strings("Version")}
        </span>
        {":"}
        <span className='versionValue value'>
          {this.props.headerData.version}
        </span>
        &nbsp;
        {!this.props.readOnly && (
          <i
            className='fa fa-pencil editVersion clickable cursorPointer'
            onClick={() =>
              this.showModal(
                Strings("Version"),
                this.props.headerData.version,
                MODAL_TYPE.VERSION
              )
            }
            title='Editar versión'
          />
        )}
      </div>
    );
  };

  renderDateAndUser = () => {
    return (
      <div className='pull-right'>
        <span className='user version'>
          {Strings("LastModified")}
          {":"}
        </span>
        <span className='user value'>
          {`${this.props.headerData.user} - `}
        </span>
        <span className='user ps-0 value'>
          {moment(this.props.headerData.lastModified).format(
            CONFIGURATION_DATE_TIME_FORMAT
          )}
        </span>
      </div>
    );
  };

  renderHeaderButtons = () => {
    return (
      <>
        {!this.props.noButtons && this.props.readOnly ? (
          <Col md={8} className='buttonPlaceHolder'>
            <Button
              className='addOrUpdateUserTopButtons'
              onClick={this.props.handleEditClick}
            >
              <i className='fa fa-pencil editIcon' />
              {Strings("Edit").toUpperCase()}
            </Button>
          </Col>
        ) : this.props.type && //NOSONAR
          (this.props.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
            this.props.type ===
            CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE) ? (
          <Col md={8} className='buttonPlaceHolder'>
            <Button
              className='addOrUpdateUserTopButtons subHeaderApplication userListAddButtonChangeFont'
              onClick={() =>
                this.props.showModal(
                  Strings(MODAL_TYPE.ADD_TYPE),
                  "",
                  MODAL_TYPE.ADD_TYPE,
                  null
                )
              }
            >
              <img src={addUser} alt='' />
              <span className='addTypeButtonText'>
                {Strings("addType").toUpperCase()}
              </span>
            </Button>
          </Col>
        ) : null}
      </>
    );
  };

  renderInputModal = () => {
    return (
      <InputModal
        open={this.state.openModal}
        type={this.state.modalType}
        toggleModal={this.toggleModal}
        modalHeader={Strings("Version")}
        confVersion={this.props.headerData.version}
        confDescription={this.props.headerData.description}
        handleChangeValue={this.handleChangeValue}
      />
    );
  };

  render() {
    let state;
    if (this.props.headerData.state === CONFIGURATION_STATUS.active)
      state = Strings("Active");
    else if (
      this.props.headerData.state === CONFIGURATION_STATUS.inactive
    )
      state = Strings("INACTIVE");
    else state = Strings("DRAFTED");
    return (
      <div className='commonComponents'>
        <Row md={12} className='totalRatingRow configurationInfoRow d-flex justify-content-center'>
          <Col className='d-flex align-items-center justify-content-center text-center'>
            <strong className='totalRatingText'>
              {`${this.props.headerText} - `}
            </strong>
            <strong className='d-flex justify-content-center state ps-1'>
              {state}
            </strong>
          </Col>
        </Row>
        <Row className='configurationInfoRow'>
          <Col md={4} className='alignSelfCenter'>
            {this.renderEditVersion()}
          </Col>
          <Col md={8}>{this.renderDateAndUser()}</Col>
        </Row>
        <Row className='userInfo pb-0'>
          {this.renderHeaderButtons()}

          {this.props.type &&
            (this.props.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
              this.props.type ===
              CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE) ? (
            <React.Fragment>
              <Col md={3}>
                <span className='pull-right'>
                  {Strings("combinedRating")}
                </span>
              </Col>
              <Col md={1} className='totalRatingPercentCol noPadding'>
                <div className='configurationTypeWeight'>
                  <div className='totalWeightBlock'>
                    <span className='configurationTypeAssignedWeight'>
                      {this.props.total}
                    </span>
                    <span className='configurationPercentage'>%</span>
                  </div>
                </div>
              </Col>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Col md={3} />
              <Col
                md={1}
                className='totalRatingPercentCol noPadding'
              />
            </React.Fragment>
          )}
        </Row>
        {this.renderInputModal()}
        <hr className="opacity-100"/>
      </div>
    );
  }
}
