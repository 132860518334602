import React from "react";

// Strings
import { Strings } from "../../resources/i18n/i18n";
import { colors } from "../../resources/styles/themes.js";
import { LOAN_TYPE_KEYS } from "../../constants/appConstants";

//Utility
import Utility from "../../utils/Utility";

export const currency = " $ ";

const renderFormatter = (value) => {
  return (
    <div className='infoContent' title={Strings(value)}>
      {value ? Strings(value) : Strings("NA")}
    </div>
  );
};

const renderDateFormatter = (value) => {
  return (
    <div className='infoContent' title={value}>
      {value ? Utility.getDateFromMiliSeconds(value) : Strings("NA")}
    </div>
  );
};

const renderCurrencyFormatter = (value) => {
  return (
    <div className='infoContent' title={value}>
      {value
        ? currency +
        " " +
        Utility.getCurrencyRepresentationOfAmount(value)
        : currency + " 0"}
    </div>
  );
};

const renderYesNOFormatter = (value) => {
  return (
    <div
      className='infoContent textWrap'
      title={value ? Strings("Yes") : Strings("No")}
    >
      {value ? Strings("Yes") : Strings("No")}
    </div>
  );
};

const renderDetails = (details) => {
  return (
    Strings("Yes") +
    " : (" +
    details.map((detail) => " " + Strings(detail)) +
    " )"
  );
};

const renderYesNOFormatterWithDetails = (value) => {
  return (
    <div
      className='infoContent'
      title={value !== null && value.length !== 0 ? Strings("Yes") : Strings("No")}
    >
      {value !== null && value.length !== 0 ? renderDetails(value) : Strings("No")}
    </div>
  );
};

const renderYearFormatter = (value) => {
  return (
    <div className='infoContent' title={value}>
      {value ? value + " " + Strings("year") : Strings("NA")}
    </div>
  );
};

const renderBlockLetterFormatter = (value) => {
  return (
    <div className='infoContent caps' title={value}>
      {value ? Strings(value) : Strings("NA")}
    </div>
  );

}

export const LOAN_APPLICATION_DETAILS = [
  {
    title: Strings("InvoiceNumber"),
    key: "invoiceNumber",
  },
  {
    title: Strings("TotalInstallments"),
    key: "termRequested",
  },
  {
    title: Strings("TypeOfLoan"),
    key: "type",
  },
  {
    title: Strings("AmountPaid"),
    key: "paidAmount",
  },
  {
    title: Strings("TotalAmount"),
    key: "totalAmount",
  },
  {
    title: Strings("BalanceAmount"),
    key: "balanceAmount",
  },
];

export const REFERENCES = [
  "typeOfReference",
  "name",
  "phone",
  "address",
];

export const EMI_CALCULATION = [
  {
    title: Strings("EMIDuration"),
    key: "duration",
    formatter: renderFormatter,
  },
  {
    title: Strings("EMIAmount"),
    key: "emi",
    formatter: renderCurrencyFormatter,
  },
];

export const PENDING_ACCOUNTS = [
  "60.0+",
  "40.0-59.0",
  "16.0-39.0",
  "0.0-15.0",
];

export const FINANCIAL_INFORMATION = [
  {
    title: Strings("monthlySalesAmount"),
    key: "monthlySalesAmount",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("accountsPending"),
    key: "accountsPending",
    formatter: (value) =>
      PENDING_ACCOUNTS.includes(value) ? (
        <div className='infoContent' title={value}>
          {value //NOSONAR
            ? Strings(value) + " %"
            : Strings("NA")}
        </div>
      ) : (
        renderCurrencyFormatter(value)
      ),
  },
  {
    title: Strings("inventory"),
    key: "inventory",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("MonthlyIncome"),
    key: "monthlyIncome",
    formatter: renderCurrencyFormatter,
  },
];

export const BASIC_INFORMATION = [
  {
    title: Strings("businessType"),
    key: "businessType",
    formatter: renderFormatter,
  },
  {
    title: Strings("ciCustomerSinceYears"),
    key: "ciCustomerSinceYears",
    formatter: (value) => {
      return (
        <div className='infoContent textWrap' title={value}>
          {value > 10
            ? "10+"
            : Strings(value) + " " + Strings("year")}
        </div>
      );
    },
  },
  {
    title: Strings("businessEstablishedSinceYears"),
    key: "businessEstablishedSinceYears",
    formatter: renderYearFormatter,
  },
  {
    title: Strings("CalmadoTelephone"),
    key: "telephone",
    formatter: renderFormatter,
  },
  {
    title: Strings("ownerName"),
    key: "ownerName",
    formatter: renderFormatter,
  },
  {
    title: Strings("IDOfOwner"),
    key: "ownerId",
    formatter: renderFormatter,
  },
  {
    title: Strings("ownerNumber"),
    key: "ownerNumber",
    formatter: renderFormatter,
  },
  {
    title: Strings("ownerAddress"),
    key: "ownerAddress",
    formatter: renderFormatter,
  },
  {
    title: Strings("ownerDateOfBirth"),
    key: "ownerDateOfBirth",
    formatter: renderDateFormatter,
  },
  {
    title: Strings("ownerHomeType"),
    key: "ownerHomeType",
    formatter: renderFormatter,
  },
  {
    title: Strings("homeLocation"),
    key: "homeLocation",
    formatter: renderFormatter,
  },
  {
    title: Strings("familyMembers"),
    key: "familyMembers",
    formatter: renderFormatter,
  },
  {
    title: Strings("numberOfVehicles"),
    key: "numberOfVehicles",
    formatter: renderFormatter,
  },
  {
    title: Strings("OwnerBankAccount"),
    key: "bankDetail",
    formatter: renderYesNOFormatterWithDetails,
  },
  {
    title: Strings("OnwerRemittance"),
    key: "remittanceDetails",
    formatter: renderYesNOFormatterWithDetails,
  },
  {
    title: Strings("socialMediaAccountDetails"),
    key: "socialMediaAccountDetails",
    formatter: renderYesNOFormatterWithDetails,
  },

  {
    title: Strings("adminDateOfBirth"),
    key: "adminDateOfBirth",
    formatter: renderDateFormatter,
  },
  {
    title: Strings("ApplicationHolder"),
    key: "holder",
    formatter: renderBlockLetterFormatter,
  },
  {
    title: Strings("supplierCreditLimit"),
    key: "ciCreditLimit",
    formatter: renderCurrencyFormatter,
  },
];

export const EXPENSES = [
  {
    title: Strings("numberOfEmployees"),
    key: "numberOfEmployees",
    formatter: renderFormatter,
  },
  {
    title: Strings("salaryOfEmployees"),
    key: "salaryOfEmployees",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("rent"),
    key: "rent",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("electricity"),
    key: "electricity",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("telephone"),
    key: "telephone",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("CableInternet"),
    key: "internet",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("GasOfDelivery"),
    key: "gas",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("waterConnection"),
    key: "water",
    formatter: renderCurrencyFormatter,
  },
  {
    title: Strings("DebtsToSuppliers"),
    key: "debtsToSuppliers",
    formatter: renderCurrencyFormatter,
  },
];

export const ADMINISTRATION_INFORMATION = [
  {
    title: Strings("NameOfAdministration"),
    key: "adminName",
    formatter: renderFormatter,
  },
  {
    title: Strings("adminId"),
    key: "adminId",
    formatter: renderFormatter,
  },
  {
    title: Strings("adminNumber"),
    key: "adminNumber",
    formatter: renderFormatter,
  },
  {
    title: Strings("adminSince"),
    key: "adminSince",
    formatter: renderYearFormatter,
  },
  {
    title: Strings("adminAddress"),
    key: "adminAddress",
    formatter: renderFormatter,
  },
  {
    title: Strings("adminSalary"),
    key: "adminSalary",
    formatter: renderCurrencyFormatter,
  },
];

export const SITE_VISIT = [
  {
    title: Strings("NoOfOwnedDeliveryMotors"),
    key: "numberOfMotors",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfExhibitionFridges"),
    key: "numberOfFridges",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfBotellero"),
    key: "numberOfBotellero",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfCars"),
    key: "numberOfCars",
    formatter: renderFormatter,
  },
  {
    title: Strings("TypeOfLocal"),
    key: "localType",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfTV"),
    key: "numberOfTVs",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfTelephone"),
    key: "numberOfTelephones",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfFreezers"),
    key: "numberOfFreezers",
    formatter: renderFormatter,
  },
  {
    title: Strings("NoOfExhibitors"),
    key: "numberOfExhibitors",
    formatter: renderFormatter,
  },
  {
    title: Strings("investor"),
    key: "investor",
    formatter: renderYesNOFormatter,
  },
  {
    title: Strings("roofType"),
    key: "roofType",
    formatter: renderFormatter,
  },
];

export const SUPPLIER_INFO = [
  {
    title: Strings("sdt"),
    key: "sdt",
    formatter: renderFormatter,
  },
  {
    title: Strings("stat"),
    key: "stat",
    formatter: renderFormatter,
  },
  {
    title: Strings("ainfo"),
    key: "ainfo",
    formatter: renderFormatter,
  },
  {
    title: Strings("sd"),
    key: "sd",
    formatter: renderFormatter,
  },
]

// Array containg keys from props of Data Object, Header text for the Secition, array of keys inside the Data Object
export const BASIC_ADMIN_INFO_DETAILS_ARRAY = [
  {
    header: Strings("BasicInformation"),
    propObjectKey: "basicInfo",
    keysArray: BASIC_INFORMATION,
  },
  {
    header: Strings("AdministrationInformation"),
    propObjectKey: "basicInfo",
    keysArray: ADMINISTRATION_INFORMATION,
  },
];

export const ainfo = [
  "bankName", "accountType", "accountNumber"
]
export const sd = [
  "rnc", "businessName"
]

export const OTHER_INFO_DETAILS_ARRAY = [
  {
    header: Strings("FinancialInformation"),
    propObjectKey: "income",
    keysArray: FINANCIAL_INFORMATION,
  },
  {
    header: Strings("Expenses"),
    propObjectKey: "expenses",
    keysArray: EXPENSES,
  },
  {
    header: Strings("SiteVisit"),
    propObjectKey: "siteVisit",
    keysArray: SITE_VISIT,
  },
];

export const MINIMART_RISK_DROPDOWN = [
  {
    key: 0,
    value: Strings("SelectMinimartRisk"),
    label: Strings("SelectMinimartRisk"),
  },
  {
    key: 1,
    value: "HIGH_RISK",
    label: Strings("highRisk"),
  },
  {
    key: 2,
    value: "MEDIUM_RISK",
    label: Strings("mediumRisk"),
  },
  {
    key: 3,
    value: "LOW_RISK",
    label: Strings("lowRisk"),
  },
];

export const ANALYST_ACTION = {
  SAVE_AS_DRAFT: "SAVE_AS_DRAFT",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  INCOMPLETE: "INCOMPLETE",
  APPROVE_WITH_CONCILIATION: "APPROVE_WITH_CONCILIATION",
};

export const OPERATIONS_OFFICER_ACTION = {
  INPROCESS_ACCOUNTS: "INPROCESS_ACCOUNTS",
  DISBURSE: "DISBURSED",
};

export const ACCOUNTS_OFFICER_ACTION = {
  READY_TO_DISBURSE: "READY_TO_DISBURSE",
  INPROCESS_OPERATIONS: "INPROCESS_OPERATIONS",
};

export const SELECT_CLASSIFICATION = [
  {
    key: 0,
    value: Strings("selectClassification"),
    label: Strings("selectClassification"),
  },
  {
    key: 1,
    value: "A",
    label: "A",
  },
  {
    key: 2,
    value: "B",
    label: "B",
  },
  {
    key: 3,
    value: "C",
    label: "C",
  },
  {
    key: 4,
    value: "D1",
    label: "D1",
  },
  {
    key: 5,
    value: "D2",
    label: "D2",
  },
  {
    key: 6,
    value: "E",
    label: "E",
  },
  {
    key: 7,
    value: "SE",
    label: Strings("unexperienced"),
  },
];

export const LOAN_TYPE_LABEL = {
  AMOUNT_REQUESTED: "amountRequested",
  TERM_REQUESTED: "termRequested",
  APPROVED_AMOUNT: "approvedAmount",
  APPROVED_TERM: "approvedTerm",
  INTEREST_RATE: "interestRate",
  APPROVED_INTEREST_RATE: "approvedInterestRate",
  EMI: "emi",
  TYPE: "type",
  STATUS: "status",
  PRODUCT_NAME: "ProductName",
  DISBURSE_DATE: "disbursedDate",
  DAYS_IN_ARREARS: "daysInArrears",
  USER_NAME: "userName",
  SUPPLIER_NAME: "SupplierName",
  PRODUCT_COST: "productCost",
  COLLECTED_DOWNPAYMENT: "collectedDownpayment",
  EXPECTED_DOWNPAYMENT: "expectedDownpayment",
  DOWNPAYMENT_RECEIPT: "downpaymentReceipt",
  SIGNED_CREDIT_AGREEMENT: "signedCreditAgreement",
  REMITTANCE_STATUS: "remittanceStatus",
  REMITTANCE_DELIVERY_RECEIPT: "remittanceDeliveryReceipt",
  INVOICE_NUMBER: "InvoiceNumber",
  INVOICE_TYPE: "InvoiceType",
  CREATION_DATE: "createdAt",
  EXPIRY_DATE: "expiryDate",
  BALANCE: "balance",
  APPROVED_FREQUENCY: "approvedFrequency",
  REQUESTED_FREQUENCY: "requestedFrequency",
  ADMINISTRATIVE_EXPENSE: "administrativeExpense",
  REQUESTED_COLLECTION_DAY: "requestedCollectionDays",
  APPROVED_COLLECTION_DAY: "approvedCollectionDays",
};

export const LOAN_DETAILS_COMMON_KEYS = [
  {
    key: LOAN_TYPE_KEYS.AMT,
    label: LOAN_TYPE_LABEL.AMOUNT_REQUESTED,
  },
  {
    key: LOAN_TYPE_KEYS.NOI,
    label: LOAN_TYPE_LABEL.TERM_REQUESTED,
  },
  {
    key: LOAN_TYPE_KEYS.RFQ,
    label: LOAN_TYPE_LABEL.REQUESTED_FREQUENCY,
  },
  {
    key: LOAN_TYPE_KEYS.APP_FQ,
    label: LOAN_TYPE_LABEL.APPROVED_FREQUENCY,
  },
  {
    key: LOAN_TYPE_KEYS.RT,
    label: LOAN_TYPE_LABEL.INTEREST_RATE,
  },
  {
    key: LOAN_TYPE_KEYS.APP_RT,
    label: LOAN_TYPE_LABEL.APPROVED_INTEREST_RATE,
  },
  {
    key: LOAN_TYPE_KEYS.EMI,
    label: LOAN_TYPE_LABEL.EMI
  },
  {
    key: LOAN_TYPE_KEYS.TY,
    label: LOAN_TYPE_LABEL.TYPE,
  },
  {
    key: LOAN_TYPE_KEYS.ST,
    label: LOAN_TYPE_LABEL.STATUS,
  },
  {
    key: LOAN_TYPE_KEYS.SCA,
    label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
  },
  {
    key: LOAN_TYPE_KEYS.requestedCollectionDays,
    label: LOAN_TYPE_LABEL.REQUESTED_COLLECTION_DAY,
  },
  {
    key: LOAN_TYPE_KEYS.approvedCollectionDays,
    label: LOAN_TYPE_LABEL.APPROVED_COLLECTION_DAY,
  },
]

export const LOAN_DETAILS_KEYS = {
  FACTORING: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.SN,
      label: LOAN_TYPE_LABEL.SUPPLIER_NAME
    },
    {
      key: LOAN_TYPE_KEYS.APP_AMT,
      label: LOAN_TYPE_LABEL.APPROVED_AMOUNT,
    },
    {
      key: LOAN_TYPE_KEYS.APP_TERM,
      label: LOAN_TYPE_LABEL.APPROVED_TERM,
    },
    {
      key: LOAN_TYPE_KEYS.DR,
      label: LOAN_TYPE_LABEL.REMITTANCE_DELIVERY_RECEIPT,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.DISBURSE_DATE,
      label: LOAN_TYPE_LABEL.DISBURSE_DATE,
    },
    {
      key: LOAN_TYPE_KEYS.IN,
      label: LOAN_TYPE_LABEL.REMITTANCE_STATUS,
    },
    {
      key: LOAN_TYPE_KEYS.INV,
      label: LOAN_TYPE_LABEL.INVOICE_NUMBER,
    },
    {
      key: LOAN_TYPE_KEYS.IT,
      label: LOAN_TYPE_LABEL.INVOICE_TYPE,
    },
    {
      key: LOAN_TYPE_KEYS.DT,
      label: LOAN_TYPE_LABEL.CREATION_DATE,
    },
    {
      key: LOAN_TYPE_KEYS.ED,
      label: LOAN_TYPE_LABEL.EXPIRY_DATE,
    },
    {
      key: LOAN_TYPE_KEYS.BAL,
      label: LOAN_TYPE_LABEL.BALANCE,
    }
  ],
  CASH_TOPUP: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.APP_AMT,
      label: LOAN_TYPE_LABEL.APPROVED_AMOUNT,
    },
    {
      key: LOAN_TYPE_KEYS.APP_TERM,
      label: LOAN_TYPE_LABEL.APPROVED_TERM,
    },
    {
      key: LOAN_TYPE_KEYS.RST,
      label: LOAN_TYPE_LABEL.REMITTANCE_STATUS,
    },
    {
      key: LOAN_TYPE_KEYS.DR,
      label: LOAN_TYPE_LABEL.REMITTANCE_DELIVERY_RECEIPT,
    },
    {
      key: LOAN_TYPE_KEYS.USER_NAME,
      label: LOAN_TYPE_LABEL.USER_NAME,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
  ],
  CONSUMER: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.USER_NAME,
      label: LOAN_TYPE_LABEL.USER_NAME,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.PNM,
      label: LOAN_TYPE_LABEL.PRODUCT_NAME,
    },
  ],

  EXTERNAL_FACTORING: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.SN,
      label: LOAN_TYPE_LABEL.SUPPLIER_NAME
    },
    {
      key: LOAN_TYPE_KEYS.APP_AMT,
      label: LOAN_TYPE_LABEL.APPROVED_AMOUNT,
    },
    {
      key: LOAN_TYPE_KEYS.APP_TERM,
      label: LOAN_TYPE_LABEL.APPROVED_TERM,
    },
    {
      key: LOAN_TYPE_KEYS.DR,
      label: LOAN_TYPE_LABEL.REMITTANCE_DELIVERY_RECEIPT,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.DISBURSE_DATE,
      label: LOAN_TYPE_LABEL.DISBURSE_DATE,
    },
    {
      key: LOAN_TYPE_KEYS.RST,
      label: LOAN_TYPE_LABEL.REMITTANCE_STATUS,
    },
    {
      key: LOAN_TYPE_KEYS.AE,
      label: LOAN_TYPE_LABEL.ADMINISTRATIVE_EXPENSE,
    },
  ],
  TU_PRESTAMO: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.APP_AMT,
      label: LOAN_TYPE_LABEL.APPROVED_AMOUNT,
    },
    {
      key: LOAN_TYPE_KEYS.APP_TERM,
      label: LOAN_TYPE_LABEL.APPROVED_TERM,
    },
    {
      key: LOAN_TYPE_KEYS.DR,
      label: LOAN_TYPE_LABEL.REMITTANCE_DELIVERY_RECEIPT,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.USER_NAME,
      label: LOAN_TYPE_LABEL.USER_NAME,
    },
    {
      key: LOAN_TYPE_KEYS.RST,
      label: LOAN_TYPE_LABEL.REMITTANCE_STATUS,
    },
  ],
  MOTORCYCLE: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.PNM,
      label: LOAN_TYPE_LABEL.PRODUCT_NAME,
    },
    {
      key: LOAN_TYPE_KEYS.PC,
      label: LOAN_TYPE_LABEL.PRODUCT_COST,
    },
    {
      key: LOAN_TYPE_KEYS.USER_NAME,
      label: LOAN_TYPE_LABEL.USER_NAME,
    },
    {
      key: LOAN_TYPE_KEYS.DAMT,
      label: LOAN_TYPE_LABEL.COLLECTED_DOWNPAYMENT,
    },
    {
      key: LOAN_TYPE_KEYS.DPR,
      label: LOAN_TYPE_LABEL.DOWNPAYMENT_RECEIPT,
    },
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.DISBURSE_DATE,
      label: LOAN_TYPE_LABEL.DISBURSE_DATE,
    },
  ],
  FAST_CASH: [
    ...LOAN_DETAILS_COMMON_KEYS,
    {
      key: LOAN_TYPE_KEYS.SCA,
      label: LOAN_TYPE_LABEL.SIGNED_CREDIT_AGREEMENT,
    },
    {
      key: LOAN_TYPE_KEYS.RST,
      label: LOAN_TYPE_LABEL.REMITTANCE_STATUS,
    },
    {
      key: LOAN_TYPE_KEYS.AE,
      label: LOAN_TYPE_LABEL.ADMINISTRATIVE_EXPENSE,
    },
  ],
};

export const NOTES_TYPE = {
  LOAN: "LOAN",
  ANALYST_INPUT_RISK: "ANALYST_INPUT_RISK",
  ANALYST_INPUT_REFERENCE: "ANALYST_INPUT_REFERENCE",
  ANALYST_REJECTION: "ANALYST_REJECTION",
  CALL: "CALL",
  ANALYST_INPUT_REGULATOR: "ANALYST_INPUT_REGULATOR",
  NEGOTIATION_REQUEST: "NEGOTIATION_REQUEST",
  NEGOTIATION_RESPONSE: "NEGOTIATION_RESPONSE",
  ANALYST_MINIMART_APPROVE: "ANALYST_MINIMART_APPROVE",
  EVALUATION_NOTE: "EVALUATION_NOTE",
};

export const ARRAY_OF_TERMS = [
  { key: 0, label: Strings("selectTerms"), value: "" },
  { key: 1, label: "12", value: 12 },
  { key: 2, label: "24", value: 24 },
  { key: 3, label: "36", value: 36 },
];

export const CREDIT_SCORE_COLOR = {
  HIGH: colors.creditScoreColor.green,
  MEDIUM: colors.creditScoreColor.yellow,
  LOW: colors.creditScoreColor.red,
};

export const LOAN_STATUS_CONSTANTS = {
  INPROCESS_ANALYSIS: "INPROCESS_ANALYSIS",
};
export const LOAN_TYPES = {
  CASH_TOPUP: "CASH_TOPUP",
  CONSUMER: "CONSUMER",
  EXTERNAL_FACTORING: "EXTERNAL_FACTORING",
  TU_PRESTAMO: "TU_PRESTAMO",
  MOTORCYCLE_LOAN: "MOTORCYCLE",
  FAST_CASH: "FAST_CASH",
  FACTORING: "FACTORING",
};

export const LOAN_APPLICATION_DETAILS_KEYS = {
  LOAN_INFO: "loanInfo",
  BASIC_INFO: "basicInfo",
  EXPENSES: "expenses",
  INCOME: "income",
  DOCUMENTS: "documents",
  SITE_VISIT: "siteVisit",
};

export const LOAN_INFO_KEYS = {
  EMI: "emi",
  PAID_AMOUNT: "paidAmount",
  TOTAL_AMOUNT: "totalAmount",
  BALANCE_AMOUNT: "balanceAmount",
  START_DATE: "startDate",
  END_DATE: "endDate",
  AMOUNT_APPROVED: "amountApproved",
  AMOUNT_REQUESTED: "amountRequested",
  TYPE: "type",
  PRODUCT_COST: "co",
  COLLECTED_DOWNPAYMENT: "dAmt",
  EXPECTED_DOWNPAYMENT: "edAmt",
};
export const BASIC_INFO_KEYS = {
  CI_CUSTOMER_SINCE_YEARS: "ciCustomerSinceYears",
  OWNER_DATE_OF_BIRTH: "ownerDateOfBirth",
  ADMIN_DATE_OF_BIRTH: "adminDateOfBirth",
  CI_CREDIT_LIMIT: "ciCreditLimit",
  BUSINESS_EST_SINCE_YEARS: "businessEstablishedSinceYears",
  BANK_ACCOUNT: "bankAccount",
  BANK_DETAIL: "bankDetail",
  NAME: "name",
  RECEIVES_REMITTANCES: "receivesRemittances",
  REMITTANCE_DETAIL: "remittanceDetail",
};

export const SITE_VISIT_KEYS = {
  INVESTOR: "investor",
};

export const EXPENSES_KEYS = {
  NUMBER_OF_EMPLOYEES: "numberOfEmployees",
};

export const INCOME_KEYS = {
  MONTHLY_SALES_AMOUNT: "monthlySalesAmount",
  ACCOUNTS_PENDING: "accountsPending",
  INVENTORY: "inventory",
  MONTHLY_INCOME: "monthlyIncome",
};

export const EMI_DURATION_KEYS = {
  MINIMART_ID: "minimartId",
  LOAN_TYPE: "loanType",
};

export const MINIMART_LABEL = {
  NAME: "name",
  OWNER_NAME: "ownerName",
};

export const IMAGE_URLS = "imageURLS";
export const ADDRESS = "address";

export const EVIDENCE_TYPES = {
  LOCAL_PHOTO: "LOCAL_PHOTO",
  INVOICE: "INVOICE",
};

export const REMITTANCE_STATUS = {
  SCHEDULE: "SCHEDULE",
  FAILED: "FAILED",
  IN_PROCESS: "IN_PROCESS",
  IN_ROUTE: "IN_ROUTE",
  CANCELLED: "CANCELLED",
};

export const VIEW_LOAN_APP_TYPES = {
  FACTORING: [
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("InvoiceNumber"),
      key: "invoiceNumber",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
  CASH_TOPUP: [
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("amountApproved"),
      key: "amountApproved",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
  CONSUMER: [
    {
      key: "pnm",
      title: Strings("ProductName"),
      formatter: renderFormatter,
    },
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
  EXTERNAL_FACTORING: [
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("amountApproved"),
      key: "amountApproved",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
  TU_PRESTAMO: [
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },

    {
      title: Strings("amountApproved"),
      key: "amountApproved",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
  MOTORCYCLE: [
    {
      key: "pnm",
      title: Strings("ProductName"),
      formatter: renderFormatter,
    },
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      key: "co",
      title: Strings("productCost"),
      formatter: renderFormatter,
    },

    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      key: "dAmt",
      title:
        Strings("collectedDownpayment") +
        "/" +
        Strings("expectedDownpayment"),
      formatter: renderCurrencyFormatter,
    },
  ],
  FAST_CASH: [
    {
      title: Strings("TypeOfLoan"),
      key: "type",
      formatter: renderFormatter,
    },
    {
      title: Strings("amountRequested"),
      key: "amountRequested",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalInstallments"),
      key: "termRequested",
      formatter: renderFormatter,
    },

    {
      title: Strings("AmountPaid"),
      key: "paidAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("TotalAmount"),
      key: "totalAmount",
      formatter: renderCurrencyFormatter,
    },
    {
      title: Strings("BalanceAmount"),
      key: "balanceAmount",
      formatter: renderCurrencyFormatter,
    },
  ],
};

export const ANALYSED_BY_SYSTEM = "ANALYSED_BY_SYSTEM";

export const CONCILIATION_DETAILS_KEY = [
  { key: "upc", label: "unpaidCapital", className: "" },
  { key: "upi", label: "unPaidInterest", className: "" },
  { key: "uplf", label: "unpaidLateFees", className: "" },
  { key: "apamt", label: "approvedAmount", className: "" },
  { key: "td", label: "totalDebt", className: "" },
  { key: "atm", label: "atmAmount", className: "" },
];

export const DEAFAULT_FREQUENCY_LIST = [
  {
    label: "WEEKLY_THRICE",
    value: "WEEKLY_THRICE",
  },
];

export const loanUpdateMandetoryCheck = {
  supplierInfo: [
    "selectedDisbursementType",
    "selectedTransferAccountType",
    "bankName",
    "businessName",
    "accountType",
    "accountNumber"
  ],
  basicInfo: [
    "businessType",
    "ciCustomerSinceYears",
    "businessEstablishedSinceYears",
    "telephone",
    "ownerNumber",
    "ownerAddress",
    "ownerDateOfBirth",
    "ownerHomeType",
    "homeLocation",
    "familyMembers",
    "numberOfVehicles",
    "ciCreditLimit",
    "holder",
    "bankDetail",
    "socialMediaAccountDetails"
  ],
  comercialRef1: ["name", "phone"],
  comercialRef2: ["name", "phone"],
  personalRef1: ["name", "phone"],
  personalRef2: ["name", "phone"],
  neighbourhoodRef1: ["name", "phone"],
  neighbourhoodRef2: ["name", "phone"],

  income: ["monthlySalesAmount", "accountsPending", "inventory"],
  expenses: [
    "numberOfEmployees",
    "salaryOfEmployees",
    "rent",
    "electricity",
    "telephone",
    "internet",
    "gas",
    "water",
    "debtsToSuppliers"],
}

export const loanApplicationEdit = {
  supplierInfo: [
    { title: "sdt", isMandatoryField: true, name: "selectedDisbursementType", type: "select" },
    { title: "stat", isMandatoryField: true, name: "selectedTransferAccountType", type: "select" },
    { title: "bankName", isMandatoryField: true, name: "bankName", type: "select" },
    { title: "businessName", isMandatoryField: true, name: "supplierId", type: "select" },
    { title: "accountType", isMandatoryField: true, name: "accountType", type: "select" },
    { title: "accountNumber", isMandatoryField: true, name: "accountNumber", type: "text", maxLength: 30 },
  ],
  basicInfo: [
    { title: "businessType", isMandatoryField: true, name: "businessType", type: "select" },
    { title: "ciCustomerSinceYears", isMandatoryField: true, name: "ciCustomerSinceYears", type: "select" },
    { title: "businessEstablishedSinceYears", isMandatoryField: true, name: "businessEstablishedSinceYears", type: "select" },
    { title: "CalmadoTelephone", isMandatoryField: true, name: "telephone", type: "number", maxLength: 10 },
    { title: "ownerNumber", isMandatoryField: true, name: "ownerNumber", type: "number", maxLength: 10 },
    { title: "ownerAddress", isMandatoryField: true, name: "ownerAddress", type: "text", maxLength: 200 },
    { title: "ownerDateOfBirth", isMandatoryField: true, name: "ownerDateOfBirth", isDate: "date", isAfter: true },
    { title: "ownerHomeType", isMandatoryField: true, name: "ownerHomeType", type: "select" },
    { title: "homeLocation", isMandatoryField: true, name: "homeLocation", type: "select" },
    { title: "familyMembers", isMandatoryField: true, name: "familyMembers", type: "select" },
    { title: "numberOfVehicles", isMandatoryField: true, name: "numberOfVehicles", type: "select" },
    { title: "OwnerBankAccount", isMandatoryField: false, name: "bankDetail", type: "text", isSelect: "multiSelect" },
    { title: "OnwerRemittance", isMandatoryField: false, name: "remittanceDetails", type: "select" },
    { title: "socialMediaAccountDetails", isMandatoryField: false, name: "socialMediaAccountDetails", type: "text", isSelect: "multiSelect" },
    { title: "supplierCreditLimit", isMandatoryField: true, name: "ciCreditLimit", type: "number", maxLength: 30 },
    { title: "ApplicationHolder", isMandatoryField: true, name: "holder", type: "select" },
    { title: "NameOfAdministration", isMandatoryField: false, name: "adminName", type: "text", maxLength: 50 },
    { title: "adminId", isMandatoryField: false, name: "adminId", type: "number", maxLength: 11 },
    { title: "adminDateOfBirth", isMandatoryField: false, name: "adminDateOfBirth", isDate: "date", isAfter: true },
    { title: "adminNumber", isMandatoryField: false, name: "adminNumber", type: "number", maxLength: 10 },
    { title: "adminSince", isMandatoryField: false, name: "adminSince", type: "select" },
    { title: "adminAddress", isMandatoryField: false, name: "adminAddress", type: "text", maxLength: 200 },
    { title: "adminSalary", isMandatoryField: false, name: "adminSalary", type: "select" },
  ],
  comercialRef1: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  comercialRef2: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  personalRef1: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  personalRef2: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  neighbourhoodRef1: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  neighbourhoodRef2: [
    { title: "name", isMandatoryField: true, name: "name", type: "text", maxLength: 50 },
    { title: "address", isMandatoryField: false, name: "address", type: "text", maxLength: 200 },
    { title: "phone", isMandatoryField: true, name: "phone", type: "number", maxLength: 10 },
  ],
  income: [
    { title: "monthlySalesAmount", isMandatoryField: true, name: "monthlySalesAmount", type: "select" },
    { title: "accountsPending", isMandatoryField: true, name: "accountsPending", type: "select" },
    { title: "inventory", isMandatoryField: true, name: "inventory", type: "select" },
  ],
  expenses: [
    { title: "numberOfEmployees", isMandatoryField: true, name: "numberOfEmployees", type: "select" },
    { title: "salaryOfEmployees", isMandatoryField: true, name: "salaryOfEmployees", type: "select" },
    { title: "rent", isMandatoryField: true, name: "rent", type: "select" },
    { title: "electricity", isMandatoryField: true, name: "electricity", type: "select" },
    { title: "telephone", isMandatoryField: true, name: "telephone", type: "select" },
    { title: "CableInternet", isMandatoryField: true, name: "internet", type: "select" },
    { title: "GasOfDelivery", isMandatoryField: true, name: "gas", type: "select" },
    { title: "waterConnection", isMandatoryField: true, name: "water", type: "select" },
    { title: "DebtsToSuppliers", isMandatoryField: true, name: "debtsToSuppliers", type: "select" },
  ],
};

export const CONCILIATION_TABLE_HEADERS = [
  { key: "lid", name: "loanId" },
  { key: "lt", name: "loanType" },
  { key: "uc", name: "unpaidCapital" },
  { key: "bdt", name: "balanceToDate" },
  { key: "action", name: "action" }
]