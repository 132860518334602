import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_CATEGORY_SELECTION = {
  key: 0,
  id: "",
  value: "",
  cat: Strings("selectCategory"),
};

export const DEFAULT_MODEL_SELECTION = {
  key: 0,
  value: "",
  label: Strings("selectModel") + "*",
};

export const STATUS_SELECTION = [
  { key: 0, value: Strings("selectStatus"), label: Strings("selectStatus") },
  { key: 0, value: "ACTIVE", label: Strings("Active") },
  { key: 0, value: "INACTIVE", label: Strings("InAcive") },
];

export const GET_PRODUCT_CATALOG_COLUMNS = () => [
  {
    key: "category",
    name: Strings("Category"),
    minWidth: 200,
  },
  {
    key: "productNameAndDescription",
    name: Strings("product"),
    minWidth: 100,
  },
  {
    key: "model",
    name: Strings("model"),
    minWidth: 80,
  },

  {
    key: "cost",
    name: Strings("cost"),
    minWidth: 80,
  },
  {
    key: "status",
    name: Strings("status"),
    minWidth: 50,
  },
];
