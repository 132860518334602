import { Strings } from "../../resources/i18n/i18n";

export const STATUSES_OF_LOAN_FOR_OPERATIONS_OFFICER = [
  {
    key: 0,
    value: Strings("selectLoanStatus"),
    label: Strings("selectLoanStatus"),
  },
  {
    key: 3,
    value: "READY_TO_DISBURSE",
    label: Strings("READY_TO_DISBURSE"),
  },
  {
    key: 4,
    value: "INPROCESS_OPERATIONS",
    label: Strings("INPROCESS_OPERATIONS"),
  },
];

export const REMITTANCE_STATUSES_FOR_OPERATIONS_OFFICER = [
  {
    key: 0,
    value: Strings("selectRemittanceStatus"),
    label: Strings("selectRemittanceStatus"),
  },

  {
    key: 1,
    value: "SCHEDULE",
    label: Strings("SCHEDULE"),
  },
  {
    key: 2,
    value: "IN_PROCESS",
    label: Strings("IN_PROCESS"),
  },
  {
    key: 3,
    value: "IN_ROUTE",
    label: Strings("IN_ROUTE"),
  },
  {
    key: 4,
    value: "FAILED",
    label: Strings("FAILED"),
  },
  {
    key: 5,
    value: "CANCELLED",
    label: Strings("CANCELLED"),
  },
];
