import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_CATEGORY_SELECTION = {
  key: 0,
  id: "",
  value: "",
  cat: `${Strings("selectCategory")}`,
};

export const STATUS_SELECTION = [
  { key: 0, value: Strings("selectStatus"), label: Strings("selectStatus") },
  { key: 1, value: "ACTIVE", label: Strings("Active") },
  { key: 2, value: "INACTIVE", label: Strings("InAcive") },
];
export const RNC_VERIFICATION = [
  { key: 0, value: "", label: Strings("RNCVerification") },
  { key: 1, value: "VERIFIED", label: Strings("Verified") },
  { key: 2, value: "UNVERIFIED", label: Strings("UnVerified") },
];
