import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_USER_SELECTION = "SelectUser";

export const GET_DEFAULT_DROPDOWN_SELECTION = () => ({
  id: DEFAULT_USER_SELECTION,
  role: Strings("SelectUser"),
});

export const DEFAULT_BRANCH_SELECTION = "selectBranch";
export const GET_DEFAULT_BRANCH_SELECTION = () => ({
  id: "selectBranch",
  name: Strings("selectBranch"),
});
export const USER_TYPE = {
  ADMIN: "ADMIN",
  BRANCH_OFFICER: "BRANCH_OFFICER",
  COLLECTION_OFFICER: "COLLECTION_OFFICER",
  CONFIGURATOR: "CONFIGURATOR",
  LOAN_PORTFOLLIO_MANAGER: "LOAN_PORTFOLLIO_MANAGER",
  ACCOUNTING_OFFICER: "ACCOUNTING_OFFICER",
};

export const USER_STATUS = [
  { key: 0, value: Strings("selectStatus"), label: Strings("selectStatus") },
  { key: 1, value: "true", label: Strings("Active") },
  { key: 2, value: "false", label: Strings("Deactivated") }
];

export const USER_AVAILABILITY_STATUS = [
  { key: 0, value: Strings("Availability"), label: Strings("Availability") },
  { key: 1, value: "true", label: Strings("Available") },
  { key: 2, value: "false", label: Strings("Unavailable") }
];