import React from "react";

import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";

import { Strings } from "../../resources/i18n/i18n";
import { Row, Col } from "react-bootstrap";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='invoices pt-3'>
        <SearchFilter
          filters={this.props.InvoiceFilters}
          handleOnClickSearchFilter={
            this.props.handleOnClickSearchFilter
          }
          handleOnClickResetFilter={
            this.props.handleOnClickResetFilter
          }
          handleAddUser={this.props.handleAddInvoice}
          buttonText={Strings("AddInvoice")}
          addButton={this.props.addInvoice}
          buttonWidth={12 + " w-auto"}
        />

        {this.props.invoices.length >= 1 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pe-0'>
              <Row className='w-100'>
                {this.props.invoices.map((invoice, index) => {
                  return (
                    <CustomCard
                      card={invoice}
                      key={index}
                      cardType='invoices'
                      handleCardClick={() => { }}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.props.activePage}
                recordsPerPage={this.props.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.props.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default Invoices;
