import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Switch } from "react-router-dom";

// Constants
import { ROUTES } from "./constants/routeConstants";
import { CONFIGURATION_TYPE } from "./constants/appConstants";

// Styles
import "./resources/styles/globalStyles.scss";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

// Components
import App from "./components/App/App";
import LandingPage from "./components/LandingPage/LandingPage";
import UserList from "./components/UserList/UserListContainer";
import BranchList from "./components/BranchList/BranchListContainer";
import BranchDetails from "./components/BranchList/BranchDetails";
import ProfileDetailsContainer from "./components/ProfileDetails/ProfileDetailsContainer";
import ChangePasswordContainer from "./components/ChangePassword/ChangePasswordContainer";
import SuperDashBoardContainer from "./components/SuperDashBoard/SuperDashBoardContainer";
import CiInterfaceContainer from "./components/CiInterface/CiInterfaceContainer";
import CiLoanRequestContainer from "./components/CiLoanRequestReport/CiLoanRequestReportContainer";
import MinimartListContainer from "./components/MinimartList/MinimartListContainer";
import MinimartDetailsContainer from "./components/MinimartDetails/MinimartDetailsContainer";
import DailyStatusContainer from "./components/DailyStatus/DailyStatusContainer";
import LoanListContainer from "./components/LoanList/LoanListContainer";
import LoanDetailsContainer from "./components/LoanDetails/LoanDetailsContainer";
import UpComing from "./components/UpComing/UpComing";
import CensusWorkloadManagementContainer from "./components/CensusWorkloadManagement/CensusWorkloadManagementContainer";
import VerifyCollectionContainer from "./components/VerifyCollection/VerifyCollectionContainer";
import MessengerListContainer from "./components/MessengerList/MessengerListContainer";
import CustomConfigurationContainer from "./components/CustomConfiguration/CustomConfigurationContainer";
import ConfigurationListContainer from "./components/ConfigurationList/ConfigurationListContainer";
import FileContentContainer from "./components/FileContent/FileContentContainer";
import ViewLoanApplicationContainer from "./components/ViewLoanApplication/ViewLoanApplicationContainer";
import UserWorkHistoryContainer from "./components/UserWorkHistory/UserWorkHistoryContainer";
// import ConsolidatedMapContainer from "./components/ConsolidatedMap/ConsolidatedMapContainer";
import GeneralReportsContainer from "./components/GeneralReports/GeneralReportsContainer";
import ApprovalDashboardContainer from "./components/ApprovalDashboard/ApprovalDashboardContainer";
import ComissionContainer from "./components/Comission/ComissionContainer";
import AnalystApprovalContainer from "./components/AnalystApproval/AnalystApprovalContainer";
import LoanHistory from "./components/LoanHistory/LoanHistoryContainer";
import LoanApprovalContainer from "./components/LoanApproval/LoanApprovalContainer";
import CIWireTransferContainer from "./components/CIWireTransfer/CIWireTransferContainer";
import ProductCatalogContainer from "./components/ProductCatalog/ProductCatalogContainer";
import CommissionReportContainer from "./components/CommissionReport/CommissionReportContainer";
import DetailCommissionReportContainer from "./components/DetailCommissionReport/DetailCommissionReportContainer";
import CallNotesContainer from "./components/CallNotes/CallNotesContainer";
import MinimartApprovalContainer from "./components/MinimartApproval/MinimartApprovalContainer";
import MinimartHistoryContainer from "./components/MinimartHistory/MinimartHistoryContainer";
import ApprovalMinimartContainer from "./components/ApprovalMinimart/ApprovalMinimartContainer";
import EfficiencyDashboardContainer from "./components/EfficiencyDashboard/EfficiencyDashboardContainer";
import SingleSalesPersonContainer from "../src/components/SingleSalesPerson/SingleSalesPersonContainer";
import MotorcycleCatalogContainer from "./components/MotorcycleCatalog/MotorcycleCatalogContainer";
import PromotionsContainer from "./components/MTPPromotions/PromotionsContainer";
import ProductsContainer from "./components/MTPProducts/ProductsContainer";
import LoanRequestsContainer from "./components/MTPLoanRequests/LoanRequestsContainer";
import ComplaintPortalContainer from "./components/MTPComplaintPortal/ComplaintPortalContainer";
import EditMinimartRequestContainer from "./components/MTPEditMinimartRequest/EditMinimartRequestContainer";
import RemittancePaymentContainer from "./components/RemittancePayment/RemittancePaymentContainer";
import ZonePositionMappingContainer from "./components/ZonePositionMapping/ZonePositionMappingContainer";
import SignupRequestContainer from "./components/MTPSignupRequest/SignupRequestContainer";
import SignupRequestDetailsContainer from "./components/MTPSignupRequestDetails/SignupRequestDetailsContainer";
import PromotionsApprovalConatiner from "./components/MTPPromotionsApproval/PromotionsApprovalContainer";
import PromotionDetailsContainer from "./components/MTPPromotionDetails/PromotionDetailsContainer";
import RewardCatalogContainer from "./components/RewardCatalog/RewardCatalogContainer";
import RewardApprovalContainer from "./components/RewardApproval/RewardApprovalContainer";
import RewardDetailsContainer from "./components/RewardDetails/RewardDetailsContainer";
import InvoiceApprovalContainer from "./components/InvoiceApproval/InvoiceApprovalContainer";
import * as serviceWorker from "./serviceWorker";
import { ScalaPrivacyPolicy } from "./components/PrivacyPolicy/ScalaPrivacyPolicy";
import { MatricesPrivacyPolicy } from "./components/PrivacyPolicy/MatricesPrivacyPolicy";
import ConsolidatedMapContainer from "./components/ConsolidatedMap/ConsolidatedMapContainer";
import ClientPortalContainer from "./components/ClientPortal/ClientPortalContainer";
import BankConfContainer from "./components/BankConf/BankConfContainer";

// Redux
import store from "./redux/store";

// Localization setup
import i18n from "./resources/i18n/i18n";

// Utils
import LanguageLookup from "../src/utils/languageLookup";
import SupplierCatalogContainer from "./components/SupplierCatalog/SupplierCatalogContainer";

//Guards
import AuthGuard from "./Guards/AuthGuard";
import MergedMinimartsDetails from "./components/MinimartDetails/MergedMinimartsDetails";
import DeactivateAccount from "./components/DeactivateAccount/DeactivateAccount";

export const APP_LANGUAGE = LanguageLookup(navigator.languages[0]);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <App>
          <Switch>
            <AuthGuard
              path='/scalaPrivacyPolicy'
              component={ScalaPrivacyPolicy}
              exact
            />
            <AuthGuard
              path={'/privacyPolicy'}
              component={MatricesPrivacyPolicy}
              exact
            />
            <AuthGuard
              path={'/deactivateAccount'}
              component={DeactivateAccount}
              exact
            />
            <AuthGuard
              path='/'
              component={LandingPage}
              exact
            />
            <AuthGuard
              path={ROUTES.LOGIN}
              component={LandingPage}
              exact
            />
            <AuthGuard
              path={ROUTES.USER_LIST}
              component={UserList}
            />
            <AuthGuard
              path={ROUTES.USER_PROFILE}
              component={ProfileDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.APPROVAL_DASHBOARD}
              component={ApprovalDashboardContainer}
            />
            <AuthGuard
              path={ROUTES.BRANCH_LIST}
              component={BranchList}
            />
            <AuthGuard
              path={ROUTES.BRANCH_DETAILS}
              component={BranchDetails}
            />
            <AuthGuard
              path={ROUTES.CHANGE_PASSWORD}
              component={ChangePasswordContainer}
            />
            <AuthGuard
              path={ROUTES.CI_INTERFACE}
              component={CiInterfaceContainer}
            />
            <AuthGuard
              path={ROUTES.CI_COLLECTION_APP_LOAN_REQUEST}
              component={CiLoanRequestContainer}
            />
            <AuthGuard
              path={ROUTES.DAILY_STATUS}
              component={DailyStatusContainer}
            />
            <AuthGuard
              path={ROUTES.MINIMART_MANAGEMENT}
              component={MinimartListContainer}
            />
            <AuthGuard
              path={ROUTES.NEW_MINIMART_MANAGEMENT}
              component={MinimartListContainer}
            />
            <AuthGuard
              path={ROUTES.MINIMART_DETAILS}
              component={MinimartDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.WORKLOAD_MANAGEMENT}
              component={CensusWorkloadManagementContainer}
            />
            <AuthGuard
              path={ROUTES.MAP}
              component={ConsolidatedMapContainer}
            />
            <AuthGuard
              path={ROUTES.UPCOMING}
              component={UpComing}
            />
            <AuthGuard
              path={ROUTES.APPROVAL_DASHBOARD}
              component={ApprovalDashboardContainer}
            />
            <AuthGuard
              path={ROUTES.DASHBOARD}
              component={SuperDashBoardContainer}
            />
            <AuthGuard
              path={ROUTES.LOAN_PORTFOLLIO_MANAGEMENT}
              component={LoanListContainer}
            />
            <AuthGuard
              path={ROUTES.LOAN_APPLICATION_DETAILS}
              component={LoanDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.VIEW_LOAN_APPLICATIONS}
              component={ViewLoanApplicationContainer}
            />
            <AuthGuard
              path={ROUTES.REPORTS}
              component={GeneralReportsContainer}
            />
            <AuthGuard
              path={ROUTES.COLLECTION_VERIFICATION}
              component={MessengerListContainer}
            />
            <AuthGuard
              path={ROUTES.VERIFY_COLLECTION}
              component={VerifyCollectionContainer}
            />
            <AuthGuard
              path={ROUTES.CONFIGURATION_DETAILS}
              component={CustomConfigurationContainer}
            />
            <AuthGuard
              path={ROUTES.EARLY_PAYMENT}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.EARLY_PAYMENT}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.EMI}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.EMI}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.PAYMENT_FREQUENCY}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.PAYMENT_FREQUENCY}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.LOAN_STATUS}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.LOAN_STATUS}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.EARLY_CANCELLATION}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.EARLY_CANCELLATION}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.MINIMART_GRADE}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.GRADING_SCALE}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.LATE_FEE}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.LATE_FEES}
                />
              )}
            />{" "}
            <AuthGuard
              path={ROUTES.LOAN_PICKUP}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.LOAN_PICKUP}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.GRACE_PERIOD}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.GRACE_PERIOD}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.CREDO_CONFIG}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.CREDO_CONFIG}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.PROVIOSION}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.PROVISION}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.CIDATA_SCHEDULER}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.CIDATA_SCHEDULER}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.USER_COMMISSION}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.COMMISSION_SCHEME}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.CREDIT_SCORE}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.CREDIT_SCORE}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.PAYMENT_DISCOUNT}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.PAYMENT_DISCOUNT}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.MTP_LADDER}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.NEW_CREDIT_SCORE}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.ANALYST}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.AUTO_ANALYSIS}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.META_MAP_CONFIGURATION}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.META_ENABLED_STATUS}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.ADMINISTRATIVE_EXPENSE_DATA}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.FILE_CONTENT}
              component={FileContentContainer}
            />
            <AuthGuard
              path={ROUTES.USER_WORK_HISTORY}
              component={UserWorkHistoryContainer}
            />
            <AuthGuard
              path={ROUTES.COMMISSION_SCHEME}
              component={ComissionContainer}
            />
            <AuthGuard
              path={ROUTES.ANALYST_LOAN_VIEW}
              component={AnalystApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.LOAN_HISTORY}
              component={LoanHistory}
            />
            <AuthGuard
              path={ROUTES.LOAN_APPROVAL_DASHBOARD}
              component={LoanApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.OPERATION_LOAN_APPROVAL_DASHBOARD}
              component={LoanApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.CI_WIRE_TRANSFER}
              component={CIWireTransferContainer}
            />
            <AuthGuard
              path={ROUTES.PRODUCT_CATALOG}
              component={ProductCatalogContainer}
            />
            <AuthGuard
              path={ROUTES.MOTORCYCLE_CATALOGUE}
              component={MotorcycleCatalogContainer}
            />
            <AuthGuard
              path={ROUTES.COMMISSION_REPORT}
              component={CommissionReportContainer}
            />
            <AuthGuard
              path={ROUTES.DETAIL_COMMISSION_REPORT}
              component={DetailCommissionReportContainer}
            />
            <AuthGuard
              path={ROUTES.CALL_NOTES}
              component={CallNotesContainer}
            />
            <AuthGuard
              path={ROUTES.HOLIDAY}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.HOLIDAY}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.REWARD_CONFIGURATION}
              component={() => (
                <ConfigurationListContainer
                  type={CONFIGURATION_TYPE.REWARDS}
                />
              )}
            />
            <AuthGuard
              path={ROUTES.MINIMART_APPROVAL_REQUESTS}
              component={MinimartApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.APPROVAL_MINIMART_HISTORY}
              component={MinimartHistoryContainer}
            />
            <AuthGuard
              path={ROUTES.APPROVAL_MINIMART_DETAILS}
              component={ApprovalMinimartContainer}
            />
            <AuthGuard
              path={ROUTES.SALES_EFFICIENCY_DASHBOARD}
              component={EfficiencyDashboardContainer}
            />
            <AuthGuard
              path={ROUTES.SINGLE_SALES_PERSON}
              component={SingleSalesPersonContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_COMPLAINT_PORTAL}
              component={ComplaintPortalContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_PROMOTIONS}
              component={PromotionsContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_PROMOTIONS_APPROVAL}
              component={PromotionsApprovalConatiner}
            />
            <AuthGuard
              path={ROUTES.MTP_PROMOTION_DETAILS}
              component={PromotionDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_PRODUCTS}
              component={ProductsContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_LOAN_REQUESTS}
              component={LoanRequestsContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_MINIMART_REQUESTS}
              component={EditMinimartRequestContainer}
            />
            <AuthGuard
              path={ROUTES.REMITTANCE_PAYMENT}
              component={RemittancePaymentContainer}
            />
            <AuthGuard
              path={ROUTES.ZONE_POSITION_MAPPING}
              component={ZonePositionMappingContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_SIGNUP_REQUEST}
              component={SignupRequestContainer}
            />
            <AuthGuard
              path={ROUTES.MTP_SIGNUP_REQUEST_DETAILS}
              component={SignupRequestDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.ZONE_POSITION_MAPPING}
              component={ZonePositionMappingContainer}
            />
            <AuthGuard
              path={ROUTES.REWARD_CATALOG}
              component={RewardCatalogContainer}
            />
            <AuthGuard
              path={ROUTES.REWARD_APPROVAL}
              component={RewardApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.REWARD_APPROVAL_DETAILS}
              component={RewardDetailsContainer}
            />
            <AuthGuard
              path={ROUTES.INVOICE_APPROVAL}
              component={InvoiceApprovalContainer}
            />
            <AuthGuard
              path={ROUTES.CLIENT_PORTAL}
              component={ClientPortalContainer}
            />
            <AuthGuard
              path={ROUTES.BANK_DETAIL_CONFIGURATION}
              component={BankConfContainer}
            />
            <AuthGuard
              path={ROUTES.MANAGE_SUPPLIER}
              component={SupplierCatalogContainer}
            />
            <AuthGuard
              path={ROUTES.MERGED_MINIMART_DETAILS}
              component={MergedMinimartsDetails}
            />
          </Switch>
        </App>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
