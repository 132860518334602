import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import CustomConfiguration from "./CustomConfiguration";
//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  COLUMNS_FOR_CONFIGURATION,
  SINGLE_VALUED_CONFIGURATION,
  DEFAULT_ROW_DATA,
  DEFAULT_EMI_AMOUNT_ADD_ROW,
  translateStatusConstantToString,
  translateStringToStatusConstant,
  SCHEDULAR_CONSTANT,
  DEFAULT_COMMISSION_SCHEME_DATA,
  CONFIGURATION_TYPE,
  CONFIGURATION_SINGLE_KEY_TYPE,
  SEARCH_FILTER_PAGES,
  CONFIGURATION_FILTER,
  DEFAULT_LOAN_STATUS_ADD_ROW,
  USER_ROLES,
  DEFAULT_ADMINISTRATIVE_EXPENSE_DATA,
} from "../../constants/appConstants";
import {
  EMI_CONF_COL_NAME,
  USER_COMMISSION,
  DAYS_ARREARS,
} from "./Constants";

//Actions
import {
  getConfigurationByIdAction,
  getActiveConfigurationAction,
  createConfigurationAction,
  updateConfigurationAction,
  getDraftedConfigurationAction,
  activateConfigurationAction,
  deleteConfigurationAction,
  getCreditScoreMappingAction,
} from "./CustomConfigurationAction";
import { setSearchFilterAction } from "../../constants/Action";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./CustomConfiguration.scss";
import {
  DEFAULT_PROVISION_ROW,
  DEFAULT_ROW_DATA2,
} from "../../constants/appConstants";

class CustomConfigurationContainer extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      id: props.configuration?.configuration?.id,
      type: props.configuration?.type,
      configuration: null,
      editedConfiguration: null,
      showLoader: false,
      readOnly: true,
      confirmationModalInformation: {
        show: false,
      },
      salesData: null,
      messengerData: null,
    };
  }

  componentDidMount() {
    if (
      this.props.loggedInUserInfo.role !== USER_ROLES.configurator &&
      this.state.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
    ) {
      this.handleGetActiveConfiguration(false);
    } else {
      this.getConfigurationById();
      (this.state.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
        this.state.type ===
        CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE) &&
        this.getCreditScoreMappings();
    }
  }

  componentWillUnmount() {
    if (
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CONFIGURATION,
        { ...CONFIGURATION_FILTER }
      );
    }
  }

  /* #endregion */

  /* #region  Events */

  getConfigurationById = () => {

    this.setState({ showLoader: true });

    let requestObj = {
      type: this.state.type,
      id: this.state.id,
    };
    getConfigurationByIdAction(requestObj, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            configuration: _.cloneDeep(Response.data.data),
            editedConfiguration: _.cloneDeep(Response.data.data),
          },
          () => {
            this.renderDataForDataTable();
          }
        );
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  getCustomizedObject = (configurationDataobj) => {
    let configValueArray = [];
    if (!Array.isArray(configurationDataobj)) {
      configValueArray.push(configurationDataobj);
    } else {
      configValueArray = configurationDataobj;
    }
    let configurationObject = [];
    configValueArray.forEach((configurationEle) => {
      let tempObj = {};
      let configKeys = Object.keys(configurationEle);
      configKeys.forEach((singleKey) => {
        if (
          singleKey ===
          CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_LOWER
        ) {
          Object.assign(tempObj, {
            daysArrears: {
              lower: configurationEle[singleKey],
              upper:
                configurationEle[
                CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_UPPER
                ],
            },
          });
        } else if (
          singleKey !==
          CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_UPPER
        ) {
          Object.assign(tempObj, {
            [singleKey]: configurationEle[singleKey],
          });
        } else if (
          singleKey === CONFIGURATION_SINGLE_KEY_TYPE.STATUS
        ) {
          Object.assign(tempObj, {
            status: translateStringToStatusConstant(
              configurationEle[CONFIGURATION_SINGLE_KEY_TYPE.STATUS]
            ),
          });
        }
      });
      configurationObject.push(tempObj);
    });
    return configurationObject;
  };

  handleAddRow = (changedConfiguration) => {
    let editedConfiguration = this.state.editedConfiguration;
    let customObj;

    if (this.state.type !== CONFIGURATION_TYPE.PROVISION) {
      customObj = this.getCustomizedObject(changedConfiguration.configuration);
      editedConfiguration.configurationObject.configuration = customObj;
    }
    else {
      customObj = changedConfiguration;
      editedConfiguration.configurationObject = customObj;
    }

    this.setState(
      {
        editedConfiguration,
      },
      () => {
        this.renderDataForDataTable();
      }
    );
  };

  updateGradeConfiguration = (changedConfiguration) => {
    let customObj = this.getCustomizedObject(changedConfiguration);
    let editedConfiguration = this.state.editedConfiguration;
    editedConfiguration.configurationObject = customObj;
    this.setState(
      {
        editedConfiguration,
      },
      () => {
        this.renderDataForDataTable();
      }
    );
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  handleActivateConfiguration = () => {
    if (this.state.editedConfiguration) {
      let configurationId = this.state.editedConfiguration.id;

      this.setState({ showLoader: true });
      activateConfigurationAction(configurationId, (response) => {
        this.setState({ showLoader: false });
        this.scrollToTop();

        if (
          response.status === OPERATION_STATUS.SUCCESS &&
          !response.error.code
        ) {
          this.setState({
            toShowToast: true,
            message: Strings("SuccessActivate"),
            level: "success",
          });
          this.handleGetActiveConfiguration(false);
        } else {
          this.setState({
            toShowToast: true,
            message: Strings(response.error.message),
            level: "error",
          });
        }
      });
    }
  };

  handleGetActiveConfiguration = (message = true) => {
    let requestData =
      this.props.loggedInUserInfo.role !== USER_ROLES.configurator &&
        this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
        ? CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
        : this.state.type;

    getActiveConfigurationAction(requestData, (response) => {
      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        !response.error.code
      ) {
        if (!message) {
          this.setState(
            {
              configuration: _.cloneDeep(response.data.data),
              editedConfiguration: _.cloneDeep(response.data.data),
            },
            () => {
              this.renderDataForDataTable();
            }
          );
        } else {
          this.setState(
            {
              configuration: _.cloneDeep(response.data.data),
              editedConfiguration: _.cloneDeep(response.data.data),
              toShowToast: true,
              message: Strings("SuccessGetActive"),
              level: "success",

            },
            () => {
              this.renderDataForDataTable();
            }
          );
        }
      } else {
        this.setState({
          toShowToast: true,
          message: Strings(response.error.message),
          level: "error",
        });
      }
      this.scrollToTop();
    });
  };

  handleSaveAsDraftCreateConfig = (requestData) => {
    this.setState({ showLoader: true });
    createConfigurationAction(requestData, (response) => {
      this.setState({ showLoader: false });
      this.scrollToTop();

      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        !response.error.code
      ) {
        this.setState({

          toShowToast: true,
          message: Strings("SuccessSaveAsDraft"),
          level: "success",

        });
        this.handleGetDraftedConfiguration(false, false);
      } else {
        this.setState({
          toShowToast: true,
          message: Strings(response.error.message),
          level: "error",
        });
      }
    });
  };

  handleSaveAsDraftUpdateConfig = (requestData) => {
    this.setState({ showLoader: true });

    updateConfigurationAction(requestData, (responseData) => {
      this.setState({ showLoader: false });
      this.scrollToTop();

      if (
        responseData.status === OPERATION_STATUS.SUCCESS &&
        !responseData.error.code
      ) {
        this.setState({
          toShowToast: true,
          message: Strings("SuccessSaveAsDraft"),
          level: "success",
        });
        this.handleGetDraftedConfiguration(false, false);
      } else {
        this.setState({
          toShowToast: true,
          message: Strings(responseData.error.message),
          level: "error",
        });
      }
    });
  };

  handleGetDraftedConfiguration = (message = true) => {
    let requestData = this.state.type;
    getDraftedConfigurationAction(requestData, (response) => {
      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        !response.error.code
      ) {
        if (!message) {
          this.setState(
            {
              configuration: _.cloneDeep(response.data.data),
              editedConfiguration: _.cloneDeep(response.data.data),
            },
            () => {
              this.renderDataForDataTable();
            }
          );
        } else {
          this.setState(
            {
              id: response.data.data.id,
              configuration: _.cloneDeep(response.data.data),
              editedConfiguration: _.cloneDeep(response.data.data),
              successMessage: Strings("SuccessGetDrafted"),
              notificationSuccess: true,
            },
            () => {
              this.renderDataForDataTable();
            }
          );
        }
      } else {
        this.setState({
          toShowToast: true,
          message: Strings(response.error.message),
          level: "error",
        });
      }
      this.scrollToTop();
    });
  };

  //reset data of configuration
  handleResetData = () => {
    this.resetNotification();
    this.getConfigurationById();
    this.setState(
      {
        editedConfiguration: this.state.configuration,
        successMessage: Strings("SuccessReset"),
        notificationSuccess: true,
      },
      () => {
        this.renderDataForDataTable();
        this.scrollToTop();
      }
    );
  };

  //delete drafted configuration
  handleDeleteDraftedConfiguration = () => {
    let requestData = this.state.type;

    if (this.state.configuration) {
      this.setState({ showLoader: true });
      deleteConfigurationAction(requestData, (response) => {
        this.setState({ showLoader: false });

        if (
          response.status === OPERATION_STATUS.SUCCESS &&
          !response.error.code
        ) {
          this.setState({
            toShowToast: true,
            message: Strings("SuccessDeleteDraft"),
            level: "success",
          });
          this.handleGetActiveConfiguration(false);
        } else {
          this.setState({
            toShowToast: true,
            message: Strings(response.error.message),
            level: "error",
          });
        }
      });
    }
  };

  /* #endregion */

  /* #region  Utility */

  resetNotification = () => {
    this.setState({
      showError: false,
      errorMessage: "",
      message: "",
      toShowToast: false,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  //render data for table
  renderDataForDataTable = () => {
    if (this.state.configuration) {
      if (
        SINGLE_VALUED_CONFIGURATION.includes(
          this.props.configuration.type
        )
      ) {
        this.renderDataTableForSingleValued();
      } else if (
        this.props.configuration.type === SCHEDULAR_CONSTANT.CI_DATA
      ) {
        this.renderDataForSchedualrConfiguration();
      } else {
        if (
          this.state.type !== CONFIGURATION_TYPE.CREDIT_SCORE &&
          this.state.type !==
          CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE &&
          this.state.type !== CONFIGURATION_TYPE.HOLIDAY &&
          this.state.type !== CONFIGURATION_TYPE.AUTO_ANALYSIS &&
          this.state.type !== CONFIGURATION_TYPE.REWARDS &&
          this.state.type !== CONFIGURATION_TYPE.EMI &&
          this.state.type !== CONFIGURATION_TYPE.PAYMENT_FREQUENCY &&
          this.state.type !== CONFIGURATION_TYPE.LATE_FEES
        )
          this.renderDataTableForMultiValued();
      }
    }
  };

  //render data for single valued configurations-"GRACE_PERIOD","LATE_FEES",
  //"LOAN_PICKUP","EARLY_CANCELLATION","EARLY_PAYMENT"
  renderDataTableForSingleValued = () => {
    let configurationData =
      this.state.configuration.configurationObject;
    let configValueArray = [];

    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }

    let configuratioObjKeys = Object.keys(
      this.state.configuration.configurationObject
    );

    let dataForTable = {};
    let dataRowsForTable = [];

    configValueArray.forEach((config) => {
      configuratioObjKeys.forEach((key) => {
        dataRowsForTable.push({
          [key]: config[key],
        });
      });
    });

    dataForTable = {
      columns:
        COLUMNS_FOR_CONFIGURATION[this.props.configuration.type],
      dataRows: dataRowsForTable,
    };
    this.setState({
      dataForTable: dataForTable,
    });
  };

  //render data for EMI multivalued configuration
  renderDataForEMI = () => {
    let configurationData =
      this.state.editedConfiguration.configurationObject
        .configuration;
    let configValueArray = [];
    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }
    let dataForTable = {};
    let dataRowsForTable = [];
    configValueArray.length > 0 &&
      configValueArray.forEach((config) => {
        dataForTable = {
          DEFAULT_ROW: DEFAULT_EMI_AMOUNT_ADD_ROW,
          columns:
            COLUMNS_FOR_CONFIGURATION[
            this.props.configuration.type + EMI_CONF_COL_NAME.AMOUNT
            ],
          dataRows: config.emiConfig,
        };
        dataRowsForTable.push(dataForTable);
      });

    dataForTable = dataRowsForTable;
    this.setState({
      dataForTable,
    });
  };

  handleTermChange = (event, index) => {
    let terms = [...this.state.terms];
    terms[index] = event.target.value;
    this.setState({ terms });
  };

  renderDataForLoanStatus = () => {
    let configurationData =
      this.state.editedConfiguration.configurationObject
        .configuration;

    let configValueArray = [];

    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }

    let dataRowsForTable = [];
    let dataForTable = {};

    configValueArray.length > 0 &&
      configValueArray.forEach((config) => {
        dataForTable = {
          DEFAULT_ROW: DEFAULT_LOAN_STATUS_ADD_ROW,
          columns: COLUMNS_FOR_CONFIGURATION["LOAN_STATUS"],
          dataRows: config,
        };
        dataRowsForTable.push(dataForTable);
      });

    this.setState({
      dataForTable: dataRowsForTable,
    });
  };

  //render data for COMMISSION_SCHEME multivalued configuration
  renderDataForCommissionScheme = () => {
    let configurationData =
      this.state.editedConfiguration.configurationObject
        .commissionData;
    let configValueArray = [];
    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }
    let dataForTable = {};
    let dataRowsForTable = [];
    configValueArray.length > 0 &&
      configValueArray.forEach((config) => {
        dataForTable = {
          DEFAULT_ROW: DEFAULT_COMMISSION_SCHEME_DATA,
          columns: COLUMNS_FOR_CONFIGURATION[USER_COMMISSION],
          dataRows: config.commission,
        };
        dataRowsForTable.push(dataForTable);
      });

    dataForTable = dataRowsForTable;
    this.setState({
      dataForTable,
    });
  };
  /// Render Data For Payment Discount
  renderDataForPaymentDiscount = () => {
    let configurationData =
      this.state.editedConfiguration.configurationObject;
    let configValueArray = [];
    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }
    let dataForTable = {};
    configValueArray.length > 0 &&
      configValueArray.forEach((config) => {
        dataForTable = {
          DEFAULT_ROW: DEFAULT_COMMISSION_SCHEME_DATA,
          columns:
            COLUMNS_FOR_CONFIGURATION[
            CONFIGURATION_TYPE.PAYMENT_DISCOUNT
            ],
          dataRows: config.configuration,
        };
      });

    this.setState({
      dataForTable,
    });
  };

  renderDataForOtherMultiValuedConfiguration = () => {
    //NOSONAR
    let configurationData =
      this.state.type === CONFIGURATION_TYPE.PROVISION
        ? this.state.editedConfiguration.configurationObject
        : this.state.editedConfiguration.configurationObject
          .configuration;

    let configValueArray = [];
    if (!Array.isArray(configurationData)) {
      configValueArray.push(configurationData);
    } else {
      configValueArray = configurationData;
    }

    let dataForTable = {};
    let dataRowsForTable = [];
    let dataRowsForTable2 = [];

    if (this.state.type === CONFIGURATION_TYPE.PROVISION) {
      configurationData.terms.forEach((provisionData) => {
        let tempObj = {
          term:
            provisionData.term === "-1" || provisionData.term === -1
              ? "Other"
              : provisionData.term,
        };
        provisionData.provisions.forEach((provision) => {
          Object.assign(tempObj, {
            [provision.provisionType]: provision.installmentDelayedBy,
          });
        });
        dataRowsForTable.push(tempObj);
      });
      dataRowsForTable2 = configurationData.amounts.map((amount) => {
        return {
          provisionType: amount.provisionType,
          percentWithCollateral: amount.percentWithCollateral,
          percentWithoutCollateral: amount.percentWithoutCollateral,
        };
      });
    } else {
      let configuratioObjKeys = Object.keys(
        this.state.editedConfiguration.configurationObject
          .configuration[0]
      );
      configValueArray.length > 0 &&
        configValueArray.forEach((config) => {
          let tempObj = {};
          configuratioObjKeys.forEach((key) => {
            let SubKeys = Object.keys(config[DAYS_ARREARS]);
            SubKeys.forEach((subKey) => {
              if (key === DAYS_ARREARS) {
                Object.assign(tempObj, {
                  [key + "_" + subKey]: config[key][subKey],
                });
              } else if (
                key === CONFIGURATION_SINGLE_KEY_TYPE.STATUS
              ) {
                Object.assign(tempObj, {
                  [key]: translateStatusConstantToString(config[key]),
                });
              } else {
                Object.assign(tempObj, { [key]: config[key] });
              }
            });
          });
          dataRowsForTable.push(tempObj);
        });
    }

    dataForTable = {
      DEFAULT_ROW:
        this.state.type === CONFIGURATION_TYPE.PROVISION
          ? DEFAULT_PROVISION_ROW
          : DEFAULT_ROW_DATA,
      columns:
        COLUMNS_FOR_CONFIGURATION[this.props.configuration.type],
      dataRows: dataRowsForTable,
    };
    let dataForTable2 = {
      DEFAULT_ROW: DEFAULT_ROW_DATA2,
      columns: COLUMNS_FOR_CONFIGURATION["SECOND_PROVISION"],
      dataRows: dataRowsForTable2,
    };
    this.setState({
      dataForTable,
      dataForTable2,
    });
  };

  //render data for multi valued configurations-EMI, COMMISSION_SCHEME, GRADING_SCALE, PROVISION
  renderDataTableForMultiValued = () => {
    if (this.state.editedConfiguration.configurationObject !== null) {
      if (this.state.type === CONFIGURATION_TYPE.EMI) {
        this.renderDataForEMI();
      } else if (
        this.state.type === CONFIGURATION_TYPE.COMMISSION_SCHEME
      ) {
        this.renderDataForCommissionScheme();
      } else if (
        this.state.type === CONFIGURATION_TYPE.PAYMENT_DISCOUNT
      ) {
        this.renderDataForPaymentDiscount();
      } else if (this.state.type === CONFIGURATION_TYPE.LOAN_STATUS) {
        this.renderDataForLoanStatus();
      } else if (
        this.state.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
      ) {
        this.renderCreditLimitConfiguration();
      } else if (
        this.state.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA
      ) {
        this.renderAdministrativeExpenseConfiguration();
      } else {
        this.renderDataForOtherMultiValuedConfiguration();
      }
    }
  };

  renderAdministrativeExpenseConfiguration = () => {
    let configurationData =
      this.state.editedConfiguration.configurationObject
        .configuration;
    let dataForTable = {};
    dataForTable = {
      DEFAULT_ROW: DEFAULT_ADMINISTRATIVE_EXPENSE_DATA,
      columns:
        COLUMNS_FOR_CONFIGURATION[
        CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA
        ],
      dataRows: configurationData,
    };

    this.setState({
      dataForTable,
    });
  }

  renderCreditLimitConfiguration = () => {
    this.setState({
      dataForTable:
        this.state.editedConfiguration.configurationObject,
    });
  };

  renderDataForSchedualrConfiguration = () => {
    let configurationData =
      this.state.configuration.configurationObject;
    let date = new Date(configurationData.time);
    let dataForTable = {};
    let dataRowsForTable = [];
    dataRowsForTable.push({
      hours: date.getHours() % 12,
      minutes: date.getMinutes(),
      period: date.getHours() > 12 ? "PM" : "AM",
    });

    dataForTable = {
      columns:
        COLUMNS_FOR_CONFIGURATION[this.props.configuration.type],
      dataRows: dataRowsForTable,
    };

    this.setState({
      dataForTable: dataForTable,
    });
  };

  getCreditScoreMappings = () => {
    this.setState({ showLoader: true });
    getCreditScoreMappingAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          creditscoreMapping: response.data.data.mappings,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  handleAddType = (name) => {
    let currentConfiguration = _.cloneDeep(
      this.state.editedConfiguration
    );
    currentConfiguration.configurationObject.type.push({
      name,
      weight: 0,
      subtype: [],
    });
    this.setState({ editedConfiguration: currentConfiguration });
  };

  editSubtypeData = (
    typeIndex,
    subtypeIndex,
    subtypeName,
    subtypeRange,
    mapping,
    template
  ) => {
    let currentConfiguration = _.cloneDeep(
      this.state.editedConfiguration
    );

    currentConfiguration.configurationObject.type[typeIndex].subType[
      subtypeIndex
    ].data = subtypeRange;
    currentConfiguration.configurationObject.type[typeIndex].subType[
      subtypeIndex
    ].name = subtypeName;
    currentConfiguration.configurationObject.type[typeIndex].subType[
      subtypeIndex
    ].mapping = mapping;
    currentConfiguration.configurationObject.type[typeIndex].subType[
      subtypeIndex
    ].template = template;

    this.setState(
      {
        editedConfiguration: currentConfiguration,
      },
      () => {
        this.resetNotification();
      }
    );
  };

  /* This method accepts type index AND / OR subType index and sets value at given object's
     key
  */
  handleChangeValue = (typeIndex, subTypeIndex, key, value) => {
    let currentConfiguration = _.cloneDeep(
      this.state.editedConfiguration
    );
    if (subTypeIndex === null) {
      currentConfiguration.configurationObject.type[typeIndex][key] =
        value;
    } else {
      currentConfiguration.configurationObject.type[
        typeIndex
      ].subType[subTypeIndex][key] = value;
    }
    this.setState(
      {
        editedConfiguration: currentConfiguration,
      },
      () => {
        this.resetNotification();
      }
    );
  };

  //validations for credit score configurations subtype data
  validateSubtypeData = (type) => {
    let subTypeTotalWeight = 0;
    let subTypeValid = true;
    if (type.subType && type.subType.length > 0) {
      type.subType.map((subType) => {
        if (subType.weight > 0 && subType.weight <= 100) {
          subTypeTotalWeight += subType.weight;
          return subTypeTotalWeight;
        } else {
          subTypeValid = false;
          return subTypeValid;
        }
      });
    } else {
      subTypeValid = false;
      return subTypeValid;
    }
    return subTypeTotalWeight <= 100 && subTypeValid;
  };

  //validations for credit score configurations type data
  validateTypeData = () => {
    let typeTotalWeight = 0;
    let subTypeValid = true;
    let currentData = _.cloneDeep(this.state.editedConfiguration);
    if (
      currentData.configurationObject.type &&
      currentData.configurationObject.type.length > 0
    ) {
      currentData.configurationObject.type.map(
        (configurationType) => {
          if (
            this.validateSubtypeData(configurationType) === true &&
            configurationType.weight > 0 &&
            configurationType.weight <= 100
          ) {
            typeTotalWeight += configurationType.weight;
            return typeTotalWeight;
          } else {
            subTypeValid = false;
            return subTypeValid;
          }
        }
      );
    } else {
      subTypeValid = false;
      return subTypeValid;
    }
    return typeTotalWeight <= 100 && subTypeValid;
  };
  /* #endregion */

  render() {
    return (
      <div className='customConfiguration ListContainer'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.message}
            userNotificationObj={{
              message: this.state.message,
              level: this.state.level,
            }}
          />
        )}

        {/* render custom confiuration */}
        {this.state.editedConfiguration !== null && (
          <CustomConfiguration
            configuration={this.state.configuration}
            type={this.state.type}
            configurationData={this.state.editedConfiguration}
            handleAddRow={this.handleAddRow}
            resetNotification={this.resetNotification}
            renderDataForDataTable={this.renderDataForDataTable}
            dataForTable={this.state.dataForTable}
            dataForTable2={this.state.dataForTable2}
            handleBackButtonClick={this.handleBackButtonClick}
            handleActivateConfiguration={
              this.handleActivateConfiguration
            }
            handleSaveAsDraftCreateConfig={
              this.handleSaveAsDraftCreateConfig
            }
            handleSaveAsDraftUpdateConfig={
              this.handleSaveAsDraftUpdateConfig
            }
            handleResetData={this.handleResetData}
            handleDeleteDraftedConfiguration={
              this.handleDeleteDraftedConfiguration
            }
            creditscoreMapping={this.state.creditscoreMapping}
            handleAddType={this.handleAddType}
            handleDeleteType={this.handleDeleteType}
            handleChangeValue={this.handleChangeValue}
            editSubtypeData={this.editSubtypeData}
            confirmationModalInformation={
              this.state.confirmationModalInformation
            }
            validateTypeData={this.validateTypeData}
            updateGradeConfiguration={this.updateGradeConfiguration}
            terms={this.state.terms}
            handleTermChange={this.handleTermChange}
            loggedInUserInfo={this.props.loggedInUserInfo}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configuration:
    state.customConfigurationReducer.EditConfigurationData,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSearchFilterAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomConfigurationContainer);
