import React from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Strings } from "../../resources/i18n/i18n";
import { BANK_DETAILS_COLUMNS } from './Constants';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckboxComponent from '../CustomUIComponents/CheckboxComponent/CheckboxComponent';
import BankModal from './BankModal';
import TextFieldComponent from '../CustomUIComponents/TextFieldComponent/TextFieldComponent';
import InformativeModal from '../CustomUIComponents/InformativeModal/InformativeModal';
import "./BankConf.scss"
class BankDetails extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            bank: {
                bn: "",
                ais: [],
                ia: true
            },
            showConfirmModal: false,
            errorMessage: ""
        }
    }

    clearBankDetails = () => {
        this.setState({
            bank: {
                bn: "",
                ais: [],
                ia: true
            },
            errorMessage: ""
        })

    }

    renderTableHeader = (columns, StyledTableCell) => {
        return <TableRow>
            {columns.length > 0 &&
                columns.map((column) => (
                    <StyledTableCell
                        key={column.key}
                        className={"text-center px-2 header"}
                        title={column.name}
                    >
                        <div>{column.name}</div>
                    </StyledTableCell>
                ))}
        </TableRow>
    }

    selectAccountStatus = (id) => {
        let newBankAccountIds = [...this.state.bank.ais]
        if (newBankAccountIds.includes(id)) {
            let index = newBankAccountIds.findIndex((i) => i === id)
            newBankAccountIds.splice(index, 1)
        } else {
            newBankAccountIds.push(id);
        }
        this.setState({
            bank: {
                ...this.state.bank,
                ais: newBankAccountIds
            },
            errorMessage: "",
        })
    }

    handleChange = (event) => {
        this.setState({
            bank: {
                ...this.state.bank,
                bn: event.target.value.toUpperCase(),
            },
            errorMessage: ""

        })
    }

    handleUpdate = (row) => {
        let ais = []
        row.ad.forEach((account) => {
            ais.push(account.ai)
        })
        this.setState({
            bank: {
                ...this.state.bank,
                bi: row.bi,
                bn: row.bn,
                ia: row.ia,
                ais,
            }
        }, () => {
            this.props.toggleModal();
        })
    }

    changeBankStatus = (bank) => {
        this.setState({
            bank,
            showConfirmModal: true,
            errorMessage: ""
        })
    }

    closeConfirmationModal = () => {
        this.setState({
            showConfirmModal: false,
            bank: {},
            errorMessage: ""
        })
    }

    renderConfirmationModal = () => {
        let accountInfo = "";
        let commonAccountInfo1 = this.state.bank.ia === true
            ? Strings("disableWarningOfAssociatedBanks")
            : Strings("enableWarningOfAssociatedBanks");
        let commonAccountInfo2 = this.state.bank.ia === true
            ? Strings("confimationWarningToDisableAccountType")
            : Strings("confimationWarningToEnableAccountType");
        accountInfo = `${commonAccountInfo1}\n${commonAccountInfo2}`

        return <InformativeModal
            openModal={this.state.showConfirmModal}
            modalHeader={Strings("bankInfo")}
            data={accountInfo}
            handleOkClick={() =>
                this.props.editBankState(this.state.bank, this.closeConfirmationModal)}
            closeModal={this.closeConfirmationModal}
        />
    };


    renderTableData = (row, column) => {
        if (column.key === "action") {
            return <div onClick={this.props.readOnly ? () => { } : () => this.changeBankStatus(row)}>
                <Form.Check
                    checked={row.ia}
                    type="switch"
                    id={row.bi}
                    title={row.ia === true ? Strings("activeBank") : Strings("inactiveBank")}
                    disabled={this.props.readOnly}
                />
            </div>
        }
        else if (column.key === "ad") {
            let at = [];
            row[column.key].forEach((account) => {
                at.push(account.at)
            })
            return <div className='d-flex justify-content-between marginForAccountTypeColumn'>
                <div>{at.length !== 0 ? at.join(', ') : Strings("NA")}</div>
                <div onClick={this.props.readOnly ? () => { } : () => {
                    this.handleUpdate(row);
                }} className='url textColor cursorPointer'
                    title={Strings("editAccountType")}
                    disabled={this.props.readOnly}>
                    {Strings("Edit")}
                </div>
            </div>

        }
        else {
            return Strings(row[column.key]);
        }
    }


    renderTableBody = (rows, columns, StyledTableRow, StyledTableCell) => {
        return (
            rows.map((row) => (
                <StyledTableRow
                    key={row.identificationNumber}
                    hover
                >
                    {columns.map((column) => (
                        <StyledTableCell
                            key={column.key}
                            className={column.key === "ad" ? "px-2 text-start" : "px-2 text-center"}
                            component='th'
                            scope='row'
                            style={{ minWidth: column.minWidth, width: column.width }}
                        >
                            <div>
                                {this.renderTableData(row, column)}
                            </div>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            ))
        )
    }

    renderCustomDataGrid = (columns, rows) => {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head} `]: {
                backgroundColor: theme.palette.divider,
                color: theme.palette.common.black,
            },
            [`&.${tableCellClasses.body} `]: {
                fontSize: 14,
            },
        }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));

        return (
            <div className='d-flex justify-content-center mx-3 mb-3'>
                <TableContainer
                    component={Paper}
                    className='d-flex justify-content-center'
                >
                    <Table aria-label='customized table'>
                        <TableHead>
                            {this.renderTableHeader(columns, StyledTableCell)}

                        </TableHead>
                        <TableBody>
                            {this.renderTableBody(rows, columns, StyledTableRow, StyledTableCell)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    renderModalData = () => {

        return (
            <div>
                <div className="errors ps-0 mb-0">{this.state.errorMessage}</div>
                <Row className='subHeaderApplication'><label className='p-0 mb-2'>{Strings("bankName")}</label>
                    <TextFieldComponent
                        placeholder={Strings("bankName")}
                        handleChange={this.handleChange}
                        value={this.state.bank.bn}
                        name="bn"
                        maxLength={45}
                        disabled={this.state.bank.bi}
                    />
                </Row>
                <Row className='subHeaderApplication mt-3'>
                    <label className='p-0 mb-2'>{Strings("accountType")}</label>
                </Row>
                <Row>
                    {this.props.activeAccountTypes.length !== 0 ? this.props.activeAccountTypes.map((accountType) => {
                        return <Col md={6} className='mt-2' key={accountType.ai}>
                            <CheckboxComponent
                                onChange={() => this.selectAccountStatus(accountType.ai)}
                                checked={this.state.bank.ais.includes(accountType.ai)}
                                key={accountType.ai}
                                label={accountType.at}
                            />
                        </Col>
                    }) : <div className="noRecordsFound">{Strings("NoRecordsFound")}</div>}
                </Row>
            </div >
        )
    }

    handleBankModalOkClick = () => {
        let existingBanks = this.props.data.map(bank => bank.bn)
        let errorMessage = "";
        if (this.state.bank.bn === "" || this.state.bank.ais.length === 0) {
            errorMessage = Strings("EmptyFieldsError")
        } else if (this.state.bank.bn.length < 3) {
            errorMessage = Strings("minimumThreeCharacterLength")
        } else {
            if (this.state.bank.bi) {
                this.props.handleEditBank(this.state.bank)
            } else {
                if (existingBanks.includes(this.state.bank.bn)) {
                    errorMessage = Strings("REQUEST_ALREADY_EXISTS")
                } else {
                    this.props.handleAddBank(this.state.bank)
                }
            }
        }
        this.setState({
            errorMessage
        })

    }


    renderModal = () => {
        return <BankModal
            openModal={this.props.showModal}
            modalHeader={Strings("bankDetails")}
            data={this.renderModalData()}
            handleOkClick={this.handleBankModalOkClick}
            closeModal={this.props.toggleModal}
        />
    }

    render() {
        return this.props.data &&
            <>
                {this.state.showConfirmModal && this.renderConfirmationModal()}
                {this.props.showModal && this.renderModal()}
                <div className='mt-3 bankConf'>
                    {this.props.data.length !== 0 && this.renderCustomDataGrid(BANK_DETAILS_COLUMNS, this.props.data)}
                    {!this.props.readOnly && <Row className='py-2'>
                        <Col>
                            <Button
                                className='buttonBackground mx-1 pull-right'
                                onClick={() => {
                                    this.props.toggleModal();
                                    this.clearBankDetails();
                                }}
                            >
                                {Strings("addBank")}
                            </Button>
                        </Col>
                    </Row>}
                </div>
            </>
    }
}

export default BankDetails;
