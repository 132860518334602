import React from "react";
import { Col, Row } from "react-bootstrap";
import Defaultminimart from "../../resources/images/ic_mini_market_new.svg";
import { Strings } from "../../resources/i18n/i18n";
import { ASSOCIATION_USERS, MINIMART_DETAILS } from "./Constants";
import Utility from "../../utils/Utility";
import moment from "moment";
import { CONFIGURATION_DATE_TIME_FORMAT } from "../../constants/appConstants";

export default class MinimartInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minimart: this.props.minimart.mergeMinimartDetails.minimartDetails,
            creditLimit: this.props.minimart.mergeMinimartDetails.creditLimit,
            association: this.props.minimart.mergeMinimartDetails.association,
            disbursedLoanProcessIds: this.props.minimart.mergedMinimartLoans.disbursedLoanProcessIds,
            rejectedApplicationIds: this.props.minimart.mergedMinimartLoans.rejectedApplicationIds,
            inprocessApplicationIds: this.props.minimart.mergedMinimartLoans.inprocessApplicationIds,
            mergedDate: this.props.minimart.mergedDate,
            userName: this.props.minimart.userName,
        }
    }

    renderMinimartDetailValues = (minimart) => {
        let values = this.state.minimart[minimart.key];
        if (!this.state.minimart[minimart.key]) {
            values = Strings("NA");
        } else if (minimart.key === "collectionDays") {
            values = Utility.convertCollectionDaysToSpanish(this.state.minimart[minimart.key].split(","));
        }
        return values;
    }

    renderColumnsForGeoLocation = (data) => {
        return data.map((location) => {
            return (
                <Col
                    md={4}
                    className='px-0 d-flex align-items-center pb-1'
                    key={location}
                >
                    <span className='titleText text-break'>
                        {Strings(location)}
                    </span>
                    &nbsp;:&nbsp;
                    <span className='pe-0 detailText'>
                        {this.state.minimart[location]
                            ? this.state.minimart[location]
                            : Strings("NA")}
                    </span>{" "}
                </Col>
            )
        })
    }

    renderMinimartDetails = () => {
        let minimartDetails = MINIMART_DETAILS;
        return (
            <>
                <Row className='underlineText pb-1 mb-3'>
                    <Col
                        md={4}
                        className='p-0 flexibleWraaper'
                    >
                        <span className='titleText'>{Strings("mergingApprovedBy")}</span>
                        &nbsp;:&nbsp;
                        <span className='colorText'>
                            {this.state.userName}
                        </span>
                    </Col>
                    <Col md={4} className='ps-0'>
                        <div className='contentWidth'>
                            <span className='titleText'>
                                {Strings("mergingApprovedOn")}
                            </span>
                            &nbsp;:&nbsp;
                            <label className='noMargin detailText'>
                                {moment(
                                    this.state.mergedDate
                                ).format(CONFIGURATION_DATE_TIME_FORMAT)}
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row className='underlineText pb-1'>
                    {Strings("minimartDetailsText")}
                </Row>
                <Row>
                    {minimartDetails.map((minimart) => {
                        return (
                            <Col
                                md={4}
                                className='px-0 d-flex align-items-center pb-2'
                                key={minimart.key}
                            >
                                <span
                                    className='titleText text-wrap'
                                >
                                    {Strings(minimart.label)}
                                </span>
                                &nbsp;:&nbsp;
                                <>
                                    <span className='pe-0 detailText'>
                                        {this.renderMinimartDetailValues(minimart)}
                                    </span>{" "}
                                </>
                            </Col>
                        )
                    })}
                </Row>
            </>
        )
    }

    renderCreditLimit = () => {
        return (
            <>
                <Row className='underlineText pt-3 p-0'>
                    {Strings("creditLimit")}
                </Row>
                <Row>
                    <Col md={4} className='ps-0'>
                        <div className='contentWidth'>
                            <span className='titleText'>
                                {Strings("creditLimitTooltip") + " ($)"}
                            </span>
                            &nbsp;:&nbsp;
                            <label className='noMargin detailText'>
                                {Utility.getCurrencyRepresentationOfAmount(this.state.creditLimit.usedCreditLimit)}
                                &nbsp;/&nbsp;
                                {Utility.getCurrencyRepresentationOfAmount(this.state.creditLimit.creditLimit)}
                                {this.state.creditLimit.usedCreditLimit > this.state.creditLimit.creditLimit &&
                                    <span className="errorMessage">
                                        {` (${Utility.getCurrencyRepresentationOfAmount(
                                            this.state.creditLimit.creditLimit - this.state.creditLimit.usedCreditLimit
                                        )})`}
                                    </span>
                                }
                            </label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    renderGeoLocation = () => {
        let geoLocation = ["latitude", "longitude"];

        return (
            <>
                <Row className='underlineText pt-3 pb-2'>
                    {Strings("GeoLocation")}
                </Row>
                <Row>
                    {this.renderColumnsForGeoLocation(geoLocation)}
                </Row>
            </>
        )
    }

    renderAssociationUser = () => {
        return (
            <>
                <Row className='underlineText pt-3'>
                    {Strings("Association")}
                </Row>
                <Row>
                    {ASSOCIATION_USERS.map((user) => {
                        return (
                            <Col
                                md={4}
                                className='px-0 d-flex align-items-center'
                                key={user.key}
                            >
                                <span className='titleText text-wrap'>{Strings(user.label)}</span>
                                &nbsp;:&nbsp;
                                <span className='pe-0 colorText'>
                                    {this.state.association[user.key]
                                        ? this.state.association[user.key]
                                        : Strings("NA")}
                                </span>
                            </Col>
                        )
                    })}
                </Row>
            </>
        )
    }

    renderLoanDetails = () => {
        return (
            <>
                <Row className='underlineText pt-3 p-0'>
                    {Strings("loanDetails")}
                </Row>

                <Col md={4} className='ps-0'>
                    <div className='titleText' style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
                        <span className='titleText'>
                            {Strings("disbursedLoans")}
                        </span>
                        &nbsp;:&nbsp;
                        {this.state.disbursedLoanProcessIds.length > 0 ? this.state.disbursedLoanProcessIds.map((loan) => {
                            console.log('loan: ', loan);
                            return (
                                <span
                                    key={loan}
                                    title={loan}
                                    className={`url textColor cursorPointer me-2`}
                                    onClick={() => { this.props.setLoanDetailsID(loan) }}
                                >
                                    {loan}
                                </span>
                            )
                        }) :
                            <span className='pe-0 detailText'>
                                {Strings("NA")}
                            </span>
                        }
                    </div>
                </Col>

                <Col md={4} className='ps-0'>
                    <div className='titleText' style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
                        <span className='titleText'>
                            {Strings("rejectedLoans")}
                        </span>
                        &nbsp;:&nbsp;
                        {this.state.rejectedApplicationIds.length > 0 ? this.state.rejectedApplicationIds.map((loan) => {
                            console.log('loan: ', loan);
                            return (
                                <span
                                    key={loan}
                                    title={loan}
                                    className={`url textColor cursorPointer me-2`}
                                    onClick={() => { this.props.setLoanDetailsID(loan) }}
                                >
                                    {loan}
                                </span>
                            )
                        }) :
                            <span className='pe-0 detailText'>
                                {Strings("NA")}
                            </span>
                        }
                    </div>
                </Col>

                <Col md={4} className='ps-0'>
                    <div className='titleText' style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
                        <span className='titleText'>
                            {Strings("inprocessLoans")}
                        </span>
                        &nbsp;:&nbsp;
                        {this.state.inprocessApplicationIds.length > 0 ? this.state.inprocessApplicationIds.map((loan) => {
                            console.log('loan: ', loan);
                            return (
                                <span
                                    key={loan}
                                    title={loan}
                                    className={`url textColor cursorPointer me-2`}
                                    onClick={() => { this.props.setLoanDetailsID(loan) }}
                                >
                                    {loan}
                                </span>
                            )
                        }) :
                            <span className='pe-0 detailText'>
                                {Strings("NA")}
                            </span>
                        }
                    </div>
                </Col>

            </>
        )
    }

    render() {
        return (
            <Row className='primaryDetails'>
                <Col md={1} className='p-0'>
                    <div className='imageplaceholder justify-center display-flex w-auto'>
                        <img src={Defaultminimart} alt='minimart' />
                    </div>
                    <div className='gradeText pt-2 justify-center display-flex'>
                        <span className={"me-1"}>
                            {Strings("Grade")}{" "}
                            {this.state.minimart.category}
                        </span>
                    </div>
                    <div
                        className='gradeText justify-center display-flex'
                        title={Strings("MinimartStatus")}>
                        <span title={Strings("MinimartStatus")}>
                            {Strings(this.state.minimart.status)}
                        </span>
                    </div>
                    <div
                        className='statusText justify-center display-flex'
                        title={Strings("MinimartType")}
                    >
                        <span title={Strings("MinimartType")}>
                            {Strings(this.state.minimart.type)}
                        </span>
                    </div>
                    <div
                        className='statusText text-center mt-2 pe-2'
                        title={Strings("gradeResetConfirmation")}
                    >
                        <span>
                            {this.state.minimart.resetGrade
                                ? Strings("gradeResetActive")
                                : Strings("gradeResetInactive")}
                        </span>
                    </div>
                </Col>
                <Col md={11}>
                    {this.renderMinimartDetails()}
                    {this.renderCreditLimit()}
                    {this.renderGeoLocation()}
                    {this.renderAssociationUser()}
                    {this.renderLoanDetails()}
                </Col>
            </Row>
        )
    }
}