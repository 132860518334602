import React from "react";
import { Row, Col } from "react-bootstrap";
import { Strings } from "../../../resources/i18n/i18n";
import PieChartComponent from "../PieChartComponent/PieChartComponent";
import BarGraphComponent from "../BarGraphComponent/BarGraphComponent";
import {
  PIE_CHART_COLORS,
  INVOICE_LEGENDS,
  PIE_CHART_COLORS_CONST,
} from "../../../constants/appConstants";
import Utility from "../../../utils/Utility";

import "./InvoiceCharts.scss";

class InvoiceCharts extends React.Component {
  renderLegend = (color, legendName, data) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return (
      <Row className='legend w-100' key={Math.random()}>
        <Col xs="auto" className='p-0'>
          <div
            className='legendCommissionColorDiv'
            style={colorStyle}
          />
        </Col>
        <Col md={6} className='ps-1'>
          <div className='branchNameInLegends'>
            {Strings(legendName)}
          </div>
        </Col>
        <Col className='branchNameInLegends fw-bold p-0'>
          {data}
        </Col>
      </Row>
    );
  };

  renderPieChartComponent = () => {
    return (
      <PieChartComponent
        data={this.props.data.invoiceStatus}
        colors='invoiceStatus'
        index={0}
        innerRadius='68%'
        outerRadius='90%'
        total={Utility.getCurrencyRepresentationOfAmount(
          this.props.data.totalInvoices
        )}
        datakey='count'
        showLabel={true}
        height={140}
      />
    );
  };

  renderPieChart = () => {
    return (
      <>
        {INVOICE_LEGENDS.length > 0 &&
          INVOICE_LEGENDS.map((legend, index) => {
            return this.renderLegend(
              PIE_CHART_COLORS[PIE_CHART_COLORS_CONST.INVOICE_STATUS][
                index
              ],
              legend,
              this.props.data.invoiceStatus[index].count
            );
          })}
      </>
    );
  };

  renderBarGraphComponent = () => {
    return (
      <BarGraphComponent
        data={this.props.MonthYearStrings}
        colorOfBar='#44883d'
      />
    );
  };

  render() {
    return (
      <div className='invoiceCharts'>
        <Row className='pb-2'>
          <Col md={6} className='borderRight'>
            <div className='BoldText'>{Strings("Invoices")}</div>
            {this.props.data.invoiceStatus.length > 0 ? (
              <Row>
                <Col md={5}>{this.renderPieChartComponent()}</Col>
                <Col md={6} className='noPadding align-self-center'>
                  {this.renderPieChart()}
                </Col>
              </Row>
            ) : (
              <div className='noRecordsFound noBranchRecords'>
                {Strings("NoData")}
              </div>
            )}
          </Col>
          <Col md={6} className='noPadding position-relative'>
            {this.props.MonthYearStrings.length > 0 ? (
              this.renderBarGraphComponent()
            ) : (
              <div className='noRecordsFound noBranchRecords'>
                {Strings("NoData")}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceCharts;