//Components
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

//Custom Components
import LoanAmountModal from "../LoanDetails/LoanAmountModal";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";

//Constants
import {
  SEARCH_FILTER_FIELDS,
  SEARCH_FILTER_TYPE,
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  DEFAULT_DURATION,
  REQUEST_STATUS,
  USER_ROLES,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  REQUEST_COLUMNS,
  EXISTING_MINIMART_DETAILS,
  NEW_MINIMART_DETAILS,
} from "./Constants";

// String
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class EditMinimartRequest extends Component {
  constructor(props) {
    super(props);
    this._columns = REQUEST_COLUMNS;
    this._secondRowFirstSection = EXISTING_MINIMART_DETAILS;
    this._secondRowSecondSection = NEW_MINIMART_DETAILS;
    this.state = {
      filters: {
        mid: "",
        st: "",
        fd: DEFAULT_DURATION.fromDate,
        td: DEFAULT_DURATION.toDate,
      },
      expanded: [],
      showLoadingModal: false,
      notesModal: false,
      //Pagination
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      readOnly: this.props.loggedInUser.role === USER_ROLES.salesManager
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(true);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.rows, this.props.rows)) {
      this.setState({
        rows: this.props.rows,
      });
    }
  }

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          mid: "",
          st: "",
          fd: DEFAULT_DURATION.fromDate,
          td: DEFAULT_DURATION.toDate,
        },
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        notesModal: false,
      },
      this.handleOnClickSearchFilter
    );
  };

  handleExpandClick = (id) => {
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        expanded: [],
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  renderList = () => {
    return (
      <div className='productListContainer'>
        {this.state.rows?.length > 0 ? (
          <>
            <CustomTableCard
              key={Math.random()}
              rows={this.state.rows}
              columns={this._columns}
              isSummary={false}
              isExpandable={true}
              handleExpandClick={this.handleExpandClick}
              expanded={this.state.expanded}
              MINIMART_REQUEST={true}
              secondRowFirstSection={this._secondRowFirstSection}
              secondRowSecondSection={this._secondRowSecondSection}
              handleUpdateRequest={this.handleUpdateRequest}
              downloadPhoto={this.props.downloadPhoto}
              handleViewCedulaHistory={this.handleViewCedulaHistory}
              warning={true}
              readOnly={this.state.readOnly}
            />
            {/* Pagination */}
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </>
        ) : (
          <div className='noRecordsFound'>
            {" "}
            {Strings("NoRecordsFound")}{" "}
          </div>
        )}
      </div>
    );
  };

  handleChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSearchDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.td)) {
          DateValue = this.state.filters.fd;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        this.setState({
          filters: {
            ...this.state.filters,
            fd: DateValue,
          },
        });
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.fd)) {
          DateValue = this.state.filters.td;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        this.setState({
          filters: {
            ...this.state.filters,
            td: DateValue,
          },
        });
        break;
      default:
    }
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    let searchFilterObject = {
      mid: this.state.filters.mid
        ? this.state.filters.mid
        : undefined,
      st: "RAISED",
      fd: this.state.filters.fd
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.fd)
        : undefined,
      td: this.state.filters.td
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.td)
        : undefined,
      lmt: isDefaultOffset
        ? DEFAULT_PAGINATION_RECORDS_COUNT
        : this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    };

    this.props.handleOnClickSearchFilter(searchFilterObject);
  };

  renderSearchFilter = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("minimartId"),
        handleOnChange: this.handleChange,
        Value: this.state.filters.mid,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        name: "mid",
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleSearchDateChange,
        fromDate: this.state.filters.fd,
        handleCalenderChange: this.handleSearchDateChange,
        toDate: this.state.filters.td,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
    ];

    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
      />
    );
  };

  handleUpdateRequest = (rows, request) => {
    this.setState(
      {
        reqObj: {
          id: request.id,
          mn: request.name,
          oid: request.oid,
          aid: request.aid,
          phno: request.phno,
          alph: request.alph,
          st: request.st,
          scr: request.scr,
          srt: request.srt,
          no: request.no,
          ref: request.ref,
          ct: request.ct,
        },
        rows,
      },
      this.toggleModal
    );
  };

  toggleModal = () => {
    this.setState({
      note: "",
      notesModal: !this.state.notesModal,
    });
  };

  addNote = (note = null) => {
    this.setState(
      {
        reqObj: {
          ...this.state.reqObj,
          nt: note ? note : null,
        },
        note,
      },
      () => {
        this.props.updateMinimartRequest(
          this.state.reqObj,
          this.toggleModal,
          this.handleOnClickSearchFilter
        );
      }
    );
  };

  handleViewCedulaHistory = (mid) => {
    this.props.handleViewCedulaHistory(mid, this.toggleCedulaModal);
  };

  toggleCedulaModal = () => {
    this.setState({
      showCedulaHistory: !this.state.showCedulaHistory,
    });
  };

  renderCedulaModal = () => {
    return (
      <LoanAmountModal
        open={this.state.showCedulaHistory}
        onModalClose={this.toggleCedulaModal}
        modalTitle={Strings("cedulaHistory")}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        loanAmountDivisionData={this.props.cedulaHistory}
        isLoanDetails={false}
        downloadPhoto={this.props.downloadPhoto}
      />
    );
  };

  render() {
    return (
      <div className='dailyStatus'>
        {this.state.showCedulaHistory && this.renderCedulaModal()}
        {this.props.errorMessage && (
          <Row className='errorText'>{this.props.errorMessage}</Row>
        )}
        {this.state.notesModal && (
          <NotesModal
            open={this.state.notesModal}
            note={this.state.note}
            modalHeader={
              this.state.reqObj.st === REQUEST_STATUS.rejected
                ? Strings("rejectionNote")
                : `${Strings("approvalNote")} (${Strings(
                  "Optional"
                )})`
            }
            toggleModal={this.toggleModal}
            isNotesCompulsory={
              this.state.reqObj.st === REQUEST_STATUS.rejected
            }
            maxLength={500}
            addNote={this.addNote}
          />
        )}

        <div className=' loanList container-fluid TabListContainer'>
          {this.renderSearchFilter()}
          {this.renderList()}
        </div>
      </div>
    );
  }
}

export default EditMinimartRequest;
