import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Constants
import { STATUS_SELECTION } from "../MotorcycleCatalog/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Css
import "./RewardCatalog.scss";

//Assets
import cameraImage from "../../resources/images/ic_update_profile_pic.svg";
import cross from "../../resources/images/ic_cross_blue.svg";
import defaultImg from "../../resources/images/ic_moto.png";

const renderRewardPhoto = (props) => {
  if (props.readOnly) {
    return props.reward.rpi !== undefined ? (
      <img
        src={Utility.getDecryptedData(props.reward.rpi)}
        alt={`${Strings("Image")}`}
        className='motorcyclePhoto backdrop'
      />
    ) : (
      <div className='backdrop'>
        <img
          src={defaultImg}
          alt={Strings("NoPhoto")}
          className='defaultImg'
        />
      </div>
    );
  } else {
    return props.reward.rpi ? (
      <div className='addOrUpdateImageDisplay'>
        <img
          src={
            Object.prototype.toString.call(props.reward.rpi) === //NOSONAR
              "[object File]"
              ? props.avatar
              : Utility.getDecryptedData(props.reward.rpi)
          }
          className='img-thumbnail w-100 h-100'
          alt=''
        />
        <img
          src={cross}
          className='crossIcon'
          onClick={props.handleRemoveImage}
          alt=''
        />
      </div>
    ) : (
      <div className='addOrUpdateImage backdrop'>
        <label htmlFor='uploadPhoto' className='browse-button '>
          <img
            src={cameraImage}
            className={"addOrUpdateImageCamera"}
            alt={Strings("UploadPhoto")}
          />
        </label>
        <input
          type='file'
          id='uploadPhoto'
          onChange={props.handleUploadPhoto}
          accept='image/png,image/jpeg,image/jpg'
          style={{ display: "none" }}
        />
      </div>
    );
  }
};

const RewardCatalogModal = (props) => {
  return (
    <Modal
      headerText={Strings("addRewardDetails")}
      open={props.modalOpen}
      onClose={() => props.handleModalClose()}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      center
      classNames={{ modal: "rewardDetailModal" }}
      autofocus
    >
      <Row className='px-0'>
        <div className='mx-0 motorcycleDetailModalHeader'>
          {Strings(props.headerText)}
        </div>
      </Row>
      {props.errorMessage && (
        <Row className='errorText pt-2'>{props.errorMessage}</Row>
      )}
      <form>
        <Col md={12} className="ps-3 pe-3">
          <Row>
            <TextFieldComponent
              id='name'
              type='text'
              className={props.readOnly ? "disableText" : "mt-3"}
              placeholder={`${Strings("ProductName")}*`}
              value={props.reward.rpn.replace(/\s+/g, " ")}
              handleChange={props.handleInputChnage}
              maxLength={50}
              disabled={props.readOnly}
              name={"rpn"}
            />
          </Row>
          <Row>
            <textarea
              id='details'
              className={
                props.readOnly
                  ? "disableText form-control"
                  : "mt-3 form-control"
              }
              rows={5}
              placeholder={Strings("Description")}
              value={props.reward.rpd || ""}
              onChange={props.handleInputChnage}
              name={"rpd"}
              disabled={props.readOnly}
            />
          </Row>
        </Col>
        <Row>
          <Col md={6}>
            <Row className='m-2 pe-2'>{renderRewardPhoto(props)}</Row>
          </Col>
          <Col md={6}>
            <Row>
              <TextFieldComponent
                id='category'
                type={props.readOnly ? "text" : "select"}
                className={props.readOnly ? "disableText" : "mt-3"}
                placeholder={`${Strings("status")}`}
                value={props.reward.rps}
                handleChange={props.handleInputChnage}
                disabled={props.readOnly}
                name={"rps"}
              >
                {!props.readOnly
                  ? STATUS_SELECTION.map((status) => {
                    return (
                      <option
                        value={status.value}
                        key={status.value}
                      >
                        {status.label}
                      </option>
                    );
                  })
                  : null}
              </TextFieldComponent>
            </Row>
            <Row>
              <TextFieldComponent
                id='model'
                type='text'
                className={props.readOnly ? "disableText" : "mt-3"}
                placeholder={`${Strings("ProductModel")}*`}
                value={props.reward.rpm.replace(/\s+/g, " ")}
                handleChange={props.handleInputChnage}
                maxLength={10}
                disabled={props.readOnly}
                name={"rpm"}
              />
            </Row>
            <Row>
              <TextFieldComponent
                id='cost'
                type='text'
                className={props.readOnly ? "disableText" : "mt-3"}
                placeholder={`${Strings("rewardPoints")}*`}
                value={props.reward.rp}
                handleChange={props.handleInputChnage}
                disabled={props.readOnly}
                onKeyDown={Utility.allowOnlyNumber}
                name={"rp"}
              />
            </Row>
          </Col>
        </Row>
        <Col md={12} className='buttonAlign pe-2'>
          <Button
            className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
            onClick={props.handleModalClose}
          >
            {Strings("Cancel")}
          </Button>

          {!props.readOnly && (
            <Button
              className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
              onClick={props.onSaveClick}
            >
              {Strings("Save")}
            </Button>
          )}
        </Col>
      </form>
    </Modal>
  );
};

export default RewardCatalogModal;
