import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utils
import Utility from "../../utils/Utility";

//Constants
import { MINIMART_STATUS } from "../../constants/appConstants";

class CedulaChangeModal extends React.Component {
  render() {
    return (
      <div>
        <Modal
          // Change visibility of modal based on props.
          open={this.props.open}
          //Modal opens with specified messages in props
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{ modal: "loanDetailModal" }}
          center
          focusTrapped={false}
        >
          <Row className={"loanDetailModal"}>
            <Col md={12} className='loanDetailModalHeader ps-3'>
              {this.props.headerText}
            </Col>
            {/* Show only specified props of modal */}
            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors p-0'>
                  {Strings(this.props.errorMessage)}
                </Row>
              )}
              {this.props.warningText && (
                <Row className='warningText p-0'>
                  {Strings(this.props.warningText)}
                </Row>
              )}
              <Row>
                <Col md={12} className="titleText mt-1">{Strings("identificationNumber")}</Col>
                <Col md={6}>
                  <TextFieldComponent
                    type='number'
                    name={'identificationNumber'}
                    value={this.props.changedClientInfo.identificationNumber}
                    placeholder={Strings("identificationNumber")}
                    placeholderClass='inputPlaceholderTop'
                    onKeyDown={Utility.allowOnlyNumber}
                    handleChange={this.props.handleEdit}
                    maxLength={11}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="titleText mt-2">{Strings("customerName")}</Col>
                <Col md={12}>
                  <TextFieldComponent
                    type='text'
                    name="customerName"
                    value={this.props.changedClientInfo.customerName}
                    placeholder={Strings("customerName")}
                    placeholderClass='inputPlaceholderTop'
                    handleChange={this.props.handleEdit}
                    maxLength={255}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="titleText mt-2">{Strings("minimartDetailsText")}</Col>
                {this.props.minimartDetails.map((minimart) => {
                  let checkBoxLabel = `${minimart.id} - ${minimart.name}`;
                  if (minimart.status === MINIMART_STATUS.BLOCKED) {
                    checkBoxLabel = `${minimart.id} - ${minimart.name} (${Strings(minimart.status)})`;
                  }
                  return <Col md={12} key={minimart.id}>
                    <CheckboxComponent
                      onChange={() => this.props.selectMatricesId(minimart.id)}
                      checked={this.props.selectedMatricesIds.includes(minimart.id)}
                      key={minimart.id}
                      label={checkBoxLabel}
                    />
                  </Col>
                })}
              </Row>
              <div className='loanDetailModalButton'>
                <Button
                  className={
                    "primaryModalButton buttonMinWidth marginCancelButton buttonBorder " +
                    this.props.modalButtonCancelStyle
                  }
                  onClick={this.props.modalButtonCancel.onClick}
                >
                  {this.props.modalButtonCancel.text}
                </Button>

                <Button
                  className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                  onClick={this.props.modalButtonOk.onClick}
                  disabled={this.props.warningText}
                >
                  {this.props.modalButtonOk.text}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default CedulaChangeModal;
