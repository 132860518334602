import React from "react";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button, Table } from "react-bootstrap";
import Utility from "../../utils/Utility";

//Components
import "./MinimartDetails.scss";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import {
  INVOICE_DATE_FORMAT,
  REQUEST_STATUS,
  CURRENCY,
} from "../../constants/appConstants";

class CreditLimitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
      requestData: props.requestData,
      readOnly: props.readOnly,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      open: nextProps.open,
      errorMessage: nextProps.errorMessage,
      requestData: nextProps.requestData,
      readOnly: nextProps.readOnly,
      isAssignRepayment: nextProps.isAssignRepayment,
    };
  }

  //to close modal
  closeModal = () => {
    this.props.modalButtonCancel.onClick();
  };

  render() {
    return (
      <div>
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{
            modal: "loanDetailModal",
          }}
          center
        >
          <Row className='loanDetailModal'>
            <Col md={12} className='loanDetailModalHeader ps-3'>
              {this.props.headerText}
            </Col>

            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors noPadding'>
                  {this.props.errorMessage}
                </Row>
              )}
              {this.state.requestData && (
                <Row
                  className={
                    this.state.requestData.status ===
                      REQUEST_STATUS.rejected
                      ? "errors bold noPadding"
                      : "success bold noPadding"
                  }
                >
                  {Strings(this.state.requestData.status)}
                </Row>
              )}
              {this.state.requestData && (
                <Row>
                  <Col md={2} className='titleText noPadding'>
                    {Strings("requester")}
                  </Col>
                  : &nbsp;
                  <Col md={3} className='detailText noPadding '>
                    {this.state.requestData.requester}
                  </Col>
                  <Col md={4} className='titleText'>
                    {Strings("RequestDate")}
                  </Col>
                  : &nbsp;
                  <Col md={2} className='detailText noPadding'>
                    {moment(
                      this.state.requestData.applicationDate
                    ).format(INVOICE_DATE_FORMAT)}
                  </Col>
                </Row>
              )}{" "}
              {this.state.requestData?.approver && (
                <Row>
                  <Col md={2} className='titleText noPadding'>
                    {Strings("approver")}
                  </Col>
                  : &nbsp;
                  <Col md={3} className='detailText noPadding'>
                    {this.state.requestData.approver}
                  </Col>
                  <Col md={4} className='titleText'>
                    {Strings("approvalDate")}
                  </Col>
                  : &nbsp;
                  <Col md={2} className='detailText noPadding'>
                    {moment(
                      this.state.requestData.approvalDate
                    ).format(INVOICE_DATE_FORMAT)}
                  </Col>
                </Row>
              )}
              {<hr className='newHr opacity-100' />}
              {this.state.requestData?.request && (
                <div>
                  <Table striped hover bordered condensed>
                    <thead className='header'>
                      <tr>
                        <td>{Strings("id")}</td>
                        <td>{Strings("minimartId")}</td>
                        <td>{Strings("applicationId")}</td>
                        <td>{Strings("loanType")}</td>
                        <td>{Strings("amount")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.requestData.referenceId}</td>
                        <td>
                          {
                            this.state.requestData.request
                              .minimartCode
                          }
                        </td>
                        <td>
                          {
                            this.state.requestData.request
                              .loanApplicationId
                          }
                        </td>
                        <td>
                          {Strings(
                            this.state.requestData.request.creditType
                          )}
                        </td>
                        <td>
                          {CURRENCY +
                            " " +
                            Utility.getCurrencyRepresentationOfAmount(
                              this.state.requestData.request.amount
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              <Row className='subHeaderApplication addOrUpdateUserFontSize'>
                <Col md={{ span: 12 }} className='ps-0 pe-3'>
                  {Strings("requester") + " " + Strings("Notes")}
                  <Row className='addOrUpdateUserExtraMargin'>
                    <Col md={{ span: 8 }} className='noPadding'>
                      <textarea
                        id='Notes'
                        type='text'
                        className='noMargin'
                        placeholder={Strings("Notes")}
                        maxLength={500}
                        value={this.state.requestData?.request?.note}
                        disabled={true}
                        cols={51}
                        rows={5}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.requestData && (
                <Row className='subHeaderApplication addOrUpdateUserFontSize'>
                  <Col md={{ span: 12 }} className='ps-0 pe-3'>
                    {Strings("approver") + " " + Strings("Notes")}
                    <Row className='addOrUpdateUserExtraMargin'>
                      <Col md={{ span: 8 }} className='noPadding'>
                        <textarea
                          id='approver'
                          type='text'
                          className='noMargin'
                          placeholder={
                            Strings("approver") +
                            " " +
                            Strings("Notes")
                          }
                          value={
                            this.state.requestData.approval?.note
                          }
                          disabled={
                            this.state.requestData.approval
                              ? true
                              : false
                          }
                          onChange={this.props.onApprovalNotesChange}
                          maxLength={500}
                          cols={51}
                          rows={5}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <div className='loanDetailModalButton'>
                {this.props.modalButtonCancel && (
                  <Button
                    className={
                      "primaryModalButton buttonMinWidth marginCancelButton buttonBorder " +
                      this.props.modalButtonCancelStyle
                    }
                    onClick={this.closeModal}
                  >
                    {this.props.modalButtonCancel.text}
                  </Button>
                )}

                {this.state.requestData && (
                  <Button
                    className='primaryModalButton marginCancelButton rejectButtonBackground buttonMinWidth'
                    onClick={() =>
                      this.props.modalButtonReject.onClick(
                        REQUEST_STATUS.rejected
                      )
                    }
                    disabled={
                      this.state.requestData.status ===
                      REQUEST_STATUS.approved ||
                      this.state.requestData.status ===
                      REQUEST_STATUS.rejected
                    }
                  >
                    {this.props.modalButtonReject.text}
                  </Button>
                )}
                {this.state.requestData && (
                  <Button
                    className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                    onClick={() =>
                      this.props.modalButtonApprove.onClick(
                        REQUEST_STATUS.approved
                      )
                    }
                    disabled={
                      this.state.requestData.status ===
                      REQUEST_STATUS.approved ||
                      this.state.requestData.status ===
                      REQUEST_STATUS.rejected
                    }
                  >
                    {this.props.modalButtonApprove.text}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default CreditLimitModal;
